// React
import React from "react";

// Styles
import "./Banner.css";

// Components
import Banner from "./Banner";

const BannerAoVivo = ({ link, urlBackground, ...dados }) => {
  return (
    <Banner
      className="edge-bottom"
      link={link}
      urlBackground={urlBackground}
    >
      <h1 className="banner__info banner__info--subtitle text-center">
        {dados.campeonato}
      </h1>

      <h1 className="banner__info banner__info--title text-center">
        {dados.casa} <span className="banner__info--vs">vs.</span> {dados.fora}
      </h1>

      <h1 className="banner__info banner__info--subtitle text-center">
        {dados.momento}
      </h1>
    </Banner>
  );
};

export default BannerAoVivo;
