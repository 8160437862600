// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Bolao/BolaoActions";

// React Router
import { withRouter } from "react-router-dom";

// Libs
import moment from "moment";

// Helpers
import { convertIntegerToMoney } from "../../../modules/helpers";

// Components
import CardEventoBolao from "./CardEventoBolao";
import BolaoRanking from "../../Modal/Bolao/BolaoRanking";

class BolaoDetalhe extends Component {
  openModalRanking(idBolao) {
    this.context.openModal(
      "Ranking Bolão",
      <BolaoRanking idBolao={idBolao} />,
      "large"
    );
  }

  //Informações sobre o tipo bolao Multiplos
  infosdeBoloesMultiplos= () => {

    const { maxCombinacoesDuplas, maxCombinacoesTriplas, tipo } = this.props.bolaoReducer.detalhe;

    if(tipo === 3){
        return(
          <div className="card-content">
            <div className="row">
              <div className="small-8 columns">
                <h3 className="text-title">Colunas Múltiplas</h3>
              </div>
              <div className="small-8 columns">
                <h3 className="text-title"> Máxima de apostas Duplas:{ maxCombinacoesDuplas ? maxCombinacoesDuplas : 0 }</h3>
              </div>
              <div className="small-8 columns">
                <h3 className="text-title"> Máxima de apostas Triplas: { maxCombinacoesTriplas ? maxCombinacoesTriplas : 0}</h3>
              </div>
            </div>
          </div>
        );
      }
  }

  render() {
    const { id, nome, inicio, fim, vlrCota, campeonatos } = this.props.bolaoReducer.detalhe;
    const rankingProps = {
      nome, inicio, fim, vlrCota,
      onClick: () => this.openModalRanking(id)
    };
    
    return (
      <div className="inner-row-wrapper">
        <div className="row">
          <div className="small-24 columns">
            <Ranking {...rankingProps} />
            {this.infosdeBoloesMultiplos()}
            {
              campeonatos.map((campeonato, index) => (
                <Campeonato key={index} campeonato={campeonato} />
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

BolaoDetalhe.contextTypes = {
  openModal: PropTypes.func
};

const Ranking = ({ inicio, fim, nome, vlrCota, onClick }) => (
  <div className="ranking" onClick={onClick}>
    <div className="row align-middle">
      <div className="small-4 medium-5 large-4 text-center columns">
        <p className="ranking__periodo text-subheader light-secondary">
          { moment(inicio).format("DD/MM") }
          <br className="show-for-small-only"/>
          <span className="show-for-medium"> - </span>
          { moment(fim).format("DD/MM") }
        </p>
      </div>
      <div className="small-12 medium-14 large-16 columns">
        <p className="text-title light-default">{ nome }</p>
      </div>
      <div className="small-8 medium-5 large-4 columns text-right">
        <p className="text-title accent-1">
          <span className="ranking__cota-valor">
            R$ { convertIntegerToMoney(vlrCota) }
          </span>
          <span className="icon icon-ranking-tab ranking__cota-icon"></span>
        </p>
      </div>
    </div>
  </div>
);

const Campeonato = ({ campeonato }) => (
  <div>
    <h2 className="text-title accent-1 edge-bottom edge-top">
      <span>{ campeonato.pais }</span>
      <span className="light-disabled icon-seta-big icon-fix" />
      <span>{ campeonato.nome }</span>
    </h2>
    {
      campeonato.momentos.map((momento, index) => (
        <Momento key={index} momento={momento} />
      ))
    }
  </div>
);

const Momento = ({ momento }) => (
  <div>
    <p className="text-subheader light-default edge-bottom edge-top">
      { moment(momento.data).format("dddd, D [de] MMMM [de] YYYY") }
    </p>
    {
      momento.eventos.map((evento, index) => (
        <CardEventoBolao key={index} evento={evento} />
      ))
    }
  </div>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { bolaoReducer: state.bolaoReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BolaoDetalhe)
);
