// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/ModalidadesEventosAoVivo/ModalidadesEventosAoVivo";
import { initPrivateStream } from '../../../reducers/privateStream/privateStream';


// React Router
import { Link, withRouter } from "react-router-dom";

// Helpers
import { popupCenter } from "../../../modules/helpers";
import classnames from 'classnames';

// Components
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import Modalidade from "./Modalidades/Modalidade";
import Aviso from "../../Common/Aviso/Aviso";
import Card from "../../Common/Card/Card";
import CampoAoVivo from "./CampoAoVivo";
import StreamPlayer from "../../Common/StreamPlayer/StreamPlayer";

const ModalidadesEventos = ({modalidadesEventos, idCampeonato,recarregarEventos}) => {
    if(typeof modalidadesEventos === "undefined" || !modalidadesEventos || modalidadesEventos.length <=0){

      return (
        <div className="row">
          <div className="small-24 columns">
            <Aviso type="secondary" className="edge-top">
              Evento bloqueado temporariamente. Atualização automática em 20 segundos...
            </Aviso>
          </div>
        </div>
      )
    }else{

      return (
        <div className="row">
          <div className="small-24 columns">
            {
              modalidadesEventos.map((modalidade, index) => (
                <Modalidade
                  key={index}
                  modalidade={modalidade}
                  idCampeonato={idCampeonato}
                  eventoAoVivo={true}
                />
              ))
            }
          </div>
        </div>
      )
    }


}

const TitleHeader = ({ casa, fora, periodo, tempo, placar }) => (
  <div className="row align-middle">
    <div className="small-4 medium-3 large-2 columns">
      <div className="card-evento__badge card-evento__badge--small show-for-small-only text-center">
        <span className="text-subheader light-secondary">
          {periodo === "Não Iniciado" ? " " : periodo === "Intervalo" ? "INT " : periodo === "2º tempo" ? "2T " : "1T "}
        </span>
        <span className="text-subheader light-secondary">
          {(tempo === "0" || tempo == null || typeof tempo === 'undefined') && periodo !== "Intervalo" ?
            tempo : periodo === "Intervalo" ?
              " " : tempo + "'"}
        </span>
      </div>
      <div className="card-evento__badge card-evento__badge--medium show-for-medium text-center">
        <span className="text-subheader light-secondary">
          {periodo === "Não Iniciado" ? " " : periodo === "Intervalo" ? "INT " : periodo === "2º tempo" ? "2T " : "1T "}
        </span>
        <span className="text-subheader light-secondary">
          {(tempo === "0" || tempo == null || typeof tempo === 'undefined') && periodo !== "Intervalo" ?
            tempo : periodo === "Intervalo" ?
              " " : tempo + "'"}
        </span>
      </div>
    </div>
    <div className="small-20 medium-21 large-22 columns">
      <h3 className="card-evento__title text-title light-default">
        {/* Small - Placar da Casa ao lado esquerdo */}
        <span className="show-for-small-only">
          <span className="accent-1">{placar.split(":")[0]}</span> &nbsp;
        </span>

        { casa }

        {/* Large - Placar da Casa ao lado direito */}
        <span className="hide-for-small-only">
          <span className="accent-1">&nbsp;{placar.split(":")[0]}&nbsp;</span>
        </span>

        {/* Small - Quebra de linha entre os times */}
        <br className="show-for-small-only" />

        {/* Large - Placar do Fora ao lado esquerdo com vs. */}
        <span className="hide-for-small-only">
          <span className="text-caption light-disabled">vs.&nbsp;</span>
          <span className="accent-1">{placar.split(":")[1]}&nbsp;</span>
        </span>

        {/* Large - Placar do Fora ao lado esquerdo */}
        <span className="show-for-small-only">
          <span className="accent-1">{placar.split(":")[1]}</span> &nbsp;
        </span>

        { fora }
      </h3>
    </div>
  </div>
);

const Title = ({ title, placar, periodo, tempo }) => (
  <div className="row align-middle">
    <div className="small-24 columns">
      <Card
        className="card-no-content"
        header={
          <TitleHeader
            casa={title.split(" x ")[0]}
            fora={title.split(" x ")[1]}
            periodo={periodo}
            tempo={tempo}
            placar={placar}
          />
        }
      />
    </div>
  </div>
);

class SubEventosAoVivo extends Component {

  componentWillMount() {
    if (!this.props.permissaoAoVivo) {
      this.props.history.push("/");
    }

    this.props.initModalidadesEventosAoVivo();
    this.props.initPrivateStream();
    this.props.fetchModalidadesEventosAoVivo(this.props.match.params.idEvento,this.props.match.params.idCampeonato)
    if(this.props.usuarioLogado) {
      this.interval = setInterval(() => this.props.fetchModalidadesEventosAoVivo(this.props.match.params.idEvento,this.props.match.params.idCampeonato), 20000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.props.initModalidadesEventosAoVivo();
    this.props.initPrivateStream();
  }

  renderSubEventoAoVivo = (nomeEvento,placar,modalidadesEventos,idCampeonato,periodo,tempo,recarregarEventos) => {
    return (
      <div className="inner-row-wrapper">
        <Title title={nomeEvento}  placar={placar} periodo={periodo} tempo={tempo}/>
        <ModalidadesEventos
          modalidadesEventos={modalidadesEventos}
          idCampeonato={idCampeonato}
          recarregarEventos={recarregarEventos}
        />
      </div>
    );
  }

  recarregarEventos = () => {
    this.props.fetchModalidadesEventosAoVivo(this.props.match.params.idEvento,this.props.match.params.idCampeonato);
  }

  renderAvisos = () => {
    const { aviso } = this.props.modalidadesEventosAovivoReducer;

    return (
      <div className="inner-row-wrapper">
        <div className="row">
          <div className="small-24 columns">
            <Link to={{pathname: "/aovivo"}}>
              <h1 className="text-display light-default">Voltar </h1>
            </Link>
          </div>
        </div>
        <Aviso className="edge-top" type={aviso.type}> { aviso.text } </Aviso>
      </div>
    )
  }

  popupEstatisticas = () => {
    const { idEvento } = this.props.match.params;
    popupCenter(`/estatisticas/${idEvento}/true`, "Estatísticas", 1000, 800);
  }

  render() {
    const {
      modalidades,
      idCampeonato,
      isFetching,
      nomeEvento,
      placar,
      periodo,
      tempoDecorridoMin,
      aviso,
      matchID,
      infoExtraEvento
    } = this.props.modalidadesEventosAovivoReducer;

    const { usuarioLogado, saldoUsuario, errorCountStreamUrl } = this.props;
    const exibirCampoAoVivo = matchID && this.props.exibirCampoLive;
    const exibirEstatisticas = this.props.exibirEstatisticas ? true : false;

    const exibirStream =
      errorCountStreamUrl < 5 &&
      (Object.keys(infoExtraEvento.liveStream || {}).length > 0 ||
        Object.keys(infoExtraEvento.tvChannel || {}).length > 0);

    /**********************************************************************
    * OBS: ISSO SERÁ REMOVIDO QUANDO O GATEWAY FIZER O AJUSTE DO SUBEVENTO
    * "Vencedor Encontro + Ambas Marcam" QUE ESTÁ QUEBRANDO O LAYOUT.
    **********************************************************************/
    let modalidadesFiltradas = [];
    if(modalidades != null){
      modalidadesFiltradas = modalidades.filter(modalidade => {
        return modalidade.titulo !== "Vencedor Encontro + Ambas Marcam";
      });
    }else{
      modalidadesFiltradas = [];
    }
    /**********************************************************************/

    return (
      <div>
        <div className="row align-middle edge-bottom">
          <div className="small-20 columns">
            <Link to={{ pathname: "/aovivo" }} className="subevento-voltar text-title light-secondary">
              <span className="subevento-voltar__icon icon-seta-voltar icon-fix"></span>
              <span className="subevento-voltar__text">Esportes Ao Vivo</span>
            </Link>
          </div>
          {
            exibirEstatisticas &&
            <div className="small-4 columns text-right">
              <span
                onClick={this.popupEstatisticas}
                className="icon-ranking icon-fix subevento-estatisticas"
              />
            </div>
          }
        </div>
        <div className="main">
          <div className="inner-row-wrapper">
            <div className={classnames('telas-aovivo', {'telas-divididas': exibirStream})}>
            {exibirCampoAoVivo && <CampoAoVivo matchID={matchID} collapsed={exibirStream}/> }
            {exibirStream && <StreamPlayer 
              idEvento={this.props.match.params.idEvento}
              usuarioLogado={usuarioLogado}
              usuarioTemSaldo={saldoUsuario > 0}
              // openModalLogin={() => {
              //   this.props.openModal({ title: formatMessage(globalMessages.login), modalType: 'LOGIN' });
              // }}
            />}
            </div>
            { typeof aviso !== "undefined" ? this.renderAvisos() : isFetching || isFetching == null ?  <LoaderCarregando className="edge-top" /> :
              this.renderSubEventoAoVivo(nomeEvento,placar,modalidadesFiltradas.length<=0 ? [] : modalidadesFiltradas,idCampeonato,periodo,tempoDecorridoMin,this.recarregarEventos)
            }
          </div>
        </div>
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    modalidadesEventosAovivoReducer: state.modalidadesEventosAoVivoReducer,
    carrinho: state.carrinho,
    permissaoAoVivo: state.permissoesReducer.permissoes.aoVivo,
    exibirCampoLive: state.permissoesReducer.configuracoes.exibirCampoLive,
    exibirEstatisticas: state.permissoesReducer.configuracoes.exibirEstatisticas,
    errorCountStreamUrl: state.privateStream.erroCount,
    usuarioLogado: state.usuarioReducer.logado,
    saldoUsuario: state.usuarioReducer.usuario.configuracao.saldo
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions, initPrivateStream}, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubEventosAoVivo)
);
