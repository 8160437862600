// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Components
import InputCelular from "../../Common/Input/InputCelular";
import InputTextPrimary from "../../Common/Input/InputTextPrimary";
import ButtonPrimary from "../../Common/Button/ButtonPrimary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import LoginLoader from "./LoginLoader";
import Cadastro from "../../Modal/Cadastro/Cadastro";
import Download from "../../../static/svg/Download.js";
import Sms from "../../../static/svg/Sms.js";

// Helpers
import { getDataMetadaContato } from "../../../modules/helpers";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.loginPorTelefone = props.loginPorTelefone;

    this.state = {
      usuario: "",
      senha: "",
      formValido: false
    };
  }

  renderButtonLogin (isFetching) {
    if (isFetching) {
      return (
        <ButtonPrimary style={{ padding: "0.25rem" }} disabled>
          <LoginLoader />
        </ButtonPrimary>
      );
    }

    return (
      <ButtonPrimary
        disabled={!this.state.formValido}
        setDisabled={!this.state.formValido}
        type="submit">
        Login
      </ButtonPrimary>
    );
  }

  openModalCadastro = (e) => {
    e.preventDefault();
    this.context.openModal(
      "Cadastro",
      <Cadastro />,
      "large"
    );
  }

  renderLoginInput = () => {
    const { error } = this.props;

    if (this.loginPorTelefone) {
      return (
        <InputCelular
          name="usuario"
          type="primary"
          error={error}
          onChange={this._onChange}
        />
      );
    }

    return (
      <InputTextPrimary
        name="usuario"
        placeholder="USUÁRIO"
        style={{ textTransform: "none" }}
        error={error}
        value={this.state.usuario}
        onChange={this._onChange}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );
  }

  _onChange = (e) => {
    const state = { ...this.state };

    if (e.target.type === "tel") {
      state[e.target.name] = e.target.value.replace(/\D/g, "");
    }
    else {
      state[e.target.name] = e.target.value;
    }

    // Verifica se todos os campos foram preenchidos
    if (this.loginPorTelefone) {
      state.formValido = (
        state.usuario !== "" &&
        state.usuario.length === 11 &&
        state.senha !== ""
      );
    }
    else {
      state.formValido = (
        state.usuario !== "" &&
        state.senha !== ""
      );
    }

    this.setState(state);
  }

  render () {
    const { onSubmit, linkAplicativo, isFetching, error, contatos } = this.props;

    return (
      <form
        className="row show-for-large edge-top"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(this.state.usuario, this.state.senha);
        }}
      >
        <div className="large-4 columns text-right">
          {contatos.length > 0 &&
            <a href={getDataMetadaContato(contatos, 1)} style={{ marginRight: 7 }}>
              <Sms />
            </a>
          }
          {!!linkAplicativo &&
            <a href={linkAplicativo}>
              <Download />
            </a>
          }
        </div>
        <div className="small-9 large-6 columns">
          {this.renderLoginInput()}
        </div>
        <div className="small-9 large-6 columns">
          <InputTextPrimary
            name="senha"
            type="password"
            placeholder="Senha"
            error={error}
            onChange={this._onChange}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
        <div className="small-3 large-3 columns">
          {this.renderButtonLogin(isFetching)}
        </div>
        <div className="small-3 large-5 columns">
          <ButtonSecondary onClick={this.openModalCadastro}>
            Cadastrar
          </ButtonSecondary>
        </div>
      </form>
    );
  }
}

LoginForm.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    loginPorTelefone: state.permissoesReducer.configuracoes.loginPorTelefone,
    linkAplicativo: state.permissoesReducer.configuracoes.linkAplicativo,
    contatos: state.permissoesReducer.configuracoes.contatos,
  }
};

export default connect(mapStateToProps)(LoginForm);
