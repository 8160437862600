// React
import React, { Component } from "react";

// Styles
import "./Input.css";

// Components
import MaskedInput from "react-text-mask";

export default class InputSMS extends Component {
  render() {
    const {
      name,
      type,
      placeholder,
      onChange,
      onClickReenviar,
      className,
      disabled
    } = this.props;

    let classNames = "input-text text-title";
    classNames += `${type === "primary" ? " input-text--primary dark-default" : " input-text--secondary"}`;
    classNames += ` ${className && className}`;

    // Permite caracteres alfanuméricos
    const regExp = /[a-zA-Z0-9]/;

    return (
      <div className="input-sms">
        <MaskedInput
          name={name}
          className={classNames}
          type="tel"
          mask={[regExp, regExp, regExp, regExp]}
          placeholder={placeholder || "Código"}
          onChange={onChange}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
        {
          onClickReenviar && (
            <button
              className="input-sms__reenviar"
              type="button"
              title="Enviar Código Novamente"
              disabled={disabled || false}
              onClick={onClickReenviar}
            >
              <span
                className={`icon-atualizar
                  ${type === "primary" ? " dark-default" : " light-secondary"}`
                }
              >
              </span>
            </button>
          )
        }
      </div>
    );
  }
};
