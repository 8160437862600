// React
import React from "react";
import {typeEventoAoVivo} from "../EventosTypes";

export default ({ pais, campeonato,type="" }) => (
  <h2 className={type === typeEventoAoVivo ? "text-title accent-1 edge-bottom edge-top" : "text-title accent-1 edge-top"}>
    <span>{ pais }</span>
    <span className="light-disabled icon-seta-big icon-fix" />
    <span>{ campeonato }</span>
  </h2>
);
