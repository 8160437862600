// React
import React from "react";

const SideNavItem = ({
  contentLeft,
  contentRight,
  active,
  onClick,
  children,
  tipoNavItem
}) => (
  <li className="sidenav-list__item">
    <div
      onClick={onClick}
      className={
        "sidenav-list__item-content " + tipoNavItem +
        (active ? " sidenav-list__item-content--active" : "")
      }
    >
      <span
        className="sidenav-list__item-content-left text-subheader light-default"
      >
        { contentLeft }
      </span>
      {/* Só renderiza se houver conteúdo à direita */}
      {
        contentRight &&
        <span className="sidenav-list__item-content-right text-caption light-disabled">
          { contentRight }
        </span>
      }
    </div>
    { children }
  </li>
);

export default SideNavItem;
