// Action Types
import {
  INIT_SAQUES,
  REQUEST_SAQUE,
  RECEIVE_SAQUE,
  SUCCESS_SAQUE,
  ERROR_SAQUE,
  RECEIVE_SAQUES_ANTERIORES,
  RECEIVE_INFO_SAQUE
} from "../ActionTypes";

// Modules
import { doOdinRequest } from "../../modules/request";
import { splitCodigo } from "../../modules/helpers";

export const initSaques = () => ({
  type: INIT_SAQUES
});

export const requestSaque = () => ({
  type: REQUEST_SAQUE
});

export const receiveSaque = (bilhetes) => ({
  type: RECEIVE_SAQUE,
  bilhetes
});

export const successSaque = (success) => ({
  type: SUCCESS_SAQUE,
  success
});

export const errorSaque = (error) => ({
  type: ERROR_SAQUE,
  error
});

export const receiveInfoSaque = (info) => ({
  type: RECEIVE_INFO_SAQUE,
  ...info
});

export const fetchSaque = (valor) => (dispatch, getState) => {
  dispatch(requestSaque());

  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/sp/saque/solicitar/",
    method: "POST",
    accessToken,
    data: {
      valorSaque: valor
    }
  };

  return doOdinRequest(params)
    .then(response => {
      let mensagem = "Solicitação de Saque Efetivada. ";
      mensagem += `Código de Autorização: ${splitCodigo(response.mensagem, 4)}`;
      dispatch(successSaque(mensagem));
      dispatch(receiveSaque());
    })
    .catch(error => {
      dispatch(errorSaque(error));
      dispatch(receiveSaque());
    });
};

// Listagem das solicitações
export const receiveSaquesAnteriores = (saques) => ({
  type: RECEIVE_SAQUES_ANTERIORES,
  saques
});

export const fetchSaquesAnteriores = () => (dispatch, getState) => {
  dispatch(requestSaque());

  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/sp/saque/listar/solicitacao",
    method: "GET",
    accessToken
  };

  return doOdinRequest(params)
    .then(response => {
      if (response.codResposta !== "000") {
        dispatch(errorSaque(response.mensagem));
      }
      else {
        dispatch(receiveSaquesAnteriores(response.solicitacaoSaque));
      }

      dispatch(receiveSaque());
    })
    .catch(error => {
      dispatch(errorSaque(error));
      dispatch(receiveSaque());
    });
};

export const fetchSaqueDisponivel = () => (dispatch, getState) => {
  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/sp/saque",
    method: "GET",
    accessToken
  };

  return doOdinRequest(params)
    .then(response => {
      dispatch(receiveInfoSaque({...response, hasInfo: true }));
    })
    .catch(error => {
      dispatch(receiveInfoSaque({ hasInfo: false }));
    });
};
