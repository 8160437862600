import {
  INIT_BOLAO_DETALHE,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  LIMPAR_CARRINHO_BOLAO,
  REQUEST_BOLAO_DETALHE,
  RECEIVE_BOLAO_DETALHE,
  ERROR_BOLAO_DETALHE
} from "../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: "",
  id: 0,
  nome: "",
  tipo: 0,
  inicio: 0,
  fim: null,
  vlrCota: 0,
  campeonatos: [],
  countTripla: 0,
  countDupla: 0,
  countCombinacoes: 0,
  maxCombinacoes: 0,
  maxCombinacoesDuplas: 0,
  maxCombinacoesTriplas: 0,
  totalEvento: 0,
};

export default function bolaoDetalhe(state = initialState, action) {
  switch (action.type) {
    case INIT_BOLAO_DETALHE:
      return initialState;

    // Ativa o evento ao adicionar no carrinho
    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA:
    {
      const campeonatos = [ ...state.campeonatos ];

      campeonatos.some(campeonato => {
        return campeonato.momentos.some(momento => {
          return momento.eventos.some(evento => {
            if (evento.id === action.idEvento) {
              evento.opcoes.forEach(opcao => {
                if (opcao.idTipoResultado === action.idTipoResultado) {
                  opcao.active = true;
                }
                else {
                  opcao.active = false;
                }
              });

              return true;
            }

            return false;
          });
        });
      });

      return {
        ...state,
        campeonatos
      };
    }

    // Desativa o evento ao adicionar no carrinho
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA:
    {
      const campeonatos = [ ...state.campeonatos ];

      campeonatos.some(campeonato => {
        return campeonato.momentos.some(momento => {
          return momento.eventos.some(evento => {
            if (evento.id === action.idEvento) {
              return evento.opcoes.some(opcao => {
                if (opcao.idTipoResultado === action.idTipoResultado) {
                  opcao.active = false;
                  return true;
                }

                return false;
              });
            }

            return false;
          });
        });
      });

      return {
        ...state,
        campeonatos
      };
    }

    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
    {

      const campeonatos = [...state.campeonatos];
      
      campeonatos.some(campeonato => {
        return campeonato.momentos.some(momento => {
          return momento.eventos.some(evento => {
            if (evento.id === action.idEvento) {
              evento.opcoes.forEach(opcao => {
                if (opcao.idTipoResultado === action.idTipoResultado) {
                  switch (evento.contadorTrue) {
                    case 0: // se for criar simples
                    opcao.active = true // se oção está ativa
                    evento.contadorTrue = evento.contadorTrue ? evento.contadorTrue + 1 : 1;
                    break;
                    case 1: // se for criar dupla
                      if ((state.maxCombinacoes ? state.maxCombinacoes : 0) - state.countCombinacoes === 0) {
                        return
                      }
                      state.countCombinacoes++
                      state.countDupla++;
                      opcao.active = true // se oção está ativa
                      evento.contadorTrue = evento.contadorTrue + 1;
                      break;
                    case 2: // se for criar tripla
                      if (state.countTripla === (state.maxCombinacoesTriplas ? state.maxCombinacoesTriplas : 0)) {
                        return
                      }
                      state.countTripla++;
                      state.countDupla--;
                      opcao.active = true // se oção está ativa
                      evento.contadorTrue = evento.contadorTrue + 1;
                      break;
                    default:
                      break
                  }
                }
              });
              return true;
            }
            return false;
          });
        });
      });

      return {
        ...state,
        campeonatos
      };
    }

    // Desativa o bolao selecionado para ser adiciona no carrinho
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
    {
      const campeonatos = [...state.campeonatos];
      campeonatos.some(campeonato => {
        return campeonato.momentos.some(momento => {
          return momento.eventos.some(evento => {
            if (evento.id === action.idEvento) {
              return evento.opcoes.forEach(opcao => {
                if (opcao.idTipoResultado === action.idTipoResultado) {
                  switch (evento.contadorTrue) {
                    case 3: // se for desativa uma de 3 
                    opcao.active = false 
                    evento.contadorTrue = evento.contadorTrue - 1;
                    state.countTripla--;
                    state.countDupla++;
                    break
                    case 2: // se for desativada duas   
                      opcao.active = false 
                      evento.contadorTrue = evento.contadorTrue - 1;
                      state.countCombinacoes--;
                      state.countDupla--;
                      break;

                    case 1: // se for desativada uma
                      opcao.active = false
                      evento.contadorTrue = evento.contadorTrue - 1;
                      break;
                    
                    default:
                        return
                  }
                }
                return false;
              });
            }
            return false;
          });
        });
      });

      return {
        ...state,
        campeonatos
      };
    }

    case LIMPAR_CARRINHO_BOLAO:
    {
      // Retorna mesmo estado se for palpite
      if (state.tipo === 1) return state;

      const campeonatos = [ ...state.campeonatos ];

      campeonatos.forEach(campeonato => {
        campeonato.momentos.forEach(momento => {
          momento.eventos.forEach(evento => {
            evento.contadorTrue = 0; //Para zerar o contador de opções selecionadas ao limpar o carrinho
            evento.opcoes.forEach(opcao => {
              opcao.active = false;
            });
          });
        });
      });

      state.countDupla =
      state.countTripla = 0;
      state.countCombinacoes = 0; //limpando o contador de triplas no bolao

      return {
        ...state,
        campeonatos,
      };
    }

    case REQUEST_BOLAO_DETALHE:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_BOLAO_DETALHE:
      return {
        ...state, //copiando o state
        isFetching: false,
        aviso: "",
        id: action.id,
        nome: action.nome,
        tipo: action.tipo,
        inicio: action.inicio,
        fim: action.fim,
        vlrCota: action.vlrCota,
        campeonatos: action.campeonatos,
        maxCombinacoes: action.maxCombinacoes,
        maxCombinacoesTriplas: action.maxCombinacoesTriplas,
        maxCombinacoesDuplas: action.maxCombinacoesDuplas,
      };

    case ERROR_BOLAO_DETALHE:
      return {
        ...state,
        isFetching: false,
        aviso: action.error
      };

    default:
      return state;
  }
};

export const initBolaoDetalhe = () => ({
  type: INIT_BOLAO_DETALHE,
});