import { INIT_COMPROVANTES, ADICIONAR_COMPROVANTE, LOGOUT } from "../../actions/ActionTypes.js";

const initialState = {
  comprovantes: []
}

export default function comprovantesReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_COMPROVANTES:
      return {
        comprovantes: action.comprovantes
      };

    case ADICIONAR_COMPROVANTE:
      return {
        comprovantes: [
          ...state.comprovantes,
          action.comprovante
        ]
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};
