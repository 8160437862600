import { ADICIONAR_ITEM_CARRINHO, REMOVER_ITEM_CARRINHO } from "../../actions/ActionTypes.js";

export default function itens(state = [], action) {
  switch (action.type) {
    case ADICIONAR_ITEM_CARRINHO:
    {
      // Checa se já existe algum item do mesmo evento
      const index = state.findIndex(item => item.idEvento === action.idEvento);

      if (index > -1) {
        return [
          ...state.slice(0, index),
          item(undefined, action),
          ...state.slice(index + 1)
        ];
      }

      return [
        ...state,
        item(undefined, action)
      ];
    }

    case REMOVER_ITEM_CARRINHO:
    {
      const index = state.findIndex(item =>
        item.subevento.id === action.subevento.id &&
        item.subevento.idOpcao === action.subevento.idOpcao
      );

      return [
        ...state.slice(0, index),
        ...state.slice(index + 1)
      ];
    }

    default:
      return state;
  }
};

function item(state = {}, action) {
  switch (action.type) {
    case ADICIONAR_ITEM_CARRINHO:
      return {
        idCampeonato: action.idCampeonato,
        idEvento: action.idEvento,
        casa: action.casa,
        fora: action.fora,
        subevento: action.subevento,
        eventoAoVivo: action.eventoAoVivo
      };

    default:
      return state;
  }
};
