// React
import React, { Component } from "react";

// React Router
import { withRouter } from "react-router-dom";

// Componentes
import Nav from "../../Nav/Nav";
import Header from "../../Header/Header";
import Promocoes from "../../Promocoes/Promocoes";

class AppPromocoes extends Component {
  render() {
    return (
      <div>
        <Header />
        <Nav />
        <div className="row-wrapper">
          <div className="row">
            <Promocoes />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AppPromocoes);
