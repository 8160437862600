// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Bolao/BolaoActions";

// Styles
import "./Bolao.css";

// Libs
import moment from "moment";

// Helpers
import {
  formatOdinDateToDateJs,
  convertIntegerToMoney,
  convertFloatToMoney
} from "../../../modules/helpers";

// Components
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import Aviso from "../../Common/Aviso/Aviso";
import Card from "../../Common/Card/Card";

class BolaoRanking extends Component {
  componentWillMount() {
    this.props.fetchRankingBolao(this.props.idBolao);
  }

  renderAviso(aviso) {
    return (
      <Aviso type="error">
        {aviso}
      </Aviso>
    );
  }

  render() {
    const {
      isFetching,
      aviso,
      nome,
      momentoInicio,
      momentoFim,
      status,
      premiacao,
      bilhetes,
      tipo,
      premiarPontuacaoMaxima
    } = this.props.bolaoRankingReducer;

    const dataInicial = moment(formatOdinDateToDateJs(momentoInicio)).format("DD/MM/YYYY");
    const dataFinal = moment(formatOdinDateToDateJs(momentoFim)).format("DD/MM/YYYY");
    const premiacaoParcial = `R$ ${convertIntegerToMoney(premiacao)}`;
    const bilhetesSorted = tipo === 1 && premiarPontuacaoMaxima ? bilhetes.sort((a, b) =>  b.qtdEventosPremiados - a.qtdEventosPremiados) : bilhetes;

    return (
      <div>
        {
          isFetching ? <LoaderCarregando /> :
          aviso ? this.renderAviso(aviso) :
          <Card
            className="edge-bottom-list card-block"
            header={<Header nome={nome}/>}
          >
            <InfoGeral label="Data Inicial" valor={dataInicial} />
            <InfoGeral label="Data Final" valor={dataFinal} />
            <InfoGeral label="Status" valor={status} />
            { status === "Encerrado" ?  <InfoGeral label="Premiação final bolão" valor={premiacaoParcial} /> : <InfoGeral label="Premiação Parcial" valor={premiacaoParcial} />}
            { bilhetes.length && <TableRanking bilhetes={bilhetesSorted} tipo={tipo} premiarPontuacaoMaxima={premiarPontuacaoMaxima}    /> }
          </Card>
        }
      </div>
    );
  }
}

const TableRanking = ({ bilhetes, tipo, premiarPontuacaoMaxima }) => (
  <div className="row">
    <div className="small-24 columns edge-bottom">
      <div className="shadow-wrapper">
        <div className="ranking-bilhetes">
          <table className="ranking-bilhetes__table">
            <thead>
              <tr>
                <th className="text-title fix-column">Bilhete</th>
                <th className="text-title">Data</th>
                <th className="text-title">Vendedor</th>
                <th className="text-title">Cotas</th>
                {tipo === 1 && premiarPontuacaoMaxima ? <th className="text-title">Acertos</th> :  <th className="text-title">Pontos</th>}
                <th className="text-title text-right">Prêmio</th>
              </tr>
            </thead>
            <tbody>
              {
                bilhetes.map((bilhete, index) => (
                  <tr key={index}>
                    <td className="text-title fix-column">{ bilhete.numBilhete }</td>
                    <td className="text-title">
                      { moment(formatOdinDateToDateJs(bilhete.momentoVenda)).format("DD/MM") }
                    </td>
                    <td
                      title={bilhete.pontoVenda}
                      className="text-title max-width-150 truncate"
                    >
                      { bilhete.pontoVenda }
                    </td>
                     <td className="text-title">{ bilhete.cotas }</td>
                    {tipo === 1 && premiarPontuacaoMaxima ? <td className="text-title">{ bilhete.qtdEventosPremiados }</td> :   <td className="text-title">{ bilhete.pontuacaoBolao }</td>}
                    <td className="text-title text-right">
                      { `R$ ${convertFloatToMoney(bilhete.valorPremio)}` }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

const Header = ({ nome }) => (
  <div className="row align-middle">
    <div className="small-24 columns">
      <h3 className="text-title padding-7 light-default">
        { nome }
      </h3>
    </div>
  </div>
);

const InfoGeral = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-title light-secondary">{label}</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-title light-default">{valor}</p>
    </div>
  </div>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    bolaoRankingReducer: state.bolaoRankingReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BolaoRanking);
