import "./Snackbar.css";

// Snackbar baseada no Material Design: talvez seja transformada em um componente.
// Referência: https://codepen.io/wibblymat/pen/avAjq
const Snackbar = (function() {
  let previous = null;

  return function(message, actionText, action, hideTime = 5000) {
    if (previous) {
      previous.dismiss();
    }

    let snackbar = document.createElement('div');
    snackbar.className = 'paper-snackbar';

    const carrinhoMobile = document.querySelector(".carrinho-mobile");

    snackbar.dismiss = function() {
      this.style.bottom = "-100px";
      this.style.opacity = 0;
    };

    const text = document.createTextNode(message);
    snackbar.appendChild(text);

    if (actionText) {
      if (!action) {
        action = snackbar.dismiss.bind(snackbar);
      }
      const actionButton = document.createElement('button');
      actionButton.className = 'action';
      actionButton.innerHTML = actionText;
      actionButton.addEventListener('click', action);
      snackbar.appendChild(actionButton);
    }

    setTimeout(function() {
      if (previous === this) {
        previous.dismiss();
      }
    }.bind(snackbar), hideTime);

    snackbar.addEventListener('transitionend', function(event, elapsed) {
      if (event.propertyName === 'opacity' && this.style.opacity === "0") {
        this.parentElement.removeChild(this);

        if (previous === this) {
          previous = null;

          if (carrinhoMobile) {
            carrinhoMobile.style.bottom = "8px";
          }
        }
      }
    }.bind(snackbar));

    previous = snackbar;
    document.body.appendChild(snackbar);
    getComputedStyle(snackbar).bottom; // eslint-disable-line

    // Não bloqueia o carrinho aumentando o "bottom"
    if (carrinhoMobile) {
      const carrinhoBottom = parseInt(getComputedStyle(carrinhoMobile).bottom, 10);
      if (carrinhoBottom === 8) {
        let snackbarHeight = parseInt(snackbar.scrollHeight, 10);
        snackbarHeight += parseInt(getComputedStyle(snackbar).marginTop, 10);
        snackbarHeight += parseInt(getComputedStyle(snackbar).marginBottom, 10);

        carrinhoMobile.style.bottom = carrinhoBottom + snackbarHeight + "px";
      }
    }

    snackbar.style.bottom = '0px';
    snackbar.style.opacity = 1;
  };
})();

export default Snackbar;
