import React from 'react'

// Libs
import moment from "moment";

// Helpers
import { convertIntegerToFloat } from "../../../../modules/helpers";


export default ({cabecalho,linha,indice}) => {
    
    
    let subeventos = linha.subeventos;
    const tamCab = cabecalho.length-1;
    const falta =  tamCab - subeventos.length;
     

    if(falta>0) {
        for(let i=0;i<(falta);i++){
            subeventos = [
                ...subeventos,
                {
                    aposta : "",
                    cotacao: 0,
                    id: 0,
                    idOpcao: 0,
                    label: "",
                    nome: "",
                    titulo: ""
                }
            ]
        }
    }    
    
    return (
        <div className="row eventoscamprint-linhajogos--linha">
            <div className="small-9 columns eventoscamprint-linhajogos--colunanomejogo">
            {linha.exibirBuscaSeq && linha.seqBuscaDiario !== "0"? linha.seqBuscaDiario+" - " :""}{linha.casa} x {linha.fora} - { moment(linha.data).format("HH:mm DD/MM/YY") }
            </div>
            {
                subeventos.map( (s,i) => {
                    return (
                        <div key={i} className="columns eventoscamprint-linhajogos--colunacotacao">
                            {s != null && s.cotacao > 0 && s.label === cabecalho[i+1].label ? convertIntegerToFloat(s.cotacao) : ""}
                        </div>
                    )
                })
                    
            }
        </div>
    )
}