import {
  TOGGLE_SUBITENS,
  TOGGLE_FIXITENS,
  INIT_MODALIDADES,
  REQUEST_MODALIDADES,
  RECEIVE_MODALIDADES,
  CHECK_CAMPEONATO_SIDEBAR,
  REMOVE_CHECK_CAMPEONATO_SIDEBAR,
  CHECK_PAIS_SIDEBAR,
  REMOVE_CHECK_PAIS_SIDEBAR
} from "./ActionTypes";

import { doRequest } from "../modules/request"

export const toggleSubitens = (id) => {
  return {
    type: TOGGLE_SUBITENS,
    id
  };
};

export const toggleFixitens = (idModalidade, idPais) => {
  return {
    type: TOGGLE_FIXITENS,
    idModalidade, idPais
  };
};

export const initModalidades = (modalidades) => {
  return {
    type: INIT_MODALIDADES,
    modalidades
  };
};

export const requestModalidades = (modalidades) => {
  return {
    type: REQUEST_MODALIDADES
  };
};

export const receiveModalidades = (modalidades,idCampeonatos) => {
  return {
    type: RECEIVE_MODALIDADES,
    modalidades,
    idCampeonatos
  };
};

export const fetchModalidades = (modalidades) => (dispatch, getState) => {
  dispatch(requestModalidades());

  doRequest({ url: "/modalidadeporpais" }).then(response => {
    dispatch(receiveModalidades(response.modalidades,getState().eventosPorCampeonatoReducer.campeonatosFiltros.idCampeonatos));
  });

};

export const checkCampeonatoSideBar = (idModalidade, idPais, idCampeonato) => {
  return {
    type: CHECK_CAMPEONATO_SIDEBAR,
    idModalidade, idPais, idCampeonato
  }

}

export const removeCheckCampeonatoSideBar = (idModalidade, idPais, idCampeonato) => {
  return {
    type: REMOVE_CHECK_CAMPEONATO_SIDEBAR,
    idModalidade, idPais, idCampeonato
  }
};

export const checkPaisSideBar = (idModalidade, idPais) => {
  return {
    type: CHECK_PAIS_SIDEBAR,
    idModalidade, idPais
  }

}

export const removeCheckPaisSidebar = (idModalidade, idPais) => {
  return {
    type: REMOVE_CHECK_PAIS_SIDEBAR,
    idModalidade, idPais
  }

};
