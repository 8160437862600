// React
import React, { Component } from "react";

// React Router
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Components
import Card from "../Common/Card/Card";

class Promocoes extends Component {
  componentWillMount() {
    // Componente não está em uso ainda
    this.props.history.push("/");

    // if (!this.props.permissaoPromocoes) {
    //   this.props.history.push("/");
    // }
  }

  render() {
    return (
      <PromocoesContainer>
        <div className="row">
          <div className="small-24 columns">
            <h1 className="text-display edge-bottom light-default">
              Promoções em Destaque
            </h1>
          </div>
        </div>

        <div className="row">
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
          <Card className="small-24 medium-12 large-6 columns column-block">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                <img src="https://files.slack.com/files-pri/T8GKFK6AG-F8UQP39QU/promo1-320w.jpg" alt={"aaaa"} />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  Aposta Grátis de R$10,00
                </p>
              </div>
            </div>
          </Card>
        </div>
      </PromocoesContainer>
    );
  }
}

const PromocoesContainer = ({ children }) => (
  <main className="small-24 columns">
    <div className="main main--block">
      <div className="inner-row-wrapper">
        {children}
      </div>
    </div>
  </main>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    permissaoPromocoes: state.permissoesReducer.permissoes.promocoes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Promocoes)
);
