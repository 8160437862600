import { RECEIVE_BANNER_SLOT } from "../../actions/ActionTypes.js";

const initialState = {
  name: "",
  link: "",
  url: "",
};

export default function slot(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_BANNER_SLOT:
      return {
        name: action.name,
        link: action.link,
        url: action.url
      };

    default:
      return state;
  }
};
