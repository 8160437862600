// React
import React from "react";

// Styles
import "./Input.css";

// Components
import MaskedInput from "react-text-mask";

export default ({ name, type, onChange, className }) => {
  let classNames = "input-text text-title";
  classNames += `${type === "primary" ? " input-text--primary dark-default" : " input-text--secondary"}`;
  classNames += ` ${className && className}`;

  return (
    <MaskedInput
      type="tel"
      name={name}
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholder="PIN 0000 0000 0000"
      className={classNames}
      onChange={onChange}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
    />
  );
};
