// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/ApostasActions";

// Styles
import "../../ShareConfiguracoes.css";

// Libs
import moment from "moment";

// Helpers
import {
  formatOdinDateToDateJs,
  convertIntegerToMoney,
  convertFloatToMoney,
} from "../../../../modules/helpers";

// Components
import CardEventoAposta, {
  EVENTO_BILHETE,
} from "../../../Common/Card/CardEventoAposta";
import Card from "../../../Common/Card/Card";
import ButtonRounded from "../../../Common/Button/ButtonRounded";
import LoaderCarregando from "../../../Common/Loader/LoaderCarregando";
import Loader from "../../../Common/Loader/Loader";

const Header = ({ numeroBilhete, showEventos, onClick }) => (
  <div className="row align-middle">
    <div className="small-20 columns">
      <h3 className="text-title padding-7 light-default">{numeroBilhete}</h3>
    </div>
    <div className="small-4 columns text-right">
      <span
        onClick={onClick}
        className={`icon-expandir-${
          showEventos ? "menos" : "mais"
        } info-aposta__toggle`}
      ></span>
    </div>
  </div>
);

const InfoGeral = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-title light-secondary">{label}</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-title light-default">{valor}</p>
    </div>
  </div>
);

class InfoAposta extends Component {
  toggleEventos = (bilhete) => {
    if (!bilhete.eventos.length) {
      this.props.fetchEventos(bilhete.numeroBilhete);
    }

    this.props.toggleBilhete(bilhete.numeroBilhete);
  };

  state = {
    cashoutPermitido: null,
    cashoutCalculado: null
  };

  renderEventos = (bilhete) => {
    if (bilhete.isFetchingEventos || (bilhete.cashout === null && this.state.cashoutPermitido)) {
      return <LoaderCarregando />;
    }

    if (bilhete.eventos.length) {
      return (
        <div className="style-info-bilhete-apostas">
          <div className="row align-center">
            {bilhete.eventos.map((evento, index) => (
              <div key={index} className="small-24 columns column-block">
                <CardEventoAposta type={EVENTO_BILHETE} evento={evento} />
              </div>
            ))}
            <div className="info-bilhete-actions">
              {bilhete.idStatus === 1 && (
                  <ButtonRounded
                    onClick={() => this.props.fetchCancelarBilhete(bilhete)}
                    disabled={bilhete.isFetchingCancelamento}
                  >
                    {bilhete.isFetchingCancelamento ? (
                      <Loader type="white" />
                    ) : (
                      "Cancelar Bilhete"
                    )}
                  </ButtonRounded>
              )}
              {bilhete.cashout && (
                  <ButtonRounded
                  onClick={() => {this.props.solicitarCashout(bilhete)}}
                  disabled={bilhete.isFetchingCashout}
                  >
                    {bilhete.isFetchingCashout ? (
                      <Loader type="white" />
                    ) : (
                      `Cashout R$ ${convertFloatToMoney(bilhete.cashout)}`
                    )}
                  </ButtonRounded>
              )}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  checkCashout = (bilhete) => {
    const { eventos } = bilhete;
    const cashoutPermitido =
      bilhete.idStatus === 1 &&
      eventos.every(
        (evento) =>
          evento.nomeStatus === "Premiado" ||
          evento.nomeStatus === "Aguardando Resultado"
      ) &&
      eventos.some(
        (evento) =>
          evento.nomeStatus === "Premiado" &&
          moment().isAfter(formatOdinDateToDateJs(evento.momentoEvento))
      ) &&
      eventos.some(
        (evento) =>
          evento.nomeStatus === "Aguardando Resultado" &&
          moment().isBefore(formatOdinDateToDateJs(evento.momentoEvento))
      );
    this.setState({ cashoutCalculado: !cashoutPermitido, cashoutPermitido });
    return cashoutPermitido;
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.bilhete.showEventos &&
      prevProps.bilhete.eventos !== this.props.bilhete.eventos &&
      this.props.bilhete.eventos.length > 0 &&
      this.props.bilhete.cashout === null &&
      this.checkCashout(this.props.bilhete)
    ) {
      this.props.preconsultaCashout(this.props.bilhete.numeroBilhete);
      this.setState({ cashoutCalculado: true });
    }
  }

  render() {
    const { bilhete } = this.props;
    const numeroBilhete = bilhete.numeroBilhete;
    const dataComprovante = moment(
      formatOdinDateToDateJs(bilhete.dataComprovante)
    ).format("DD/MM/YYYY [-] HH:mm:ss");
    const quantidadeDeEventos = bilhete.quantidadeDeEventos;
    const valorPago = convertIntegerToMoney(bilhete.valorPago);
    const valorPremio = convertIntegerToMoney(bilhete.valorPremio);
    const statusBilhete = bilhete.statusBilhete;
    const header = (
      <Header
        numeroBilhete={numeroBilhete}
        showEventos={bilhete.showEventos}
        onClick={() => this.toggleEventos(bilhete)}
      />
    );

    return (
      <Card className="edge-bottom-list card-block" header={header}>
        <InfoGeral label="Data Comprovante" valor={dataComprovante} />
        <InfoGeral label="Quantidade de Eventos" valor={quantidadeDeEventos} />
        <InfoGeral label="Valor Pago" valor={valorPago} />
        <InfoGeral label="Possível Retorno" valor={valorPremio} />
        <InfoGeral label="Status Bilhete" valor={statusBilhete} />
        {bilhete.showEventos && this.renderEventos(bilhete)}
      </Card>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { apostasReducer: state.apostasReducer };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoAposta);
