// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Comprovantes/ComprovantesActions";

// Styles
import "./Comprovantes.css";

// Components
import CardComprovante from "./CardComprovante";

class Comprovantes extends Component {
  renderComprovantes = () => {
    const { comprovantes } = this.props.comprovantesReducer;

    return comprovantes.map((comprovante, index) => (
      <CardComprovante
        key={index}
        comprovante={comprovante}
      />
    ));
  }

  render() {
    return (
      <div className="comprovantes edge-top">
        <div className="inner-row-wrapper">
          <div className="row">
            <div className="small-24 columns">
              <h1 className="text-display edge-bottom light-default">Comprovantes</h1>
              { this.renderComprovantes() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { comprovantesReducer: state.comprovantesReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comprovantes);
