import {
  INIT_CADASTRO_RAPIDO,
  REQUEST_CADASTRO_RAPIDO_DADOS,
  RECEIVE_CADASTRO_RAPIDO_DADOS,
  ERROR_CADASTRO_RAPIDO_DADOS,
  REQUEST_CADASTRO_RAPIDO_FORMULARIO,
  RECEIVE_CADASTRO_RAPIDO_FORMULARIO,
  ERROR_CADASTRO_RAPIDO_FORMULARIO,
} from "../../../actions/ActionTypes";

import dados from "./CadastroRapidoDadosReducer";
import formulario from "./CadastroRapidoFormularioReducer";

const initialState = {
  dados: dados(undefined, {}),
  formulario: formulario(undefined, {})
};

export default function cadastroRapido(state = initialState, action) {
  switch (action.type) {
    case INIT_CADASTRO_RAPIDO:
      return initialState;

    case REQUEST_CADASTRO_RAPIDO_DADOS:
    case RECEIVE_CADASTRO_RAPIDO_DADOS:
    case ERROR_CADASTRO_RAPIDO_DADOS:
      return {
        ...state,
        dados: dados(state.dados, action)
      };

    case REQUEST_CADASTRO_RAPIDO_FORMULARIO:
    case RECEIVE_CADASTRO_RAPIDO_FORMULARIO:
    case ERROR_CADASTRO_RAPIDO_FORMULARIO:
      return {
        ...state,
        formulario: formulario(state.formulario, action)
      };

    default:
      return state;
  }
};
