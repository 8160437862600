// React
import React from "react";

// Styles
import "./Banner.css";

// Components
import Banner from "./Banner";

const BannerSlot = ({ link, urlBackground }) => {
  return (
    <Banner
      className="edge-bottom show-for-large"
      link={link}
      urlBackground={urlBackground}
    />
  );
};

export default BannerSlot;
