// React
import React, { Component } from "react";

// Libs
import moment from "moment";

// Helpers
import { splitCodigo, convertIntegerToMoney } from "../../../modules/helpers";

//CopytoClipboard
import {CopyToClipboard} from "react-copy-to-clipboard";

// Components
import CardEventoAposta, { EVENTO_ETICKET, EVENTO_BOLAO } from "../../Common/Card/CardEventoAposta";
import Card from "../../Common/Card/Card";
import Aviso from "../../Common/Aviso/Aviso";


const Header = ({ codigo }) => (
  <div className="row align-middle">
    <div className="small-24 columns">
      <h3 className="text-title padding-7 light-default card-info-eticket__copy">
        { splitCodigo(codigo, 3) }
      </h3>
    </div>
  </div>
);

const InfoGeral = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-title light-secondary">{label}</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-title light-default">{valor}</p>
    </div>
  </div>
);

export default class InfoEticket extends Component {

  state = {
    value: (this.props.eticket.codigo),
    copied: false,
    display: false,};

    componentWillUnmount(){
      clearTimeout(this.timeoutfunc);
    }

  renderEventos = (eventos, tipoEvento) => (
    <div className="row align-center">
      {
        eventos.map((evento, index) => (
          <div key={index} className="small-24 columns column-block">
            <CardEventoAposta
              type={tipoEvento}
              evento={evento}
            />
          </div>
        ))
      }
    </div>
  );

  renderInfoEsporte(eticket) {
    // Data de emissão / Validade do E-Ticket
    const dateMomento = new Date(eticket.momento);
    const dataEticket = moment(dateMomento)
      .format("DD/MM/YYYY [-] HH:mm:ss");
    const validadeEticket = moment(dateMomento)
      .add(eticket.duracao, "hours")
      .format("DD/MM/YYYY [-] HH:mm:ss");
    const valorTotal = convertIntegerToMoney(eticket.valorTotal);
    const possivelRetorno = convertIntegerToMoney(eticket.possivelRetorno);
    const quantidadeDeEventos = eticket.eventos.length;

    return (
      <div>
        <InfoGeral label="Data E-Ticket" valor={dataEticket} />
        <InfoGeral label="Validade E-Ticket" valor={validadeEticket} />
        <InfoGeral label="Valor E-Ticket" valor={valorTotal} />
        <InfoGeral label="Possível Retorno" valor={possivelRetorno} />
        <InfoGeral label="Quantidade de Eventos" valor={quantidadeDeEventos} />
      </div>
    );
  }

  renderInfoBolao(eticket) {
    // Data de emissão / Validade do E-Ticket
    const dateMomento = new Date(eticket.momento);
    const dataEticket = moment(dateMomento)
      .format("DD/MM/YYYY [-] HH:mm:ss");
    const validadeEticket = moment(dateMomento)
      .add(eticket.duracao, "hours")
      .format("DD/MM/YYYY [-] HH:mm:ss");
    const qtdCotas = eticket.qtdCotas;
    const valorTotal = convertIntegerToMoney(eticket.valorTotal);
    const quantidadeDeEventos = eticket.eventos.length;

    return (
      <div>
        <InfoGeral label="Data E-Ticket" valor={dataEticket} />
        <InfoGeral label="Validade E-Ticket" valor={validadeEticket} />
        <InfoGeral label="Quantidade de Cotas" valor={qtdCotas} />
        <InfoGeral label="Valor E-Ticket" valor={valorTotal} />
        <InfoGeral label="Quantidade de Eventos" valor={quantidadeDeEventos} />
      </div>
    );
  }

  onCopy = () => {
    this.setState({value: (this.props.eticket.codigo),copied: true});
  };

  renderAviso = () => {
    if (this.state.copied === true) {
      return (
        <Aviso type="default">
          Código copiado com sucesso
        </Aviso>
      );
    }

    return null;
  }

  displayTimeOut = () => {
    if (this.state.display === true) return;
      this.setState({display: true})
    this.timeoutfunc = setTimeout(() => {
      this.setState({display: false})
    },4000) 
  }
  
  render() {
    const { eticket } = this.props;
    const tipoEvento = eticket.tipo === "bolao" ? EVENTO_BOLAO : EVENTO_ETICKET;
   
    return (
      
          <Card
            className="edge-bottom-list card-block"
            header={
              <CopyToClipboard
                onCopy={this.onCopy}
                text={this.state.value}>
                  <div className="info-eticket-cursor"  onClick={this.displayTimeOut}>
                    <Header codigo={eticket.codigo} />
                    </div>
              </CopyToClipboard>}
          >
            {
              this.state.display && <div className="display-aviso">
                {this.renderAviso()}            
              </div>
            }
            { eticket.tipo === "esporte" && this.renderInfoEsporte(eticket) }
            { eticket.tipo === "bolao" && this.renderInfoBolao(eticket) }
            { this.renderEventos(eticket.eventos, tipoEvento) }
          </Card>
    );
  }
}
