// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/HelloBar/HelloBarActions";

// Styles
import "./HelloBar.css";

// Components
import Configuracoes from "../../Modal/Configuracoes/Configuracoes";

// Ações da HelloBar - isso ta uma bosta mas não achei outra solução.
// (OBS: não é possível salvar uma função no reducer)
export const OPEN_MODAL_CADASTRO = "OPEN_MODAL_CADASTRO";

class HelloBar extends Component {
  onClickAction = (acao) => {
    switch (acao) {
      case OPEN_MODAL_CADASTRO:
        return () => this.context.openModal(
          "Configurações",
          <Configuracoes active={"cadastro"} />
        );

      default:
        break;
    }
  }

  // Remove HelloBar sem abrir o modal
  hideHelloBar = (e) => {
    e.stopPropagation();
    this.props.hideHelloBar();
  }

  render() {
    const { helloBarReducer } = this.props;
    const onClick = this.onClickAction(helloBarReducer.acao);

    if (helloBarReducer.active) {
      return (
        <div className="hello-bar row" onClick={onClick}>
          <div className="small-24 columns">
            <p className="hello-bar__text text-title dark-default">
              { helloBarReducer.texto }
            </p>
            <span onClick={this.hideHelloBar} className="hello-bar__close icon-fechar"></span>
          </div>
        </div>
      );
    }

    return null;
  }
};

HelloBar.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { helloBarReducer: state.helloBarReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelloBar);
