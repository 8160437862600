// React
import React, { Component } from "react";

// Styles
import "./Input.css";

/*
  O componente raiz devem ser uma classe ao invés de Stateless Functions
  para poder usar "ref" e recuperar seus valores.
*/
export default class Input extends Component {
  render() {
    const { className, inputRef, error, ...props } = this.props;

    let classes = `input-text ${className || ""}`;
    classes += error ? " input-text--error" : "";

    return (
      <input
        className={classes}
        ref={inputRef}
        {...props}
      />
    )
  }
};
