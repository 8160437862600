import {
    RECEIVE_TRADICIONAIS
} from "../../actions/ActionTypes.js";

export default function tradicionais(state = [], action) {
    switch (action.type) {
        case RECEIVE_TRADICIONAIS:
            return state.map(itemLoterico => tradicional(itemLoterico, action));

        default:
            return state;
    }
};

const initialState = {
    id: "",
    nome: "",
    urlImagem: "",
    valorMinimo: 0,
    valorMaximo: 0,
    jackpots: [],
};

function tradicional(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_TRADICIONAIS:
            return {
                id: state.idGame,
                nome: state.nomeGame,
                urlImagem: state.urlImagem,
                valorMinimo: state.valorMin,
                valorMaximo: state.valorMax
            };
        default:
            return state;
    }
};
