// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Styles
import "./Modal.css";

const ModalContainer = ({ size, children }) => {
  let containerSize = null;

  // Padroniza tamanhos do Modal de acordo com o "size"
  // Ser� decidido com Patrick depois
  switch (size) {
    case "xsmall":
      containerSize = "small-24 medium-18 large-12 xlarge-6 columns";
      break;

    case "small":
      containerSize = "small-24 medium-18 large-12 xlarge-9 columns";
      break;

    case "medium":
      containerSize = "small-24 medium-18 large-12 xlarge-10 columns";
      break;

    case "large":
    default:
      containerSize = "small-24 medium-20 large-14 columns";
  }

  return (
    <div className="modal modal-target-close">
      <div className="row align-center modal-target-close">
        <div className="modal-edge-top small-24 columns show-for-large modal-target-close" />
        <div className={containerSize}>
          <div className="inner-row-wrapper modal-container">
            { children }
          </div>
        </div>
      </div>
    </div>
  );
}

const ModalHeader = ({ title, close }) => (
  <div className="row">
    <div className="small-24 columns">
      <header className="modal-header">
        <h1 className="text-display light-default">{ title }</h1>
        <span onClick={close} className="modal-header__close icon-fechar" />
      </header>
    </div>
  </div>
);

const ModalContent = ({ children }) => (
  <div className="row">
    <div className="small-24 columns">
      <div className="modal-content">
        { children }
      </div>
    </div>
  </div>
);

export default class Modal extends Component {
  // Oculta Dropdown se clicar em qualquer lugar da tela (window)
  handleModal = (event) => {
    if (event.target.matches('.modal-target-close')) {
      this.context.closeModal();
    };
  }

  // Adiciona e remove listener do objeto window / blur no background
  componentDidMount() {
    document.querySelector(".container").classList.add("container--blur");

    setTimeout(() => {
      window.addEventListener("click", this.handleModal);
      window.addEventListener("touchend", this.handleModal);
    }, 100);
  }

  componentWillUnmount() {
    document.querySelector(".container").classList.remove("container--blur");
    window.removeEventListener("click", this.handleModal);
    window.removeEventListener("touchend", this.handleModal);
  }

  render() {
    const { title, children, size } = this.props;
    const { closeModal } = this.context;

    return (
      <ModalContainer size={size}>
        <ModalHeader title={title} close={closeModal} />
        <ModalContent>
          { children }
        </ModalContent>
      </ModalContainer>
    );
  }
}

Modal.contextTypes = {
  closeModal: PropTypes.func
};
