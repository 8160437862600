import { SUCCESS_CARRINHO_BOLAO, ERROR_CARRINHO_BOLAO } from "../../../actions/ActionTypes";

const initialState = {
  type: "default",
  text: "Selecione os Eventos do Bolão"
};

export default function aviso(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CARRINHO_BOLAO:
      return {
        type: "success",
        text: action.text
      };

    case ERROR_CARRINHO_BOLAO:
      return {
        type: "error",
        text: action.text
      };

    default:
      return state;
  }
};
