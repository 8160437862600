// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/EventosAoVivo/EventosAoVivoActions";

// React Router
import { withRouter } from "react-router-dom";

// Components
import CampeonatoAoVivo from "./Campeonato/CampeonatoAoVivo";
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import Aviso from "../../Common/Aviso/Aviso";
import LegendaSubeventos from "../../Modal/LegendaSubeventos/LegendaSubeventos";
import BannerAoVivo from "../../Common/Banner/BannerAoVivo";

const Campeonatos = ({ campeonatos }) => (
  <div className="row">
    <div className="small-24 columns">
      {
        campeonatos.map((campeonato, index) => (
          <CampeonatoAoVivo key={index} campeonato={campeonato} />
        ))
      }
    </div>
  </div>
);

const Title = ({ title, openModalLegenda }) => (
  <div className="row">
    <div className="small-24 columns">
      <h1 className="text-display light-default">
        { title }
        <span
          style={{ fontSize: "2rem" }}
          className="cursor-pointer float-right icon icon-ajuda-outline"
          onClick={openModalLegenda}
        >
        </span>
      </h1>
    </div>
  </div>
);

class EventosAoVivo extends Component {

  componentWillMount() {
    if (!this.props.permissaoAoVivo) {
      this.props.history.push("/");
    }

    this.props.fetchEventosAoVivo(this.props.carrinho);
    this.props.fetchBannersAoVivo();
  }

  openModalLegenda = () => {
    this.context.openModal(
      "Legenda",
      <LegendaSubeventos />,
      "small"
    );
  }

  renderAvisos = () => {
    const { aviso } = this.props.eventosAoVivoReducer;

    return (
      <div className="inner-row-wrapper">
        <Aviso className="edge-top" type={aviso.type}> { aviso.text } </Aviso>
      </div>
    )
  }

  renderBanner = () => {
    const { aoVivo } = this.props.bannerReducer;

    if (aoVivo.link) {
      return (
        <BannerAoVivo
          link={aoVivo.link}
          urlBackground={aoVivo.url}
          {...aoVivo.dados}
        />
      );
    }

    return null;
  }

  renderAoVivo = (campeonatos) => {
    if(!campeonatos.length) return null;

    return (
      <div className="main">
        <div className="inner-row-wrapper">
          <Title title="Eventos Ao Vivo" openModalLegenda={this.openModalLegenda} />
          <Campeonatos campeonatos={campeonatos} />
        </div>
      </div>
    );
  }

  renderEmBreve = (campeonatos) => {
    if (!campeonatos.length) return null;

    return (
      <div className="main edge-top">
        <div className="inner-row-wrapper">
          <Title title="Eventos Em Breve" openModalLegenda={this.openModalLegenda} />
          <Campeonatos campeonatos={campeonatos} />
        </div>
      </div>
    );
  }

  getCampeonatos(campeonatos, tipo) {
    return campeonatos.reduce((resultado, campeonato) => {
      const eventos = campeonato.eventos.filter(evento => {
        switch (tipo) {
          case "aoVivo": return evento.periodo !== "Não Iniciado";
          default: return evento.periodo === "Não Iniciado";
        }
      });

      if (eventos.length) {
        resultado.push({ ...campeonato, eventos });
      }

      return resultado;
    }, []);
  }

  render() {
    const { campeonatos, isFetching, aviso } = this.props.eventosAoVivoReducer;

    return (
      <div>
        { this.renderBanner() }
        {
          aviso ? this.renderAvisos() :
          isFetching ? <LoaderCarregando className="edge-top" /> :
          <div>
            { this.renderAoVivo(this.getCampeonatos(campeonatos, "aoVivo")) }
            { this.renderEmBreve(this.getCampeonatos(campeonatos, "emBreve")) }
          </div>
        }
      </div>
    );
  }
}

EventosAoVivo.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    eventosAoVivoReducer: state.eventosAoVivoReducer,
    carrinho: state.carrinho,
    permissaoAoVivo: state.permissoesReducer.permissoes.aoVivo,
    bannerReducer: state.bannerReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventosAoVivo)
);
