import {
  INIT_RECUPERAR_SENHA,
  REQUEST_ENVIAR_CODIGO_SMS,
  RECEIVE_ENVIAR_CODIGO_SMS,
  ERROR_ENVIAR_CODIGO_SMS,
  REQUEST_RECUPERAR_SENHA,
  RECEIVE_RECUPERAR_SENHA,
  ERROR_RECUPERAR_SENHA
} from "../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  codigoEnviado: false,
  sucesso: false,
  aviso: ""
};

export default function recuperarSenhaReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_RECUPERAR_SENHA:
      return initialState;

    case REQUEST_ENVIAR_CODIGO_SMS:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_ENVIAR_CODIGO_SMS:
      return {
        ...state,
        isFetching: false,
        codigoEnviado: true
      };

    case REQUEST_RECUPERAR_SENHA:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_RECUPERAR_SENHA:
      return {
        ...state,
        isFetching: false,
        sucesso: true
      };

    case ERROR_RECUPERAR_SENHA:
    case ERROR_ENVIAR_CODIGO_SMS:
      return {
        ...state,
        isFetching: false,
        aviso: action.error
      };

    default:
      return state;
  }
};
