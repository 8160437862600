// Action Types
import {
  INIT_CADASTRO_RAPIDO,
  REQUEST_CADASTRO_RAPIDO_DADOS,
  RECEIVE_CADASTRO_RAPIDO_DADOS,
  ERROR_CADASTRO_RAPIDO_DADOS,
  REQUEST_CADASTRO_RAPIDO_FORMULARIO,
  RECEIVE_CADASTRO_RAPIDO_FORMULARIO,
  ERROR_CADASTRO_RAPIDO_FORMULARIO
} from "../ActionTypes";

// Actions
import { fetchLogin } from "../Usuario/UsuarioActions";

// Modules
import { doOdinRequest } from "../../modules/request";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initCadastroRapido = () => ({
  type: INIT_CADASTRO_RAPIDO
});

// Actions dos dados
export const requestCadastroRapidoDados = () => ({
  type: REQUEST_CADASTRO_RAPIDO_DADOS
});

export const receiveCadastroRapidoDados = ({ login, urlImagem, descricao }) => ({
  type: RECEIVE_CADASTRO_RAPIDO_DADOS,
  login, urlImagem, descricao
});

export const errorCadastroRapidoDados = (error) => ({
  type: ERROR_CADASTRO_RAPIDO_DADOS,
  error
});

export const fetchCadastroRapidoDados = (token) => (dispatch, getState) => {
  dispatch(requestCadastroRapidoDados());

  const params = {
    url: "/sp/promocao/preconsulta",
    method: "GET",
    params: { token }
  };

  return doOdinRequest(params)
    .then(response => {
      if (!response.codResposta) {
        return dispatch(errorCadastroRapidoDados(response.response.data.message));
      }

      if (response.codResposta === "000") {
        const dados = {
          login: response.login,
          urlImagem: response.urlImagem,
          descricao: response.descricao
        };

        dispatch(receiveCadastroRapidoDados(dados));
      }
      else {
        dispatch(errorCadastroRapidoDados(response.messagem));
      }
    }).catch(error => {
      dispatch(errorCadastroRapidoDados(error));
    });
};

// Actions do formulário
export const requestCadastroRapidoFormulario = () => ({
  type: REQUEST_CADASTRO_RAPIDO_FORMULARIO
});

export const receiveCadastroRapidoFormulario = () => ({
  type: RECEIVE_CADASTRO_RAPIDO_FORMULARIO
});

export const errorCadastroRapidoFormulario = (error) => ({
  type: ERROR_CADASTRO_RAPIDO_FORMULARIO,
  error
});

export const fetchCadastroRapidoFormulario = ({ token, login, senha, confirmarSenha }) => (dispatch, getState) => {
  dispatch(requestCadastroRapidoFormulario());

  // Verifica se campos são válidos
  const validarCampos = checarCampos(senha, confirmarSenha);

  if (!validarCampos.valido) {
    return dispatch(errorCadastroRapidoFormulario(validarCampos.error));
  }

  const params = {
    url: "/sp/promocao/autoCadastro/usuario",
    method: "POST",
    data: { token, senha }
  };

  return doOdinRequest(params)
    .then(response => {
      if (!response.codResposta) {
        return dispatch(errorCadastroRapidoFormulario(response.response.data.message));
      }

      if (response.codResposta === "000") {
        dispatch(receiveCadastroRapidoFormulario());
        dispatch(fetchLogin(login, senha));
        Snackbar("Cadastro realizado com sucesso", "Ok");
      }
      else {
        dispatch(errorCadastroRapidoFormulario(response.mensagem));
      }
    }).catch(error => {
      dispatch(errorCadastroRapidoFormulario(error));
    });
};

const checarCampos = (senha, confirmarSenha) => {
  // Se a senha não conferir
  if (senha !== confirmarSenha) {
    return { valido: false, error: "Senha não confere" };
  }

  // Se a senha for menor que 6 dígitos
  if (senha.length < 6) {
    return { valido: false, error: "Senha deve ter 6 ou mais dígitos" };
  }

  // Se a senha não possuir ao menos uma letra e um número
  if (senha.search(/\d/) === -1 || senha.search(/[a-zA-Z]/) === -1) {
    return { valido: false, error: "Senha deve conter ao menos uma letra e um número" };
  }

  return { valido: true };
};

