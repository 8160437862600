// React
import React, { Component } from "react";

// Redux
import * as actions from "../../../../actions/Carrinho/CarrinhoActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Styles
import "./SubeventosHeader.css";

// Components
import ButtonOdd from "../../../Common/Button/ButtonOdd";

class SubeventosHeader extends Component {
  acaoCarrinho = (subevento) => {
    subevento.titulo = subevento.nomeSubevento;

    if (!subevento.nome) {
      subevento.nome = subevento.label;
    }

    if (!subevento.aposta) {
      subevento.aposta = subevento.nome;
    }

    if (subevento.idCampeonato) {
      subevento.idCampeonato = this.props.idCampeonato;
    }

    if (subevento.active) {
      this.props.removerItemCarrinhoComSubEvento(subevento);
    }
    else {
      subevento.outright = this.props.outright;
      this.props.adicionarItemCarrinhoComSubEvento(subevento, false);
    }
  }

  render() {
    const { momento, casa, fora, subeventos, outright } = this.props;

    return (
      <div className="row">
        <div className="small-24 columns">
          <div className="header-subevento inner-row-wrapper">
            <Momento momento={momento} />
            <Evento casa={casa} fora={fora} outright={outright} />
            {!outright && <Cotacoes subeventos={subeventos} acaoCarrinho={this.acaoCarrinho} />}
          </div>
        </div>
      </div>
    );
  }
}

const Momento = ({ momento }) => (
  <div className="row">
    <div className="small-24 columns">
      <h1 className="header-subevento__momento text-center text-title light-active">
        { momento }
      </h1>
    </div>
  </div>
);

const Evento = ({ casa, fora, outright }) => {
  return(
  <div className="row">
    <div className="small-24 columns edge-top edge-bottom">
      <h1 className="text-center text-display light-active">
        {`${casa} `}
        { !outright && <span className="show-for-large text-title">vs.</span>}
        { !outright && <div className="hide-for-large edge-padding-8 text-title">vs.</div>}
        {` ${outright ? '':fora}`}
      </h1>
    </div>
  </div>
)};

const Cotacoes = ({ subeventos, acaoCarrinho }) => (
  <div className="row align-center">
    {
      subeventos.map((subevento, index) => (
        <div key={index} className="small-8 medium-6 large-4 columns button-subevento-header">
          <ButtonOdd
            label={subevento.label}
            cotacao={subevento.cotacao}
            onClick={() => acaoCarrinho(subevento)}
            active={subevento.active}
            isHeader={true}
          />
        </div>
      ))
    }
  </div>
);

// --- Container Logic --- //
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  () => ({}),
  mapDispatchToProps
)(SubeventosHeader);
