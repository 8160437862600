import {
  INIT_MODALIDADES_EVENTOS_POR_CAMPEONATO,
  REQUEST_MODALIDADES_EVENTOS_CAMPEONATO,
  RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  SUCCESS_AVISO_MODALIDADES_EVENTO_PREMATCH,
  ERROR_AVISO_MODALIDADES_EVENTO_PREMATCH
} from "../../actions/ActionTypes.js";

import cotacao from "./CotacaoReducer";
import aviso from "./AvisosModalidadesEventosReducer";

const modalidadesEventosPrematchInitialState = {
  isFetching : null,
  idEvento : 0,
  idCampeonato : 0,
  modalidades : [],
  nomeEvento : "",
  aviso: undefined,
  outright: false,
}


export default function modalidadesEventosPrematchReducer(state = modalidadesEventosPrematchInitialState, action) {

  switch (action.type) {
    case INIT_MODALIDADES_EVENTOS_POR_CAMPEONATO:
      return modalidadesEventosPrematchInitialState;
    case REQUEST_MODALIDADES_EVENTOS_CAMPEONATO:
      return {
        ...state,
        isFetching : true,
        aviso: undefined,
        outright: false,
      };

    case RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO:
      return {
        ...state,
        isFetching : false,
        idEvento : action.evento.idEvento,
        modalidades: action.evento.modalidades.map(item => modalidadeEventoPreMatch(item, action)),
        nomeEvento: action.evento.nomeEvento,
        idCampeonato : action.evento.idCampeonato,
        aviso: undefined,
        outright : action.evento.outright,
      };

    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
      if (state.idEvento === action.idEvento) {
        return {
          ...state,
          modalidades: state.modalidades.map(item => modalidadeEventoPreMatch(item, action)),
        }
      }

      return state;

    case LIMPAR_CARRINHO:
      return {
        ...state,
        modalidades: state.modalidades.map(item => modalidadeEventoPreMatch(item, action)),
      }
    case SUCCESS_AVISO_MODALIDADES_EVENTO_PREMATCH:
      return {
        ...state,
        aviso: aviso(state.aviso, action)
      };

    case ERROR_AVISO_MODALIDADES_EVENTO_PREMATCH:
      return {
        ...state,
        isFetching: false,
        aviso: aviso(state.aviso, action)
      };

    default:
      return state;
  }
};

const modalidadeEventoInitialState = {
  titulo : "",
  cotacoes : []
};

export const modalidadeEventoPreMatch = (state = modalidadeEventoInitialState, action) => {

  switch (action.type) {
    case RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO:
    {

      const campeonatoNoCarrinho = action.carrinho.itens.some(item =>
        action.evento.idEvento === item.idEvento
      );

      if (campeonatoNoCarrinho) {
        return {
          ...state,
          cotacoes: state.cotacoes.map(item =>
            cotacao(item, action)
          )
        };
      }

      return state;
    }
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      return {
        ...state,
        cotacoes: state.cotacoes.map(item =>
          cotacao(item, action)
        )
      };

    default:
      return state;
  }
};
