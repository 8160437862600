// React
import React from "react";

const SideNavSubitem = ({
  contentLeft,
  contentRight,
  active,
  onClick,
  children,
  tipoSubItem
}) => (
  <li className={"sidenav-sublist__item "+tipoSubItem}>
    <div
      className={
        "sidenav-sublist__item-content clearfix" +
        (active ? " sidenav-sublist__item-content--active" : "")
      }
    >
      <span
        onClick={onClick}
        className="sidenav-sublist__item-content-left text-subheader light-default"
      >
        { contentLeft }
      </span>
      <span
        className="sidenav-sublist__item-content-right text-caption light-disabled"
      >
        { contentRight }
      </span>
    </div>
    { children }
  </li>
)

export default SideNavSubitem;
