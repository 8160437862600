import {
  RECEIVE_LISTA_DE_BOLOES
} from "../../actions/ActionTypes.js";

export default function boloesAtivos(state = [], action) {
  switch (action.type) {
    case RECEIVE_LISTA_DE_BOLOES:
      return action.boloesAtivos.map((bolao) => (
        bolaoAtivo(bolao, action)
      ));

    default:
      return state;
  }
};

const initialState = {
  id: 0,
  nome: ""
};

function bolaoAtivo(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LISTA_DE_BOLOES:
      return state;

    default:
      return state;
  }
};
