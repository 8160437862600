// React
import React from "react";

// Styles
import "./Aviso.css";

export default ({ type, className, children, ...props }) => {
  let classes = `aviso text-subheader light-default`;
  classes += type ? ` aviso--${type}` : "";
  classes += className ? ` ${className}` : "";

  return (
    <p className={classes} {...props}>
      { children }
    </p>
  );
};
