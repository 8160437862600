// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// React Router
import { withRouter } from "react-router-dom";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../../../actions/Usuario/UsuarioActions";
import * as actions from "../../../actions/Cadastro/CadastroBannerActions";

// Components
import InputCelular from "../../Common/Input/InputCelular";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import Aviso from "../../Common/Aviso/Aviso";
import Loader from "../../Common/Loader/Loader";

class CadastroBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      promocao: props.promocao,
      cadastroValido: false
    };
  }

  componentWillMount() {
    // Dispacha logout se tiver alguém logado
    // if (this.props.usuarioLogado) {
    //   this.props.logout();
    // }

    this.props.initCadastroBanner();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.cadastroBannerReducer.sucesso) {
      this.context.closeModal();
      this.props.history.push("/");
    }
  }

  realizarCadastro = (e) => {
    e.preventDefault();

    this.props.fetchCadastroBanner({
      login: this.state.login,
      promocao: this.state.promocao,
    });
  }

  renderButtonCadastrar(isFetching) {
    if (isFetching) {
      return (
        <ButtonSecondary disabled>
          <Loader type="white" />
        </ButtonSecondary>
      );
    }

    return (
      <ButtonSecondary
        disabled={!this.state.cadastroValido}
        setDisabled={!this.state.cadastroValido}
        className="disabled"
        type="submit">
        Cadastrar
      </ButtonSecondary>
    );
  }

  renderAviso(aviso) {
    if (aviso) {
      return (
        <div className="small-24 columns">
          <Aviso className="edge-top" type="error">
            { aviso }
          </Aviso>
        </div>
      );
    }

    return null;
  }

  _onChange = (e) => {
    const state = {...this.state};

    state.login = e.target.value.replace(/\D/g, "");

    // Verifica se o telefone foi preenchido por completo
    state.cadastroValido = state.login.length === 11;

    this.setState(state);
  }

  render() {
    const { isFetching, aviso } = this.props.cadastroBannerReducer;

    return (
      <form onSubmit={this.realizarCadastro}>
        <div className="row">
          <div className="small-24 columns column-block">
            <InputCelular
              type="primary"
              placeholder="Celular (00) 00000-0000"
              name="login"
              disabled={isFetching}
              onChange={this._onChange}
            />
          </div>
          <div className="small-24 columns">
            { this.renderButtonCadastrar(isFetching) }
          </div>
          { this.renderAviso(aviso) }
        </div>
      </form>
    );
  }
}

CadastroBanner.contextTypes = {
  closeModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    cadastroBannerReducer: state.cadastroBannerReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, logout }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CadastroBanner)
);
