// React
import React, { Component } from "react";

// Components
// import OldSideNav from "../OldSideNav/SideNav";

export default class ItemBolao extends Component {
  render() {
    const { nome, active, onClick } = this.props;

    return (
      <div
        className={`bolao-sidenav__item ${active ? "bolao-sidenav__item--active" : ""}`}
        onClick={onClick}
        title={nome}
      >
        <p className="text-subheader light-default truncate">
          {nome}
        </p>
      </div>
    );
  }
}
