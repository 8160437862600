import {
  RECEIVE_BANNER_PREMATCH,
  RECEIVE_BANNER_AO_VIVO,
  RECEIVE_BANNER_SLOT
} from "../../actions/ActionTypes.js";

import prematch from "./BannerPrematchReducer";
import aoVivo from "./BannerAoVivoReducer";
import slot from "./BannerSlotReducer";

const initialState = {
  prematch: prematch(undefined, {}),
  aoVivo: aoVivo(undefined, {}),
  slot: slot(undefined, {})
}

export default function bannerReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_BANNER_PREMATCH:
      return {
        ...state,
        prematch: prematch(undefined, action)
      };

    case RECEIVE_BANNER_AO_VIVO:
      return {
        ...state,
        aoVivo: aoVivo(undefined, action)
      };

    case RECEIVE_BANNER_SLOT:
      return {
        ...state,
        slot: slot(undefined, action)
      };

    default:
      return state;
  }
};
