// React
import React from "react";

// Styles
import "./Input.css";

// Components
import SimpleCurrencyInput from "react-simple-currency";

export default ({ id, value, type, onChange, className }) => {
  let classNames = "input-text text-title";
  classNames += `${type === "primary" ? (" input-text--primary dark-default") : " input-text--secondary"}`;
  classNames += ` ${className && className}`;

  return (
    <SimpleCurrencyInput
      id={id}
      className={classNames}
      value={value}
      precision={2}
      separator=','
      delimiter='.'
      onInputChange={onChange}
    />
  );
};
