import { combineReducers } from "redux";
import { filterActions } from 'redux-ignore';


// Reducers
import carrinho from "./Carrinho/CarrinhoReducer";
import modalidadesReducer from "./OldSideNav/ModalidadesReducer";
import eventosPorCampeonatoReducer from "./EventosPorCampeonato/EventosPorCampeonatoReducer";
import eventosAoVivoReducer from "./EventosPorCampeonato/EventosAoVivoReducer";
import configuracoesReducer from "./Configuracoes/ConfiguracoesReducer";
import usuarioReducer from "./Usuario/UsuarioReducer";
import cadastroPinReducer from "./Cadastro/CadastroPin/CadastroPinReducer";
import cadastroRapidoReducer from "./Cadastro/CadastroRapido/CadastroRapidoReducer";
import concluirCadastroReducer from "./Cadastro/ConcluirCadastro/ConcluirCadastroReducer";
import cadastroBannerReducer from "./Cadastro/CadastroBanner/CadastroBannerReducer";
import recuperarSenhaReducer from "./Usuario/RecuperarSenhaReducer";
import apostasReducer from "./Configuracoes/Apostas/ApostasReducer";
import extratoReducer from "./Configuracoes/Extrato/ExtratoReducer";
import saquesReducer from "./Configuracoes/Saques/SaquesReducer";
import inserirCreditosReducer from "./Configuracoes/InserirCreditos/InserirCreditosReducer";
import promocoesReducer from "./Configuracoes/Promocoes/PromocoesReducer";
import validarUsuarioReducer from "./Configuracoes/Cadastro/ValidarUsuarioReducer";
import alterarSenhaReducer from "./Configuracoes/Cadastro/AlterarSenhaReducer";
import eticketReducer from "./Eticket/EticketReducer";
import comprovantesReducer from "./Comprovantes/ComprovantesReducer";
import modalidadesEventosPrematchReducer from "./ModalidadesEventos/ModalidadesEventosPrematchReducer";
import modalidadesEventosAoVivoReducer from "./ModalidadesEventos/ModalidadesEventosAoVivoReducer";
import jogosReducer from "./Jogos/JogosReducer";
import lotericosReducer from "./Lotericos/LotericosReducer"
import permissoesReducer from "./Permissoes/PermissoesReducer";
import bannerReducer from "./Banner/BannerReducer";
import bolaoReducer from "./Bolao/BolaoReducer";
import bolaoCarrinhoReducer from "./Bolao/Carrinho/BolaoCarrinhoReducer";
import bolaoRankingReducer from "./Bolao/BolaoRankingReducer";
import helloBarReducer from "./HelloBar/HelloBarReducer";
import consultaBilheteReducer from "./ConsultaBilhete/ConsultaBilheteReducer";
import lotericoTradicionaisReducer from "./LotericoTradicional/LotericoTradicionalReducer"
import privateStream, { types as privateStreamTypes } from './privateStream/privateStream';

const ignoredReducer = (reducer, types) => {
  return filterActions(reducer, Object.keys(types).map(type => types[type]));
};

const rootReducer = combineReducers({
  carrinho,
  modalidadesReducer,
  eventosPorCampeonatoReducer,
  eventosAoVivoReducer,
  usuarioReducer,
  cadastroPinReducer,
  cadastroRapidoReducer,
  concluirCadastroReducer,
  cadastroBannerReducer,
  recuperarSenhaReducer,
  configuracoesReducer,
  apostasReducer,
  extratoReducer,
  saquesReducer,
  inserirCreditosReducer,
  promocoesReducer,
  validarUsuarioReducer,
  alterarSenhaReducer,
  eticketReducer,
  comprovantesReducer,
  modalidadesEventosPrematchReducer,
  modalidadesEventosAoVivoReducer,
  jogosReducer,
  lotericosReducer,
  permissoesReducer,
  bannerReducer,
  bolaoReducer,
  bolaoCarrinhoReducer,
  bolaoRankingReducer,
  helloBarReducer,
  consultaBilheteReducer,
  lotericoTradicionaisReducer,
  privateStream: ignoredReducer(privateStream, privateStreamTypes)
});

export default rootReducer;
