// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import Card from "../../Common/Card/Card";

// Libs
import moment from "moment";

class BolaoCarrinhoCard extends Component {
  renderHeader(nome) {
    return (
      <h3
        className="text-title accent-1"
        style={{padding: "0.6rem"}}>
        { nome }
      </h3>
    );
  }

  render() {
    const { nome, inicio, fim, qtdEventos } = this.props.bolaoCarrinhoReducer;

    return (
      <Card header={this.renderHeader(nome)}>
        <p className="text-subheader light-default edge-bottom">
          { moment(inicio).format("DD/MM") } - { moment(fim).format("DD/MM") }
        </p>
        <p className="text-subheader light-default edge-bottom">
          { qtdEventos } eventos
        </p>
      </Card>
    );
  }
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    bolaoCarrinhoReducer: state.bolaoCarrinhoReducer
  }
};

export default connect(
  mapStateToProps
)(BolaoCarrinhoCard);
