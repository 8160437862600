// React
import React, { Component } from "react";

// Components
import PaisCampeonato from "./PaisCampeonato";
import Evento from "../Evento/Evento";
import { typeEventoAoVivo } from "../EventosTypes";

class CampeonatoAoVivo extends Component  {
  render() {
    const { campeonato } = this.props;

    return(
      <div>
        <PaisCampeonato pais={campeonato.pais} campeonato={campeonato.nome} type={typeEventoAoVivo} />
        {
          campeonato.eventos.map((evento, index) => (
            <Evento key={index} idCampeonato={campeonato.id} evento={evento} tipoEvento={typeEventoAoVivo} />
          ))
        }
      </div>
    )
  }
}


export default CampeonatoAoVivo;
// export default ({ campeonato }) => (
//   <div>
//     <Card className="text-small light-active hide-for-small-only edge-padding-8">
//       <p>
//         Vencedor do Encontro (1 - X - 2),Handicap de Gols (Europeu) (H -1) ,Ambas Marcam (S - N), Dupla Possibilidade (C/E - F/E), Total de Gols (TG +1,TG -1)
//       </p>
//     </Card>
//     <PaisCampeonato pais={campeonato.pais} campeonato={campeonato.nome} />
//     {
//       campeonato.momentos.map((momento, index) => (
//         <Momento key={index} idCampeonato={campeonato.id} momento={momento} />
//       ))
//     }
//   </div>
// );
