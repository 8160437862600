import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { checarPermissao } from "../../modules/helpers";
import LotericoTradicional from "./LotericoTradicional";
import "./LotericoTradicional.css";

class LotericoTradicionais extends Component {
    componentDidMount() {
        if (!checarPermissao(7, this.props.produtosPermitidos)) {
            this.props.history.push("/");
            return;
        }
    }

    render() {
        const { exibirEsportes } = this.props

        return (
            <JogosContainer>
                <Switch>
                    {!exibirEsportes && <Route exact path="/" component={LotericoTradicional} />}
                    <Route exact path="/tradicional" component={LotericoTradicional} />
                </Switch>
            </JogosContainer>
        );
    }
}

const JogosContainer = ({ banner, children }) => (
    <main className="small-24 columns">
        {banner}
        <div className="main main--block">
            <div className="inner-row-wrapper">
                {children}
            </div>
        </div>
    </main>
);

const mapStateToProps = (state) => {
    return {
        produtosPermitidos: state.permissoesReducer.produtosPermitidos,
        exibirEsportes: state.permissoesReducer.configuracoes.exibirEsportes
    };
};

export default withRouter(
    connect(mapStateToProps)(LotericoTradicionais)
);
