// <title>Nome Fantasia</title>
export const addTitle = (nomeFantasia) => {
  const title = document.createElement("title");
  const textNode = document.createTextNode(nomeFantasia || "");
  title.appendChild(textNode);
  document.getElementsByTagName("head")[0].appendChild(title);
};

// <link rel="manifest" href="/manifest.json">
export const addManifest = (pathAssets) => {
  const link = document.createElement("link");
  link.rel = "manifest";

  let manifestHref = `${process.env.PUBLIC_URL}/manifest/`;

  if (pathAssets) {
    manifestHref += `${pathAssets}-manifest.json`;
  }
  else {
    manifestHref += "default-manifest.json";
  }

  link.href = manifestHref;
  document.getElementsByTagName("head")[0].appendChild(link);
};

export const addFavicon = (pathAssets) => {
  const head = document.getElementsByTagName("head")[0];
  const iconPath = `${process.env.PUBLIC_URL}/icon/${pathAssets || "default"}`;

  const link = document.createElement("link");
  link.rel = "shortcut icon";
  link.type = "image/png";

  // <link rel="shortcut icon" type="image/png" sizes="16x16" href="%PUBLIC_URL%/icon/favicon-16x16.png">
  const favicon16 = link.cloneNode();
  favicon16.sizes = "16x16";
  favicon16.href = `${iconPath}/favicon-16x16.png`;
  head.appendChild(favicon16);

  // <link rel="shortcut icon" type="image/png" sizes="32x32" href="%PUBLIC_URL%/icon/favicon-32x32.png">
  const favicon32 = link.cloneNode();
  favicon32.sizes = "32x32";
  favicon32.href = `${iconPath}/favicon-32x32.png`;
  head.appendChild(favicon32);
};

export const addAppleIcons = (pathAssets) => {
  const head = document.getElementsByTagName("head")[0];
  const iconPath = `${process.env.PUBLIC_URL}/icon/${pathAssets || "default"}`;

  // <link rel="apple-touch-icon" sizes="180x180" href="%PUBLIC_URL%/icon/apple-touch-icon.png">
  const appleTouchIcon = document.createElement("link");
  appleTouchIcon.rel = "apple-touch-icon";
  appleTouchIcon.sizes = "180x180";
  appleTouchIcon.href = `${iconPath}/apple-touch-icon.png`;
  head.appendChild(appleTouchIcon);

  // <link rel="mask-icon" href="%PUBLIC_URL%/icon/safari-pinned-tab.svg" color="#424242">
  const maskIcon = document.createElement("link");
  maskIcon.rel = "mask-icon";
  maskIcon.color = "#424242";
  maskIcon.href = `${iconPath}/safari-pinned-tab.svg`;
  head.appendChild(maskIcon);
};

const addHeadInfo = ({ nomeFantasia, pathAssets = "" }) => {
  addTitle(nomeFantasia);
  addManifest(pathAssets);
  addFavicon(pathAssets);
  addAppleIcons(pathAssets);
};

export default addHeadInfo;
