import {
  INIT_EVENTOS_POR_CAMPEONATO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  RECEIVE_EVENTOS_AOVIVO,
  INIT_EVENTOS_AOVIVO,
  RECEIVE_EVENTOS_CAMPEONATO
} from "../../actions/ActionTypes.js";

import eventos from "./EventosReducer";

export default function momentos(state = [], action) {

  switch (action.type) {
    case INIT_EVENTOS_POR_CAMPEONATO:
    case RECEIVE_EVENTOS_CAMPEONATO:
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:

      return state.map(item =>
        momento(item, action)
      );

    default:
      return state;
  }
};

const initialState = {
  data: 0,
  info: "",
  eventos: []
};

function momento(state = initialState, action) {

  switch (action.type) {
    case RECEIVE_EVENTOS_AOVIVO:
    case INIT_EVENTOS_AOVIVO:
    case INIT_EVENTOS_POR_CAMPEONATO:
    case RECEIVE_EVENTOS_CAMPEONATO:
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      return {
        ...state,
        eventos: eventos(state.eventos, action)
      };

    default:
      return state;
  }
};
