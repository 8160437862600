// React
import React, { Component } from "react";

// React Router
import { Route, withRouter } from "react-router-dom";

// Componentes
// import Main from "../../Main/Main";
import EventosPorCampeonatoPrinter from "../../Main/EventosPorCampeonato/EventosPorCampeonatoPrinter";

class AppPrinter extends Component {
  render() {
    return (
        <div className="row-wrapper">
            <div className="row">
                <main className="medium-24 xlarge-24 columns">
                    <Route path="/impressao/eventos" component={EventosPorCampeonatoPrinter} />
                </main>
            </div>
        </div>
    );
  }
}

export default withRouter(AppPrinter);