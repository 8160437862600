// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import DropdownConfiguracoes from "./DropdownConfiguracoes";
import Download from "../../../static/svg/Download.js";

// Helpers
import { convertIntegerToMoney } from "../../../modules/helpers";

class UserInfo extends Component {
  render() {
    const { saldo, nome, linkAplicativo } = this.props;

    return (
      <div className="row align-middle">
        {/* < Medium */}
        <div className="small-24 columns text-right hide-for-large">
          <MediumGrid>
            <div>
              {!!linkAplicativo && (
                <a href={linkAplicativo}>
                  <Download />
                </a>
              )}
            </div>
            <DropdownConfiguracoes />
          </MediumGrid>
        </div>

        {/* > Large */}
        <div className="small-14 medium-13 medium-offset-9 columns text-right show-for-large edge-top">
          <LargeGrid>
            <div>
              {!!linkAplicativo && (
                <a href={linkAplicativo}>
                  <Download />
                </a>
              )}
            </div>
            <div>
              <p className="text-title accent-1 edge-padding-4">{ nome }</p>
              <p className="text-body light-default edge-padding-4">R$ { convertIntegerToMoney(saldo) }</p>
            </div>
          </LargeGrid>
        </div>
        <div className="small-1 medium-2 columns text-center show-for-large edge-top">
          <DropdownConfiguracoes />
        </div>
      </div>
    );
  }
}

const MediumGrid = ({ children }) => (
  <div style={{
    display: 'grid',
    gridTemplateColumns: '3.2rem 2.4rem',
    gridGap: '0.8rem',
    justifyContent: 'end',
    alignItems: 'center',
  }}>
    {children}
  </div>
);

const LargeGrid = ({ children }) => (
  <div style={{
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridGap: '1.6rem',
    alignItems: 'center'
  }}>
    {children}
  </div>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    linkAplicativo: state.permissoesReducer.configuracoes.linkAplicativo
  }
};

export default connect(mapStateToProps)(UserInfo);
