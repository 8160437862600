// Action Types
import {
  INIT_ALTERAR_SENHA,
  REQUEST_ALTERAR_SENHA,
  RECEIVE_ALTERAR_SENHA,
  ERROR_ALTERAR_SENHA
} from "../ActionTypes";

// Config
import { KEY_CLIENT_APP } from "../../config.js";

// Modules
import { doRequest } from "../../modules/request";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initAlterarSenha = () => ({
  type: INIT_ALTERAR_SENHA
});

export const requestAlterarSenha = () => ({
  type: REQUEST_ALTERAR_SENHA
});

export const receiveAlterarSenha = () => ({
  type: RECEIVE_ALTERAR_SENHA
});

export const errorAlterarSenha = (error) => ({
  type: ERROR_ALTERAR_SENHA,
  error
});

export const fetchAlterarSenha = ({ novaSenha, confirmarSenha, senhaAtual }) => (dispatch, getState) => {
  dispatch(requestAlterarSenha());

  // Verifica se campos são válidos
  if (novaSenha !== confirmarSenha) {
    return dispatch(errorAlterarSenha("Nova senha não confere."));
  }

  const data = { novaSenha, senhaAtual };
  const accessToken = getState().usuarioReducer.usuario.accessToken;

  let headers = `AuthSnet `;
  headers += `accessToken="${accessToken}", `;
  headers += `idCliente="${KEY_CLIENT_APP}"`;

  const params = {
    url: "/user/password",
    method: "POST",
    headers: {
      Authorization: headers
    },
    accessToken,
    data
  };

  return doRequest(params)
    .then(response => {
      dispatch(receiveAlterarSenha());
      Snackbar("Senha alterada com sucesso", "Ok");
    })
    .catch(error => {
      dispatch(errorAlterarSenha(error));
    });
};
