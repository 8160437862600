import React, { Component } from "react";
import SideNavItem from "./SideNavItem";
import SideNavSubitem from "./SideNavSubitem";
import { InputSideBarPaisCheckbox } from "../Common/Input/InputCheckbox";

export class SideNavToggleItem extends Component {
  constructor() {
    super();
    this.state = {
      scrollHeight: 0
    }
  }

  componentDidMount() {
    const scrollHeight = this.div.scrollHeight;
    this.setState({ scrollHeight })
  }

  refreshScrollHeight = (height) => {
    const scrollHeight = this.state.scrollHeight + height;
    this.setState({ scrollHeight });
    this.forceUpdate();
  }

  render() {
    const { nome, quantidadeDeEventos, active, onClick,tipoNavItem } = this.props;

    const subitensWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        refreshScrollHeight: this.refreshScrollHeight
      })
    );

    return (
      <SideNavItem
        active={active}
        onClick={onClick}
        contentLeft={nome}
        contentRight={quantidadeDeEventos}
        tipoNavItem={tipoNavItem || ""}
      >
        <div
          ref={(node) => this.div = node}
          className="sidenav-sublist"
          style={{
            maxHeight: (active ? this.state.scrollHeight : 0)
          }}
        >
          <ul>
            { subitensWithProps }
          </ul>
        </div>
      </SideNavItem>
    )
  }
}

export class SideNavToggleSubitem extends Component {
  constructor() {
    super();
    this.state = {
      scrollHeight: 0
    }
  }

  componentDidMount() {
    const scrollHeight = this.div.scrollHeight;
    this.setState({ scrollHeight })
  }

  toggleAndRefresh = () => {
    this.props.onClick();
    if (this.props.active) {
      this.props.refreshScrollHeight(this.state.scrollHeight * -1);
    }
    else {
      this.props.refreshScrollHeight(this.state.scrollHeight);
    }
  }

  handleCheckPais = (modalidadeId,paisId,pais,check) => {
    this.props.onClickCheckBox(modalidadeId,paisId,pais,check);
  }

  render() {
    const {
      children: subitens,
      nome, quantidadeDeEventos, active,tipoSubItem,dado,quantidadeDeCampeonatosCheck
    } = this.props;

    const contentRight = (
      <div className="sublist-checkbox-wrapper">
        { typeof quantidadeDeCampeonatosCheck !== 'undefined' && quantidadeDeCampeonatosCheck > 0 ? quantidadeDeEventos + " ("+quantidadeDeCampeonatosCheck+")" : quantidadeDeEventos }
        <InputSideBarPaisCheckbox
          dado={{modalidadeId: dado.modalidadeId,paisId : dado.paisId, pais : dado.pais,check:dado.check}}
          onClick={this.handleCheckPais}/>
      </div>
    );

    return (
      <SideNavSubitem
        active={active}
        onClick={this.toggleAndRefresh}
        contentLeft={nome}
        contentRight={contentRight}
        tipoSubItem={tipoSubItem}
      >
        <div
          ref={(node) => this.div = node}
          className="sidenav-fixlist"
          style={{
            maxHeight: (active ? this.state.scrollHeight : 0)
          }}
        >
          { subitens }
        </div>
      </SideNavSubitem>
    )
  }
}
