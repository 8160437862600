// React
import React, { Component } from "react";

// Styles
import "./Dropdown.css";

export default class Dropdown extends Component {
  // Exibe ou oculta Dropdown
  toggleDropdown = () => {
    this.dropdown.classList.toggle("show");
  }

  // Oculta Dropdown se clicar em qualquer lugar da tela (window)
  handleDropdown = (event) => {
    if (
      !(event.target.matches(".dropdown__button")) &&
      !(event.target.matches(".dropdown__button *"))
    ) {
      if (this.dropdown.classList.contains("show")) {
        this.dropdown.classList.remove("show");
      }
    }
  }

  // Adiciona e remove listener do objeto window
  componentDidMount() {
    window.addEventListener("click", this.handleDropdown);
    window.addEventListener("touchend", this.handleDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleDropdown);
    window.removeEventListener("touchend", this.handleDropdown);
  }

  render() {
    const { buttonJSX, className, children } = this.props;

    return (
      <div className={`dropdown ${className}`}>
        <div
          onClick={this.toggleDropdown}
          className="dropdown__button"
        >
          { buttonJSX }
        </div>
        <div ref={(node) => this.dropdown = node} className="dropdown-content">
          { children }
        </div>
      </div>
    );
  }
}


// ref={(node) => this.dropdownButton = node}