// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Helpers
import { onlyAllowNumbers } from "../../../modules/helpers";

// Components
import InputTextPrimary from "../../Common/Input/InputTextPrimary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import ConsultaBilheteModal from "../../Modal/ConsultaBilhete/ConsultaBilhete";

export default class ConsultaBilhete extends Component {
  constructor(props) {
    super(props);
    this.state = { numeroBilhete: "" };
  }

  _onChange = (e) => {
    this.setState({ numeroBilhete: e.target.value });
  }

  _onSubmit = (e) => {
    e.preventDefault();

    this.context.openModal(
      `Consultar Bilhete`,
      <ConsultaBilheteModal
        numeroBilhete={this.state.numeroBilhete}
      />,
      "large"
    );

    this.setState({ numeroBilhete: "" });
  }

  render() {
    return (
      <div className="comprovantes edge-top show-for-xlarge">
        <div className="inner-row-wrapper">
          <form onSubmit={this._onSubmit} className="row">
            <div className="small-24 columns column-block">
              <h1 className="text-display edge-bottom light-default">Consultar Bilhete</h1>
            </div>
            <div className="small-24 columns column-block">
              <InputTextPrimary
                placeholder="Número do Bilhete"
                value={this.state.numeroBilhete}
                onKeyPress={(e) => onlyAllowNumbers(e)}
                onChange={this._onChange}
                type="tel"
              />
            </div>
            <div className="small-24 columns">
              <ButtonSecondary
                disabled={!this.state.numeroBilhete}
                setDisabled={!this.state.numeroBilhete}
              >
                Consultar
              </ButtonSecondary>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ConsultaBilhete.contextTypes = {
  openModal: PropTypes.func
};
