import {
  INIT_COMPROVANTES,
  ADICIONAR_COMPROVANTE
} from "../ActionTypes";

export const initComprovantes = () => ({
  type: INIT_COMPROVANTES
});

export const adicionarComprovante = (comprovante) => ({
  type: ADICIONAR_COMPROVANTE,
  comprovante
});

