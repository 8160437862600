// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout, fetchSaldo } from "../../../actions/Usuario/UsuarioActions";

// Components
import Dropdown from "../../Common/Dropdown/Dropdown";
import Configuracoes from "../../Modal/Configuracoes/Configuracoes";
import AlterarSenha from "../../Modal/Configuracoes/AlterarSenha/AlterarSenha";
import { convertIntegerToMoney, getDataMetadaContato } from "../../../modules/helpers";

const DropdownLink = ({ title, onClick }) => (
  <div onClick={onClick} className="text-title dark-default dropdown-content__item">
    { title }
  </div>
);

// Estilos do botão de Atualizar Saldo
const AtualizarCSS = {
  div: {
    position: "relative"
  },
  icon: {
    fontWeight: "normal",
    fontSize: "2.4rem",
    position: "absolute",
    right: "0.8rem",
    top: "48%",
    transform: "translateY(-50%)"
  }
};

const AtualizarSaldoButton = ({ onClick }) => (
  <div onClick={onClick} style={AtualizarCSS.div} className="text-title dark-default dropdown-content__item">
    Atualizar Saldo <span style={AtualizarCSS.icon} className="icon-atualizar"></span>
  </div>
);

// Nome e saldo do Usuário (visível em < Medium)
const InfoUsuario = ({ usuario, saldo }) => (
  <div className="dark-default inner-row-wrapper dropdown-content__info-usuario hide-for-large">
    <div className="row">
      <span className="small-12 columns text-title">
        {usuario}
      </span>
      <span className="small-12 columns text-subheader text-right">
        R$ {convertIntegerToMoney(saldo)}
      </span>
    </div>
  </div>
);

class DropdownConfiguracoes extends Component {
  renderModalConfiguracoes = (active) => {
    this.context.openModal(
      "Configurações",
      <Configuracoes active={active} />
    );
  }

  renderModalAlterarSenha = () => {
    this.context.openModal(
      "Alterar Senha",
      <AlterarSenha />,
      "small"
    );
  }

  renderDropdownButton() {
    return (
      <button className="light-secondary">
        <span style={{ fontSize: "2.4rem" }} className="icon-perfil" />
      </button>
    );
  }

  abrirContato() {
    window.location = getDataMetadaContato(this.props.contato,1)
  }

  render() {
    const { logout, showContato } = this.props;
    const nomeUsuario = this.props.usuarioReducer.usuario.nome;
    const saldoUsuario = this.props.usuarioReducer.usuario.configuracao.saldo;
    const dropdownButton = this.renderDropdownButton();

    // Permissão das Promoções
    const { exibirPromocoes } = this.props.permissoesReducer.configuracoes;

    return (
      <Dropdown buttonJSX={dropdownButton}>
        <InfoUsuario usuario={nomeUsuario} saldo={saldoUsuario} />
        <AtualizarSaldoButton onClick={this.props.fetchSaldo} />
        { showContato && <DropdownLink title="Fale Conosco" onClick={() => this.abrirContato()} />}
        <DropdownLink title="Apostas" onClick={() => this.renderModalConfiguracoes("apostas")} />
        <DropdownLink title="Extrato" onClick={() => this.renderModalConfiguracoes("extrato")} />
        <DropdownLink title="Saques" onClick={() => this.renderModalConfiguracoes("saques")} />
        <DropdownLink title="Inserir Créditos" onClick={() => this.renderModalConfiguracoes("inserirCreditos")} />
        {
          exibirPromocoes &&
          <DropdownLink title="Promoções" onClick={() => this.renderModalConfiguracoes("promocoes")} />
        }
        <DropdownLink title="Cadastro" onClick={() => this.renderModalConfiguracoes("cadastro")} />
        <DropdownLink title="Logout" onClick={() => logout()} />
      </Dropdown>
    );
  }
};

DropdownConfiguracoes.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    permissoesReducer: state.permissoesReducer,
    usuarioReducer: state.usuarioReducer,
    showContato: !!state.permissoesReducer.configuracoes.contato && state.permissoesReducer.configuracoes.contato.length > 0,
    contato: state.permissoesReducer.configuracoes.contato,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout, fetchSaldo }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownConfiguracoes);
