import { SET_CONFIGURACAO_ACTIVE, LOGOUT } from "../../actions/ActionTypes.js";

const initialState = { active: "" };

export default function configuracoesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIGURACAO_ACTIVE:
      return { active: action.active };

    case LOGOUT:
      return { active: "" };

    default:
      return state;
  }
};
