// React
import React from "react";

// Styles
import "./Input.css";

// Components
import MaskedInput from "react-text-mask";

const InputCupom = ({ name, value, type, success, error, disabled, onChange, onClickReenviar, className }) => {
  let classNames = "input-text text-title";
  classNames += `${type === "primary" ? " input-text--primary dark-default" : " input-text--secondary"}`;
  classNames += success ? " input-text--success" : "";
  classNames += error ? " input-text--error" : "";
  classNames += ` ${className && className}`;

  return (
    <div className="input-cupom edge-top">
      <MaskedInput
        type="text"
        name={name}
        value={value}
        mask={[/[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i, /[a-z0-9]/i]}
        placeholder="Código do Cupom"
        className={classNames}
        onChange={onChange}
        disabled={disabled || false}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
      {
        onClickReenviar && (
          <button
            className="input-cupom__reenviar"
            type="button"
            title="Enviar Cupom Novamente"
            disabled={disabled || false}
            onClick={onClickReenviar}
          >
            <span
              className={`icon-atualizar
                ${type === "primary" ? " dark-default" : " light-secondary"}`
              }
            >
            </span>
          </button>
        )
      }
    </div>
  );
};

export default InputCupom;
