// React
import React from "react";
import { render } from "react-dom";

// Libs
import initReactFastclick from "react-fastclick";
import 'rxjs';

// CSS
import "./static/index.css";

// Components
import Root from "./components/Root/Root";

// Others
import registerServiceWorker from './modules/registerServiceWorker';
import { store } from "./config";

// Inicia Fast-click para dispositivos touch
initReactFastclick();

render(<Root store={store} />, document.getElementById("root"));
registerServiceWorker();

// Deploy Heroku - Configura Codeship pra fazer deploy em todas as aplicações
