import {
  INIT_ALTERAR_SENHA,
  REQUEST_ALTERAR_SENHA,
  RECEIVE_ALTERAR_SENHA,
  ERROR_ALTERAR_SENHA,
} from "../../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  sucesso: false,
  aviso: "",
};

export default function alterarSenhaReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_ALTERAR_SENHA:
      return initialState;

    case REQUEST_ALTERAR_SENHA:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_ALTERAR_SENHA:
      return {
        isFetching: false,
        sucesso: true,
        aviso: ""
      };

    case ERROR_ALTERAR_SENHA:
      return {
        ...state,
        isFetching: false,
        aviso: action.error
      };

    default:
      return state;
  }
};
