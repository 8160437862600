// React
import React, {Component} from "react";

// React Router
import { Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Components
import Card from "../../../Common/Card/Card";

// Others
import { typeEventoAoVivo } from "../EventosTypes";

// Helpers
import { popupCenter } from "../../../../modules/helpers";

class CardEvento extends Component {

  Badge = (evento,tipoEvento) => {

    if(tipoEvento === typeEventoAoVivo){
      return (
        <div>
          <div className="card-evento__badge card-evento__badge--small show-for-small-only text-center">
          <span className="text-subheader light-secondary">
            {evento.periodo === "Não Iniciado" ? " " : evento.periodo === "Intervalo" ? "INT ": evento.periodo === "2º tempo" ? "2T " : "1T " }
          </span>
          <span className="text-subheader light-secondary">
            {(evento.tempoDecorridoMin === "0" || evento.tempoDecorridoMin == null || typeof evento.tempoDecorridoMin === 'undefined') && evento.periodo !== "Intervalo" ?
               evento.horario :  evento.periodo === "Intervalo" ?
                " ":evento.tempoDecorridoMin+"'" }
          </span>
          </div>
          <div className="card-evento__badge card-evento__badge--medium show-for-medium text-center">
            <span className="text-subheader light-secondary">
              {evento.periodo === "Não Iniciado" ? " " : evento.periodo === "Intervalo" ? "INT ": evento.periodo === "2º tempo" ? "2T " : "1T " }
            </span>
            <span className="text-subheader light-secondary">
            {(evento.tempoDecorridoMin === "0" || evento.tempoDecorridoMin == null || typeof evento.tempoDecorridoMin === 'undefined') && evento.periodo !== "Intervalo" ?
              evento.horario :  evento.periodo === "Intervalo" ?
               " ":evento.tempoDecorridoMin+"'" }
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="card-evento__badge card-evento__badge--small show-for-small-only text-center">
          <span className="text-subheader light-secondary">
            {evento.horario}
          </span>
          </div>
          <div className="card-evento__badge card-evento__badge--medium show-for-medium text-center">
            <span className="text-subheader light-secondary">
            {evento.horario }
            </span>
          </div>
        </div>
      );
    }
  }

  Title = (evento,tipoEvento) => {
    if(tipoEvento === typeEventoAoVivo){
      return (
        <h3 className="card-evento__title text-title light-default">
          {/* Small - Placar da Casa ao lado esquerdo */}
          <span className="show-for-small-only">
            <span className="accent-1">{evento.placar.split(":")[0]}</span> &nbsp;
          </span>

          { evento.casa }

          {/* Large - Placar da Casa ao lado direito */}
          <span className="hide-for-small-only">
            <span className="accent-1">&nbsp;{evento.placar.split(":")[0]}&nbsp;</span>
          </span>

          {/* Small - Quebra de linha entre os times */}
          <br className="show-for-small-only" />

          {/* Large - Placar do Fora ao lado esquerdo com vs. */}
          <span className="hide-for-small-only">
            {!this.props.outright && <span className="text-caption light-disabled">vs.&nbsp;</span>}
            <span className="accent-1">{evento.placar.split(":")[1]}&nbsp;</span>
          </span>

          {/* Large - Placar do Fora ao lado esquerdo */}
          <span className="show-for-small-only">
            <span className="accent-1">{evento.placar.split(":")[1]}</span> &nbsp;
          </span>

          { evento.fora }
        </h3>
      );
    }else {
      return (
        <h3 className="card-evento__title text-title light-default">
          { evento.casa }<span className="hide-for-small-only">&nbsp;&nbsp;</span>
          <div className="show-for-small-only"></div>
          {!this.props.outright && <span className="text-caption light-disabled hide-for-small-only">vs.</span>}
          <span className="hide-for-small-only">&nbsp;&nbsp;</span>{ evento.fora }
        </h3>
      );
    }

  }

  Header = (evento, tipoEvento, idCampeonato) => {
    const eventoAoVivo = tipoEvento === typeEventoAoVivo;
    const exibirEstatisticas = this.props.exibirEstatisticas ? true : false;
    const to = `/subevento/${eventoAoVivo ? "aovivo" : "prematch"}/${idCampeonato}/${evento.id}`;
    return (
      <Link to={to}>
        <div className="row align-middle">
          <div className="small-4 medium-3 large-2 columns">
            { this.Badge(evento, tipoEvento) }
          </div>
          <div className="small-18 medium-19 large-20 columns">
            { this.Title(evento, tipoEvento) }
          </div>
          {
            // Exibe ícone de ranking se as estatísticas forem habilitadas
            !this.props.outright && exibirEstatisticas ?
            (
              <div
                className="small-3 medium-2 columns text-right"
                onClick={(e) => this.popupEstatisticas(e, evento.id, eventoAoVivo)}
              >
                <span className="icon-ranking accent-1 card-evento__icon"></span>
                {evento.hasTVStream && <span className="icon-stream accent-1 card-evento__icon"></span>}
              </div>
            ) :
            (
              <div className="small-3 medium-2 columns text-right">
                <span className="icon-soma accent-1 card-evento__icon card-evento__icon--soma"></span>
                {evento.hasTVStream && <span className="icon-stream accent-1 card-evento__icon"></span>}
              </div>
            )
          }
        </div>
      </Link>
    );
  }

  // Redireciona para o popup de estatísticas
  popupEstatisticas = (e, idEvento, eventoAoVivo) => {
    e.preventDefault();
    const aoVivo = eventoAoVivo ? "true" : "false";
    popupCenter(`/estatisticas/${idEvento}/${aoVivo}`, "Estatísticas", 1000, 800);
  }

  render() {
    const { evento, children,tipoEvento,idCampeonato } = this.props;

    return (
      <Card
        header={this.Header(evento,tipoEvento,idCampeonato)}
      >
        { children }
      </Card>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    exibirEstatisticas: state.permissoesReducer.configuracoes.exibirEstatisticas
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CardEvento);
