// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Bolao/BolaoActions";
import { initCarrinho } from "../../../actions/Bolao/BolaoCarrinhoActions";

// React Router
import { Redirect, withRouter } from "react-router-dom";

// Styles
import "./Bolao.css";

// Components
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import Aviso from "../../Common/Aviso/Aviso";
import BolaoDetalhe from "./BolaoDetalhe";

// TODO: fazer lógica do carrinho do bolão ao invés
// do carrinho principal
class BolaoMain extends Component {
  // Faz fetch no detalhe do bolão novamente, caso usuário dê refresh
  componentWillMount() {
    //limpar o reducer do carrinho ao mudar de bolao
    this.props.initCarrinho();
    if (!this.props.permissaoBolao) {
      this.props.history.push("/");
    }

    const { idBolao } = this.props.match.params;
    const { campeonatos } = this.props.bolaoReducer.detalhe;

    if (idBolao && !campeonatos.length) {
      this.props.fetchBolaoDetalhe(idBolao);
    }
  }

  // Faz fetch em um novo detalhe ao mudar url do bolão
  componentWillUpdate(nextProps) {
    const { idBolao: currentIdBolao } = this.props.match.params;
    const { idBolao: nextIdBolao } = nextProps.match.params;

    if (currentIdBolao !== nextIdBolao) {
      //limpar o reducer do carrinho ao mudar de bolao
      this.props.initCarrinho();
      this.props.fetchBolaoDetalhe(nextIdBolao);

    }
  }

  // Redireciona para o primeiro bolão caso url seja "/bolao" apenas
  redirectToPrimeiroBolao = () => {
    const { ativos } = this.props.bolaoReducer;

    if (!ativos.length) {
      return (
        <div className="inner-row-wrapper">
          <div className="row">
            <div className="small-24 columns">
              <Aviso type="error">
                Nenhum bolão ativo no momento
              </Aviso>
            </div>
          </div>
        </div>
      );
    }

    const { id } = this.props.bolaoReducer.ativos[0];
    return <Redirect to={`/bolao/${id}`} />;
  }

  render() {
    const { idBolao } = this.props.match.params;
    const { isFetching } = this.props.bolaoReducer.detalhe;

    return (
      <MainContainer>
        {
          isFetching ? <LoaderCarregando /> :
          !idBolao ? this.redirectToPrimeiroBolao() :
          <BolaoDetalhe />
        }
        <CarrinhoMobile
          onClick={() => this.context.toggleRightSidebar(true)}
          bolaoPreenchido={this.props.bolaoPreenchido}
        />
      </MainContainer>
    );
  }
}

BolaoMain.contextTypes = {
  toggleRightSidebar: PropTypes.func
};

const MainContainer = ({ children }) => (
  <main className="medium-24 xlarge-14 columns">
    <div className="main">
      { children }
    </div>
  </main>
);

const CarrinhoMobile = ({ onClick, bolaoPreenchido }) => (
  <button
    className="carrinho-mobile hide-for-xlarge"
    onClick={onClick}
  >
    {
      bolaoPreenchido &&
      <span className="carrinho-mobile__qtd">1</span>
    }
    <span className="icon-carrinho light-active"></span>
  </button>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    permissaoBolao: state.permissoesReducer.permissoes.bolao,
    bolaoReducer: state.bolaoReducer,
    bolaoPreenchido: state.bolaoCarrinhoReducer.bolaoPreenchido
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, initCarrinho }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BolaoMain)
);
