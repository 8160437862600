// Action Types
import {
  INIT_CONCLUIR_CADASTRO,
  REQUEST_CONCLUIR_CADASTRO_DADOS,
  RECEIVE_CONCLUIR_CADASTRO_DADOS,
  ERROR_CONCLUIR_CADASTRO_DADOS,
  REQUEST_CONCLUIR_CADASTRO_FORMULARIO,
  RECEIVE_CONCLUIR_CADASTRO_FORMULARIO,
  ERROR_CONCLUIR_CADASTRO_FORMULARIO
} from "../ActionTypes";

// Actions
import { fetchLogin } from "../Usuario/UsuarioActions";

// Modules
import { doOdinRequest } from "../../modules/request";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initConcluirCadastro = () => ({
  type: INIT_CONCLUIR_CADASTRO
});

// Actions dos dados
export const requestConcluirCadastroDados = () => ({
  type: REQUEST_CONCLUIR_CADASTRO_DADOS
});

export const receiveConcluirCadastroDados = (descricao, login) => ({
  type: RECEIVE_CONCLUIR_CADASTRO_DADOS,
  descricao, login
});

export const errorConcluirCadastroDados = (error) => ({
  type: ERROR_CONCLUIR_CADASTRO_DADOS,
  error
});

export const fetchConcluirCadastroDados = (token) => (dispatch, getState) => {
  dispatch(requestConcluirCadastroDados());

  const params = {
    url: "/sp/site/usuario/concluirCadastro/preconsulta",
    method: "GET",
    params: { token }
  };

  return doOdinRequest(params)
    .then(response => {
      if (!response.codResposta) {
        return dispatch(errorConcluirCadastroDados(response.response.data.message));
      }

      if (response.codResposta === "000") {
        dispatch(receiveConcluirCadastroDados(`${response.nome}: ${response.descricao}`, response.login));
      }
      else {
        dispatch(errorConcluirCadastroDados(response.messagem));
      }
    }).catch(error => {
      dispatch(errorConcluirCadastroDados(error));
    });
};

// Actions do formulário
export const requestConcluirCadastroFormulario = () => ({
  type: REQUEST_CONCLUIR_CADASTRO_FORMULARIO
});

export const receiveConcluirCadastroFormulario = () => ({
  type: RECEIVE_CONCLUIR_CADASTRO_FORMULARIO
});

export const errorConcluirCadastroFormulario = (error) => ({
  type: ERROR_CONCLUIR_CADASTRO_FORMULARIO,
  error
});

export const fetchConcluirCadastroFormulario = ({ token, login, senha, confirmarSenha }) => (dispatch, getState) => {
  dispatch(requestConcluirCadastroFormulario());

  // Verifica se campos são válidos
  const validarCampos = checarCampos(senha, confirmarSenha);

  if (!validarCampos.valido) {
    return dispatch(errorConcluirCadastroFormulario(validarCampos.error));
  }

  const params = {
    url: "/sp/site/usuario/concluirCadastro",
    method: "POST",
    data: { token, senha }
  };

  return doOdinRequest(params)
    .then(response => {
      if (!response.codResposta) {
        return dispatch(errorConcluirCadastroFormulario(response.response.data.message));
      }

      if (response.codResposta === "000") {
        dispatch(receiveConcluirCadastroFormulario());
        dispatch(fetchLogin(login, senha));
        Snackbar("Cadastro realizado com sucesso", "Ok");
      }
      else {
        dispatch(errorConcluirCadastroFormulario(response.mensagem));
      }
    }).catch(error => {
      dispatch(errorConcluirCadastroFormulario(error));
    });
};

const checarCampos = (senha, confirmarSenha) => {
  // Se a senha não conferir
  if (senha !== confirmarSenha) {
    return { valido: false, error: "Senha não confere" };
  }

  // Se a senha for menor que 6 dígitos
  if (senha.length < 6) {
    return { valido: false, error: "Senha deve ter 6 ou mais dígitos" };
  }

  // Se a senha não possuir ao menos uma letra e um número
  if (senha.search(/\d/) === -1 || senha.search(/[a-zA-Z]/) === -1) {
    return { valido: false, error: "Senha deve conter ao menos uma letra e um número" };
  }

  return { valido: true };
};
