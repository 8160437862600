import {
  RECEIVE_JOGOS_LEANDER
} from "../../actions/ActionTypes.js";

export default function jogos(state = [], action) {
  switch (action.type) {
    case RECEIVE_JOGOS_LEANDER:
      return state.map(itemJogo => jogo(itemJogo, action));

    default:
      return state;
  }
};

const initialState = {
  id: "",
  nome: "",
  urlImagem: "",
  valorMinimo: 0,
  valorMaximo: 0,
  jackpots: [],
};

function jogo (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_JOGOS_LEANDER:
      return {
        id: state.idGame,
        nome: state.nomeGame,
        urlImagem: state.urlImagem,
        valorMinimo: state.valorMin,
        valorMaximo: state.valorMax,
        jackpots: state.jackpots,
      };

    default:
      return state;
  }
};
