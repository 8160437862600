import {
  INIT_BOLAO,
  REQUEST_LISTA_DE_BOLOES,
  RECEIVE_LISTA_DE_BOLOES,
  ERROR_LISTA_DE_BOLOES,
  REQUEST_BOLAO_DETALHE,
  RECEIVE_BOLAO_DETALHE,
  ERROR_BOLAO_DETALHE,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA,
  LIMPAR_CARRINHO_BOLAO,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS
} from "../../actions/ActionTypes.js";

import boloesAtivos from "./BoloesAtivosReducer";
import boloesInativos from "./BoloesInativosReducer";
import bolaoDetalhe from "./BolaoDetalheReducer";

const initialState = {
  isFetching: false,
  aviso: "",
  ativos: boloesAtivos(undefined, {}),
  inativos: boloesInativos(undefined, {}),
  detalhe: bolaoDetalhe(undefined, {})
};

export default function bolaoReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_BOLAO:
      return initialState;

    case REQUEST_LISTA_DE_BOLOES:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_LISTA_DE_BOLOES:
      return {
        ...state,
        isFetching: false,
        ativos: boloesAtivos(undefined, action),
        inativos: boloesInativos(undefined, action)
      };

    case ERROR_LISTA_DE_BOLOES:
      return {
        ...state,
        isFetching: false,
        aviso: action.error
      };

    case REQUEST_BOLAO_DETALHE:
    case RECEIVE_BOLAO_DETALHE:
    case ERROR_BOLAO_DETALHE:
      return {
        ...state,
        detalhe: bolaoDetalhe(undefined, action)
      };

    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA:
    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA:
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
    case LIMPAR_CARRINHO_BOLAO:
      return {
        ...state,
        detalhe: bolaoDetalhe(state.detalhe, action)
      };

    default:
      return state;
  }
};

