import {
  TOGGLE_FIXITENS,
  CHECK_PAIS_SIDEBAR,
  REMOVE_CHECK_PAIS_SIDEBAR,
  CHECK_CAMPEONATO_SIDEBAR,
  REMOVE_CHECK_CAMPEONATO_SIDEBAR,
  RECEIVE_MODALIDADES
} from "../../actions/ActionTypes.js";

import campeonatos from "./CampeonatoReducer";

const initialState = {
  id: null,
  nome: "",
  quantidadeDeEventos: 0,
  quantidadeDeCampeonatosCheck: 0,
  campeonatos: [],
  active: false,
  check: null
};

export default function pais(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FIXITENS:
      if(state.id === action.idPais){
        return {
          ...state,
          active: !state.active
        };
      }else {
        return {
          ...state,
          active: false
        };
      }

    case CHECK_PAIS_SIDEBAR:
      return {
        ...state,
        check: true
      }
    case REMOVE_CHECK_PAIS_SIDEBAR:
      return {
        ...state,
        check: true
      }
    case CHECK_CAMPEONATO_SIDEBAR:
    case REMOVE_CHECK_CAMPEONATO_SIDEBAR:
    {
      const index = state.campeonatos.findIndex(campeonato => campeonato.id === action.idCampeonato);

      if(typeof state.quantidadeDeCampeonatosCheck === "undefined") {
        state.quantidadeDeCampeonatosCheck = 0;
      }

      if(action.type === CHECK_CAMPEONATO_SIDEBAR) {

        if(typeof state.campeonatos[index].check === "undefined" ||  state.campeonatos[index].check === false){
          state.quantidadeDeCampeonatosCheck += 1
        }

      }else{
        if(typeof state.campeonatos[index].check === "undefined" ||  state.campeonatos[index].check === true){
          state.quantidadeDeCampeonatosCheck -= 1
        }

      }

      if(state.campeonatos.length === state.quantidadeDeCampeonatosCheck) {
        state.check = true;
      } else {
        state.check = false;
      }

      return {
        ...state,
        campeonatos: [
          ...state.campeonatos.slice(0, index),
          campeonatos(state.campeonatos[index], action),
          ...state.campeonatos.slice(index + 1)
        ]
      };
    }
    case RECEIVE_MODALIDADES:
    {
      for(let i in action.idCampeonatos) {
        const index = state.campeonatos.findIndex(campeonato => campeonato.id === action.idCampeonatos[i]);

        if (index >= 0) {

          if(typeof state.quantidadeDeCampeonatosCheck === "undefined") {
            state.quantidadeDeCampeonatosCheck = 0;
          }

          if(typeof state.campeonatos[index].check === "undefined" ||  state.campeonatos[index].check === false){
            state.quantidadeDeCampeonatosCheck += 1
          }

          if(state.campeonatos.length === state.quantidadeDeCampeonatosCheck) {
            state.check = true;
          } else {
            state.check = false;
          }

          state.campeonatos  = [
            ...state.campeonatos.slice(0, index),
            campeonatos(state.campeonatos[index], action),
            ...state.campeonatos.slice(index + 1)
          ];

        }
      }

      return {...state};
    }
    default:
      return state;
  }
};
