// React
import React from "react";

// Styles
import "./Card.css";

const Card = ({ className, header, children }) => (
  <div className={`card ${className || ''}`}>
    {
      header &&
      <header className="card-header">
        { header }
      </header>
    }
    <div className="card-content">
      { children }
    </div>
  </div>
);

export default Card;
