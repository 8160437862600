// React
import React from "react";
import PropTypes from "prop-types";

// Styles
import "./Filter.css";

const Filter = ({ itens }) => (
  <div className="filter">
    <ul className="filter-list">
      {
        itens.map((item, index) => {
          const { onClick, active, text } = item;

          return (
            <li
              key={index}
              onClick={onClick}
              className={
                "filter-list__item text-title" +
                (active ? " light-default filter-list__item--active" : " light-secondary")
              }
            >
              { text }
            </li>
          )
        })
      }
    </ul>
  </div>
);

Filter.propTypes = {
  itens: PropTypes.array.isRequired
};

export default Filter;
