// Action Types
import {
  INIT_LOGIN,
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  ERROR_LOGIN,
  LOGOUT,
  ATUALIZAR_SALDO,
  VALIDAR_USUARIO
} from "../ActionTypes";

import { KEY_CLIENT_APP } from "../../config";

// Modules
import { doRequest, doOdinRequest } from "../../modules/request";
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initLogin = () => ({
  type: INIT_LOGIN
});

export const requestLogin = () => ({
  type: REQUEST_LOGIN
});

export const receiveLogin = (usuario) => ({
  type: RECEIVE_LOGIN,
  usuario
});

export const errorLogin = (error) => ({
  type: ERROR_LOGIN,
  error
});

export const logout = () => ({
  type: LOGOUT,
});

export const fetchLogin = (user, password) => (dispatch, getState) => {
  dispatch(requestLogin());

  const params = {
    url: "/login",
    method: "POST",
    data: { user, password, idClient: KEY_CLIENT_APP || 12 }
  };

  return doRequest(params)
    .then(response => {
      if (!response.usuario) {
        return Promise.reject(response.message);
      }

      dispatch(receiveLogin(response.usuario))
    })
    .catch(error => {
      dispatch(errorLogin(error));
    });
};

export const atualizarSaldo = (saldo) => ({
  type: ATUALIZAR_SALDO,
  saldo
});

export const validarUsuario = () => ({
  type: VALIDAR_USUARIO
});

export const fetchSaldo = () => (dispatch, getState) => {
  const { accessToken } = getState().usuarioReducer.usuario;

  const params = {
    url: "/sp/saldo/apostador",
    method: "GET",
    accessToken
  };

  return doOdinRequest(params)
    .then(response => {
      dispatch(atualizarSaldo(response.saldo));
    },
      error => {
        dispatch(logout());
        Snackbar("Sessão Expirada", "Ok");
      });
};
