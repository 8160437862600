import {
  RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  RECEIVE_MODALIDADES_EVENTOS_AOVIVO
} from "../../actions/ActionTypes.js";

import subeventos from "../EventosPorCampeonato/SubeventosReducer";

const cotacaoInitialState = {
  titulo : "",
  subeventos : []
}

export default function cotacao(state = cotacaoInitialState, action) {

  switch (action.type) {
    case RECEIVE_MODALIDADES_EVENTOS_AOVIVO:
    case RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO:
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      if(!state.subeventos || state.subeventos.length <=0) {
        return state;
      }else{
        return {
          ...state,
          subeventos: subeventos(state.subeventos, action)
        }
      }


    default:
      return state;
  }
}
