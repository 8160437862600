import { RECEIVE_BANNER_PREMATCH } from "../../actions/ActionTypes.js";

const initialState = {
  media: []
};

export default function prematch(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_BANNER_PREMATCH:
      return { media: action.media };

    default:
      return state;
  }
};
