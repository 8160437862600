// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// React Router
import { Switch, Route, withRouter } from "react-router-dom";

// Components
import Carrinho from "./Carrinho/Carrinho";
import BolaoCarrinho from "./Bolao/BolaoCarrinho";
import Eticket from "./Eticket/Eticket";
import Comprovantes from "./Comprovantes/Comprovantes";
import ConsultaBilhete from "./ConsultaBilhete/ConsultaBilhete";

class Aside extends Component {
  render() {
    const { etickets } = this.props.eticketReducer;
    const { comprovantes } = this.props.comprovantesReducer;
    const { logado } = this.props.usuarioReducer;
    const renderEtickets = etickets.length > 0;
    const renderComprovantes = comprovantes.length > 0 && logado;

    return (
      <aside className={`small-24 xlarge-5 columns ${this.props.className}`}>
        <Switch>
          <Route path="/bolao" component={BolaoCarrinho} />
          <Route path="/" component={Carrinho} />
        </Switch>
        <ConsultaBilhete />
        { renderComprovantes && <Comprovantes /> }
        { renderEtickets && <Eticket /> }
      </aside>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    usuarioReducer: state.usuarioReducer,
    eticketReducer: state.eticketReducer,
    comprovantesReducer: state.comprovantesReducer
  }
};

export default withRouter(
  connect(
    mapStateToProps,
  )(Aside)
);
