// React
import React, { Component } from "react";
import { connect } from "react-redux";

// Componentes
import AppEsportes from "./AppEsportes";
import AppJogos from "./AppJogos";

class AppPrincipal extends Component {
  render() {
    const { exibirEsportes } = this.props.configuracoes;
    const { permissoesIsFetch } = this.props;
    
    return (
      <div>
      {
        !permissoesIsFetch ? (
              exibirEsportes ? <AppEsportes /> : <AppJogos />
        ) : null
      }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  configuracoes: state.permissoesReducer.configuracoes,
  permissoesIsFetch: state.permissoesReducer.isFetching
})

export default connect(mapStateToProps, null)(AppPrincipal)