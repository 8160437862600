// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// React Router
import { withRouter } from "react-router-dom";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../../../actions/Usuario/UsuarioActions";
import * as actions from "../../../actions/Cadastro/ConcluirCadastroActions";

// Styles
import "./Cadastro.css";

// Components
import InputCelular from "../../Common/Input/InputCelular";
import InputTextPrimary from "../../Common/Input/InputTextPrimary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import Aviso from "../../Common/Aviso/Aviso";
import Loader from "../../Common/Loader/Loader";
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";

class ConcluirCadastro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      senha: "",
      confirmarSenha: "",
      cadastroValido: false
    };
  }

  componentWillMount() {
    // Dispacha logout se tiver alguém logado
    if (this.props.usuarioLogado) {
      this.props.logout();
    }

    this.props.initConcluirCadastro();

    // O token ta sendo mandado por props, pois o Router não funcionou
    this.props.fetchConcluirCadastroDados(this.props.token);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.formularioReducer.sucesso) {
      this.context.closeModal();
      this.props.history.push("/");
    }
  }

  realizarCadastro = (e) => {
    e.preventDefault();

    this.props.fetchConcluirCadastroFormulario({
      login: this.props.dadosReducer.login,
      token: this.props.token,
      senha: this.state.senha,
      confirmarSenha: this.state.confirmarSenha
    });
  }

  renderButtonCadastrar(isFetching) {
    if (isFetching) {
      return (
        <ButtonSecondary disabled>
          <Loader type="white" />
        </ButtonSecondary>
      );
    }

    return (
      <ButtonSecondary
        disabled={!this.state.cadastroValido}
        setDisabled={!this.state.cadastroValido}
        className="disabled"
        type="submit">
        Cadastrar
      </ButtonSecondary>
    );
  }

  renderAviso(aviso) {
    if (aviso) {
      return (
        <div className="small-24 columns">
          <Aviso className="edge-top" type="error">
            { aviso }
          </Aviso>
        </div>
      );
    }

    return null;
  }

  _onChange = (e) => {
    const state = {...this.state};

    state[e.target.name] = e.target.value;

    // Verifica se todos os campos foram preenchidos
    state.cadastroValido = (
      state.senha !== "" &&
      state.confirmarSenha !== ""
    );

    this.setState(state);
  }

  render() {
    let dados;
    {
      const { isFetching, login, aviso } = this.props.dadosReducer;
      dados = { isFetching, login, aviso };
    }

    let formulario;
    {
      const { isFetching, aviso } = this.props.formularioReducer;
      formulario = { isFetching, aviso };
    }

    return (
      <form onSubmit={this.realizarCadastro}>
        {
          dados.isFetching ? <LoaderCarregando /> :
          dados.aviso ? (
            <Aviso type="error">
              { dados.aviso }
            </Aviso>
          ) :
          (
            <div className="row">
              <div className="small-24 columns column-block">
                <InputCelular
                  type="primary"
                  placeholder="Celular (00) 00000-0000"
                  value={dados.login}
                  disabled
                />
              </div>
              <div className="small-24 columns column-block">
                <InputTextPrimary
                  placeholder="SENHA"
                  name="senha"
                  className="text-transform-none"
                  type="password"
                  onChange={this._onChange}
                />
              </div>
              <div className="small-24 columns column-block">
                <InputTextPrimary
                  placeholder="CONFIRMAR SENHA"
                  name="confirmarSenha"
                  className="text-transform-none"
                  type="password"
                  onChange={this._onChange}
                />
              </div>
              <div className="small-24 columns">
                { this.renderButtonCadastrar(formulario.isFetching) }
              </div>
              { this.renderAviso(formulario.aviso) }
            </div>
          )
        }
      </form>
    );
  }
}

ConcluirCadastro.contextTypes = {
  closeModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    dadosReducer: state.concluirCadastroReducer.dados,
    formularioReducer: state.concluirCadastroReducer.formulario,
    usuarioLogado: state.usuarioReducer.logado
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, logout }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConcluirCadastro)
);
