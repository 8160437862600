// React
import React, {Component} from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as actions from "../../../../actions/Carrinho/CarrinhoActions";

import Subeventos from "./Subeventos";

class Cotacoes extends Component {
  renderCotacoes = () => {
    const { cotacao, idCampeonato, eventoAoVivo, outright } = this.props;

    return (
      <div>
        <h2 className="text-title light-default edge-bottom">{cotacao.titulo}</h2>
        {
          typeof cotacao.info !== "undefined" ? <p className="text-small light-secondary edge-bottom">
            {cotacao.info}
          </p> : <span></span>
        }
        <div className="row">
          {
            cotacao.subeventos.map((subevento,index) => (
              <Subeventos
                key={index}
                subevento={subevento}
                idCampeonato={idCampeonato}
                eventoAoVivo={eventoAoVivo}
                outright={outright}
              />
            ))
          }
        </div>
      </div>
    )
  }

  render () {
    const { cotacao } = this.props;

    if (cotacao.subeventos == null) {
      return <span/>;
    }
    else {
      return this.renderCotacoes();
    }
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {}
};

// Apenas Actions do Carrinho
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cotacoes);
