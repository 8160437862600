// React
import React, { Component } from "react";

// Helpers
import { convertIntegerToMoney } from "../../../../modules/helpers";

// Components
import InputMoney from "../../../Common/Input/InputMoney";
import Cupom from "./Cupom";

const ValorApostaContainer = ({ children }) => (
  <div className="row edge-padding-8">
    <div className="small-24 columns">
      { children }
    </div>
  </div>
);

const ValorTotal = ({ valorTotal, children }) => (
  <div className="row align-middle edge-bottom">
    <div className="small-15 columns">
      <p className="text-title style-aposta-e-retorno">Valor da Aposta</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline light-default">
        { convertIntegerToMoney(valorTotal) }
      </p>
    </div>
  </div>
);

const PossivelRetorno = ({ possivelRetorno }) => (
  <div className="row align-middle edge-bottom">
    <div className="small-15 columns">
      <p className="text-title style-aposta-e-retorno">Possível Retorno</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline accent-1">
        { convertIntegerToMoney(possivelRetorno) }
      </p>
    </div>
  </div>
);

export default class ValorAposta extends Component {
  // Transforma o InputMoney em type="tel".
  // (Auxilia teclados do mobile para aparecerem apenas números)
  componentDidMount() {
    document.getElementById("valor-aposta").setAttribute("type", "tel");
  }

  render() {
    const { valorTotal, possivelRetorno, atualizarPossivelRetorno } = this.props;

    return (
      <ValorApostaContainer>
        <ValorTotal valorTotal={valorTotal} />
        <PossivelRetorno possivelRetorno={possivelRetorno} />
        <InputMoney
          id="valor-aposta"
          type="primary"
          value={valorTotal}
          onChange={(novoValor) => atualizarPossivelRetorno(novoValor)}
        />
        <Cupom valorTotal={valorTotal} possivelRetorno={possivelRetorno} />
      </ValorApostaContainer>
    );
  }
};
