import {
  ADICIONAR_ETICKET,
  ADICIONAR_ETICKET_BOLAO,
  REMOVER_ETICKET
} from "../../actions/ActionTypes.js";

import itens from "./ItensEticketReducer";

const initialState = {
  etickets: itens(undefined, {})
}

export default function eticketReducer(state = initialState, action) {
  switch (action.type) {
    case ADICIONAR_ETICKET:
    case ADICIONAR_ETICKET_BOLAO:
    case REMOVER_ETICKET:
      return { etickets: itens(state.etickets, action) };

    default:
      return state;
  }
};
