// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { initCupomCarrinho, cupomPreconsulta } from "../../../../actions/Carrinho/CarrinhoActions";

// Components
import InputCupom from "../../../Common/Input/InputCupom";
import Snackbar from "../../../../components/Common/Snackbar/Snackbar";

class Cupom extends Component {
  state = {
    codigo: "",
    codigoPreenchido: false
  }

  enviarCupomPreconsulta = ({ codigo, codigoPreenchido }) => {
    const { valorTotal } = this.props;

    if (!valorTotal) {
      return Snackbar("Informe o valor da aposta para aplicar cupom", "Ok");
    }

    const { cupomPreconsulta, possivelRetorno } = this.props;
    codigo = codigo || this.state.codigo;
    codigoPreenchido = codigoPreenchido || this.state.codigoPreenchido;

    cupomPreconsulta(
      codigo,
      valorTotal,
      possivelRetorno
    );
  }

  onChange = (e) => {
    const state = { ...this.state };
    state.codigo = e.target.value.replace(/_/g, "").toUpperCase();

    // Verifica se o código foi preenchido
    state.codigoPreenchido = (
      state.codigo !== "" &&
      state.codigo.length === 10
    );

    // Se preencheu o cupom, realiza consulta
    if (!this.state.codigoPreenchido && state.codigoPreenchido) {
      this.enviarCupomPreconsulta({ ...state });
    }

    // Se despreencheu o cupom, reinicia estado do cupom
    if (this.props.cupomReducer.codigo && (this.state.codigoPreenchido && !state.codigoPreenchido)) {
      this.props.initCupomCarrinho();
    }

    this.setState(state);
  }

  render() {
    const { codigoPreenchido } = this.state;
    const { usuarioLogado, permissaoCupom, cupomReducer } = this.props;
    if (!permissaoCupom || !usuarioLogado) return null;

    return (
      <InputCupom
        type="primary"
        success={cupomReducer.codigo && codigoPreenchido}
        error={cupomReducer.error && codigoPreenchido}
        placeholder="Código do Cupom"
        value={this.state.codigo}
        onChange={this.onChange}
        onClickReenviar={this.enviarCupomPreconsulta}
        disabled={cupomReducer.isFetching}
      />
    );
  }
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    usuarioLogado: state.usuarioReducer.logado,
    permissaoCupom: state.permissoesReducer.configuracoes.exibirPromocoes,
    cupomReducer: state.carrinho.cupom
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { initCupomCarrinho, cupomPreconsulta },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cupom);
