// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

import {
  INIT_CARRINHO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  REQUEST_APOSTA_CARRINHO,
  RECEIVE_APOSTA_CARRINHO,
  ERROR_CARRINHO,
  SUCCESS_CARRINHO,
  INIT_CUPOM_CARRINHO,
  REQUEST_CUPOM_CARRINHO,
  RECEIVE_CUPOM_CARRINHO,
  ERROR_CUPOM_CARRINHO,
} from "../ActionTypes";

// Modules
import { doRequest, doOdinRequest } from "../../modules/request";

// Actions
import { adicionarEticket } from "../Eticket/EticketActions";
import { adicionarComprovante } from "../Comprovantes/ComprovantesActions";
import { fetchSaldo } from "../Usuario/UsuarioActions";

export const initCarrinho = () => ({
  type: INIT_CARRINHO,
});

export const adicionarItemCarrinho = (idCampeonato, evento, subevento, eventoAoVivo) => ({
  type: ADICIONAR_ITEM_CARRINHO,
  idCampeonato: idCampeonato,
  idEvento: evento.id,
  casa: evento.casa,
  fora: evento.fora,
  subevento,
  eventoAoVivo
});

export const adicionarItemCarrinhoComSubEvento = (subevento, eventoAoVivo) => ({
  type: ADICIONAR_ITEM_CARRINHO,
  idCampeonato: subevento.idCampeonato === "undefined" ? 0 : subevento.idCampeonato,
  idEvento:subevento.idEvento,
  casa: subevento.nomeEvento.split(" x ")[0],
  fora: subevento.nomeEvento.split(" x ")[1],
  subevento,
  eventoAoVivo
});

export const removerItemCarrinho = (idCampeonato, idEvento, subevento) => ({
  type: REMOVER_ITEM_CARRINHO,
  idCampeonato,
  idEvento,
  subevento
});

export const removerItemCarrinhoComSubEvento = (subevento) => ({
  type: REMOVER_ITEM_CARRINHO,
  idCampeonato: subevento.idCampeonato === "undefined" ? 0 : subevento.idCampeonato,
  idEvento:subevento.idEvento,
  subevento
});

export const limparCarrinho = (carrinho) => ({
  type: LIMPAR_CARRINHO,
  carrinho
});

export const requestApostaCarrinho = () => ({
  type: REQUEST_APOSTA_CARRINHO,
});

export const receiveApostaCarrinho = () => ({
  type: RECEIVE_APOSTA_CARRINHO,
});

export const successCarrinho = (text) => ({
  type: SUCCESS_CARRINHO,
  text
});

export const errorCarrinho = (text) => ({
  type: ERROR_CARRINHO,
  text
});

// Actions do Cupom
export const requestCupomPreconsulta = () => ({
  type: REQUEST_CUPOM_CARRINHO,
});

export const receiveCupomPreconsulta = (codigo, percentualGanho) => ({
  type: RECEIVE_CUPOM_CARRINHO,
  codigo, percentualGanho
});

export const errorCupomPreconsulta = () => ({
  type: ERROR_CUPOM_CARRINHO
});

/*
* Faz aposta se tiver logado.
* Gera E-ticket se não estiver logado.
*/
export const apostar = (valorTotal, possivelRetorno, openLoginModal, nomeCliente) => (dispatch, getState) => {
  // Checa se tem permissão para efetuar aposta/eticket
  const { eticket: permissaoEticket } = getState().permissoesReducer.permissoes;
  const { logado } = getState().usuarioReducer;

  // Abre modal de Login se não tiver permissão para Eticket
  if (!permissaoEticket && !logado) {
    openLoginModal("Faça login para efetuar apostas");
    return;
  }

  const { carrinho } = getState();

  const contemEventoAoVivo = carrinho.itens.some((item) => item.eventoAoVivo);

  // Abre modal de Login se houver evento ao vivo e não estiver logado
  if (contemEventoAoVivo && !logado) {
    openLoginModal("Faça login para efetuar apostas ao vivo");
    return;
  }

  // 1 - Simples
  // 2 - Casadinha
  const tipo = carrinho.itens.length === 1 ? 1 : 2;

  const carrinhoParam = {
    totalPago: valorTotal,
    possivelRetorno: possivelRetorno,
    itens: []
  };

  carrinho.itens.forEach(function(item) {    
    carrinhoParam.itens.push({
      idSubEvento: item.subevento.id,
      idOpcao: item.subevento.idOpcao,
      cotacao: item.subevento.cotacao,
      valorPago: tipo === 1 ? valorTotal : 0,
      nomeEvento: `${item.casa} x ${item.fora}`,
    });
  });

  const data = {
    tipo,
    confirmaPrinterAuto: false,
    carrinho: carrinhoParam,
    nomeCliente
  };

  // Adiciona cupom promocional se houver
  if (carrinho.cupom.codigo) {
    data.codigoPromocional = carrinho.cupom.codigo;
  }

  if (!logado) {
    dispatch(gerarEticket(data));
  }
  else {
    dispatch(realizarAposta(data));
  }
};

export const gerarEticket = (data) => (dispatch, getState) => {
  dispatch(requestApostaCarrinho());
  const params = {
    url: "/venda/eticket",
    method: "POST",
    data
  };

  return doRequest(params)
    .then(response => {
      if (response.codResposta !== "000") {
        return Promise.reject(response.mensagem);
      }

      dispatch(adicionarEticket({
        momento: new Date(),
        codigo: response.eticket,
        duracao: response.tempoExpirar,
        valorTotal: data.carrinho.totalPago,
        possivelRetorno: data.carrinho.possivelRetorno,
        eventos: getState().carrinho.itens
      }));

      dispatch(limparCarrinho(getState().carrinho));
      dispatch(successCarrinho("E-ticket gerado com sucesso"));
      dispatch(receiveApostaCarrinho());
    })
    .catch(error => {
      dispatch(errorCarrinho(error));
      dispatch(receiveApostaCarrinho());
    });
};

export const realizarAposta = (data) => (dispatch, getState) => {
  dispatch(requestApostaCarrinho());

  const { usuarioReducer } = getState();
  const { accessToken } = usuarioReducer.usuario;

  data.confirmaPrinterAuto = true;

  const params = {
    url: "/sp/venda/",
    method: "POST",
    accessToken, data
  };

  return doOdinRequest(params)
    .then(response => {
      if (!response.idBilhete) {
        dispatch(errorCarrinho(response.response.data.message));
        dispatch(receiveApostaCarrinho());
        return;
      }

      // Atualiza saldo do apostador após creditar o Pin
      dispatch(fetchSaldo());

      // TODO: criar um padrão no Reducer de comprovantes.
      // Isso vai dar merda futuramente.
      dispatch(adicionarComprovante({
        idBilhete: response.idBilhete,
        momento: new Date(),
        valorPago: data.carrinho.totalPago,
        valorPremio: data.carrinho.possivelRetorno,
      }));

      dispatch(limparCarrinho(getState().carrinho));
      dispatch(successCarrinho("Aposta realizada com sucesso"));
      dispatch(receiveApostaCarrinho());
    },
    error => {
      dispatch(errorCarrinho(error));
      dispatch(receiveApostaCarrinho());
    });
};

// Actions do Cupom
export const initCupomCarrinho = () => dispatch => {
  dispatch({ type: INIT_CUPOM_CARRINHO });
}

export const cupomPreconsulta = (codigo, valorTotal, possivelRetorno) => (dispatch, getState) => {
  dispatch(requestCupomPreconsulta());
  const { carrinho } = getState();

  // Mesma coisa da função apostar()
  const { usuarioReducer } = getState();
  const { accessToken } = usuarioReducer.usuario;

  // 1 - Simples
  // 2 - Casadinha
  const tipo = carrinho.itens.length === 1 ? 1 : 2;

  const carrinhoParam = {
    totalPago: valorTotal,
    possivelRetorno: possivelRetorno,
    itens: []
  };

  carrinho.itens.forEach(function(item) {
    carrinhoParam.itens.push({
      idSubEvento: item.subevento.id,
      idOpcao: item.subevento.idOpcao,
      cotacao: item.subevento.cotacao,
      valorPago: tipo === 1 ? valorTotal : 0
    });
  });

  const data = {
    tipo,
    carrinho: carrinhoParam,
    codigoPromocional: codigo
  };

  const params = {
    url: "/sp/venda/preconsulta/promocao",
    method: "POST",
    accessToken, data
  };

  return doOdinRequest(params)
    .then(response => {
      if (!response.codResposta) {
        Snackbar(response.response.data.message, "Ok");
        return dispatch(errorCupomPreconsulta());
      }

      if (response.codResposta === "000") {
        Snackbar(response.descricao, "Ok");
        return dispatch(receiveCupomPreconsulta(codigo, response.percentualGanho));
      }
      else {
        Snackbar(response.mensagem, "Ok");
        return dispatch(errorCupomPreconsulta());
      }
    })
    .catch(error => {
      Snackbar(error, "Ok");
      dispatch(errorCupomPreconsulta());
    });
};
