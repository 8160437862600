// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

class Logo extends Component {
  render() {
    const { configuracoes } = this.props;

    if (configuracoes.pathAssets && configuracoes.exibirLogo) {
      const logoSource = `${process.env.PUBLIC_URL}/icon/${configuracoes.pathAssets}/logo.svg`;

      return (
        <img
          className="header-left__logo-img"
          src={logoSource}
          alt={`Logo ${configuracoes.nomeFantasia}`}
        />
      );
    }

    const title = configuracoes.nomeFantasia;

    return (
      <h1 className="header-left__logo">
        {title}
      </h1>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { configuracoes: state.permissoesReducer.configuracoes };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
