// React
import React, { Component } from "react";

// Styles
import "./Input.css";

export const InputCheckbox = ({checked=false,onChange}) => (
  <input type="checkbox" className="checkbox icon-" checked={checked} onChange={onChange}/>
);

export class InputSideBarCampeonatoCheckbox extends Component {

  handleInputChange = () => {
      const {modalidadeId,paisId,campeonatoId,check} = this.props.dado;
      this.props.onClick(modalidadeId,paisId,campeonatoId,check);
  }

  render() {
    const { dado } = this.props;

    return (
      <InputCheckbox
        checked={dado.check}
        onChange={this.handleInputChange}
      />
    )
  }
};

export class InputSideBarPaisCheckbox extends Component {

  handleInputChange = () => {
      const {modalidadeId,paisId,pais,check} = this.props.dado;
      this.props.onClick(modalidadeId,paisId,pais,check);
  }

  render() {
    const { dado } = this.props;

    return (
      <InputCheckbox
        checked={dado.check}
        onChange={this.handleInputChange}
      />
    )
  }
};
