// React
import React, { Component } from 'react';
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// React Router
import { Switch, Route } from "react-router-dom";

// Libs
import Sidebar from "react-sidebar";

// Components
import OldSideNav from "../../OldSideNav/SideNav";
import BolaoSideNav from "../../SideNav/BolaoSideNav";
import ButtonPrimary from "../../../components/Common/Button/ButtonPrimary";
import ButtonSecondary from "../../../components/Common/Button/ButtonSecondary";
import Cadastro from "../../../components/Modal/Cadastro/Cadastro";
import Login from "../../../components/Modal/Login/Login";
import ConsultaBilhete from "../../../components/Modal/ConsultaBilhete/ConsultaBilhete";

class SidebarSideNav extends Component {
  openModalLogin = (e) => {
    e.preventDefault();
    this.context.openModal(
      "Login",
      <Login />,
      "large"
    );
  }

  openModalCadastro = (e) => {
    e.preventDefault();
    this.context.openModal(
      "Cadastro",
      <Cadastro />,
      "large"
    );
  }

  openModalConsultaBilhete = (e) => {
    e.preventDefault();
    this.context.openModal(
      "Consultar Bilhete",
      <ConsultaBilhete />,
      "large"
    );
  }

  renderSidebarContent = (logado, permissaoLogin) => {
    const { configuracoes, permissoesIsFetch } = this.props
    return (
      <div>
        <div className="row sidebar-left__header-row align-right">
          <div className="small-21 columns text-left">
            <h1 className="text-display light-default">
              Navegação
            </h1>
          </div>
          <div className="small-3 columns sidebar-left__close-column text-right">
            <span onClick={this.props.closeLeftSidebar} className="sidebar-close sidebar-left__close icon-fechar"></span>
          </div>
        </div>
        {
          // Oculta botões de Login e Cadastrar se usuário estiver logado
          // ou se PIN não estiver habilitado
          (permissaoLogin && !logado) &&
          <div className="row edge-top hide-for-large">
            <div className="small-12 columns">
              <ButtonPrimary onClick={this.openModalLogin}>
                Login
              </ButtonPrimary>
            </div>
            <div className="small-12 columns">
              <ButtonSecondary onClick={this.openModalCadastro}>
                Cadastrar
              </ButtonSecondary>
            </div>
          </div>
        }

        {!permissoesIsFetch && configuracoes.exibirEsportes ?
          <div className="row edge-top hide-for-xlarge">
            <div className="small-24 columns">
              <ButtonPrimary onClick={this.openModalConsultaBilhete}>
                Consultar Bilhete
            </ButtonPrimary>
            </div>
          </div>
          : null}

        <Switch>
          <Route path="/bolao/:idBolao" component={BolaoSideNav} />
          <Route path="/bolao" component={BolaoSideNav} />
          <Route path="/" component={OldSideNav} />
        </Switch>
      </div>
    );
  }

  render() {
    const { children, open, onSetOpen, permissaoLogin } = this.props;
    const { logado } = this.props.usuarioReducer;

    const sidebarProps = {
      sidebar: this.renderSidebarContent(logado, permissaoLogin),
      sidebarClassName: "sidebar sidebar-left",
      shadow: true,
      touchHandleWidth: 0,
      dragToggleDistance: 1,
      open: open,
      onSetOpen: onSetOpen
    };

    return (
      <Sidebar {...sidebarProps}>
        {children}
      </Sidebar>
    );
  }

};

SidebarSideNav.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    permissaoLogin: state.permissoesReducer.permissoes.pin,
    usuarioReducer: state.usuarioReducer,
    configuracoes: state.permissoesReducer.configuracoes,
    permissoesIsFetch: state.permissoesReducer.isFetching,
  }
};

export default connect(mapStateToProps)(SidebarSideNav);
