// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions/OldSideNavActions";
import * as actionsEventosCamp from "../../actions/EventosPorCampeonato/EventosPorCampeonatoActions";

// React Router
import { withRouter } from "react-router-dom";

// Styles
import "./SideNav.css";

// Components
import SideNavList from "./SideNavList";
import SideNavDivider from "./SideNavDivider";
import { SideNavToggleItem, SideNavToggleSubitem } from "./SideNavToggle";
import SideNavItem from "./SideNavItem";
import InputTextSecondary from "../Common/Input/InputTextSecondary.js";
import { InputSideBarCampeonatoCheckbox } from "../Common/Input/InputCheckbox";
import SideNavItemFixo from "./SideNavItemFixo";
import LoaderCarregando from "../Common/Loader/LoaderCarregando"

export class SideNav extends Component {

  constructor(props) {
    super(props)

    this.state = {
      busca: "",
      exibirTodos: true,
      exibirJogosDoDia: false,
      exibirProximaHora: false,
      exibirAmanha: false
    }
  }

  componentWillMount() {
    this.props.fetchModalidades();
  }

  renderModalidades = (modalidades) => {
    return modalidades.map((modalidade, index) => (
      <SideNavToggleItem
        key={index}
        nome={modalidade.nome}
        active={modalidade.active}
        onClick={() => { this.props.toggleSubitens(modalidade.id); this.props.toggleFixitens(modalidade.id, -1); }}
        tipoNavItem={"sidenav-list__item--modalidade"}
      >
        {this.renderPaises(modalidade)}
      </SideNavToggleItem>
    ));
  }

  handleCheckPais = (modalidadeId, paisId, pais, check) => {
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/")
    }

    if (typeof check === "undefined" || !check) {
      for (let i in pais.campeonatos) {
        this.props.checkCampeonatoSideBar(modalidadeId, paisId, pais.campeonatos[i].id)
        this.props.addFiltroEventosPorCampeonato(pais.campeonatos[i].id);
      }

    } else {
      for (let i in pais.campeonatos) {
        this.props.removeCheckCampeonatoSideBar(modalidadeId, paisId, pais.campeonatos[i].id)
        this.props.removeFiltroEventosPorCampeonato(pais.campeonatos[i].id);
      }
    }
  }

  renderPaises = (modalidade) => {
    return modalidade.paises.map((pais, index) => (
      <SideNavToggleSubitem
        key={index}
        nome={pais.nome}
        quantidadeDeEventos={pais.quantidadeDeEventos}
        quantidadeDeCampeonatosCheck={pais.quantidadeDeCampeonatosCheck}
        active={pais.active}
        onClick={() => this.props.toggleFixitens(modalidade.id, pais.id)}
        tipoSubItem=" sidenav-list__item--pais"
        dado={{ modalidadeId: modalidade.id, paisId: pais.id, pais: pais, check: pais.check }}
        onClickCheckBox={this.handleCheckPais}
      >
        {this.renderCampeonatos(modalidade.id, pais.id, pais.campeonatos)}
      </SideNavToggleSubitem>
    ));
  }

  handleCheckCampeonato = (modalidadeId, paisId, campeonatoId, check) => {
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/")
    }

    if (typeof check === "undefined" || !check) {
      this.props.checkCampeonatoSideBar(modalidadeId, paisId, campeonatoId)
      this.props.addFiltroEventosPorCampeonato(campeonatoId);
    } else {
      this.props.removeCheckCampeonatoSideBar(modalidadeId, paisId, campeonatoId)
      this.props.removeFiltroEventosPorCampeonato(campeonatoId);
    }

  }

  renderCampeonatos = (modalidadeId, paisId, campeonatos) => {
    return campeonatos.map((campeonato, index) => {
      const contentRight = (
        <div className="fixlist-checkbox-wrapper">
          <span className="fixlist-checkbox-qtd">{campeonato.quantidadeDeEventos}</span>
          <InputSideBarCampeonatoCheckbox dado={{ modalidadeId: modalidadeId, paisId: paisId, campeonatoId: campeonato.id, check: campeonato.check }}
            onClick={this.handleCheckCampeonato} />
        </div>
      );

      return (
        <SideNavItemFixo
          key={index}
          contentLeft={campeonato.nome}
          contentRight={contentRight}
        />
      )
    });
  }

  handleClickEventosProximaHora = () => {
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/")
    }

    this.setState({
      exibirTodos: false,
      exibirJogosDoDia: false,
      exibirProximaHora: true,
      exibirAmanha: false
    });
    this.props.FiltrarEventosCampeonatoProximaHora();
  }

  handleClickEventosDoDia = () => {
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/")
    }

    this.setState({
      exibirTodos: false,
      exibirJogosDoDia: true,
      exibirProximaHora: false,
      exibirAmanha: false
    });
    this.props.FiltrarEventosDoDiaCampeonato();

  }

  handleClickEventosDeAmanha = () => {
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/")
    }

    this.setState({
      exibirTodos: false,
      exibirJogosDoDia: false,
      exibirProximaHora: false,
      exibirAmanha: true
    });
    this.props.FiltrarEventosDeAmanhaCampeonato();

  }

  handleClickTodosOsEventos = () => {
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/")
    }

    this.setState({
      exibirTodos: true,
      exibirJogosDoDia: false,
      exibirProximaHora: false,
      exibirAmanha: false
    });
    this.props.FiltrarTodosEventosCampeonato();
  }

  handleClickLongoPrazo = () => {
    if (this.props.location.pathname !== "/longoprazo") {
      this.props.history.push("/longoprazo")
    }

    this.setState({
      exibirTodos: true,
      exibirJogosDoDia: false,
      exibirProximaHora: false,
      exibirAmanha: false
    });
    this.props.setEventosLongoPrazo(true);
  }
  handleClickDisputaIndireta = () => {
    if (this.props.location.pathname !== "/disputa-indireta") {
      this.props.history.push("/disputa-indireta")
    }

    this.setState({
      exibirTodos: true,
      exibirJogosDoDia: false,
      exibirProximaHora: false,
      exibirAmanha: false
    });
    this.props.setEventosDisputaIndireta(true);
  }

  handleOnChangeSearchEventos = (event) => {
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/")
    }

    this.setState({ busca: event.target.value });
    this.props.setBuscaEventosCampeonato(event.target.value);
  }

  render() {
    const { modalidades, isFetching } = this.props.modalidadesReducer;
    const { showLongoPrazo, configuracoes } = this.props;

    return configuracoes.exibirEsportes ? (
      <div className="row">
        <section className="small-24 columns">
          <SideNavList>
            <SideNavDivider className="hide-for-xlarge">
              Busca
            </SideNavDivider>
            <InputTextSecondary placeholder="Buscar Eventos..." value={this.state.busca} onChange={this.handleOnChangeSearchEventos} />
            <SideNavDivider>
              Filtros
            </SideNavDivider>
            <SideNavItem contentLeft="Todos os Eventos" onClick={this.handleClickTodosOsEventos} active={this.state.exibirTodos} tipoNavItem={"sidenav-list__item--modalidade"} />
            <SideNavItem contentLeft="Eventos na Próxima Hora" onClick={this.handleClickEventosProximaHora} active={this.state.exibirProximaHora} tipoNavItem={"sidenav-list__item--modalidade"} />
            <SideNavItem contentLeft="Eventos do Dia" onClick={this.handleClickEventosDoDia} active={this.state.exibirJogosDoDia} tipoNavItem={"sidenav-list__item--modalidade"} />
            <SideNavItem contentLeft="Eventos de Amanhã" onClick={this.handleClickEventosDeAmanha} active={this.state.exibirAmanha} tipoNavItem={"sidenav-list__item--modalidade"} />
            <SideNavDivider>
              Modalidades
            </SideNavDivider>
            {showLongoPrazo && <SideNavItem contentLeft="Longo Prazo" onClick={this.handleClickLongoPrazo} active={false} tipoNavItem={"sidenav-list__item--modalidade"} />}
            {configuracoes.exibirDisputaIndireta && <SideNavItem contentLeft="Disputa Indireta" onClick={this.handleClickDisputaIndireta} active={window.location.pathname === "/disputa-indireta"} tipoNavItem={"sidenav-list__item--modalidade"} />}
            {
              isFetching || isFetching == null ? <LoaderCarregando className="edge-top" /> : this.renderModalidades(modalidades)
            }
          </SideNavList>
        </section>
      </div>
    ) : null;
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    modalidadesReducer: state.modalidadesReducer,
    showLongoPrazo: state.permissoesReducer.permissoes.longoPrazo,
    configuracoes: state.permissoesReducer.configuracoes
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, ...actionsEventosCamp }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNav)
);
