// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Bolao/BolaoCarrinhoActions";

// Styles
import "./Bolao.css";

// Helpers
import { convertIntegerToMoney } from "../../../modules/helpers";

// Components
import ButtonPrimary from "../../Common/Button/ButtonPrimary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import Loader from "../../Common/Loader/Loader";
import Login from "../../Modal/Login/Login";
import Snackbar from "../../Common/Snackbar/Snackbar";

class BolaoCarrinhoFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qtdCotas: 0,
      valorTotal: 0,
      qtdApostas: 0,
    };
  }

  componentWillUpdate(nextProps) {
    const bolaoAlterado = (
      this.props.bolaoCarrinhoReducer.idBolao !==
      nextProps.bolaoCarrinhoReducer.idBolao
    );

    const eventosPreenchidos = this.props.bolaoCarrinhoReducer.eventos.length;
    
    // Exibe aviso se o bolão for alterado com eventos preenchidos
    if (bolaoAlterado && eventosPreenchidos) {
      Snackbar("A seleção atual de eventos é perdida ao trocar o bolão.", "Ok");
      this.limparCarrinho();
      return;
    }

    // Limpa carrinho se bolão for alterado
    if (bolaoAlterado) {
      this.limparCarrinho();
      return;
    }
    
  }

  //calculo dos valores das apostas do bolao multiplas
  componentDidUpdate(prevProps){
    if(this.props.bolaoCarrinhoReducer.tipo === 3){
    
      const {countTripla, countDupla} = this.props.bolaoDetalheReducer;

      if(this.props.bolaoCarrinhoReducer !== prevProps.bolaoCarrinhoReducer) {
        let qtdApostas = Math.pow(3, countTripla) * Math.pow(2, countDupla);
        this.setState({qtdApostas, valorTotal : qtdApostas * this.props.bolaoCarrinhoReducer.vlrCota });
      }
    } return

  }

  openLoginModal = () => {
    this.context.openModal(
    "Faça Login para efetuar Apostas",
      <Login />,
      "large"
    );
  }

  _modificarQtdCota = (tipo) => {
    const { vlrCota } = this.props.bolaoCarrinhoReducer;
    let { qtdCotas, qtdApostas } = this.state;
    let valorTotal = 0;
    
    if (tipo === "mais") {
      qtdCotas += 1;
    }
    
    if (tipo === "menos") {
      if (qtdCotas === 0) return;
      qtdCotas -= 1;
    }

    if(this.props.bolaoCarrinhoReducer.tipo === 2 || this.props.bolaoCarrinhoReducer.tipo === 1){
      valorTotal = qtdCotas * vlrCota;
      this.setState({ qtdCotas, valorTotal });
    }
        
    if(this.props.bolaoCarrinhoReducer.tipo === 3){
      valorTotal = (qtdCotas * qtdApostas) * vlrCota;
      this.setState({ qtdCotas, valorTotal });
    }
  }
  
  limparState = () => {
    this.setState({
      qtdCotas: 0,
      valorTotal: 0,
      qtdApostas: 0,
    });
  }
  
  limparCarrinho() {
    this.props.limparCarrinho(this.limparState); 
  }
  //verificar se ultrapassou a quantidade de apostas duplas permitido
  checkMaxMultiplas = () => {
    const { countDupla, maxCombinacoesDuplas }= this.props.bolaoDetalheReducer
    
    if(maxCombinacoesDuplas < countDupla){
      Snackbar("Ultrapassou o máximo de apostas duplas (" + maxCombinacoesDuplas + " apostas)", "Ok", undefined, 10000);
      return;
    } else{
      this.props.apostar(
        this.state.qtdCotas,
        this.state.valorTotal,
        this.openLoginModal,
        this.limparState,
      )
    }
  }
  
  renderAcoes = () => {
    const { isFetching, bolaoPreenchido } = this.props.bolaoCarrinhoReducer;
    
    let buttonApostar;
    
    if (isFetching) {
      buttonApostar = (
        <ButtonSecondary disabled>
          <Loader type="white" />
        </ButtonSecondary>
      );
    }
    else if (!bolaoPreenchido || !this.state.qtdCotas) {
      buttonApostar = (
        <ButtonSecondary disabled={true} setDisabled={true}>
          Apostar
        </ButtonSecondary>
      );
    }
    else {
        buttonApostar = (
          <ButtonSecondary
          onClick={() => this.checkMaxMultiplas()}
            >
            Apostar
          </ButtonSecondary>
        );
    }
    
    return (
      <div className="row edge-top">
        <div className="small-10 columns">
          <ButtonPrimary
            id="limparCarrinhoBolao"
            onClick={() => this.limparCarrinho()}
            >
            Limpar
          </ButtonPrimary>
        </div>
        <div className="small-14 columns">
          { buttonApostar }
        </div>
      </div>
    );
  }
  
  //componente para ser renderizado apenas se o bolao for do tipo multiplas e triplas (tipo 3)
  checkMultiplas = () => {
      const apostas = this.state.qtdApostas;
      const { countDupla, countTripla, countCombinacoes }= this.props.bolaoDetalheReducer
      if(this.props.bolaoCarrinhoReducer.tipo === 3){
          return (
            <div>
              <QtdApostas qtdApostas={apostas} />
              <QtdApostasMultiplas countCombinacoes={countCombinacoes} />
              <QtdApostasDuplas  countDupla={countDupla} />
              <QtdApostasTriplas  countTripla={countTripla} />  
            </div>
          );
      }
  }
      
      render() {
        const { vlrCota } = this.props.bolaoCarrinhoReducer;

        return (
        <ValorApostaContainer>
        <NumeroDeCotas
          qtdCotas={this.state.qtdCotas}
          modificarQtdCota={this._modificarQtdCota}
          />
        {this.checkMultiplas()}
        <ValorDaCota valorDaCota={vlrCota} />
        <ValorTotal valorTotal={this.state.valorTotal} />
        { this.renderAcoes() }
      </ValorApostaContainer>
    );
  }
};

BolaoCarrinhoFooter.contextTypes = {
  openModal: PropTypes.func
};

const NumeroDeCotas = ({ qtdCotas, modificarQtdCota }) => (
  <div className="row align-middle edge-top">
    <div className="small-15 columns">
      <p className="text-title light-secondary">Número de Cotas</p>
    </div>
    <div className="small-9 columns">
      <p className="numero-de-cotas text-right text-headline light-default">
        <span
          onClick={() => modificarQtdCota("menos")}
          className="numero-de-cotas__menos icon-menos icon-fix"
        >
        </span>
        <span className="numero-de-cotas__quantidade text-center">
          { qtdCotas }
        </span>
        <span
          onClick={() => modificarQtdCota("mais")}
          className="numero-de-cotas__mais icon-mais icon-fix"
        >
        </span>
      </p>
    </div>
  </div>
);

const ValorApostaContainer = ({ children }) => (
  <div className="row">
    <div className="small-24 columns">
      { children }
    </div>
  </div>
);

const ValorDaCota = ({ valorDaCota }) => (
  <div className="row align-middle edge-top">
    <div className="small-15 columns">
      <p className="text-title light-secondary">Valor da Cota</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline light-default">
        { convertIntegerToMoney(valorDaCota) }
      </p>
    </div>
  </div>
);

//componente para exibir a quantidade de apostas estão sendo realizadas
const QtdApostas = ({ qtdApostas }) => (
  <div className="row align-middle edge-top">
    <div className="small-15 columns">
      <p className="text-title light-secondary">Total de Apostas</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline light-default">
        {qtdApostas}
      </p>
    </div>
  </div>
);

const QtdApostasMultiplas = ({ countCombinacoes }) => (
  <div className="row align-middle edge-top">
    <div className="small-15 columns">
      <p className="text-title light-secondary">Qtd de Apostas Múltiplas</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline light-default">
        {countCombinacoes}
      </p>
    </div>
  </div>
);

const QtdApostasDuplas = ({ countDupla }) => (
  <div className="row align-middle edge-top">
    <div className="small-15 columns">
      <p className="text-title light-secondary">Qtd de Duplas</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline light-default">
        {countDupla}
      </p>
    </div>
  </div>
);


const QtdApostasTriplas = ({ countTripla }) => (
  <div className="row align-middle edge-top">
    <div className="small-15 columns">
      <p className="text-title light-secondary">Qtd de Triplas</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline light-default">
        {countTripla}
      </p>
    </div>
  </div>
);

const ValorTotal = ({ valorTotal }) => (
  <div className="row align-middle edge-top">
    <div className="small-15 columns">
      <p className="text-title light-secondary">Valor Total</p>
    </div>
    <div className="small-9 columns">
      <p className="overflow-hidden text-right text-headline accent-1">
        { convertIntegerToMoney(valorTotal) }
      </p>
    </div>
  </div>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    tipoBolao: state.bolaoReducer.detalhe.tipo,
    bolaoCarrinhoReducer: state.bolaoCarrinhoReducer,
    bolaoDetalheReducer: state.bolaoReducer.detalhe
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BolaoCarrinhoFooter);

