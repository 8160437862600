import {
  INIT_EVENTOS_AOVIVO,
  REQUEST_EVENTOS_AOVIVO,
  RECEIVE_EVENTOS_AOVIVO,
  ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS,
  RECEIVE_BANNER_AO_VIVO
} from "../ActionTypes";

import { doRequest } from "../../modules/request";

export const initEventosAoVivo = (carrinho) => ({
  type: INIT_EVENTOS_AOVIVO,
  carrinho
});

export const requestEventosAoVivo = (modalidades) => {
  return {
    type: REQUEST_EVENTOS_AOVIVO
  };
};

export const receiveEventosAoVivo = (campeonatos, carrinho) => {
  return {
    type: RECEIVE_EVENTOS_AOVIVO,
    campeonatos,
    carrinho
  };
};

export const errorEventosAoVivo = (text) => ({
  type: ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS,
  text
});

export const fetchEventosAoVivo = (carrinho) => (dispatch, getState) => {
  dispatch(requestEventosAoVivo());

  const params = {
    url: "/principal/aovivo",
    method: "GET"
  };

  doRequest(params).then(response => {

    if(typeof response.campeonatos === "undefined" || response.campeonatos.length <= 0){
      dispatch(initEventosAoVivo(carrinho))
      return Promise.reject(response.message);
    }else{
      dispatch(receiveEventosAoVivo(response.campeonatos,carrinho));
    }

  }).catch(error => {
    dispatch(errorEventosAoVivo(error));
  });

};

// Banners do Ao Vivo
export const receiveBannerAoVivo = ({ name, link, dados, url }) => {
  return {
    type: RECEIVE_BANNER_AO_VIVO,
    name, link, dados, url
  };
};

export const fetchBannersAoVivo = () => (dispatch, getState) => {
  const params = {
    url: "/crm/banner",
    method: "GET",
    params: { target: "aovivo" }
  };

  doRequest(params).then(response => {
    dispatch(receiveBannerAoVivo({ ...response }));
  }).catch(error => { /* ... */ });
};
