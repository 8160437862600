// Action Types
import {
  INIT_RECUPERAR_SENHA,
  REQUEST_ENVIAR_CODIGO_SMS,
  RECEIVE_ENVIAR_CODIGO_SMS,
  ERROR_ENVIAR_CODIGO_SMS,
  REQUEST_RECUPERAR_SENHA,
  RECEIVE_RECUPERAR_SENHA,
  ERROR_RECUPERAR_SENHA
} from "../ActionTypes";

// Helpers
import { formatTelefone } from "../../modules/helpers";

// Modules
import { doRequest } from "../../modules/request";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initRecuperarSenha = () => ({
  type: INIT_RECUPERAR_SENHA
});

export const requestEnviarCodigoSms = () => ({
  type: REQUEST_ENVIAR_CODIGO_SMS
});

export const receiveEnviarCodigoSms = () => ({
  type: RECEIVE_ENVIAR_CODIGO_SMS
});

export const errorEnviarCodigoSms = (error) => ({
  type: ERROR_ENVIAR_CODIGO_SMS,
  error
});

export const requestRecuperarSenha = () => ({
  type: REQUEST_RECUPERAR_SENHA
});

export const receiveRecuperarSenha = () => ({
  type: RECEIVE_RECUPERAR_SENHA
});

export const errorRecuperarSenha = (error) => ({
  type: ERROR_RECUPERAR_SENHA,
  error
});

export const fetchEnviarCodigoSms = (telefone) => (dispatch, getState) => {
  dispatch(requestEnviarCodigoSms());

  const data = { login: telefone };

  const params = {
    url: "/user/password/recovery/sender",
    method: "POST",
    data
  };

  return doRequest(params)
    .then(response => {
      dispatch(receiveEnviarCodigoSms());
      Snackbar(`Código enviado para ${formatTelefone(telefone)}.`, "Ok");
    })
    .catch(error => {
      dispatch(errorEnviarCodigoSms(error));
    });
};

export const fetchRecuperarSenha = ({ login, token, novaSenha, confirmarSenha }) => (dispatch, getState) => {
  dispatch(requestRecuperarSenha());

  // Verifica se campos são válidos
  if (novaSenha !== confirmarSenha) {
    return dispatch(errorRecuperarSenha("Nova senha não confere."));
  }

  const data = { login, token, novaSenha };

  const params = {
    url: "/user/password/recovery",
    method: "POST",
    data
  };

  return doRequest(params)
    .then(response => {
      if (response.codResposta !== "000") {
        return Promise.reject(response.mensagem);
      }

      dispatch(receiveRecuperarSenha());
      Snackbar("Senha alterada com sucesso.", "Ok");
    })
    .catch(error => {
      dispatch(errorRecuperarSenha(error));
    });
};
