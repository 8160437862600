// React
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";

// React Router
import { withRouter } from "react-router-dom";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions/Jogos/JogosActions";

// Components
import Aviso from "../Common/Aviso/Aviso";
import LoaderCarregando from "../Common/Loader/LoaderCarregando";
import { getDeviceType } from "../../modules/helpers";

import ButtonSecondary from "../Common/Button/ButtonSecondary";
import ButtonPrimary from "../Common/Button/ButtonPrimary";

import Snackbar from "../Common/Snackbar/Snackbar";
import Login from "../Modal/Login/Login";

class Jogo extends Component {
  constructor(props) {
    super(props);
    this.state = { modoGame: "" };
  }

  componentWillMount() {
    // Finaliza jogo se já houver url no jogo atual
    // (útil devido ao redirect e delay da Leander no saldo)
    if (this.props.jogosReducer.jogoAtual.urlLaunch) {
      this.finalizarJogo();
      this.props.history.push("/jogos");
      return;
    }

    // Volta pros jogos se usuário não estiver logado
    if (!this.props.usuarioLogado && !this.props.permiteSlotFun) {
      this.props.history.push("/jogos");
      return;
    }

    // Reseta propriedades do jogo atual
    this.props.initJogoAtual();

    // Resgata objeto do jogo do jogosReducer
    const { slotID } = this.props.match.params;
    this.jogo = this.props.jogosReducer.jogos.find(jogo => jogo.id === slotID);
    this.setState({ modoGame: this.props.location.modoGame });
  }

  // Fazer com que o jogo seja finalizado em breve
  componentWillUpdate(nextProps) {
    if (!nextProps.usuarioLogado && !nextProps.permiteSlotFun) {
      this.props.history.push("/jogos");
    }
  }

  componentDidMount() {
    if (this.state.modoGame){
      this.props.fetchIniciarJogo(this.jogo, this.state.modoGame);}
  }

    // Callback do click nos Cards de jogos:
  // solicita login e impede link de modificar a URL
  solicitarLogin = (e) => {
    const { usuarioLogado } = this.props;
    if (!usuarioLogado) {
      e.preventDefault();
      this.context.openModal(
        "Login",
        <Login />,
        "large"
      );
      Snackbar("Faça Login para ter acesso aos Slots.", "Ok");
    }
  }

  clickSlot = (e, modoGame) => {
    console.log("this.props", this.props);
    const { usuarioLogado, permiteSlotFun, fetchIniciarJogo } = this.props;
    this.setState({ modoGame: modoGame });
    e.preventDefault();
    if (modoGame === 'jogar' || !permiteSlotFun) {
      if (!usuarioLogado) {
        this.solicitarLogin(e);
      } else {
        fetchIniciarJogo(this.jogo, modoGame);
      }
    } else if (modoGame === 'demo' && permiteSlotFun) {
      fetchIniciarJogo(this.jogo, modoGame);
    }
  };

  renderIframe = (urlLaunch, title) => {
    return (
      <div className="row">
        <div className="small-24 columns">
          <iframe
            title={`${title} - ${this.state.modoGame}`}
            className="iframe-jogo"
            src={urlLaunch}
            frameBorder="0"
            allowFullScreen
          >
          </iframe>
        </div>
      </div>
    );
  }

  finalizarJogo = () => {
    this.props.fetchFinalizarJogo();
    this.props.history.push("/jogos");
  }

  render() {
    if (!this.props.usuarioLogado && !this.props.permiteSlotFun) return null;

    const { nome } = this.jogo;
    const { jogoAtual } = this.props.jogosReducer;

    let gameTarget = 'frame'; // Abrir no iframe padrão
    if (getDeviceType() === 'mobile') {
      gameTarget =
        jogoAtual && jogoAtual.target && jogoAtual.target.mobile
          ? jogoAtual.target.mobile
          : 'frame';
    } else {
      gameTarget =
        jogoAtual && jogoAtual.target && jogoAtual.target.desktop
          ? jogoAtual.target.desktop
          : 'frame';
    }

    return (
      <div style={{ paddingBottom: "0.8rem" }}>

        <div className="row">
          <Title title={nome} />
          {/* Se houver jogo, renderiza botão para finalizar */}
          {
            !!jogoAtual.urlLaunch && getDeviceType() === "desktop" &&
            <FinalizarJogo onClick={this.finalizarJogo} />
          }
        </div>
        {/* seletor Mobile */}
        {
          !this.state.modoGame &&
          <SeletorModoGameMobile jogo={this.jogo} permiteSlotFun={this.props.permiteSlotFun} clickSlot={this.clickSlot}/>
        }

        {/* Loader  */}
        {jogoAtual.isFetching && <LoaderCarregando />}

        {/* Iframe do jogo */}

        {
          !!jogoAtual.urlLaunch && getDeviceType() === "desktop" && gameTarget === 'frame' &&
          this.renderIframe(jogoAtual.urlLaunch, nome)
        }

        {
          !!jogoAtual.urlLaunch && getDeviceType() === "desktop" && gameTarget === 'blank' &&
          window.open(jogoAtual.urlLaunch, "_blank") && this.props.history.push("/jogos")
        }

        {
          !!jogoAtual.urlLaunch && getDeviceType() === "mobile" && gameTarget === 'blank' &&
          window.open(jogoAtual.urlLaunch, "_blank") && this.props.history.push("/jogos")
        }

        {
          !!jogoAtual.urlLaunch && getDeviceType() === "mobile" && gameTarget === 'frame' &&
          window.location.replace(jogoAtual.urlLaunch)
        }


        {/* Aviso */}
        {
          !!jogoAtual.aviso &&
          <AvisoJogo aviso={jogoAtual.aviso} />
        }
      </div>
    );
  }
}

Jogo.contextTypes = {
  openModal: PropTypes.func
};


const SeletorModoGameMobile = ({ jogo, permiteSlotFun, clickSlot }) => (
  <Fragment>
    <div
      className="jogo-frame-seletor"
      style={{ backgroundImage: `url(${jogo.urlImagem})` }}
    >
      <div className="jogos-card-button-seletor-mobile">
        <ButtonPrimary
          className="slot-button-jogar-mob"
          onClick={e => clickSlot(e, 'jogar')}
        >Jogar</ButtonPrimary>

        {permiteSlotFun && (
          <ButtonSecondary
            className="slot-button-demo-mob"
            onClick={e => clickSlot(e, 'demo')}
          >Demonstração</ButtonSecondary>
        )}
      </div>
    </div>
  </Fragment>
)

const Title = ({ title }) => (
  <div className="small-12 columns">
    <h1 className="text-display edge-bottom light-default">{title}</h1>
  </div>
);

const FinalizarJogo = ({ onClick }) => (
  <div className="small-12 columns text-right">
    <span onClick={onClick} className="text-title accent-1 cursor-pointer">
      Finalizar Slot
    </span>
  </div>
);

const AvisoJogo = ({ aviso }) => (
  <div className="row edge-top">
    <div className="small-24 columns">
      <Aviso type="error">
        {aviso}
      </Aviso>
    </div>
  </div>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    usuarioLogado: state.usuarioReducer.logado,
    jogosReducer: state.jogosReducer,
    permiteSlotFun: state.permissoesReducer.configuracoes.permiteSlotFun
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchIniciarJogo: actions.fetchIniciarJogo,
    fetchFinalizarJogo: actions.fetchFinalizarJogo,
    initJogoAtual: actions.initJogoAtual
  }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Jogo)
);
