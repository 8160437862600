import { doRequest } from '../../modules/request';

var streamURLAux = undefined;
var makeRequestStream = undefined;
var countErroRequest = 0;

export const types = {
  INIT: 'INIT/INIT',
  PRIVATESTREAM_REQUEST: 'PRIVATESTREAM/REQUEST',
  PRIVATESTREAM_SUCCESS: 'PRIVATESTREAM/CAMPEONATOS_SUCCESS',
  PRIVATESTREAM_ERROR: 'PRIVATESTREAM/CAMPEONATOS_ERROR'
};

export const initialState = {
  isFetching: false,
  streamURL: undefined,
  erroCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;

    case types.PRIVATESTREAM_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case types.PRIVATESTREAM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        streamURL: action.response.url
      };

    case types.PRIVATESTREAM_ERROR:
      const count = state.erroCount + 1;
      return {
        ...state,
        isFetching: false,
        streamURL: undefined,
        erroCount: count
      };
    default:
      return state;
  }
};

export const actions = {
  init: () => ({ type: types.INIT }),
  privateStreamRequest: () => ({ type: types.PRIVATESTREAM_REQUEST }),
  privateStreamSuccess: response => ({ type: types.PRIVATESTREAM_SUCCESS, response }),
  privateStreamError: () => ({ type: types.PRIVATESTREAM_ERROR })
};

export const initPrivateStream = () => (dispatch, getState) => {
  countErroRequest = 0;
  makeRequestStream = undefined;
  streamURLAux = undefined;
  dispatch(actions.init());
};

export const fetchURLPrivateStream = idEvento => (dispatch, getState) => {
  const accessToken = getState().usuarioReducer.usuario.accessToken;
  const streamURL = getState().privateStream.streamURL;
  const erroCount = getState().privateStream.erroCount;

  //Caso a API tenha mais de 5 erros no request não faz mais request.
  if (erroCount > 5 || countErroRequest > 5) {
    return;
  }

  if (makeRequestStream) {
    return;
  }

  //Só faz o fetch se o usuário estiver logado.
  if (accessToken === '') {
    return;
  }

  //Se ja tiver uma URL cadastrada não faz request
  if (streamURLAux !== undefined && streamURLAux !== null && streamURLAux !== '') {
    return;
  }

  //Se ja tiver uma URL cadastrada não faz request
  if (streamURL !== undefined && streamURL !== null && streamURL !== '') {
    return;
  }

  makeRequestStream = true;

  dispatch(actions.privateStreamRequest());

  const params = {
    url: '/principal/aovivo/subevento/stream',
    method: 'GET',
    params: { idEvento: idEvento },
    accessToken: accessToken
    /* Quando for teste para apresentar a stream tem q colocar o IP no HEADER*/
    // ,headers: {
    //   'X-Real-Ip': '168.196.171.80'
    // }
  };

  doRequest(params)
    .then(response => {
      if (response.codResposta === '140') {
        dispatch(actions.privateStreamError());
      }
      //Se tiver streamDoisFatores precisa de outra perna no request
      if (response.streamDoisFatores !== undefined) {
        dispatch(fetchURLPrivateStreamDoisFatores(response.streamDoisFatores));
      } else {
        makeRequestStream = undefined;
        //Para ficar mais rápido a atualização a identificação q tem URL de stream.
        streamURLAux = response.url;
        dispatch(actions.privateStreamSuccess({ url: response.url, doisFatores: false }));
      }
    })
    .catch(error => {
      makeRequestStream = undefined;
      countErroRequest++;
      console.log('erro', error);
      streamURLAux = undefined;
      dispatch(actions.privateStreamError());
    });
};

export const fetchURLPrivateStreamDoisFatores = streamDoisFatores => (dispatch, getState) => {
  //streamDoisFatores.tipo //quando precisar fazer alguma diferenca no resquest
  const headers = {
    Authorization: streamDoisFatores.authorization,
  };

  const url = new URL(streamDoisFatores.url);

  const params = {
    baseURL: url.origin,
    url: url.pathname,
    method: 'GET',
    params: {
      _fmt: url.searchParams.get('_fmt'),
      _rt: url.searchParams.get('_rt'),
      aLng: url.searchParams.get('aLng')
    },
    headers: headers,
    hideSPTRequestID: true
  };
  doRequest(params)
    .then(response => {
      makeRequestStream = undefined;
      let UrlStream = undefined;

      //Verifica se tem uma traducao expecifica do video.
      const streamLauncherXcdst =
        [] || response.launchInfo.streamLauncher.filter(s => s.audioLang === 'x-cdst');

      if (streamLauncherXcdst !== undefined && streamLauncherXcdst.length > 0) {
        UrlStream = streamLauncherXcdst[0].launcherURL;
      } else {
        UrlStream = response.launchInfo.streamLauncher[0].launcherURL;
      }

      const protocoloHTTP = window.location.protocol;
      if (!UrlStream.includes(protocoloHTTP)) {
        let streamLauncherURL = response.launchInfo.streamLauncher;
        //Procura URL que tenha o mesmo protocolo do site.
        if (streamLauncherXcdst !== undefined && streamLauncherXcdst.length > 0) {
          streamLauncherURL = streamLauncherXcdst;
        }

        const filterProtocolo = streamLauncherURL.filter(s => {
          return s.launcherURL.includes(protocoloHTTP);
        });

        if (filterProtocolo.length > 0) {
          UrlStream = filterProtocolo[0].launcherURL;
        }
      }

      streamURLAux = UrlStream;
      dispatch(actions.privateStreamSuccess({ url: UrlStream, doisFatores: true }));
    })
    .catch(error => {
      makeRequestStream = undefined;
      countErroRequest++;
      console.log('erro', error);
      streamURLAux = undefined;
      dispatch(actions.privateStreamError());
    });
};
