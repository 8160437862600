// React
import React, { Component } from "react";

// Libs
import moment from "moment";

// Components
import Card from "../../../Common/Card/Card";

const Header = ({ cupom, resgatar }) => (
  <div className="row align-middle">
    <div className="small-24 columns">
      <h3 className="text-title padding-7 light-default">
        {cupom.titulo}

        {/* Carregando... */}
        {cupom.isFetchingResgatar && (
          <span className="float-right text-title light-default text-right cursor-pointer">
            Carregando...
          </span>
        )}

        {/* Resgatar */}
        {!cupom.resgatado &&
          !cupom.isFetchingResgatar && (
            <span
              onClick={resgatar}
              className="float-right text-title accent-1 text-right cursor-pointer"
            >
              Resgatar
            </span>
          )}
      </h3>
    </div>
  </div>
);

const InfoGeral = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-caption old-light-secondary">{label}</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-title light-default">{valor}</p>
    </div>
  </div>
);

export default class Cupom extends Component {
  render() {
    const { cupom, resgatar } = this.props;
    const validade = moment(new Date(cupom.validade)).format(
      "DD/MM/YYYY [-] HH:mm:ss"
    );
    const mercado = cupom.mercado || "Não informado";
    const descricao = cupom.descricao;
    const status = cupom.status;
    const resgatado = cupom.resgatado ? "Sim" : "Não";
    const header = <Header cupom={cupom} resgatar={resgatar} />;

    return (
      <Card className="edge-bottom-list card-block" header={header}>
        <InfoGeral label="Validade" valor={validade} />
        <InfoGeral label="Categoria" valor={mercado} />
        <InfoGeral label="Status" valor={status} />
        <InfoGeral label="Resgatado" valor={resgatado} />
        <div className="row">
          <div className="small-24 columns column-block">
            <p className="text-title">{descricao}</p>
          </div>
        </div>
      </Card>
    );
  }
}
