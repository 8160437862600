import {
  INIT_EXTRATO,
  REQUEST_EXTRATO,
  RECEIVE_EXTRATO,
  ERROR_EXTRATO
} from "../../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: null,
  saldoAnterior: 0,
  dataSaldoAnterior: null,
  dataSaldoFinal: null,
  extrato: []
};

export default function extratoReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_EXTRATO:
      return initialState;

    case REQUEST_EXTRATO:
      return {
        ...state,
        isFetching: true,
        aviso: null,
        extrato: []
      };

    case RECEIVE_EXTRATO:
      return {
        ...state,
        isFetching: false,
        aviso: null,
        extrato: action.extrato.extrato,
        saldoAnterior: action.extrato.saldoAnterior,
        dataSaldoAnterior: action.extrato.dataSaldoAnterior,
        dataSaldoFinal: action.extrato.dataSaldoFinal
      };

    case ERROR_EXTRATO:
      return {
        ...state,
        isFetching: false,
        aviso: { type: "error", text: action.error },
        extrato: []
      };

    default:
      return state;
  }
};
