// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Styles
import "./HeaderLeft.css";

// Components
import ButtonMenu from "../../Common/Button/ButtonMenu";
import Logo from "./Logo";

export default class HeaderLeft extends Component {
  render() {
    return (
      <div className="small-19 medium-21 large-8 xlarge-12 columns">
        <div className="row align-middle header-left__row-wrapper">
          <ButtonMenu onClick={() => this.context.toggleLeftSidebar(true)} />
          <Logo />
        </div>
      </div>
    );
  }
}

HeaderLeft.contextTypes = {
  toggleLeftSidebar: PropTypes.func
};
