// Config
import { API_ROOT, API_ROOT_ODIN, KEY_CLIENT_APP, store } from "../config";

// Snackbar
import Snackbar from "../components/Common/Snackbar/Snackbar";

// Actions
import { LOGOUT } from "../actions/ActionTypes";

// Libs
import axios from "axios";
import { generate } from "shortid";

// Request Defaults
// axios.defaults.baseURL = API_ROOT;
axios.defaults.headers.post["Content-Type"] = "application/json";

/****************************************************
 * OBS:
 * As respostas do Odin devem ser tratadas com:
 * response.data.codResposta !== "000"
 *
 * E as do Gateway:
 * response.status !== 200
 *
 * Porém, algumas respostas do Gateway ainda retornam
 * status 200 e codResposta diferente de 0. Os casos
 * devem ser tratados nas próprias Actions. Deve-se
 * decidir um padrão de resposta no Gateway.
 ****************************************************/

const getAppName = () => {
  const hostSplit = window.location.host.split(".");
  let appName = hostSplit[hostSplit.length - 2];

  if (appName === "herokuapp") {
    appName = hostSplit[hostSplit.length - 3];
  }

  return appName;
};

const appName = getAppName();

export const doRequest = ({
  url = "",
  method = "GET",
  headers,
  data,
  params,
  baseURL,
  accessToken = undefined,
  getErrorObj,
  hideSPTRequestID = false
} = {}) => {

  if (accessToken) {
    let header = 'AuthSnet ';
    header += `accessToken="${accessToken}", `;
    header += `idCliente="${KEY_CLIENT_APP}"`;
    headers = { ...headers, Authorization: header };
  }
  const sptRequestIdHeader = `${appName}:${generate()}`;

  const requestHeaders = {
    ...headers
  };

  if(!hideSPTRequestID) {
    requestHeaders["SPT-Request-ID"] = sptRequestIdHeader
  }
  return axios({
    url,
    method,
    headers: requestHeaders,
    data,
    params,
    baseURL: baseURL || API_ROOT
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(getErrorObj ? error.response : error.response.data.message)
      } else {
        return Promise.reject(error.message);
      }
    });
};

/*
* doOdinRequest realiza requisições diretamente no Odin
* (Útil para usar as transações que existem no Odin, mas não no Gateway)
*/
export const doOdinRequest = ({
  url = "",
  method = "GET",
  redirect = true,
  accessToken,
  data,
  params
} = {}) => {
  let headers = `AuthSnet `;
  headers += `accessToken="${accessToken}", `;
  headers += `idCliente="${KEY_CLIENT_APP}"`;

  const sptRequestIdHeader = `${appName}:${generate()}`;

  return axios({
    url,
    method,
    data,
    params,
    baseURL: redirect ? API_ROOT_ODIN : API_ROOT,
    headers: {
      Authorization: headers,
      "SPT-Request-ID": sptRequestIdHeader
    }
  })
    .then(response => {
      const codSessaoFinalizada = ["023", "024"];
      if (codSessaoFinalizada.indexOf(response.data.codResposta) > -1) {
        store.dispatch({ type: LOGOUT });
        Snackbar("Sessão Expirada", "Ok");
        throw Promise.reject(response.data.mensagem);
      }

      if (response.data.codResposta !== "000") {
        throw Promise.reject(response.data.mensagem);
      }

      return response.data;
    })
    .catch(error => {
      return error;
    });
};
