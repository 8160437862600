// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// React Router
import { withRouter } from "react-router-dom";

// Components
import HeaderLeft from "./HeaderLeft/HeaderLeft";
import Usuario from "./Usuario/Usuario";

const HeaderContainer = ({ children }) => (
  <div className="row-wrapper">
    <header className="header full-width">
      <div className="row align-middle">
        { children }
      </div>
    </header>
  </div>
);

class Header extends Component {
  render() {
    return (
      <HeaderContainer>
        <HeaderLeft />
        <Usuario />
      </HeaderContainer>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {...state}
};

export default withRouter(
  connect(
    mapStateToProps,
  )(Header)
);
