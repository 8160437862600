import {
  ADICIONAR_ETICKET,
  ADICIONAR_ETICKET_BOLAO,
  REMOVER_ETICKET
} from "../../actions/ActionTypes.js";

export default function itens(state = [], action) {
  switch (action.type) {
    case ADICIONAR_ETICKET:
      return [
        item(undefined, action),
        ...state
      ];

    case ADICIONAR_ETICKET_BOLAO:
      return [
        itemBolao(undefined, action),
        ...state
      ];

    case REMOVER_ETICKET:
      const index = state.findIndex(item =>
        item.codigo === action.eticket.codigo
      );

      return [
        ...state.slice(0, index),
        ...state.slice(index + 1)
      ];

    default:
      return state;
  }
};

// OBS: o array de Eventos é igual aos itens do Carrinho
const initialState = {
  tipo: "esporte",
  valorTotal: 0,
  possivelRetorno: 0,
  momento: null,
  codigo: "",
  duracao: 0,
  eventos: []
};

function item(state = initialState, action) {
  switch (action.type) {
    case ADICIONAR_ETICKET:
      return {
        ...state,
        valorTotal: action.valorTotal,
        possivelRetorno: action.possivelRetorno,
        momento: action.momento,
        codigo: action.codigo,
        duracao: action.duracao,
        eventos: action.eventos
      };

    default:
      return state;
  }
};

const initialStateBolao = {
  tipo: "bolao",
  qtdCotas: 0,
  valorTotal: 0,
  momento: null,
  codigo: "",
  duracao: 0,
  eventos: []
};

function itemBolao(state = initialStateBolao, action) {
  switch (action.type) {
    case ADICIONAR_ETICKET_BOLAO:
      return {
        ...state,
        qtdCotas: action.qtdCotas,
        valorTotal: action.valorTotal,
        momento: action.momento,
        codigo: action.codigo,
        duracao: action.duracao,
        eventos: action.eventos
      };

    default:
      return state;
  }
};
