import {
  CHECK_CAMPEONATO_SIDEBAR,
  REMOVE_CHECK_CAMPEONATO_SIDEBAR,
  RECEIVE_MODALIDADES
} from "../../actions/ActionTypes.js";

const campeonatoInitialState = {
  id: null,
  nome: "",
  quantidadeDeEventos: 0,
  check: false
};


export default function campeonatos(state = campeonatoInitialState, action) {

  switch (action.type) {
    case CHECK_CAMPEONATO_SIDEBAR:
      return {
        ...state,
        check:true
      }

    case REMOVE_CHECK_CAMPEONATO_SIDEBAR:
      return {
        ...state,
        check:false
      }
    case RECEIVE_MODALIDADES:
      return {
        ...state,
        check:true
      }
    default:
      return state;
  }


}
