// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// React Router
import { withRouter } from "react-router-dom";

// Styles
import "./SideNav.css";

// Components
import OldSideNav from "../OldSideNav/SideNav";

/*
* TODO: ESSE COMPONENTE PRECISA SER REESCRITO
* Utilizando o OldSideNav temporariamente por motivos de prazo.
*/
class SideNav extends Component {
  render() {
    return (
      <section className={`small-24 xlarge-5 columns ${this.props.className}`}>
        <OldSideNav />
      </section>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {...state}
};

export default withRouter(
  connect(
    mapStateToProps,
  )(SideNav)
);
