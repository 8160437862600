import {
  REQUEST_CONCLUIR_CADASTRO_DADOS,
  RECEIVE_CONCLUIR_CADASTRO_DADOS,
  ERROR_CONCLUIR_CADASTRO_DADOS,
} from "../../../actions/ActionTypes";

const initialState = {
  login: "",
  isFetching: false,
  aviso: ""
};

export default function dados(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CONCLUIR_CADASTRO_DADOS:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_CONCLUIR_CADASTRO_DADOS:
      return {
        ...initialState,
        login: action.login,
        isFetching: false
      };

    case ERROR_CONCLUIR_CADASTRO_DADOS:
      return {
        ...initialState,
        aviso: action.error
      };

    default:
      return state;
  }
};
