// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Bolao/BolaoCarrinhoActions";
import { initBolaoDetalhe } from "../../../reducers/Bolao/BolaoDetalheReducer";

// Helpers
import { onlyAllowNumbers } from "../../../modules/helpers";

// Components
import Card from "../../Common/Card/Card";
import ButtonOddRounded from "../../Common/Button/ButtonOddRounded";
import InputTextSecondary from "../../Common/Input/InputTextSecondary";


class CardEventoBolao extends Component {
  constructor(props) {
    super(props);
    // this.tipo = this.props.detalheBolao.tipo === 1 ? "palpite" : "coluna";

    this.state = {
      tipo: [],
    }
  }

  componentDidMount(){
  
    //limpar o reducer dos detalhes do bolao
    this.props.initBolaoDetalhe();
    const tipo = this.props.detalheBolao.tipo;
    
    //verificação o tipo de bolao
    switch(tipo) {
      case 1:
        return this.setState({tipo: "palpite"});
      case 2:
        return this.setState({tipo: "coluna"});
      case 3:
        return this.setState({tipo: "coluna multipla"});
      default:
        return null;
    }
  }


  _onChangePalpite = (idEvento) => {
    const { evento } = this.props;
    let { value: casa } = this.casa;
    let { value: fora } = this.fora;

    if (!!(casa && fora)) {
      casa = parseInt(casa, 10);
      fora = parseInt(fora, 10);

      const itemCarrinho = {
        idEvento: evento.id,
        casa,
        fora,
        detalhes: {
          casa: evento.casa,
          fora: evento.fora,
          resultado: `${casa}x${fora}`
        }
      };

      return this.props.adicionarItemCarrinhoPalpite({ ...itemCarrinho });
    }

    this.props.removerItemCarrinhoPalpite(idEvento);
  }

  _onChangeColuna = (idTipoResultado, resultado, active) => {
    const { evento } = this.props;
  
    if (active) {
      return this.props.removerItemCarrinhoColuna(evento.id, idTipoResultado);
    } 

    const itemCarrinho = {
      idEvento: evento.id,
      idTipoResultado,
      detalhes: {
        casa: evento.casa,
        fora: evento.fora,
        resultado: resultado
      }
    };

    this.props.adicionarItemCarrinhoColuna({ ...itemCarrinho });
  }

  //executar as funcoes dos botoes das apostas multiplas
  _onChangeColunaMultiplas = (idTipoResultado, resultado, active) => {

    const { evento } = this.props;
    
    const itemCarrinho = {
      idEvento: evento.id,
      idTipoResultado,
      detalhes: {
        casa: evento.casa,
        fora: evento.fora,
        resultado: resultado
      }
    };
    if (active) {
      return this.props.removerItemCarrinhoColunaMultiplas({ ...itemCarrinho, bolaoDetalhe: this.props.detalheBolao });
    } 


    this.props.adicionarItemCarrinhoColunaMultiplas({ ...itemCarrinho, bolaoDetalhe: this.props.detalheBolao });


  }

  renderOpcoesPalpite(idEvento) {
    return (
      <div className="row">
        <div className="small-12 medium-6 large-4 columns">
          <InputTextSecondary
            className="input-palpite"
            inputRef={(node) => this.casa = node}
            onChange={() => this._onChangePalpite(idEvento)}
            onKeyPress={(e) => onlyAllowNumbers(e)}
            type="tel"
            placeholder="-"
          />
        </div>
        <div className="small-12 medium-6 large-4 columns">
          <InputTextSecondary
            className="input-palpite"
            inputRef={(node) => this.fora = node}
            onChange={() => this._onChangePalpite(idEvento)}
            onKeyPress={(e) => onlyAllowNumbers(e)}
            type="tel"
            placeholder="-"
          />
        </div>
      </div>
    );
  }

  renderOpcoesColuna(evento, opcoes) {
    return (
      <div className="row">
        {
          opcoes.map((opcao, index) => (
            <div key={index} className="small-8 columns">
              <ButtonOddRounded
                title={opcao.label}
                active={opcao.active}
                onClick={() =>
                  this._onChangeColuna(opcao.idTipoResultado, opcao.resultado, opcao.active)
                }
              />
            </div>
          ))
        }
      </div>
    );
  }

  //renderizar o tipo de apostas multipas
  renderOpcoesColunaMultipla(evento, opcoes) {
    return (
      <div className="row">
       {
          opcoes.map((opcao, index) => (
            <div key={index} className="small-8 columns">
              <ButtonOddRounded
                title={opcao.label}
                active={opcao.active}
                onClick={() =>
                  this._onChangeColunaMultiplas(opcao.idTipoResultado, opcao.resultado, opcao.active)
                }
              />
            </div>
          ))
        }
      </div>
    );
  }

  render() {
    const { evento } = this.props;
    
    //verificacao do tipo de bolao para ser renderizado
    const renderTipo = () => {
      const validacao = this.state.tipo;
      switch(validacao) {
        case "coluna":
          return this.renderOpcoesColuna(evento, evento.opcoes)
        case "palpite":
          return this.renderOpcoesPalpite(evento.id)
        case "coluna multipla":
          return this.renderOpcoesColunaMultipla(evento, evento.opcoes)
        default:
          return null;
      }

    }
    

    return (
      <Card
        className="edge-bottom"
        header={<Header evento={evento} />}
      >
        {renderTipo()}
      </Card>
    );
  }
};

const Header = ({ evento }) => (
  <div className="row align-middle">
    <div className="small-4 medium-3 large-2 columns">
      <Badge horario={evento.horario} />
    </div>
    <div className="small-20 medium-21 large-22 columns">
      <Title casa={evento.casa} fora={evento.fora} />
    </div>
  </div>
);

const Badge = ({ horario }) => (
  <div>
    <div className="card-evento__badge card-evento__badge--small show-for-small-only text-center">
      <span className="text-subheader light-secondary">
        { horario }
      </span>
    </div>
    <div className="card-evento__badge card-evento__badge--medium show-for-medium text-center">
      <span className="text-subheader light-secondary">
        { horario }
      </span>
    </div>
  </div>
);

const Title = ({ casa, fora }) => (
  <h3 className="card-evento__title text-title light-default">
    { casa }<span className="hide-for-small-only">&nbsp;&nbsp;</span>
    <div className="show-for-small-only"></div>
    <span className="text-caption light-disabled hide-for-small-only">vs.</span>
    <span className="hide-for-small-only">&nbsp;&nbsp;</span>{ fora }
  </h3>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { detalheBolao: state.bolaoReducer.detalhe }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, initBolaoDetalhe }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardEventoBolao);

