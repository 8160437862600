import React, {Component} from 'react';

// Redux
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import * as actions from "../../../actions/EventosPorCampeonato/EventosPorCampeonatoActions.js";

// React Router
// import { withRouter } from "react-router-dom";

// Components
//import Campeonato from "./Campeonato/Campeonato";
import CabecalhoJogos from "./Printer/CabecalhoJogos";
import ListagemJogos from "./Printer/ListagemJogos";

// CSS
import './EventosPorCampeonatoPrinter.css'

/*
    {
            campeonatos.map((campeonato, index) => (
                <Campeonato key={index} campeonato={campeonato} />
            ))
            }
*/

const Campeonatos = ({ cabecalho, linhas }) => (
    <div className="row">
      <div className="small-24 columns">
        <h1 className="eventoscamprint-tituloprinter"> Tabela de Jogos </h1>
        <CabecalhoJogos cabecalho={cabecalho}/>
        <ListagemJogos cabecalho={cabecalho} linhas={linhas}/>
      </div>
    </div>
  );


class EventosPorCampeonatoPrinter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cabecalho: null,
            linhas: null
        };
        
    }


    componentWillMount() {
        const dadosPrinter = JSON.parse(localStorage.getItem('eventosPrinter'));
        //localStorage.removeItem('eventosPrinter')
        let cabecalho = [];
        let linhas = [];

        //cabecalho = dadosPrinter.campeonatos[0].momentos[0].eventos[0].subeventos

        cabecalho = [ 
            {
                "id": 0,
                "nome": "C",
                "idOpcao": 0,
                "aposta": "",
                "label": "1",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "E",
                "idOpcao": 0,
                "aposta": "",
                "label": "X",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "F",
                "idOpcao": 0,
                "aposta": "",
                "label": "2",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "C1T",
                "idOpcao": 0,
                "aposta": "",
                "label": "1T 1",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "E1T",
                "idOpcao": 0,
                "aposta": "",
                "label": "1T X",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "F1T",
                "idOpcao": 0,
                "aposta": "",
                "label": "1T 2",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "-2,5",
                "idOpcao": 0,
                "aposta": "",
                "label": "TG  -2.5",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "+2,5",
                "idOpcao": 0,
                "aposta": "",
                "label": "TG  2.5",
                "cotacao": 0,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "AM S",
                "idOpcao": 0,
                "aposta": "",
                "label": "AM S",
                "cotacao": 210,
                "titulo": ""
              },
              {
                "id": 0,
                "nome": "AM N",
                "idOpcao": 0,
                "aposta": "",
                "label": "AM N",
                "cotacao": 0,
                "titulo": ""
              }];

        cabecalho = [
            {"id":0,"nome":"Jogos","idOpcao":0,"aposta":"","label":"Jogos","cotacao":0,"titulo":""},
            ...cabecalho
        ]
        

        dadosPrinter.campeonatos.forEach((campeonato,x) => {
            campeonato.momentos.forEach((momento,y) => {
                momento.eventos.forEach((evento,z) => {
                    evento.exibirBuscaSeq = dadosPrinter.campeonatosFiltros.exibirJogosDoDia;    
                    evento.data = momento.data;
                    linhas.push(evento)
                })
            })
        })
        
        

        if(dadosPrinter){
            this.setState({cabecalho : cabecalho,linhas:linhas})
        } else {
            window.close();
        }

        
        
    }

    componentDidMount() {
        window.print();
    }

    render() {
        return (
            <Campeonatos cabecalho={this.state.cabecalho} linhas={this.state.linhas} />
        )
    }
}
  
  export default EventosPorCampeonatoPrinter;
