import {
  INIT_CONSULTA_BILHETE,
  REQUEST_CONSULTA_BILHETE,
  RECEIVE_CONSULTA_BILHETE,
  ERROR_CONSULTA_BILHETE
} from "../ActionTypes";

// Modules
import { doRequest } from "../../modules/request";

export const initConsultaBilhetes = () => ({
  type: INIT_CONSULTA_BILHETE
});

export const requestConsultaBilhetes = () => ({
  type: REQUEST_CONSULTA_BILHETE
});

export const receiveConsultaBilhetes = (bilhete) => ({
  type: RECEIVE_CONSULTA_BILHETE,
  bilhete
});

export const errorConsultaBilhetes = (error) => ({
  type: ERROR_CONSULTA_BILHETE,
  error
});

export const fetchBilhete = (idBilhete) => (dispatch, getState) => {
  dispatch(requestConsultaBilhetes());

  const params = {
    url: "/bilhete",
    method: "GET",
    params: { idBilhete }
  };

  doRequest(params).then(response => {
    if (response.codResposta !== "000") {
      return Promise.reject(response.mensagem);
    }

    dispatch(receiveConsultaBilhetes(response));
  }).catch(error => {
    dispatch(errorConsultaBilhetes(error));
  });
};
