// React
import React from "react";

// Components
import ButtonPrimary from "../../../Common/Button/ButtonPrimary";
import ButtonSecondary from "../../../Common/Button/ButtonSecondary";
import Loader from "../../../Common/Loader/Loader";

export default ({ onClickLimpar, onClickApostar, isFetching }) => {
  let buttonApostar;

  if (isFetching) {
    buttonApostar = (
      <ButtonSecondary disabled>
        <Loader type="white" />
      </ButtonSecondary>
    );
  }
  else {
    buttonApostar = (
      <ButtonSecondary onClick={onClickApostar}>
        Apostar
      </ButtonSecondary>
    );
  }

  return (
    <div className="row">
      <div className="small-10 columns">
        <ButtonPrimary onClick={onClickLimpar}>Limpar</ButtonPrimary>
      </div>
      <div className="small-14 columns">
        { buttonApostar }
      </div>
    </div>
  );
};
