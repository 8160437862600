// React
import React from "react";

const SideNavItemFixo = ({
  contentLeft,
  contentRight,
  active,
  onClick,
}) => (
  <div className="sidenav-fixlist__item">
    <div
      onClick={onClick}
      className={
        "sidenav-fixlist__item-content" +
        (active ? " sidenav-fixlist__item-content--active" : "")
      }
    >
      <span
        className="sidenav-fixlist__item-content-left text-subheader light-default"
      >
        { contentLeft }
      </span>
      <span
        className="sidenav-fixlist__item-content-right text-caption light-disabled"
      >
        { contentRight }
      </span>
    </div>
  </div>
);

export default SideNavItemFixo;
