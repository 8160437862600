// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/ExtratoActions";

// Libs
import moment from "moment";

// Styles
import "./Extrato.css";

// Helpers
import { convertIntegerToMoney } from "../../../../modules/helpers";

// Components
import Card from "../../../Common/Card/Card";
import InputDate from "../../../Common/Input/InputDate";
import ButtonSecondary from "../../../Common/Button/ButtonSecondary";
import LoaderCarregando from "../../../Common/Loader/LoaderCarregando";
import Aviso from "../../../Common/Aviso/Aviso";
import CardExtrato from "./CardExtrato";

class Extrato extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInicio: moment(new Date()).format("YYYY-MM-DD"),
      dataFim: moment(new Date()).format("YYYY-MM-DD")
    };
  }

  componentWillUnmount() {
    this.props.initExtrato();
  }

  atualizarDataState = (event, tipo) => {
    if (tipo === "inicio") {
      this.setState({
        dataInicio: event.target.value
      });
    }
    else {
      this.setState({
        dataFim: event.target.value
      });
    }
  }

  renderSaldoFinal() {
    const { extrato, saldoAnterior, dataSaldoFinal } = this.props.extratoReducer;

    let saldoFinal = 0;

    // Soma o valor de todos os itens do Extrato
    extrato.forEach((infoExtrato, index) => {
      saldoFinal += infoExtrato.valor;
    });

    // Soma o saldo anterior com a soma dos itens
    saldoFinal += saldoAnterior;

    return (
      <div className="row">
        <div className="small-24 columns">
          <div className="extrato__saldo-atual padding-7">
            <div className="row align-middle">
              <div className="small-10 columns">
                <h3 className="text-title light-default">
                  { moment(dataSaldoFinal).format("DD/MM/YYYY") }
                </h3>
              </div>
              <div className="small-8 columns text-right">
                <p className="text-title light-secondary">Saldo Final</p>
              </div>
              <div className="small-6 columns text-right">
                <p className="text-title accent-1">
                  { convertIntegerToMoney(saldoFinal) }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { extrato, saldoAnterior, dataSaldoAnterior, aviso } = this.props.extratoReducer;

    if (aviso) {
      return (
        <Aviso className="edge-top" type={aviso.type}>
          { aviso.text }
        </Aviso>
      );
    }

    if (extrato.length) {
      return (
        <div className="edge-top">
          <CardExtrato
            extrato={extrato}
            saldoAnterior={saldoAnterior}
            dataSaldoAnterior={dataSaldoAnterior}
            atualizarSaldoResultado={this.atualizarSaldoResultado}
          />
          { this.renderSaldoFinal() }
        </div>
      );
    }
  }

  render() {
    const { isFetching } = this.props.extratoReducer;

    return (
      <div className="configuracoes-extrato">
        <form
          className="row"
          onSubmit={(event) => {
            event.preventDefault();
            this.props.fetchExtrato(
              this.state.dataInicio,
              this.state.dataFim
            );
          }}
        >
          <div className="small-24 columns">
            <Card className="card-block">
              <div className="row">
                <div className="small-24 medium-12 large-8 columns column-block">
                  <p className="filtro-label text-subheader light-default">Data Início</p>
                  <InputDate
                    value={this.state.dataInicio}
                    onChange={(event) => this.atualizarDataState(event, "inicio")}
                  />
                </div>
                <div className="small-24 medium-12 large-8 columns column-block">
                  <p className="filtro-label text-subheader light-default">Data Fim</p>
                  <InputDate
                    value={this.state.dataFim}
                    onChange={(event) => this.atualizarDataState(event, "fim")}
                  />
                </div>
                <div className="small-24 medium-12 large-8 xlarge-6 columns column-block">
                  <p className="filtro-label filtro-label--empty show-for-large"></p>
                  <ButtonSecondary
                    type="submit"
                    disabled={isFetching}
                    setDisabled={isFetching}
                  >
                    Exibir
                  </ButtonSecondary>
                </div>
              </div>
            </Card>
          </div>
        </form>
        {
          isFetching ?
          <LoaderCarregando className="edge-top" /> :
          this.renderContent()
        }
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    extratoReducer: state.extratoReducer,
    usuarioReducer: state.usuarioReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Extrato);
