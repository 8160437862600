// React
import React, { Component } from "react";

// Styles
import "./Input.css";

// Components
import MaskedInput from "react-text-mask";

export default class InputCelular extends Component {
  render() {
    const {
      name,
      type,
      error,
      placeholder,
      onChange,
      className,
      value,
      disabled
    } = this.props;

    let classNames = "input-text text-title";
    classNames += error ? " input-text--error" : "";
    classNames += `${type === "primary" ? " input-text--primary dark-default" : " input-text--secondary"}`;
    classNames += ` ${className && className}`;

    return (
      <MaskedInput
        type="tel"
        name={name}
        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholder={placeholder || "(00) 00000-0000"}
        className={classNames}
        onChange={onChange}
        value={value}
        disabled={disabled || false}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );
  }
};
