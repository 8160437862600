// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Configuracoes/ConfiguracoesActions.js";

// Components
import Filter from "../../Common/Filter/Filter";
import Apostas from "./Apostas/Apostas";
import Extrato from "./Extrato/Extrato";
import Saques from "./Saques/Saques";
import InserirCreditos from "./InserirCreditos/InserirCreditos";
import Promocoes from "./Promocoes/Promocoes";
import Cadastro from "./Cadastro/Cadastro";

class Configuracoes extends Component {
  componentWillMount() {
    const { setConfiguracaoActive, active } = this.props;
    setConfiguracaoActive(active);
  }

  componentWillUpdate(nextProps) {
    if (!nextProps.usuarioReducer.logado) {
      this.context.closeModal();
    }
  }

  render() {
    const { setConfiguracaoActive } = this.props;
    const { active } = this.props.configuracoesReducer;

    const itens = [
      {
        text: "Apostas",
        active: active === "apostas",
        onClick: () => setConfiguracaoActive("apostas")
      },
      {
        text: "Extrato",
        active: active === "extrato",
        onClick: () => setConfiguracaoActive("extrato")
      },
      {
        text: "Saques",
        active: active === "saques",
        onClick: () => setConfiguracaoActive("saques")
      },
      {
        text: "Inserir Créditos",
        active: active === "inserirCreditos",
        onClick: () => setConfiguracaoActive("inserirCreditos")
      },
      {
        text: "Cadastro",
        active: active === "cadastro",
        onClick: () => setConfiguracaoActive("cadastro")
      }
    ];

    // Permissão das Promoções
    const { exibirPromocoes } = this.props.permissoesReducer.configuracoes;

    if (exibirPromocoes) {
      itens.splice(
        itens.length - 1, 0,
        {
          text: "Promoções",
          active: active === "promocoes",
          onClick: () => setConfiguracaoActive("promocoes")
        }
      );
    }

    return (
      <div>
        <Filter itens={itens} />
        { active === "apostas" && <Apostas />}
        { active === "extrato" && <Extrato />}
        { active === "saques" && <Saques />}
        { active === "inserirCreditos" &&  <InserirCreditos />}
        { active === "promocoes" && <Promocoes /> }
        { active === "cadastro" &&  <Cadastro />}
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    permissoesReducer: state.permissoesReducer,
    configuracoesReducer: state.configuracoesReducer,
    usuarioReducer: state.usuarioReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Configuracoes);

Configuracoes.contextTypes = {
  closeModal: PropTypes.func
};