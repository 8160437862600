// React
import React from "react";

export default ({ titulo, casa, fora }) => (
  <div>
    <p className="text-caption light-secondary edge-bottom">{ titulo }</p>
    <p className="text-subheader light-default edge-bottom">{ casa }</p>
    <p className="text-subheader light-default edge-bottom">{ fora }</p>
  </div>
);
