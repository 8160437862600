// React
import React from "react";

// Libs
import moment from "moment";

// Helpers
import { formatOdinDate2ToDateJs, convertIntegerToMoney } from "../../../../modules/helpers";

// Components
import Card from "../../../Common/Card/Card";

const Header = ({ dataSaldoAnterior, saldoAnterior }) => (
  <div className="row padding-7 align-middle">
    <div className="small-10 columns">
      <h3 className="text-title light-default">
        { dataSaldoAnterior }
      </h3>
    </div>
    <div className="small-8 columns text-right">
      <p className="text-title light-secondary">Saldo Anterior</p>
    </div>
    <div className="small-6 columns text-right">
      <p className="text-title accent-1">
        { convertIntegerToMoney(saldoAnterior) }
      </p>
    </div>
  </div>
);

const InfoExtrato = ({ dataMovimento, descricao, valor }) => (
  <div className="row align-middle">
    <div className="small-18 columns column-block">
      <p className="text-title light-secondary">
        { `${moment(formatOdinDate2ToDateJs(dataMovimento)).format("DD/MM")} - ${descricao}` }
      </p>
    </div>
    <div className="small-6 columns column-block text-right">
      <p className="text-title light-default">
        { convertIntegerToMoney(valor) }
      </p>
    </div>
  </div>
);

export default ({ extrato, dataSaldoAnterior, saldoAnterior }) => {
  const dataSaldoAnteriorFormatada = moment(
    formatOdinDate2ToDateJs(dataSaldoAnterior)
    ).format("DD/MM/YYYY");
  const header = <Header dataSaldoAnterior={dataSaldoAnteriorFormatada} saldoAnterior={saldoAnterior} />;

  return (
    <Card className="edge-bottom-list card-block" header={header}>
      {
        extrato.map((infoExtrato, index) => (
          <InfoExtrato
            key={index}
            dataMovimento={infoExtrato.dataMovimento}
            descricao={infoExtrato.descricao}
            valor={infoExtrato.valor}
          />
        ))
      }
    </Card>
  );
};
