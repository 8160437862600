import {
  RECEIVE_LISTA_DE_BOLOES
} from "../../actions/ActionTypes.js";

export default function boloesInativos(state = [], action) {
  switch (action.type) {
    case RECEIVE_LISTA_DE_BOLOES:
      return action.boloesInativos.map((bolao) => (
        bolaoInativo(bolao, action)
      ));

    default:
      return state;
  }
};

const initialState = {
  id: 0,
  nome: ""
};

function bolaoInativo(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LISTA_DE_BOLOES:
      return state;

    default:
      return state;
  }
};
