// React
import React, { Component } from "react";

export default class LegendaSubeventos extends Component {
  render() {
    return (
      <div className="light-active evento-info">
        <Descricao titulo="Vencedor do encontro" legenda="1 · X · 2" />
        <Descricao titulo="Handicap de gols (europeu)" legenda="H -1" />
        <Descricao titulo="Ambas equipes marcam" legenda="S · N" />
        <Descricao titulo="Dupla possibilidade" legenda="C/E · F/E" />
        <Descricao titulo="Total de gols" legenda="TG +1 · TG -1 · TG -2.5 · TG +2.5" />
      </div>
    );
  }
}

const Descricao = ({ titulo, legenda }) => (
  <div className="text-title edge-bottom light-active">
    <p className="edge-bottom-4">{titulo}:</p>
    <span className="accent-1">{legenda}</span>
  </div>
);
