// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Libs
import moment from "moment";
import QRCode from "qrcode.react";

// Helpers
import { splitCodigo } from "../../../modules/helpers";

//CopytoClipboard
import {CopyToClipboard} from "react-copy-to-clipboard";

// Components
import Card from "../../Common/Card/Card";
import InfoEticket from "./InfoEticket";
import Aviso from "../../Common/Aviso/Aviso";

const Title = ({ momento }) => (
  <div className="small-21 columns">
    <h3 className="text-caption light-active padding-8">
      { moment(momento).format("DD/MM/YYYY [-] HH:mm:ss")
      }
    </h3>
  </div>
);

const CloseButton = ({ onClick }) => (
  <div className="small-3 columns text-right">
    <span
      onClick={onClick}
      className="icon-fechar light-disabled card-eticket__icon">
    </span>
  </div>
);


const Header = ({ momento, onClick }) => (
  <div className="row align-middle">
    <Title momento={momento} />
    <CloseButton onClick={onClick} />
  </div>
);

export default class CardEticket extends Component {

  state = {
    value: (this.props.eticket.codigo),
    copied: false,
    display: false,}
    
    componentWillUnmount(){
      clearTimeout(this.timeoutfunc);
    }

  infoEticket = () => {
    this.context.openModal(
      "E-Ticket",
      <InfoEticket eticket={this.props.eticket} />,
      "medium"
    );
  }

  onCopy = () => {
    this.setState({copied: true});
  };

  renderAviso = () => {
    if (this.state.copied === true) {
      return (
        <Aviso type="default">
          Código copiado com sucesso
        </Aviso>
      );
    }
    return null;
  }



  displayTimeOut = () => {
    if (this.state.display === true) return;
      this.setState({display: true})
    this.timeoutfunc = setTimeout(() => {
      this.setState({display: false})
    },4000) 
  }


  render() {
    const { eticket, removerEticket } = this.props;
    
    return (
      <Card
        className="card-eticket edge-bottom-list"
        header={
          <Header
            momento={eticket.momento}
            onClick={() => removerEticket(eticket)}
          />
        }
      >
        <div className="card-eticket__container">
          <CopyToClipboard
              onCopy={this.onCopy}
              text={eticket.codigo}>
              <div className="container-id-eticket" onClick={this.displayTimeOut}>
                <h1 className="card-eticket__codigo text-headline text-center noselect">
                  { splitCodigo(eticket.codigo, 3) }
                </h1>
              </div>
          </CopyToClipboard>
          {this.state.display && <div className="display-aviso">
            {this.renderAviso()}            
          </div>}
          <div onClick={this.infoEticket}>
            <p className="card-eticket__qrcode text-center">
            <QRCode value={eticket.codigo} 
              renderAs={"canvas"}
              size={150}
              bgColor={"#ffffff"}
              fgColor={"#000000"} />
            </p>
            <p className="text-small light-default text-center">
              Este Eticket deve ser pago em até { eticket.duracao } horas <br/><br/>
              Valide em um de nossos parceiros
            </p>
           </div>
        </div>
      </Card>
    );
  }
};

CardEticket.contextTypes = {
  openModal: PropTypes.func
};
