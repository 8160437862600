// React
import React, { Component } from "react";

// React Router
import { Route, Switch, withRouter } from "react-router-dom";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchFinalizarJogo } from "../../actions/Jogos/JogosActions";

// Styles
import "./Jogos.css";

// Components
import JogosList from "./JogosList";
import Jogo from "./Jogo";
import BannerSlot from "../Common/Banner/BannerSlot";

class Jogos extends Component {
  componentWillMount() {
    if (!this.props.permissaoJogos) {
      this.props.history.push("/");
    }

    if (this.props.jogoAtual.urlLaunch) {
      this.props.fetchFinalizarJogo();
    }
  }

  renderBanner = () => {
    const { slot } = this.props.bannerReducer;

    if (slot.url) {
      return (
        <BannerSlot
          link={slot.link}
          urlBackground={slot.url}
        />
      );
    }

    return null;
  }

  render() {
    const { exibirEsportes } = this.props

    return (
      <JogosContainer banner={this.renderBanner()}>
        <Switch>
          {!exibirEsportes && <Route exact path="/" component={JogosList} /> }
          <Route
            exact path="/jogos/slot/:slotID/f/:slotToken"
            render={(props) =>
              <FinalizarJogo {...props} fetchFinalizarJogo={this.props.fetchFinalizarJogo} />
            }
          />
          <Route
            exact path="/jogos/slot/:slotID/f/"
            render={(props) =>
              <FinalizarJogo {...props} fetchFinalizarJogo={this.props.fetchFinalizarJogo} />
            }
          />
          <Route exact path="/jogos" component={JogosList} />
          <Route exact path="/jogos/slot/:slotID" component={Jogo} />
          
        </Switch>
      </JogosContainer>
    );
  }
}

class FinalizarJogo extends Component {
  componentWillMount() {
    const { slotID, slotToken } = this.props.match.params;
    this.props.fetchFinalizarJogo(slotID, slotToken);
    this.props.history.push("/jogos");
  }

  render() {
    return null;
  }
}

const JogosContainer = ({ banner, children }) => (
  <main className="small-24 columns">
    {banner}
    <div className="main main--block">
      <div className="inner-row-wrapper">
        {children}
      </div>
    </div>
  </main>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    permissaoJogos: state.permissoesReducer.permissoes.slot,
    exibirEsportes: state.permissoesReducer.configuracoes.exibirEsportes,
    jogoAtual: state.jogosReducer.jogoAtual,
    bannerReducer: state.bannerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchFinalizarJogo: fetchFinalizarJogo,
  }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Jogos)
);
