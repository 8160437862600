import { Observable } from 'rxjs/Observable';
import { filtrarEventosPorCampeonatoPorBusca } from '../actions/EventosPorCampeonato/EventosPorCampeonatoActions'
import * as ActionTypes from '../actions/ActionTypes';


export default function setBuscaEventosCampeonato(action$) {
  return action$.ofType(ActionTypes.ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS)
  .debounceTime(1000)
  .mergeMap(() => Observable.merge(
      Observable.of(filtrarEventosPorCampeonatoPorBusca())
    ));

}
