import {
  INIT_CARRINHO_BOLAO,
  ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA,
  REMOVER_ITEM_CARRINHO_BOLAO_PALPITE,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA,
  LIMPAR_CARRINHO_BOLAO,
  REQUEST_APOSTA_CARRINHO_BOLAO,
  RECEIVE_APOSTA_CARRINHO_BOLAO,
  ERROR_CARRINHO_BOLAO,
  SUCCESS_CARRINHO_BOLAO,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS
} from "../ActionTypes";

// Modules
import { doRequest, doOdinRequest } from "../../modules/request";

// Actions
import { adicionarEticketBolao } from "../Eticket/EticketActions";
import { adicionarComprovante } from "../Comprovantes/ComprovantesActions";
import { fetchSaldo } from "../Usuario/UsuarioActions";

export const initCarrinho = () => ({
  type: INIT_CARRINHO_BOLAO,
});

export const adicionarItemCarrinhoPalpite = ({
  idEvento, casa, fora, detalhes
}) => ({
  type: ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE,
  idEvento, casa, fora, detalhes
});

export const adicionarItemCarrinhoColuna = ({
  idEvento, idTipoResultado, detalhes
}) => ({
  type: ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA,
  idEvento, idTipoResultado, detalhes
});

export const adicionarItemCarrinhoColunaMultiplas = ( //nova funcao para ser executada em CardEventoBolao - state e props
 { idEvento, idTipoResultado, detalhes, bolaoDetalhe}
) => ({
  type: ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  idEvento, idTipoResultado, detalhes, bolaoDetalhe
});

export const removerItemCarrinhoPalpite = (idEvento) => (dispatch, getState) => {
  const eventos = getState().bolaoCarrinhoReducer.eventos;

  for (let evento of eventos) {
    if (evento.idEvento === idEvento) {
      return dispatch({
        type: REMOVER_ITEM_CARRINHO_BOLAO_PALPITE,
        idEvento
      });
    }
  }
};

export const removerItemCarrinhoColuna = (idEvento, idTipoResultado) => ({
  type: REMOVER_ITEM_CARRINHO_BOLAO_COLUNA,
  idEvento, idTipoResultado
});

export const removerItemCarrinhoColunaMultiplas = ({ idEvento, idTipoResultado, detalhes, bolaoDetalhe}) => ({   //nova funcao para ser executada em CardEventoBolao - state e props
  type: REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  idEvento, idTipoResultado, detalhes, bolaoDetalhe
});

export const limparCarrinho = (limparStateCarrinho) => (dispatch, getState) => {
  // Infelizmente tem que usar o DOM pra limpar o bolão de palpite
  const inputs = Array.from(document.getElementsByClassName("input-palpite"));
  inputs.forEach(input => {
    input.value = "";
  });

  limparStateCarrinho();
  dispatch({ type: LIMPAR_CARRINHO_BOLAO });
}

export const requestApostaCarrinho = () => ({
  type: REQUEST_APOSTA_CARRINHO_BOLAO,
});

export const receiveApostaCarrinho = () => ({
  type: RECEIVE_APOSTA_CARRINHO_BOLAO,
});

export const successCarrinho = (text) => ({
  type: SUCCESS_CARRINHO_BOLAO,
  text
});

export const errorCarrinho = (text) => ({
  type: ERROR_CARRINHO_BOLAO,
  text
});

/*
* Faz aposta se tiver logado.
* Gera E-ticket se não estiver logado.
*/
export const apostar = (qtdCotas, valorTotal, openLoginModal, limparStateCarrinho) => (dispatch, getState) => {
  // Checa se tem permissão para efetuar aposta/eticket
  const { eticket: permissaoEticket } = getState().permissoesReducer.permissoes;
  const { logado } = getState().usuarioReducer;

  if (!permissaoEticket && !logado) {
    openLoginModal();
    return;
  }

  const { bolaoCarrinhoReducer } = getState();
  const { idBolao, eventos } = bolaoCarrinhoReducer;
  const bolaoParam = { idBolao, eventos, qtdCotas };

  if (!logado) {
    const data = {
      tipo: 3,
      confirmaPrinterAuto: false,
      bolao: bolaoParam
    };

    dispatch(gerarEticket(data, qtdCotas, valorTotal, limparStateCarrinho));
  }
  else {
    const data = {
      ...bolaoParam,
    };

    dispatch(realizarAposta(data, qtdCotas, valorTotal, limparStateCarrinho));
  }
};

export const gerarEticket = (data, qtdCotas, valorTotal, limparStateCarrinho) => (dispatch, getState) => {
  dispatch(requestApostaCarrinho());

  const params = {
    url: "/venda/eticket",
    method: "POST",
    data
  };

  return doRequest(params)
    .then(response => {
      if (response.codResposta !== "000") {
        return Promise.reject(response.mensagem);
      }

      // AJUSTAR PARA GERAR UM ETICKET DE VERDADE
      dispatch(adicionarEticketBolao({
        momento: new Date(),
        codigo: response.eticket,
        duracao: response.tempoExpirar,
        qtdCotas: qtdCotas,
        valorTotal: valorTotal,
        eventos: getState().bolaoCarrinhoReducer.eventos
      }));

      // TODO: procurar uma solução mais consistente.
      // Precisei utilizar o DOM para poder modificar o State
      // do componente BolaoCarrinhoFooter.
      dispatch(limparCarrinho(limparStateCarrinho));
      dispatch(successCarrinho("E-ticket gerado com sucesso"));
    })
    .catch(error => {
      dispatch(errorCarrinho(error));
    });
};

export const realizarAposta = (data, qtdCotas, valorTotal, limparStateCarrinho) => (dispatch, getState) => {
  dispatch(requestApostaCarrinho());

  const { usuarioReducer } = getState();
  const { accessToken } = usuarioReducer.usuario;

  data.confirmaPrinterAuto = true;

  const params = {
    url: "/sp/venda/bolao/",
    method: "POST",
    accessToken, data
  };

  return doOdinRequest(params)
    .then(response => {
      if (!response.idBilhete) {
        dispatch(errorCarrinho(response.response.data.message));
        return;
      }

      // Atualiza saldo do apostador após creditar o Pin
      dispatch(fetchSaldo());

      dispatch(adicionarComprovante({
        idBilhete: response.idBilhete,
        momento: new Date(),
        qtdCotas: qtdCotas,
        valorPago: valorTotal
      }));

      // TODO: procurar uma solução mais consistente.
      // Precisei utilizar o DOM para poder modificar o State
      // do componente BolaoCarrinhoFooter.
      dispatch(limparCarrinho(limparStateCarrinho));
      dispatch(successCarrinho("Aposta realizada com sucesso"));
    },
    error => {
      dispatch(errorCarrinho(error));
    });
};

