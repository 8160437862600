// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/ConsultaBilhete/ConsultaBilheteActions";

// Libs
import moment from "moment";

// Helpers
import {
  formatOdinDateToDateJs,
  convertIntegerToMoney
} from "../../../modules/helpers";

// Components
import CardEventoAposta, { EVENTO_BILHETE, EVENTO_BOLAO_CONSULTA } from "../../Common/Card/CardEventoAposta";
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import InputTextSecondary from "../../Common/Input/InputTextSecondary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import Card from "../../Common/Card/Card";
import Aviso from "../../Common/Aviso/Aviso";

class ConsultaBilhete extends Component {
  componentWillMount() {
    this.props.initConsultaBilhetes();

    const { numeroBilhete } = this.props;

    if (numeroBilhete) {
      this.props.fetchBilhete(numeroBilhete);
    }
  }

  _onSubmit = (e) => {
    e.preventDefault();
    if (!this.numeroBilhete.value) return;
    this.props.fetchBilhete(this.numeroBilhete.value);
  }

  renderForm() {
    return (
      <form className="row edge-bottom-list" onSubmit={this._onSubmit}>
        <div className="small-24 columns">
          <Card className="card-block">
            <div className="row">
              <div className="small-24 medium-12 xlarge-8 columns column-block">
                <InputTextSecondary
                  inputRef={(node) => this.numeroBilhete = node}
                  placeholder="Número do Bilhete"
                  type="tel"
                />
              </div>
              <div className="small-24 medium-12 xlarge-8 columns column-block">
                <ButtonSecondary>
                  Consultar
                </ButtonSecondary>
              </div>
            </div>
          </Card>
        </div>
      </form>
    );
  }

  renderBilhete(bilhete) {
    if (bilhete.qtdCotas) {
      return <BilheteBolao bilhete={bilhete} />;
    }

    return <Bilhete bilhete={bilhete} />;
  }

  render() {
    const { numeroBilhete } = this.props;
    const { isFetching, aviso, bilhete } = this.props.consultaBilheteReducer;

    return (
      <div>
        {!numeroBilhete && this.renderForm()}
        {isFetching && <LoaderCarregando />}
        {aviso && <Aviso type="error">{aviso}</Aviso>}
        {bilhete && this.renderBilhete(bilhete)}
      </div>
    );
  }
}

const bilhetePerdido = (bilhete) => {
  return [1, 3].indexOf(bilhete.idStatus) < 0;
}

const Bilhete = ({ bilhete }) => {
  const numeroBilhete = bilhete.numeroBilhete;
  const dataComprovante = moment(formatOdinDateToDateJs(bilhete.momento)).format("DD/MM/YYYY [-] HH:mm:ss");
  const quantidadeDeEventos = bilhete.qtdEventos;
  const valorPago = convertIntegerToMoney(bilhete.valorPago);
  const valorPremio = convertIntegerToMoney(bilhete.valorPremio);
  const statusBilhete = bilhete.nomeStatus;
  const header = (
    <Header
      numeroBilhete={numeroBilhete}
    />
  );

  return (
    <Card className="edge-bottom-list card-block" header={header}>
      <InfoGeral label="Data Comprovante" valor={dataComprovante} />
      <InfoGeral label="Quantidade de Eventos" valor={quantidadeDeEventos} />
      <InfoGeral label="Valor Pago" valor={valorPago} />
      <InfoGeral label="Possível Retorno" valor={valorPremio} />
      <InfoGeral label="Status Bilhete" valor={statusBilhete} />
      {
        bilhete.eventosBilhetes &&
        <Eventos
          type={EVENTO_BILHETE}
          eventos={bilhete.eventosBilhetes}
          bilhetePerdido={bilhetePerdido(bilhete)}
        />
      }
    </Card>
  );
};

const BilheteBolao = ({ bilhete }) => {
  const numeroBilhete = bilhete.numeroBilhete;
  const dataComprovante = moment(formatOdinDateToDateJs(bilhete.momento)).format("DD/MM/YYYY [-] HH:mm:ss");
  const quantidadeDeEventos = bilhete.qtdEventos;
  const qtdCotas = bilhete.qtdCotas;
  const pontosBolao = bilhete.pontosBolao;
  const valorPago = convertIntegerToMoney(bilhete.valorPago);
  const valorPremio = convertIntegerToMoney(bilhete.valorPremio);
  const statusBilhete = bilhete.nomeStatus;
  const header = (
    <Header
      numeroBilhete={numeroBilhete}
    />
  );

  return (
    <Card className="edge-bottom-list card-block" header={header}>
      <InfoGeral label="Data Comprovante" valor={dataComprovante} />
      <InfoGeral label="Quantidade de Eventos" valor={quantidadeDeEventos} />
      <InfoGeral label="Cotas" valor={qtdCotas} />
      <InfoGeral label="Pontuação" valor={pontosBolao || "---"} />
      <InfoGeral label="Valor Pago" valor={valorPago} />
      <InfoGeral label="Possível Retorno" valor={valorPremio} />
      <InfoGeral label="Status Bilhete" valor={statusBilhete} />
      {
        bilhete.eventosBilhetes &&
        <Eventos
          type={EVENTO_BOLAO_CONSULTA}
          eventos={bilhete.eventosBilhetes}
          bilhetePerdido={bilhetePerdido(bilhete)}
        />
      }
    </Card>
  );
};

const Eventos = ({ eventos, type, bilhetePerdido }) => (
  <div className="row align-center">
    {
      eventos.map((evento, index) => (
        <div key={index} className="small-24 columns column-block">
          <CardEventoAposta
            type={type}
            evento={evento}
            bilhetePerdido={bilhetePerdido}
          />
        </div>
      ))
    }
  </div>
);

const Header = ({ numeroBilhete }) => (
  <div className="row align-middle">
    <div className="small-20 columns">
      <h3 className="text-title padding-7 light-default">
        {numeroBilhete}
      </h3>
    </div>
  </div>
);

const InfoGeral = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-title light-secondary">{label}</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-title light-default">{valor}</p>
    </div>
  </div>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { consultaBilheteReducer: state.consultaBilheteReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultaBilhete);
