import { doRequest, doOdinRequest } from "../../modules/request";
import {
    INIT_TRADICIONAIS,
    REQUEST_TRADICIONAIS,
    RECEIVE_TRADICIONAIS,
    ERROR_TRADICIONAIS,
    INIT_TRADICIONAL_ATUAL,
    REQUEST_INICIAR_TRADICIONAL,
    RECEIVE_INICIAR_TRADICIONAL,
    RECEIVE_INICIAR_TRADICIONAL_MOBILE,
    ERROR_INICIAR_TRADICIONAL,
    RECEIVE_BANNER_TRADICIONAL
} from "../ActionTypes";

export const fetchTradicionais = () => dispatch => {
    dispatch(requestTradicionais());

    const params = {
        url: "/tradicionals/",
        method: "GET"
    };

    doRequest(params).then(response => {
        if (response.codResposta !== "000") {
            dispatch(errorTradicionais(response));
            return;
        }
        dispatch(receiveTradicionais(response.tradicionals));
    }).catch(error => {
        dispatch(errorTradicionais(error));
    });
};

export const fetchIniciarTradicional = () => (dispatch, getState) => {
    dispatch(requestIniciarTradicional());

    const accessToken = getState().usuarioReducer.usuario.accessToken;

    const params = {
        url: "/sp/lotericos/game/preinit",
        method: "POST",
        accessToken,
        data: {
            tipoProduto: 7,
        }
    };

    return doOdinRequest(params)
        .then(response => {
            console.log('fetchIniciarTradicional:',response);
            
            dispatch(receiveIniciarTradicional(response));
        })
        .catch(error => {
            dispatch(errorIniciarTradicional(error));
        });
};

export const fetchBannerTradicional = () => dispatch => {
    const params = {
        url: "/crm/banner",
        method: "GET",
        params: { target: "tradicional" }
    };

    doRequest(params).then(response => {
        dispatch(receiveBannerTradicional({ ...response }));
    }).catch(error => { /* ... */ });
};

export const initTradicionais = () => ({
    type: INIT_TRADICIONAIS
});

export const requestTradicionais = () => ({
    type: REQUEST_TRADICIONAIS
});

export const receiveTradicionais = tradicionals => ({
    type: RECEIVE_TRADICIONAIS,
    tradicionals
});

export const errorTradicionais = error => ({
    type: ERROR_TRADICIONAIS,
    error
});

export const initTradicionalAtual = () => ({
    type: INIT_TRADICIONAL_ATUAL
});

export const requestIniciarTradicional = () => ({
    type: REQUEST_INICIAR_TRADICIONAL
});

export const receiveIniciarTradicional = (tradicional) => ({
    type: RECEIVE_INICIAR_TRADICIONAL,
    tradicional
});

export const receiveIniciarTradicionalMobile = (tradicional) => ({
    type: RECEIVE_INICIAR_TRADICIONAL_MOBILE,
    tradicional
});

export const errorIniciarTradicional = error => ({
    type: ERROR_INICIAR_TRADICIONAL,
    error
});

export const receiveBannerTradicional = ({ name, link, dados, url }) => {
    return {
        type: RECEIVE_BANNER_TRADICIONAL,
        name, link, dados, url
    };
};