import {
  INIT_CADASTRO_PIN,
  REQUEST_CADASTRO_PIN,
  RECEIVE_CADASTRO_PIN,
  ERROR_CADASTRO_PIN,
} from "../../../actions/ActionTypes";

const initialState = {
  isFetching: false,
  sucesso: false,
  aviso: "",
};

export default function cadastroPin(state = initialState, action) {
  switch (action.type) {
    case INIT_CADASTRO_PIN:
      return initialState;

    case REQUEST_CADASTRO_PIN:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_CADASTRO_PIN:
      return {
        isFetching: false,
        sucesso: true,
        aviso: ""
      };

    case ERROR_CADASTRO_PIN:
      return {
        ...state,
        isFetching: false,
        aviso: action.error
      };

    default:
      return state;
  }
};
