// Action Types
import {
  INIT_APOSTAS,
  REQUEST_BILHETES,
  RECEIVE_BILHETES,
  ERROR_BILHETES,
  REQUEST_PRECONSULTA_CASHOUT,
  RECEIVE_PRECONSULTA_CASHOUT,
  ERROR_PRECONSULTA_CASHOUT,
  REQUEST_CASHOUT,
  RECEIVE_CASHOUT,
  ERROR_CASHOUT,
  REQUEST_CANCELAR_BILHETE,
  RECEIVE_CANCELAR_BILHETE,
  ERROR_CANCELAR_BILHETE,
  SET_BILHETE_CANCELADO,
  TOGGLE_BILHETE,
  REQUEST_EVENTOS,
  RECEIVE_EVENTOS,
  ERROR_EVENTOS
} from "../ActionTypes";

import { KEY_CLIENT_APP } from "../../config";

// Modules
import { doOdinRequest, doRequest } from "../../modules/request";
import { formatStringDateToOdin } from "../../modules/helpers";
import { fetchSaldo } from "../Usuario/UsuarioActions";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initApostas = () => ({
  type: INIT_APOSTAS
});

export const requestPreconsultaCashout = () => ({
  type: REQUEST_PRECONSULTA_CASHOUT
});

export const receivePreconsultaCashout = (valorCashout, idBilhete) => ({
  type: RECEIVE_PRECONSULTA_CASHOUT,
  valorCashout,
  idBilhete
});

export const requestCashout = (numeroBilhete) => ({
  type: REQUEST_CASHOUT,
  numeroBilhete
});

export const receiveCashout = (numeroBilhete) => ({
  type: RECEIVE_CASHOUT,
  numeroBilhete
});

export const errorCashout = (numeroBilhete) => ({
  type: ERROR_CASHOUT,
  numeroBilhete
});

export const errorPreconsultaCashout = (idBilhete) => ({
  type: ERROR_PRECONSULTA_CASHOUT,
  idBilhete
});

export const requestBilhetes = () => ({
  type: REQUEST_BILHETES
});

export const receiveBilhetes = (bilhetes) => ({
  type: RECEIVE_BILHETES,
  bilhetes
});

export const errorBilhetes = (error) => ({
  type: ERROR_BILHETES,
  error
});

export const toggleBilhete = (numeroBilhete) => ({
  type: TOGGLE_BILHETE,
  numeroBilhete
});

export const preconsultaCashout = (idBilhete) => (dispatch, getState) => {
  const accessToken = getState().usuarioReducer.usuario.accessToken;
  let headers = `AuthSnet `;
  headers += `accessToken="${accessToken}", `;
  headers += `idCliente="${KEY_CLIENT_APP}"`;
  const params = {
    url: "/cashout/preconsulta/auth",
    method: "GET",
    accessToken,
    headers: {
      Authorization: headers
    },
    params: { idBilhete }
  };
  return doRequest(params)
    .then(response => {
      dispatch(receivePreconsultaCashout(response.cashout, idBilhete))
    })
    .catch(error => {
      dispatch(errorPreconsultaCashout(idBilhete));
    });
}

export const solicitarCashout = (bilhete) => (dispatch, getState) => {
  dispatch(requestCashout(bilhete.numeroBilhete));
  const accessToken = getState().usuarioReducer.usuario.accessToken;
  let headers = `AuthSnet `;
  headers += `accessToken="${accessToken}", `;
  headers += `idCliente="${KEY_CLIENT_APP}"`;
  const params = {
    url: "/cashout/solicitar/auth",
    method: "POST",
    accessToken,
    headers: {
      Authorization: headers
    },
    data: {
      idBilhete : bilhete.numeroBilhete,
      cashout: bilhete.cashout,
      autentic: bilhete.autenticacao
    }
  };
  fetchSaldo()(dispatch, getState);
  return doRequest(params)
    .then(response => {
      if(response.codResposta === "000") {
        dispatch(receiveCashout(bilhete.numeroBilhete));
        Snackbar("Cashout realizado com sucesso", "Ok");
      }
    })
    .catch(error => {
      Snackbar(error, "Ok");
      dispatch(errorCashout(bilhete.numeroBilhete));
    });
}

export const fetchBilhetes = (inicio, fim) => (dispatch, getState) => {
  // Despacha erro se a data estiver inválida
  if (
    (!inicio || !fim) ||
    (new Date(inicio) > new Date(fim))
  ) {
    return dispatch(errorBilhetes("Informe um período válido."));
  }

  // Faz request se data estiver válida
  dispatch(requestBilhetes());

  const dataInicio = formatStringDateToOdin(inicio);
  const dataFim = formatStringDateToOdin(fim);
  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/sp/relatorio/bilhete/listar",
    method: "GET",
    accessToken,
    params: { dataInicio, dataFim }
  };

  return doOdinRequest(params)
    .then(response => {
      dispatch(receiveBilhetes(response.bilhetes))
    })
    .catch(error => {
      dispatch(errorBilhetes(error));
    });
};

// Eventos
export const requestEventos = (numeroBilhete) => ({
  type: REQUEST_EVENTOS,
  numeroBilhete
});

export const receiveEventos = (numeroBilhete, eventos) => ({
  type: RECEIVE_EVENTOS,
  numeroBilhete,
  eventos
});

export const errorEventos = (error) => ({
  type: ERROR_EVENTOS,
  error
});

export const fetchEventos = (numeroBilhete) => (dispatch, getState) => {
  dispatch(requestEventos(numeroBilhete));
  const accessToken = getState().usuarioReducer.usuario.accessToken;
  const params = {
    url: "/sp/relatorio/bilhete/detalheEventos/listar",
    method: "GET",
    accessToken,
    params: { idBilhete: numeroBilhete }
  };

  return doOdinRequest(params)
    .then(response => {
      dispatch(receiveEventos(numeroBilhete, response.eventosBilhetes));
    })
    .catch(error => {
      dispatch(errorEventos(error));
    });
};

// Cancelar Bilhete
export const requestCancelarBilhete = (numeroBilhete) => ({
  type: REQUEST_CANCELAR_BILHETE,
  numeroBilhete
});

export const receiveCancelarBilhete = (numeroBilhete) => ({
  type: RECEIVE_CANCELAR_BILHETE,
  numeroBilhete
});

export const errorCancelarBilhete = (numeroBilhete) => ({
  type: ERROR_CANCELAR_BILHETE,
  numeroBilhete
});

export const setBilheteCancelado = (numeroBilhete) => ({
  type: SET_BILHETE_CANCELADO,
  numeroBilhete
});

export const fetchCancelarBilhete = (bilhete) => (dispatch, getState) => {
  dispatch(requestCancelarBilhete(bilhete.numeroBilhete));

  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/sp/operacional/cancelamento/bilhete/pedido/",
    method: "POST",
    accessToken,
    data: {
      idBilhete: bilhete.numeroBilhete,
      autenticacao: bilhete.autenticacao,
      valorPago: bilhete.valorPago,
      idMotivo: 99,
      observacao: "",
      confirmar: true
    }
  };

  return doOdinRequest(params)
    .then(response => {
      if (response.codResposta === "000") {
        Snackbar(response.mensagem, "Ok");
        dispatch(setBilheteCancelado(bilhete.numeroBilhete));

        // Atualiza saldo do apostador após cancelar Bilhete
        dispatch(fetchSaldo());
      }

      dispatch(receiveCancelarBilhete(bilhete.numeroBilhete));
    })
    .catch(error => {
      Snackbar(error, "Ok");
      dispatch(errorCancelarBilhete(bilhete.numeroBilhete));
    });
};
