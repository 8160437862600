// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Libs
import moment from "moment";
import RecuperarSenha from "../../Modal/RecuperarSenha/RecuperarSenha";

class Topo extends Component {
  openModalRecuperarSenha = () => {
    this.context.openModal(
      "Recuperar Senha",
      <RecuperarSenha />,
      "xsmall"
    );
  }

  renderRecuperarSenha = () => {
    const { permissaoLogin, usuarioReducer, loginPorTelefone } = this.props;

    if (!permissaoLogin || !loginPorTelefone) return null;

    if (!usuarioReducer.logado) {
      return (
        <span
          className="text-subheader accent-1 cursor-pointer"
          onClick={this.openModalRecuperarSenha}
        >
          Esqueceu sua senha?
        </span>
      );
    }

    return null;
  }

  render () {
    return (
      <div className="row show-for-large">
        <div className="large-offset-4 large-10 columns">
          {this.renderRecuperarSenha()}
        </div>
        <CurrentTime></CurrentTime>
      </div>
    );
  };
}

Topo.contextTypes = {
  openModal: PropTypes.func
};

class CurrentTime extends Component {
  constructor(props) {
    super(props);
    this.state = { time: new Date() };
    this.interval = setInterval(() => this.setState({ time: new Date() }), 1000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  render () {
    return (
      <div className="large-10 columns">
        <div className="text-subheader light-secondary text-right">
          {moment(this.state.time).format("HH:mm:ss [(GMT-03:00)]")}
        </div>
      </div>
    );
  }
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    loginPorTelefone: state.permissoesReducer.configuracoes.loginPorTelefone,
    permissaoLogin: state.permissoesReducer.permissoes.pin,
    usuarioReducer: state.usuarioReducer
  }
};

export default connect(mapStateToProps)(Topo);
