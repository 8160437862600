// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Styles
import "../../ShareConfiguracoes.css";


// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/AlterarSenhaActions";

// Components
import Card from "../../../Common/Card/Card";
import InputTextSecondary from "../../../Common/Input/InputTextSecondary";
import ButtonSecondary from "../../../Common/Button/ButtonSecondary";
import Aviso from "../../../Common/Aviso/Aviso";
import Loader from "../../../Common/Loader/Loader";

class AlterarSenha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      novaSenha: "",
      confirmarSenha: "",
      senhaAtual: "",
      formValido: false
    };
  }

  componentWillMount() {
    this.props.initAlterarSenha();
  }

  componentWillUpdate(nextProps) {
    // Se atualizar senha com sucesso, retorna ao state inicial
    if (
      !this.props.alterarSenhaReducer.sucesso &&
      nextProps.alterarSenhaReducer.sucesso
    ) {
      this.setState({
        novaSenha: "",
        confirmarSenha: "",
        senhaAtual: "",
        formValido: false
      });
    }
  }

  alterarSenha = (e) => {
    e.preventDefault();

    this.props.fetchAlterarSenha({
      novaSenha: this.state.novaSenha,
      confirmarSenha: this.state.confirmarSenha,
      senhaAtual: this.state.senhaAtual
    });
  }

  renderHeader = () => {
    return (
      <p className="padding-7 extrato__saldo-atual">
        <span className="text-subheader light-default">Alterar Senha</span>
      </p>
    );
  }

  renderButtonAlterarSenha(isFetching) {
    if (isFetching) {
      return (
        <ButtonSecondary disabled>
          <Loader type="white" />
        </ButtonSecondary>
      );
    }

    return (
      <ButtonSecondary
        type="submit"
        disabled={!this.state.formValido}
        setDisabled={!this.state.formValido}
      >
        Alterar
      </ButtonSecondary>
    );
  }

  renderAviso(aviso) {
    if (aviso) {
      return (
        <div className="small-24 columns column-block">
          <Aviso type="error">
            { aviso }
          </Aviso>
        </div>
      );
    }

    return null;
  }

  _onChange = (e) => {
    const state = {...this.state};
    state[e.target.name] = e.target.value;

    // Verifica se todos os campos foram preenchidos
    state.formValido = (
      state.novaSenha !== "" &&
      state.confirmarSenha !== "" &&
      state.senhaAtual !== ""
    );

    this.setState(state);
  }

  render() {
    const { aviso, isFetching } = this.props.alterarSenhaReducer;

    return (
      <div className="configuracoes-alterar-senha">
        <form className="row" onSubmit={this.alterarSenha}>
          <div className="small-24 columns">
            <Card header={this.renderHeader()} className="card-block">
              <div className="row">
                <div className="small-24 medium-12 large-6 columns column-block">
                  <InputTextSecondary
                    placeholder="Nova Senha"
                    value={this.state.novaSenha}
                    name="novaSenha"
                    type="password"
                    onChange={this._onChange}
                  />
                </div>
                <div className="small-24 medium-12 large-6 columns column-block">
                  <InputTextSecondary
                    placeholder="Confirmar Senha"
                    value={this.state.confirmarSenha}
                    name="confirmarSenha"
                    type="password"
                    onChange={this._onChange}
                  />
                </div>
                <div className="small-24 medium-12 large-6 columns column-block">
                  <InputTextSecondary
                    placeholder="Senha Atual"
                    value={this.state.senhaAtual}
                    name="senhaAtual"
                    type="password"
                    onChange={this._onChange}
                  />
                </div>
                <div className="small-24 medium-12 large-6 columns column-block">
                  { this.renderButtonAlterarSenha(isFetching) }
                </div>
                { this.renderAviso(aviso) }
              </div>
            </Card>
          </div>
        </form>
      </div>
    );
  }
}

AlterarSenha.contextTypes = {
  closeModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { alterarSenhaReducer: state.alterarSenhaReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlterarSenha);
