import {
  INIT_CONSULTA_BILHETE,
  REQUEST_CONSULTA_BILHETE,
  RECEIVE_CONSULTA_BILHETE,
  ERROR_CONSULTA_BILHETE
} from "../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: "",
  bilhete: null
};

export default function consultaBilheteReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_CONSULTA_BILHETE:
      return initialState;

    case REQUEST_CONSULTA_BILHETE:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_CONSULTA_BILHETE:
      return {
        ...initialState,
        bilhete: action.bilhete
      };

    case ERROR_CONSULTA_BILHETE:
      return {
        ...initialState,
        aviso: action.error
      };

    default:
      return state;
  }
};
