// React
import React from "react";

// Components
import PaisCampeonato from "./PaisCampeonato";
import Momento from "../Momento/Momento";

export default ({ campeonato, outright }) => (
  <div>
    <PaisCampeonato pais={campeonato.pais} campeonato={campeonato.nome} outright={outright} />
    {
      campeonato.momentos.map((momento, index) => (
        <Momento key={index} idCampeonato={campeonato.id} momento={momento} outright={outright} />
      ))
    }
  </div>
);
