// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// React Router
import { Route, Switch, withRouter } from "react-router-dom";

// Styles
import "./Main.css";

// Components
import EventosPorCampeonato from "./EventosPorCampeonato/EventosPorCampeonato";
import EventosLongoPrazo from "./EventosPorCampeonato/EventosLongoPrazo";
import EventosDisputaIndireta from "./EventosPorCampeonato/EventosDisputaIndireta";
import EventosAoVivo from "./EventosPorCampeonato/EventosAoVivo";
import SubeventosPrematch from "./SubEventos/SubeventosPrematch";
import SubEventosAoVivo from "./SubEventos/SubEventosAoVivo";
import CadastroRapido from "../Modal/Cadastro/CadastroRapido";
import ConcluirCadastro from "../Modal/Cadastro/ConcluirCadastro";
import CadastroBanner from "../Modal/Cadastro/CadastroBanner";

const MainContainer = ({ children }) => (
  <main className="medium-24 xlarge-14 columns">
    {children}
  </main>
);

const CarrinhoMobile = ({ onClick, itensNoCarrinho }) => (
  <button
    className="carrinho-mobile hide-for-xlarge"
    onClick={onClick}
  >
    <span className="carrinho-mobile__qtd">{itensNoCarrinho}</span>
    <span className="icon-carrinho light-active"></span>
  </button>
);

class Main extends Component {
  componentWillMount() {
    if (this.props.match.path === "/cadastro/:token") {
      this.context.openModal(
        "Cadastrar",
        <CadastroRapido token={this.props.match.params.token} />,
        "small"
      );
    }

    if (this.props.match.path === "/concluirCadastro/:token") {
      this.context.openModal(
        "Cadastrar",
        <ConcluirCadastro token={this.props.match.params.token} />,
        "small"
      );
    }

    if (this.props.match.path === "/cadastroBanner/:promocao") {
      this.openBannerPromocaoModal(this.props.match.params.promocao);
    }
  }

  componentWillUpdate(nextProps) {
    const currentPath = this.props.match.path === "/cadastroBanner/:promocao";
    const nextPath = nextProps.match.path === "/cadastroBanner/:promocao";
    if (!currentPath && nextPath) {
      this.openBannerPromocaoModal(nextProps.match.params.promocao);
    }
  }

  // Abre o modal de cadastro promocional e redireciona para "/"
  openBannerPromocaoModal = (promocao) => {
    this.context.openModal(
      "Cadastro Promocional",
      <CadastroBanner promocao={promocao} />,
      "xsmall"
    );
    this.props.history.push("/");
  }

  render() {
    const itensNoCarrinho = this.props.carrinho.itens.length;

    return (
      <MainContainer>
        <Switch>
          <Route exact path="/" component={EventosPorCampeonato} />
          <Route path="/longoprazo" component={EventosLongoPrazo} />
          <Route path="/disputa-indireta" component={EventosDisputaIndireta} />
          <Route path="/cadastro/:token" component={EventosPorCampeonato} />
          <Route path="/concluirCadastro/:token" component={EventosPorCampeonato} />
          <Route path="/cadastroBanner/:promocao" component={EventosPorCampeonato} />
          <Route path="/aovivo" component={EventosAoVivo} />
          <Route path="/subevento/prematch/:idCampeonato/:idEvento" component={SubeventosPrematch} />
          <Route path="/subevento/aovivo/:idCampeonato/:idEvento" component={SubEventosAoVivo} />
          <Route path="/consulta/venda/:idBilhete" component={EventosPorCampeonato} />
        </Switch>
        {
          // Renderiza Carrinho caso tenha algum item
          itensNoCarrinho > 0 &&
          <CarrinhoMobile
            onClick={() => this.context.toggleRightSidebar(true)}
            itensNoCarrinho={itensNoCarrinho}
          />
        }
      </MainContainer>
    );
  }
}

Main.contextTypes = {
  toggleRightSidebar: PropTypes.func,
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { carrinho: state.carrinho }
};

export default withRouter(
  connect(
    mapStateToProps,
  )(Main)
);
