import {
  INIT_PERMISSOES,
  REQUEST_PERMISSOES,
  RECEIVE_PERMISSOES,
  ERROR_PERMISSOES
} from "../../actions/ActionTypes.js";

// "momentoExpiracao" é o tempo limite para que as permissões
// sejam recarregadas ao atualizar, sem que seja necessário
// limpar o localStorage. O "300000" significa 5 minutos.
const initialState = {
  isFetching: false,
  finish: false,
  momentoExpiracao: new Date(new Date().getTime() + 300000),
  produtosPermitidos: [],
  permissoes: {
    aoVivo: false,
    bolao: false,
    promocoes: false,
    pin: false,
    eticket: false,
    slot: false,
    lotericos: false,
    descontoGanho: 0,
    valorMaxPremio: 0,
    fatorGanhoFixo: 0,
    regulamento: "",
    longoPrazo: false,
  },
  configuracoes: {
    nomeFantasia: "",
    pathAssets: "",
    linkAplicativo: "",
    exibirCampoLive: false,
    exibirEstatisticas: false,
    exibirDisputaIndireta: false,
    loginPorTelefone: false,
    exibirLogo: false,
    exibirPromocoes: false,
    exibirBotaoImprimir: false,
    exibirEsportes: true,
    contatos: [],
    tagManager: null,
    permiteSlotFun: false
  }
};

export default function permissoesReducer (state = initialState, action) {
  switch (action.type) {
    case INIT_PERMISSOES:
      return initialState;

    case REQUEST_PERMISSOES:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_PERMISSOES:
      return {
        ...state,
        isFetching: false,
        finish: true,
        momentoExpiracao: new Date(new Date().getTime() + 300000),
        produtosPermitidos: action.permissoes.produtosPermitidos,
        permissoes: {
          aoVivo: action.permissoes.aoVivo,
          bolao: action.permissoes.bolao,
          promocoes: action.permissoes.promocoes,
          pin: action.permissoes.pin,
          eticket: action.permissoes.eticket,
          slot: action.permissoes.slot,
          descontoGanho: action.permissoes.descontoGanho,
          valorMaxPremio: action.permissoes.valorMaxPremio,
          fatorGanhoFixo: action.permissoes.fatorGanhoFixo,
          regulamento: action.permissoes.regulamento || "",
          longoPrazo: action.permissoes.longoPrazo || false
        },
        configuracoes: {
          nomeFantasia: action.permissoes.nomeFantasia,
          pathAssets: action.permissoes.pathAssets,
          linkAplicativo: action.permissoes.linkAplicativo,
          exibirCampoLive: action.permissoes.exibirCampoLive,
          exibirEsportes: action.permissoes.exibirEsportes,
          exibirEstatisticas: action.permissoes.exibirEstatisticas || action.permissoes.exibirEstatistica,
          exibirDisputaIndireta: action.permissoes.disputaIndireta,
          // OBS: esse tratamento foi necessário para contornar erro no response temporariamente.
          // Deve ser removido quando variável for ajustada.
          loginPorTelefone: action.permissoes.loginPorTelefone || action.permissoes.loginTelefone,
          exibirLogo: action.permissoes.exibirLogo,
          exibirPromocoes: action.permissoes.exibirPromocoes,
          exibirBotaoImprimir: action.permissoes.exibirBotaoImprimir,
          contatos: action.permissoes.contatos || [],
          tagManager: action.permissoes.tagManager,
          permiteSlotFun: action.permissoes.permiteSlotFun
        }
      };

    case ERROR_PERMISSOES:
      return initialState;

    default:
      return state;
  }
};
