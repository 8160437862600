// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Bolao/BolaoActions";

// React Router
import { withRouter } from "react-router-dom";

// Styles
import "./BolaoSideNav.css";

// Components
import ItemBolao from "./ItemBolao";
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import Aviso from "../../Common/Aviso/Aviso";
import BolaoRanking from "../../Modal/Bolao/BolaoRanking";

class BolaoSideNav extends Component {
  componentWillMount() {
    this.props.fetchListaDeBoloes();
  }

  openModalRanking(idBolao) {
    this.context.openModal(
      "Ranking Bolão",
      <BolaoRanking idBolao={idBolao} />,
      "large"
    );
  }

  renderBoloesAtivos = () => {
    const { ativos } = this.props.bolaoReducer;
    const { idBolao } = this.props.match.params;

    if (!ativos.length) {
      return (
        <Aviso type="error">
          Nenhum bolão ativo no momento
        </Aviso>
      );
    }

    return (
      ativos.map((bolao, index) => (
        <ItemBolao
          key={index}
          nome={bolao.nome}
          active={bolao.id === parseInt(idBolao, 10)}
          onClick={() => this.props.history.push(`/bolao/${bolao.id}`)}
        />
      ))
    )
  }

  renderBoloesInativos = () => {
    const { inativos } = this.props.bolaoReducer;

    if (!inativos.length) {
      return (
        <Aviso type="error">
          Sem Ranking no momento
        </Aviso>
      );
    }

    return (
      inativos.map((bolao, index) => (
        <ItemBolao
          key={index}
          nome={bolao.nome}
          onClick={() => this.openModalRanking(bolao.id)}
        />
      ))
    )
  }

  render() {
    const { isFetching } = this.props.bolaoReducer;

    return (
      <section className={`small-24 xlarge-5 columns ${this.props.className}`}>
        {/* Bolões Ativos */}
        <p className="text-subheader light-secondary bolao-sidenav__divider">
          Ativos
        </p>
        {
          isFetching ? <LoaderCarregando /> :
          this.renderBoloesAtivos()
        }

        {/* Bolões Inativos */}
        <p className="text-subheader light-secondary bolao-sidenav__divider">
          Ranking
        </p>
        {
          isFetching ? <LoaderCarregando /> :
          this.renderBoloesInativos()
        }
      </section>
    );
  }
}

BolaoSideNav.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { bolaoReducer: state.bolaoReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BolaoSideNav)
);
