import {
  INIT_APOSTAS,
  REQUEST_BILHETES,
  RECEIVE_BILHETES,
  RECEIVE_PRECONSULTA_CASHOUT,
  ERROR_PRECONSULTA_CASHOUT,
  REQUEST_CASHOUT,
  RECEIVE_CASHOUT,
  ERROR_CASHOUT,
  ERROR_BILHETES,
  REQUEST_CANCELAR_BILHETE,
  RECEIVE_CANCELAR_BILHETE,
  ERROR_CANCELAR_BILHETE,
  SET_BILHETE_CANCELADO,
  TOGGLE_BILHETE,
  REQUEST_EVENTOS,
  RECEIVE_EVENTOS,
  ERROR_EVENTOS,
} from "../../../actions/ActionTypes.js";

import {
  bilhetes,
  setCashout,
  errorPreconsultaCashout,
} from "./BilhetesReducer";

const initialState = {
  isFetching: false,
  aviso: null,
  bilhetes: [],
};

export default function apostasReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_APOSTAS:
      return initialState;

    case REQUEST_BILHETES:
      return {
        isFetching: true,
        aviso: null,
        bilhetes: bilhetes(undefined, action),
      };

    case RECEIVE_BILHETES:
      return {
        isFetching: false,
        aviso: null,
        bilhetes: bilhetes(action.bilhetes, action),
      };
    case ERROR_CASHOUT:
    case RECEIVE_CASHOUT:
    case REQUEST_CASHOUT:
      return {
        ...state,
        bilhetes: bilhetes(state.bilhetes, action),
      };

    case RECEIVE_PRECONSULTA_CASHOUT:
      return {
        ...state,
        bilhetes: setCashout(state, action),
      };

    case ERROR_PRECONSULTA_CASHOUT:
      return {
        ...state,
        bilhetes: errorPreconsultaCashout(state, action),
      };

    case ERROR_BILHETES:
      return {
        isFetching: false,
        aviso: { type: "error", text: action.error },
        bilhetes: bilhetes(undefined, action),
      };

    case TOGGLE_BILHETE:
      return {
        ...state,
        bilhetes: bilhetes(state.bilhetes, action),
      };

    case REQUEST_EVENTOS:
    case RECEIVE_EVENTOS:
    case ERROR_EVENTOS:
    case REQUEST_CANCELAR_BILHETE:
    case RECEIVE_CANCELAR_BILHETE:
    case ERROR_CANCELAR_BILHETE:
    case SET_BILHETE_CANCELADO:
      return {
        ...state,
        bilhetes: bilhetes(state.bilhetes, action),
      };

    default:
      return state;
  }
}
