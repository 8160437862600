import { RECEIVE_BANNER_AO_VIVO } from "../../actions/ActionTypes.js";

const initialState = {
  name: "",
  link: "",
  url: "",
  dados: {}
};

export default function aoVivo(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_BANNER_AO_VIVO:
      return {
        name: action.name,
        link: action.link,
        dados: action.dados,
        url: action.url
      };

    default:
      return state;
  }
};
