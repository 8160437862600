// React
import React from "react";

// Styles
import "./Input.css";

// Components
import Input from "./Input";

export default ({ className, ...props }) => (
  <Input
    className={`input-text--secondary text-title ${className}`}
    {...props}
  />
);
