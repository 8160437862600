// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/ModalidadesEventosPrematch/ModalidadesEventosPrematchActions";

// React Router
import { Link, withRouter } from "react-router-dom";

// Libs
import moment from "moment";

// Helpers
import { popupCenter } from "../../../modules/helpers";

// Components
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import Modalidade from "./Modalidades/Modalidade";
import Aviso from "../../Common/Aviso/Aviso";
import SubeventosHeader from "./SubeventosHeader/SubeventosHeader";
import { formatOdinDateToDateJs } from "../../../modules/helpers";

const ModalidadesEventos = ({modalidadesEventos, idCampeonato, outright}) => (
  <div className="row">
    <div className="small-24 columns">
      {
        modalidadesEventos.map((modalidade, index) => (
          <Modalidade key={index} modalidade={modalidade} idCampeonato={idCampeonato} outright={outright}/>
        ))
      }
    </div>
  </div>
);

class SubEventosPorCampeonato extends Component {

  componentWillMount() {
    this.props.fetchSubEventosPorCampeonato(this.props.match.params.idEvento,this.props.match.params.idCampeonato);
  }

  renderAvisos= () => {
    const { aviso } = this.props.modalidadesEventosPrematchReducer;

    return (
      <div className="inner-row-wrapper">
        <div className="row">
          <div className="small-24 columns">
            <Link to={{pathname: "/"}}>
              <h1 className="text-display light-default">Voltar </h1>
            </Link>
          </div>
        </div>
        <Aviso className="edge-top" type={aviso.type}> { aviso.text } </Aviso>
      </div>
    )
  }

  renderHeader = () => {
    const { modalidades, nomeEvento, outright } = this.props.modalidadesEventosPrematchReducer;

    if (modalidades && nomeEvento) {
      /**********************************************************************
      * OBS: será modificado após mudanças efetuadas no Gateway para retornar
      * esses valores diretamente
      **********************************************************************/
      const eventoSplit = nomeEvento.split(" x ");
      const titleCasa = eventoSplit[0];
      const titleFora = eventoSplit[1];
      const titleMomento = moment(formatOdinDateToDateJs(modalidades[0].momentoEvento))
        .format("DD/MM - HH[H]mm");
      /**********************************************************************/

      return (
        <SubeventosHeader
          momento={titleMomento}
          casa={titleCasa}
          fora={titleFora}
          subeventos={modalidades[0].cotacoes[0].subeventos}
          outright = {outright}
        />
      );
    }

    return null;
  }

  popupEstatisticas = () => {
    const { idEvento } = this.props.match.params;
    popupCenter(`/estatisticas/${idEvento}/false`, "Estatísticas", 1000, 800);
  }

  render() {
    const { modalidades, isFetching, aviso, outright } = this.props.modalidadesEventosPrematchReducer;
    let modalidadesSemVencedor = [];
    if(outright) {
      modalidadesSemVencedor = modalidades;
    } else {
      modalidadesSemVencedor = [...modalidades.slice(1)];
    }
    const exibirEstatisticas = this.props.exibirEstatisticas ? true : false;

    return (
      <div>
        <div className="row align-middle edge-bottom">
          <div className="small-20 columns">
            <Link to={outright ? { pathname: "/longoprazo" } : { pathname: "/" }} className="subevento-voltar text-title light-secondary">
              <span className="subevento-voltar__icon icon-seta-voltar icon-fix"></span>
              <span className="subevento-voltar__text">Esportes</span>
            </Link>
          </div>
          {
            !outright && exibirEstatisticas &&
            <div className="small-4 columns text-right">
              <span
                onClick={this.popupEstatisticas}
                className="icon-ranking icon-fix subevento-estatisticas"
              />
            </div>
          }
        </div>
        <div className="main">
          <div className="inner-row-wrapper">
            { aviso && this.renderAvisos() }
            {
              isFetching ?
              <LoaderCarregando className="edge-top" /> :
              <div>
                { this.renderHeader() }
                <ModalidadesEventos
                  modalidadesEventos={modalidadesSemVencedor}
                  idCampeonato={this.props.modalidadesEventosPrematchReducer.idCampeonato}
                  outright={outright}
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    modalidadesEventosPrematchReducer: state.modalidadesEventosPrematchReducer,
    carrinho: state.carrinho,
    exibirEstatisticas: state.permissoesReducer.configuracoes.exibirEstatisticas
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubEventosPorCampeonato)
);
