// React
import React, { Component } from "react";

export default class CampoAoVivo extends Component {
  state = {
    idWidget: 0
  };
  componentWillMount() {
    const srwlScript = document.getElementById('sr-wl');

    if (srwlScript) {
      // window.location.reload(false);
    }

    const { matchID } = this.props;
    /* eslint-disable no-unused-vars */
    if (!window.SRConfig) {
      window.SRConfig = {
        on: {
          srlive_initialized: function() {
            var lmtsOptions = {};
            var lmtsNamespace = 'lmts';

            lmtsOptions.container = '.lmts-container';
            lmtsOptions.matchId = matchID;
            lmtsOptions.pitchCrowd = true;
            lmtsOptions.collapse_startCollapsed = true;

            var collapse_flag = lmtsOptions.collapse_startCollapsed;

            if (collapse_flag) {
              window.SRjQuery('body').addClass('lmts-ml-collapsed');
            }

            const idWidget = window.SRLive.addWidget({
              name: 'widgets.lmts',
              config: lmtsOptions
            });
            this.setState({ idWidget });

            // window.SRLive.event.trigger('set_match_focus', {
            //   matchId: matchID,
            //   idContainer: 'testIdContainer'
            // });
          }
        }
      };

      (function(d, s, id) {
        var js,
          h = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.async = false;
        js.setAttribute('data-autoInit', true);
        js.src =
          window.location.origin +
          '/betradar/cs//ls/widgets/?/servicenet/br/America:Sao_Paulo/widgetloader/widgets';
        h.parentNode.insertBefore(js, h);
      })(document, 'script', 'sr-wl');
      /* eslint-enable no-unused-vars */
    } else {
      if (window.SRLive) {
        setTimeout(() => {
          var lmtsOptions = {};

          lmtsOptions.container = '.lmts-container';
          lmtsOptions.matchId = matchID;
          lmtsOptions.pitchCrowd = true;
          lmtsOptions.collapse_startCollapsed = true;

          window.SRLive.addWidget({
            name: 'widgets.lmts',
            config: lmtsOptions
          });
        }, 100);

        // window.SRLive.event.trigger('set_match_focus', {
        //   matchId: matchID,
        //   idContainer: 'testIdContainer'
        // });
      }
    }
  }

  componentWillUnmount() {
    if (
      window.SRLive !== null &&
      window.SRLive !== undefined &&
      window.SRLive.event !== null &&
      window.SRLive.event !== undefined
    ) {
      setTimeout(() => {
        window.SRLive.removeWidget(this.state.idWidget);
      }, 100);
    }
  }

  render() {
    return <div className="lmts-container edge-bottom" />
  }
}
