// Action Types
import {
  INIT_JOGOS_LEANDER,
  REQUEST_JOGOS_LEANDER,
  RECEIVE_JOGOS_LEANDER,
  ERROR_JOGOS_LEANDER,
  INIT_JOGO_ATUAL,
  REQUEST_INICIAR_JOGO,
  RECEIVE_INICIAR_JOGO,
  RECEIVE_INICIAR_JOGO_MOBILE,
  ERROR_INICIAR_JOGO,
  RECEIVE_BANNER_SLOT
} from "../ActionTypes";

// Modules
import { doRequest, doOdinRequest } from "../../modules/request";
import { getDeviceType } from "../../modules/helpers";

// Actions
import { fetchSaldo } from "../Usuario/UsuarioActions";

// Lista dos jogos
export const initJogos = () => ({
  type: INIT_JOGOS_LEANDER
});

export const requestJogos = () => ({
  type: REQUEST_JOGOS_LEANDER
});

export const receiveJogos = (jogos) => ({
  type: RECEIVE_JOGOS_LEANDER,
  jogos
});

export const errorJogos = (error) => ({
  type: ERROR_JOGOS_LEANDER,
  error
});

export const fetchJogos = () => (dispatch, getState) => {
  dispatch(requestJogos());

  const params = {
    url: "/slots/",
    method: "GET"
  };

  doRequest(params).then(response => {
    if (response.codResposta !== "000") {
      dispatch(errorJogos(response));
      return;
    }

    dispatch(receiveJogos(response.games));
  }).catch(error => {
    dispatch(errorJogos(error));
  });
};

// Jogo Atual
export const initJogoAtual = () => ({
  type: INIT_JOGO_ATUAL
});

export const requestIniciarJogo = () => ({
  type: REQUEST_INICIAR_JOGO
});

export const receiveIniciarJogo = (jogo, urlLaunch, token, target) => ({
  type: RECEIVE_INICIAR_JOGO,
  jogo,
  urlLaunch,
  token,
  target
});

export const receiveIniciarJogoMobile = (jogo, urlLaunch, token, target) => ({
  type: RECEIVE_INICIAR_JOGO_MOBILE,
  jogo,
  urlLaunch,
  token,
  target
});

export const errorIniciarJogo = (error) => ({
  type: ERROR_INICIAR_JOGO,
  error
});

export const fetchIniciarJogo = (jogo, modoGame) => (dispatch, getState) => {
  dispatch(requestIniciarJogo());
  const accessToken = getState().usuarioReducer.usuario.accessToken;
  const valor = getState().usuarioReducer.usuario.configuracao.saldo;
  const deviceType = getDeviceType();
  const modoFun = modoGame === 'demo' ? true : false; //modoGame pode ser do tipo 'Jogar' ou 'Demonstração'
  const params = {
    // url: "/sp/leander/game/preinit",
    url: '/rgs/game/preinit',
    method: "POST",
    accessToken,
    redirect: false,
    data: {
      idGame: jogo.id,
      valor: parseFloat(valor / 100),
      channel: deviceType,
      modoFun: modoFun,
    }
  };

  return doRequest(params)
    .then(response => {
      if (deviceType === "mobile") {
          dispatch(receiveIniciarJogoMobile(jogo, response.urlLaunch, response.token, response.target));
      }
      dispatch(receiveIniciarJogo(jogo, response.urlLaunch, response.token, response.target));
    })
    .catch(error => {
      dispatch(errorIniciarJogo(error));
    });
};

export const fetchFinalizarJogo = (slotId, slotToken) => (dispatch, getState) => {
  const accessToken = getState().usuarioReducer.usuario.accessToken;

  let idGame, token;

  if (slotId && slotToken) {
    idGame = slotId;
    token = slotToken;
  }
  else {
    idGame = getState().jogosReducer.jogoAtual.id;
    token = getState().jogosReducer.jogoAtual.token;
  }

  dispatch(requestIniciarJogo());

  const params = {
    url: "/sp/leander/game/finalize",
    method: "POST",
    accessToken,
    data: { idGame, token }
  };

  return doOdinRequest(params)
    .then(response => {
      dispatch(initJogoAtual());
      dispatch(fetchSaldo());
    })
    .catch(error => {
      dispatch(initJogoAtual());
    });
};

// Banners dos Slots
export const receiveBannerSlot = ({ name, link, dados, url }) => {
  return {
    type: RECEIVE_BANNER_SLOT,
    name, link, dados, url
  };
};

export const fetchBannerSlot = () => (dispatch, getState) => {
  const params = {
    url: "/crm/banner",
    method: "GET",
    params: { target: "slot" }
  };

  doRequest(params).then(response => {
    dispatch(receiveBannerSlot({ ...response }));
  }).catch(error => { /* ... */ });
};
