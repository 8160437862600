import { Component } from "react";

// Redux
import { connect } from 'react-redux';

import TagManager from 'react-gtm-module';

class GTMTagManager extends Component {
  state = {
    inicializado: false
  };


  componentDidMount() {

    const { inicializado } = this.state;

    const { tagManager } = this.props.configuracoes;

    if (!inicializado && tagManager) {
      //Configure TAG Manager
      const tagManagerArgs = { gtmId: tagManager };

      TagManager.initialize(tagManagerArgs);
      //Call reactReady event if needed in GTM
      TagManager.dataLayer({
        dataLayer: {
          event: 'reactReady',
          reactReady: true
        }
      });
      this.setState({ inicializado: true });
    }
  }

  render() {
    // FIXME: retorno alterado para null - testar em Dev
    return null;
  }
}

// --- Container Logic --- //
const mapStateToProps = state => {
  return { configuracoes: state.permissoesReducer.configuracoes };
};

export default connect(mapStateToProps)(GTMTagManager);
