// Action Types
import {
  INIT_EXTRATO,
  REQUEST_EXTRATO,
  RECEIVE_EXTRATO,
  ERROR_EXTRATO
} from "../ActionTypes";

// Modules
import { doOdinRequest } from "../../modules/request";
import { formatStringDateToOdin } from "../../modules/helpers";

export const initExtrato = () => ({
  type: INIT_EXTRATO
});

export const requestExtrato = () => ({
  type: REQUEST_EXTRATO
});

export const receiveExtrato = (extrato) => ({
  type: RECEIVE_EXTRATO,
  extrato
});

export const errorExtrato = (error) => ({
  type: ERROR_EXTRATO,
  error
});

export const fetchExtrato = (inicio, fim) => (dispatch, getState) => {
  // Despacha erro se a data estiver inválida
  if (
    (!inicio || !fim) ||
    (new Date(inicio) > new Date(fim))
  ) {
    return dispatch(errorExtrato("Informe um período válido."));
  }

  // Faz request se data estiver válida
  dispatch(requestExtrato());

  const dataInicio = formatStringDateToOdin(inicio);
  const dataFim = formatStringDateToOdin(fim);
  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/sp/relatorio/extratoUsuario",
    method: "GET",
    accessToken,
    params: { dataInicio, dataFim }
  };

  return doOdinRequest(params)
    .then(response => {
      const extrato = {
        saldoAnterior: response.saldoAnterior,
        dataSaldoAnterior: response.dataSaldoAnterior,
        extrato: response.extrato,
        dataSaldoFinal: dataFim
      };

      dispatch(receiveExtrato(extrato));
    })
    .catch(error => {
      dispatch(errorExtrato(error));
    });
};
