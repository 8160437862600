import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as actions from "../../actions/Lotericos/LotericosActions";
import Login from "../Modal/Login/Login";
import Aviso from "../Common/Aviso/Aviso";
import SnackBar from '../Common/Snackbar/Snackbar';
import LoaderCarregando from "../Common/Loader/LoaderCarregando";

class Loterico extends Component {

    componentDidMount () {
        // Volta para o inicio se não estiver logado.
        if (!this.props.usuarioLogado) {
            this.openModalLogin();
            return;
        }

        // Reseta propriedades do jogo atual.
        this.props.initLotericoAtual();

        // Faz um novo request.
        this.props.fetchIniciarLoterico();
    }

    // Fazer com que o jogo seja finalizado em breve.
    componentWillUpdate (nextProps) {
        if (!nextProps.usuarioLogado) {
            this.props.history.push("/");
        }
    }

    openModalLogin = () => {
        this.context.openModal(
            "Faça Login para acessar os lotéricos",
            <Login />,
            "medium"
        );
    }

    renderIframe = (urlLaunch, title) => {
        return (
            <div className="row">
                <div className="small-24 columns">
                    <iframe
                        title={title}
                        className="iframe-jogo"
                        src={urlLaunch}
                        frameBorder="0"
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </div>
        );
    }

    checarErros = () => {
        const { aviso } = this.props.lotericosReducer.lotericoAtual;

        if (aviso !== '' && aviso === 'Saldo Insuficiente') {
            SnackBar(aviso, 'Ok')
            this.props.history.push('/');
            return;
        }

        if (aviso && aviso !== '') {
            SnackBar(aviso, 'Ok')
        }
    };

    render () {
        if (!this.props.usuarioLogado) return null;
        const { lotericoAtual } = this.props.lotericosReducer;
        const { loterico } = lotericoAtual;

        return (
            <div style={{ paddingBottom: "0.8rem" }}>

                {this.checarErros()}

                {lotericoAtual.isFetching && <LoaderCarregando />}

                {
                    !!loterico.urlLaunch &&
                    this.renderIframe(loterico.urlLaunch, 'Lotéricos')
                }

                {
                    !!lotericoAtual.aviso &&
                    <AvisoJogo aviso={loterico.mensagem} />
                }
            </div>
        );
    }
}

Loterico.contextTypes = {
    openModal: PropTypes.func
};

const AvisoJogo = ({ aviso }) => (
    <div className="row edge-top">
        <div className="small-24 columns">
            <Aviso type="error">
                {aviso}
            </Aviso>
        </div>
    </div>
);

const mapStateToProps = (state) => {
    return {
        usuarioLogado: state.usuarioReducer.logado,
        lotericosReducer: state.lotericosReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchIniciarLoterico: actions.fetchIniciarLoterico,
        initLotericoAtual: actions.initLotericoAtual
    }, dispatch);
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Loterico)
);
