// React
import React from "react";

// Libs
import moment from "moment";

// Helpers
import { convertIntegerToMoney } from "../../../modules/helpers";

// Components
import Card from "../../Common/Card/Card";

const Header = ({ numeroBilhete }) => (
  <div className="row align-middle">
    <div className="small-24 columns">
      <h3 className="text-subheader padding-7 light-default">
        Bilhete { numeroBilhete }
      </h3>
    </div>
  </div>
);

const InfoComprovante = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-small-bold light-secondary">{ label }</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-subheader light-default">{ valor }</p>
    </div>
  </div>
);

export default ({ comprovante }) => {
  const numeroBilhete = comprovante.idBilhete;
  const dataComprovante = moment(comprovante.momento).format("DD/MM/YYYY");
  const valorPago = convertIntegerToMoney(comprovante.valorPago);
  const valorPremio = comprovante.valorPremio ? convertIntegerToMoney(comprovante.valorPremio) : null;
  const qtdCotas = comprovante.qtdCotas ? comprovante.qtdCotas : null;
  const header = <Header numeroBilhete={numeroBilhete} />;

  return (
    <Card className="edge-bottom-list card-block" header={header}>
      <InfoComprovante label="Data" valor={dataComprovante} />
      { qtdCotas && <InfoComprovante label="Qtd. de Cotas" valor={qtdCotas} /> }
      <InfoComprovante label="Valor Pago" valor={valorPago} />
      { valorPremio && <InfoComprovante label="Valor Prêmio" valor={valorPremio} /> }
    </Card>
  );
};
