// React
import React from "react";

// Components
import Loader from "../../Common/Loader/Loader";

// Styles
const styles = {
  loader: {
    display: "block",
    width: "1.5rem",
    height: "1.5rem",
    maxHeight: "32px",
    margin: "0.6rem auto"
  }
}

// TODO: Refatorar essa merda desse Loader 😡
export default () => (
  <Loader style={styles.loader} />
);
