// Action Types
import {
  INIT_CUPONS,
  REQUEST_CUPONS,
  RECEIVE_CUPONS,
  ERROR_CUPONS,
  REQUEST_RESGATAR_CUPOM,
  RECEIVE_RESGATAR_CUPOM,
  ERROR_RESGATAR_CUPOM
} from "../ActionTypes";

// Modules
import { doOdinRequest } from "../../modules/request";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

// Actions
import { fetchSaldo } from "../Usuario/UsuarioActions";

export const initCupons = () => ({
  type: INIT_CUPONS
});

export const requestCupons = () => ({
  type: REQUEST_CUPONS
});

export const receiveCupons = (cupons) => ({
  type: RECEIVE_CUPONS,
  cupons
});

export const errorCupons = (error) => ({
  type: ERROR_CUPONS,
  error
});

export const fetchCupons = () => (dispatch, getState) => {
  dispatch(requestCupons());

  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/promocao/cupons/usuario",
    method: "GET",
    accessToken,
    redirect: false
  };

  return doOdinRequest(params).then(response => {
    if (!response.cupons) {
      return dispatch(errorCupons("Nenhum cupom promocional no momento."));
    }

    dispatch(receiveCupons(response.cupons));
  }).catch(error => {
    dispatch(errorCupons(error));
  });
};

// Resgatar Cupom
export const requestResgatarCupom = (idVoucher) => ({
  type: REQUEST_RESGATAR_CUPOM,
  idVoucher
});

export const receiveResgatarCupom = (idVoucher) => ({
  type: RECEIVE_RESGATAR_CUPOM,
  idVoucher
});

export const errorResgatarCupom = (idVoucher, error) => ({
  type: ERROR_RESGATAR_CUPOM,
  idVoucher,
  error
});

export const fetchResgatarCupom = (idVoucher) => (dispatch, getState) => {
  dispatch(requestResgatarCupom(idVoucher));

  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "sp/promocao/voucher/resgatar",
    method: "POST",
    accessToken,
    data: { idVoucher }
  };

  return doOdinRequest(params).then(response => {
    if (!response.codResposta) {
      Snackbar(response.response.data.message, "Ok");
      return dispatch(errorResgatarCupom(response.response.data.message));
    }

    if (response.codResposta === "000") {
      // Atualiza saldo do apostador após resgatar voucher
      dispatch(fetchSaldo());

      Snackbar("Promoção ativada com sucesso", "Ok");
      dispatch(receiveResgatarCupom(idVoucher));
    }
    else {
      Snackbar(response.mensagem, "Ok");
      dispatch(errorResgatarCupom(response.messagem));
    }
  }).catch(error => {
    Snackbar(error, "Ok");
    dispatch(errorResgatarCupom(idVoucher, error));
  });
};
