import React from 'react';

import './CabecalhoJogos.css'

export default ({cabecalho}) => {
    
    return (
        <div className="row eventoscamprint-cabecalhojogos--linha">
            {
                cabecalho.map((c,i) => {
                    if(i === 0 ){
                        return (
                            <div key={i} className="small-9 columns eventoscamprint-cabecalhojogos--header">
                                {c.nome}
                            </div>
                        )
                    } else {
                        return (
                            <div key={i} className="columns eventoscamprint-cabecalhojogos--header">
                                {c.label}
                            </div>
                        )

                    }
                    
                })
            }
        </div>
    )
}