// React
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// React Router
import { Link } from "react-router-dom";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions/Jogos/JogosActions";

// Components
import Card from "../Common/Card/Card";
import Aviso from "../Common/Aviso/Aviso";
import LoaderCarregando from "../Common/Loader/LoaderCarregando"
import Snackbar from "../Common/Snackbar/Snackbar";
import Login from "../Modal/Login/Login";

import jackpot1 from "../../static/svg/jackpot-1.svg";
import jackpot2 from "../../static/svg/jackpot-2.svg";
import jackpot3 from "../../static/svg/jackpot-3.svg";
import jackpot4 from "../../static/svg/jackpot-4.svg";
import jackpot5 from "../../static/svg/jackpot-5.svg";

import Slider from "react-slick";

import { convertFloatToMoney, getDeviceType } from "../../modules/helpers"
import ButtonSecondary from "../Common/Button/ButtonSecondary";
import ButtonPrimary from "../Common/Button/ButtonPrimary";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';  


class JogosList extends Component {
  componentWillMount() {
    this.props.fetchJogos();
    this.props.fetchBannerSlot();
  }

  // Callback do click nos Cards de jogos:
  // solicita login e impede link de modificar a URL
  solicitarLogin = (e) => {
    const { usuarioLogado } = this.props;
    if (!usuarioLogado) {
      e.preventDefault();
      this.context.openModal(
        "Login",
        <Login />,
        "large"
      );
      Snackbar("Faça Login para ter acesso aos Slots.", "Ok");
    }
  }

  // Renderiza jogos se houver
  renderJogos = () => {
    const { jogos, isFetching } = this.props.jogosReducer;
    const { permiteSlotFun } = this.props;

    if (jogos.length) {
      return (
        <div className="row">
          {
            jogos.map((jogo, index) => (
              <CardJogo key={index} jogo={jogo} onClick={this.solicitarLogin} permiteSlotFun={permiteSlotFun} history={this.props.history} usuarioLogado={this.props.usuarioLogado} />
            ))
          }
        </div>
      );
    }

    if (!jogos.length && !isFetching) {
      return <AvisoError aviso="Sem jogos no momento." />;
    }
  }

  render() {
    const { isFetching } = this.props.jogosReducer;
    return (
      <div>
        <Title title="Slots em Destaque" />

        {/* Loader */}
        {isFetching && <LoaderCarregando />}

        {/* Exibe os jogos se tiver */}
        {this.renderJogos()}
      </div>
    );
  }
}

JogosList.contextTypes = {
  openModal: PropTypes.func
};

class SliderJackpot extends Component {
  renderJackpotsList = () => {
    const { jackpots } = this.props;

    return jackpots.map((jackpot, index) => (
      <div key={index}>
        <span className="row jogos-linhajackpot">
          <span className="small-6 columns text-center">
            <img
              src={
                index === 0 ?
                  jackpot1 : index === 1 ?
                    jackpot2 : index === 2 ?
                      jackpot3 : index === 3 ?
                        jackpot4 : jackpot5
              }
              className="jogos-jackpoticon"
              alt="Jackpot"
            />
          </span>
          <span className="small-18 columns">
            <h1 className="jogos-jackpotvalor text-right" style={{ marginRight: "0.8rem" }}>R$ {convertFloatToMoney(jackpot.valor)}</h1>
          </span>
        </span>
      </div>
    ));
  }

  render() {
    const { jackpots } = this.props;

    if (jackpots.length > 1) {
      const settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        adaptiveHeight: true,
        vertical: true
      };

      return (
        <Slider {...settings} className="jogos-jackpotheader">
          {this.renderJackpotsList(jackpots)}
        </Slider>
      );
    }

    return (
      <div className="jogos-jackpotheader">
        {this.renderJackpotsList(jackpots)}
      </div>
    );
  }
}



const CardJogo = ({ jogo, onClick, permiteSlotFun, history, usuarioLogado }) => {
  const selectJogo = (e, jogo, modoGame) => {
    e.preventDefault();
    if (modoGame === 'jogar' || !permiteSlotFun) {
      if (!usuarioLogado) {
        onClick(e);
      } else {
        history.push({
          pathname:`/jogos/slot/${jogo.id}/`,
          modoGame: modoGame,
        })
      }
    } else if (modoGame === 'demo' && permiteSlotFun) {
      history.push({
        pathname:`/jogos/slot/${jogo.id}/`,
        modoGame: modoGame,
      })
    }
    
  }

  return (
    <Fragment>
      {(!permiteSlotFun || getDeviceType() === "mobile") &&
        <Link
          to={`/jogos/slot/${jogo.id}`}
          className="small-24 medium-12 large-6 columns column-block"
          onClick={getDeviceType() !== "mobile"?onClick:null}
        >
          <Card className="jogos-card">
            <div className="row edge-bottom">
              <div className="small-24 columns text-center jogo-imagemdestaque">
                {jogo.jackpots.length > 0 ? <SliderJackpot jackpots={jogo.jackpots} /> : <div />}
                {/* <img src={jogo.urlImagem} alt={jogo.nome} loading='lazy'/> */}
                <LazyLoadImage
                  alt={jogo.nome}
                  effect="blur"
                  src={jogo.urlImagem} // use normal <img> attributes as props
                  // height={image.height}
                  // width={image.width} 
                  />
              </div>
            </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  {jogo.nome}
                </p>
              </div>
            </div>
          </Card>
        </Link>}
      {(permiteSlotFun && getDeviceType() === "desktop") &&
        <div className="small-24 medium-12 large-6 columns column-block">
          <Card className="jogos-card-desktop">
              <div className="jogos-item row edge-bottom ">
                <div className="small-24 columns text-center jogo-imagemdestaque">
                  {jogo.jackpots.length > 0 ? <SliderJackpot jackpots={jogo.jackpots} /> : <div />}
                  {/* <img src={jogo.urlImagem} alt={jogo.nome} className="image-slot" loading="lazy"/> */}
                  <LazyLoadImage
                   className="image-slot"
                    alt={jogo.nome}
                    effect="blur"
                    src={jogo.urlImagem} // use normal <img> attributes as props
                    height={260}
                    // width={300} 
                  />
                </div>
                <div className="card-buttons-selector">
                  <ButtonSecondary
                    id="jogar-desktop"
                    onClick={(e) => selectJogo(e, jogo, "jogar")}
                  >
                    Jogar
                  </ButtonSecondary>
                  <ButtonPrimary
                    id="demo-desktop"
                    onClick={(e) => selectJogo(e, jogo, "demo")}
                  >
                    Demonstração
                  </ButtonPrimary>
                </div>
              </div>
            <div className="row">
              <div className="small-24 columns">
                <p className="text-title light-default">
                  {jogo.nome}
                </p>
              </div>
            </div>
          </Card>
        </div>
      }
    </Fragment>
  )
};

const Title = ({ title }) => (
  <div className="row">
    <div className="small-24 columns">
      <h1 className="text-display edge-bottom light-default">{title}</h1>
    </div>
  </div>
);

const AvisoError = ({ aviso }) => (
  <div className="row">
    <div className="small-24 columns">
      <Aviso className="edge-bottom" type="error">
        {aviso}
      </Aviso>
    </div>
  </div>
);


// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    usuarioLogado: state.usuarioReducer.logado,
    jogosReducer: state.jogosReducer,
    permiteSlotFun: state.permissoesReducer.configuracoes.permiteSlotFun
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(JogosList);
