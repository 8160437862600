// React
import React from "react";

// Libs
import moment from "moment";

export default ({ data, info }) => (
  <div className="row align-middle edge-padding-8">
    <div className="small-24 columns">
      <p className="text-subheader light-default">
        { moment(data).format("dddd, D [de] MMMM [de] YYYY") }
      </p>
    </div>
  </div>
);
