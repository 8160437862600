import {
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  INIT_CUPOM_CARRINHO,
  REQUEST_CUPOM_CARRINHO,
  RECEIVE_CUPOM_CARRINHO,
  ERROR_CUPOM_CARRINHO
} from "../../actions/ActionTypes.js";

const initialState = {
  codigo: null,
  isFetching: false,
  error: false,
  dados: {
    percentualGanho: null
  }
};

export default function cupom(state = initialState, action) {
  switch (action.type) {
    case INIT_CUPOM_CARRINHO:
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      return initialState;

    case REQUEST_CUPOM_CARRINHO:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_CUPOM_CARRINHO:
      return {
        codigo: action.codigo,
        isFetching: false,
        error: false,
        dados: {
          percentualGanho: action.percentualGanho || null
        }
      };

    case ERROR_CUPOM_CARRINHO:
      return {
        codigo: null,
        isFetching: false,
        error: true,
        dados: { ...initialState.dados }
      };

    default:
      return state;
  }
};
