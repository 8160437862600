// React
import React from "react";

// Components
import MomentoInfo from "./MomentoInfo";
import Evento from "../Evento/Evento";

export default ({ idCampeonato, momento, outright }) => (
  <div>
    <MomentoInfo data={momento.data} info={momento.info} outright={outright} />
    {
      momento.eventos.map((evento, index) => (
        <Evento key={index} idCampeonato={idCampeonato} evento={evento} tipoEvento={""} outright={outright} />
      ))
    }
  </div>
);
