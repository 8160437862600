// React
import React from "react";

// Styles
import "./Banner.css";

const Banner = ({ className, link, urlBackground, children }) => {
  let classes = `banner ${className}`;

  return (
    <a href={link} className={classes} style={{ backgroundImage: `url(${urlBackground})` }}>
      <div className="banner__wrapper">
        { children }
      </div>
    </a>
  );
};

export default Banner;
