import {
  INIT_EVENTOS_POR_CAMPEONATO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  RECEIVE_EVENTOS_CAMPEONATO,
  RECEIVE_EVENTOS_AOVIVO,
  INIT_EVENTOS_AOVIVO
} from "../../actions/ActionTypes.js";

import subeventos from "./SubeventosReducer";

export default function eventos(state = [], action) {

  switch (action.type) {
    case RECEIVE_EVENTOS_AOVIVO:
    case INIT_EVENTOS_AOVIVO:
    case RECEIVE_EVENTOS_CAMPEONATO:
    case INIT_EVENTOS_POR_CAMPEONATO:
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      return state.map(item =>
        evento(item, action)
      );

    default:
      return state;
  }
};

const initialState = {
  id: null,
  casa: "",
  fora: "",
  horario: "",
  seqBuscaDiario: "0",
  subeventos: []
};

function evento(state = initialState, action) {

  switch (action.type) {
    case RECEIVE_EVENTOS_AOVIVO:
    case INIT_EVENTOS_AOVIVO:
    case INIT_EVENTOS_POR_CAMPEONATO:
    case RECEIVE_EVENTOS_CAMPEONATO:
    case LIMPAR_CARRINHO:
      const eventoNoCarrinho = action.carrinho.itens.some(item =>
        item.idEvento === state.id
      );

      if (eventoNoCarrinho) {
        return {
          ...state,
          subeventos: subeventos(state.subeventos, action)
        };
      }

      return state;

    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
      if (state.id === action.idEvento) {
        return {
          ...state,
          subeventos: subeventos(state.subeventos, action)
        };
      }

      return state;

    default:
      return state;
  }
};
