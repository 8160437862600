import {
    RECEIVE_LOTERICOS
} from "../../actions/ActionTypes.js";

export default function lotericos(state = [], action) {
    switch (action.type) {
        case RECEIVE_LOTERICOS:
            return state.map(itemLoterico => loterico(itemLoterico, action));

        default:
            return state;
    }
};

const initialState = {
    id: "",
    nome: "",
    urlImagem: "",
    valorMinimo: 0,
    valorMaximo: 0,
    jackpots: [],
};

function loterico(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_LOTERICOS:
            return {
                id: state.idGame,
                nome: state.nomeGame,
                urlImagem: state.urlImagem,
                valorMinimo: state.valorMin,
                valorMaximo: state.valorMax
            };
        default:
            return state;
    }
};
