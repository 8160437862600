import { doRequest, doOdinRequest } from "../../modules/request";
import {
    INIT_LOTERICOS,
    REQUEST_LOTERICOS,
    RECEIVE_LOTERICOS,
    ERROR_LOTERICOS,
    INIT_LOTERICO_ATUAL,
    REQUEST_INICIAR_LOTERICO,
    RECEIVE_INICIAR_LOTERICO,
    RECEIVE_INICIAR_LOTERICO_MOBILE,
    ERROR_INICIAR_LOTERICO,
    RECEIVE_BANNER_LOTERICO
} from "../ActionTypes";

export const fetchLotericos = () => dispatch => {
    dispatch(requestLotericos());

    const params = {
        url: "/lotericos/",
        method: "GET"
    };

    doRequest(params).then(response => {
        if (response.codResposta !== "000") {
            dispatch(errorLotericos(response));
            return;
        }
        dispatch(receiveLotericos(response.lotericos));
    }).catch(error => {
        dispatch(errorLotericos(error));
    });
};

export const fetchIniciarLoterico = () => (dispatch, getState) => {
    dispatch(requestIniciarLoterico());

    const accessToken = getState().usuarioReducer.usuario.accessToken;

    const params = {
        url: "/lotericos/preinit",
        method: "POST",
        accessToken,
        data: {
            lotericos: {
                "lotericosID": "0001",
                "gameManagerURL": "http://app.barracuda.link/jbweb",
                "lobbyURL": "http://dev-sitebets.herokuapp.com/jogos/lotericos",
                "tema": "default"
            }
        }
    };

    return doOdinRequest(params)
        .then(response => {
            dispatch(receiveIniciarLoterico(response));
        })
        .catch(error => {
            dispatch(errorIniciarLoterico(error));
        });
};

export const fetchBannerLoterico = () => dispatch => {
    const params = {
        url: "/crm/banner",
        method: "GET",
        params: { target: "loterico" }
    };

    doRequest(params).then(response => {
        dispatch(receiveBannerLoterico({ ...response }));
    }).catch(error => { /* ... */ });
};

export const initLotericos = () => ({
    type: INIT_LOTERICOS
});

export const requestLotericos = () => ({
    type: REQUEST_LOTERICOS
});

export const receiveLotericos = lotericos => ({
    type: RECEIVE_LOTERICOS,
    lotericos
});

export const errorLotericos = error => ({
    type: ERROR_LOTERICOS,
    error
});

export const initLotericoAtual = () => ({
    type: INIT_LOTERICO_ATUAL
});

export const requestIniciarLoterico = () => ({
    type: REQUEST_INICIAR_LOTERICO
});

export const receiveIniciarLoterico = (loterico) => ({
    type: RECEIVE_INICIAR_LOTERICO,
    loterico
});

export const receiveIniciarLotericoMobile = (loterico) => ({
    type: RECEIVE_INICIAR_LOTERICO_MOBILE,
    loterico
});

export const errorIniciarLoterico = error => ({
    type: ERROR_INICIAR_LOTERICO,
    error
});

export const receiveBannerLoterico = ({ name, link, dados, url }) => {
    return {
        type: RECEIVE_BANNER_LOTERICO,
        name, link, dados, url
    };
};