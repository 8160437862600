// Action Types
import {
  INIT_CADASTRO_BANNER,
  REQUEST_CADASTRO_BANNER,
  RECEIVE_CADASTRO_BANNER,
  ERROR_CADASTRO_BANNER
} from "../ActionTypes";

// Modules
import { doRequest } from "../../modules/request";
import { formatTelefone } from "../../modules/helpers";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initCadastroBanner = () => ({
  type: INIT_CADASTRO_BANNER
});

// Actions dos dados
export const requestCadastroBanner = () => ({
  type: REQUEST_CADASTRO_BANNER
});

export const receiveCadastroBanner = () => ({
  type: RECEIVE_CADASTRO_BANNER
});

export const errorCadastroBanner = (error) => ({
  type: ERROR_CADASTRO_BANNER,
  error
});

export const fetchCadastroBanner = ({ login, promocao }) => (dispatch, getState) => {
  dispatch(requestCadastroBanner());

  const params = {
    url: "/promocao/banner",
    method: "POST",
    data: { login, idCodePromo: promocao }
  };

  doRequest(params)
    .then(response => {
      dispatch(receiveCadastroBanner());
      Snackbar(`SMS enviado para ${formatTelefone(login)}`, "Ok");
    }).catch(error => {
      dispatch(errorCadastroBanner(error));
    });
};
