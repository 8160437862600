import {
  ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA,
  REMOVER_ITEM_CARRINHO_BOLAO_PALPITE,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
} from "../../../actions/ActionTypes";

export default function eventos(state = [], action) {
  switch (action.type) {
    case ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE:
    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA:
    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
    {
      // Checa se já existe algum item do mesmo evento
      const index = state.findIndex(item => item.idEvento === action.idEvento);

      if (index > -1) {
        return [
          ...state.slice(0, index),
          evento(undefined, action),
          ...state.slice(index + 1)
        ];
      }

      return [
        ...state,
        evento(undefined, action)
      ];
    }

    case REMOVER_ITEM_CARRINHO_BOLAO_PALPITE:
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA:
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
    {
      const index = state.findIndex(item => item.idEvento === action.idEvento);

      return [
        ...state.slice(0, index),
        ...state.slice(index + 1)
      ];
    }

    default:
      return state;
  }
};

// Muda de acordo com o tipo do bolão palpite/coluna
const initialState = {
  idEvento: 0
};

function evento(state = initialState, action) {
  switch (action.type) {
    case ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE:
      return {
        idEvento: action.idEvento,
        casa: action.casa,
        fora: action.fora,
        detalhes: action.detalhes
      };

    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA:
    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
      return {
        idEvento: action.idEvento,
        idTipoResultado: action.idTipoResultado,
        detalhes: action.detalhes
      };

    default:
      return state;
  }
};

