import lotericos from "./ItensLotericosReducer";
import lotericoAtual from "./LotericoAtualReducer";
import {
    INIT_LOTERICOS,
    REQUEST_LOTERICOS,
    RECEIVE_LOTERICOS,
    ERROR_LOTERICOS,
    REQUEST_INICIAR_LOTERICO,
    RECEIVE_INICIAR_LOTERICO,
    RECEIVE_INICIAR_LOTERICO_MOBILE,
    ERROR_INICIAR_LOTERICO,
    INIT_LOTERICO_ATUAL
} from "../../actions/ActionTypes.js";

const initialState = {
    isFetching: false,
    aviso: null,
    lotericos: lotericos(undefined, {}),
    lotericoAtual: lotericoAtual(undefined, {})
};


export default function lotericosReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_LOTERICOS:
            return initialState;

        case REQUEST_LOTERICOS:
            return {
                isFetching: true,
                aviso: null,
                lotericos: lotericos(undefined, {}),
                lotericoAtual: lotericoAtual(undefined, {})
            };

        case RECEIVE_LOTERICOS:
            return {
                isFetching: false,
                aviso: null,
                lotericos: lotericos(action.lotericos, action),
                lotericoAtual: lotericoAtual(undefined, {})
            };

        case ERROR_LOTERICOS:
            return {
                isFetching: false,
                aviso: true, // Deve ser tratado ainda
                lotericos: lotericos(undefined, {}),
                lotericoAtual: lotericoAtual(undefined, {})
            };

        case INIT_LOTERICO_ATUAL:
        case REQUEST_INICIAR_LOTERICO:
        case RECEIVE_INICIAR_LOTERICO:
        case RECEIVE_INICIAR_LOTERICO_MOBILE:
        case ERROR_INICIAR_LOTERICO:
            return {
                ...state,
                lotericoAtual: lotericoAtual(undefined, action)
            };

        default:
            return state;
    }
};
