import {
  INIT_CADASTRO_BANNER,
  REQUEST_CADASTRO_BANNER,
  RECEIVE_CADASTRO_BANNER,
  ERROR_CADASTRO_BANNER
} from "../../../actions/ActionTypes";

const initialState = {
  isFetching: false,
  sucesso: false,
  aviso: ""
};

export default function cadastroBanner(state = initialState, action) {
  switch (action.type) {
    case INIT_CADASTRO_BANNER:
      return initialState;

    case REQUEST_CADASTRO_BANNER:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_CADASTRO_BANNER:
      return {
        ...initialState,
        sucesso: true
      };

    case ERROR_CADASTRO_BANNER:
      return {
        ...initialState,
        aviso: action.error
      };

    default:
      return state;
  }
};
