// React
import React from "react";

// Styles
import "./Button.css";

export default ({ onClick }) => (
  <button
    className="button-menu hide-for-xlarge"
    onClick={onClick}
  >
    <span className="icon-menu light-active" />
  </button>
);
