import {
  ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS,
  ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS
 } from "../../actions/ActionTypes.js";

const initialState = {
  type: "default",
  text: ""
};

export default function aviso(state = initialState, action) {
  switch (action.type) {
    case ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS:
    case ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS:
      return {
        type: "error",
        text: action.text
      };

    default:
      return state;
  }
};
