import {
  TOGGLE_SUBITENS,
  TOGGLE_FIXITENS,
  CHECK_CAMPEONATO_SIDEBAR,
  REMOVE_CHECK_CAMPEONATO_SIDEBAR,
  RECEIVE_MODALIDADES
} from "../../actions/ActionTypes";
import pais from "./PaisReducer";

const modalidadeInitialState = {
  id: null,
  nome: "",
  quantidadeDeEventos: 0,
  paises: [],
  active: false
};

export default function modalidade(state = modalidadeInitialState, action) {
  switch (action.type) {
    case TOGGLE_SUBITENS:
      if(state.id === action.id) {
        return {
          ...state,
          active: !state.active
        };
      } else {
        return {
          ...state,
          active: false
        };
      }

    case RECEIVE_MODALIDADES:
    case TOGGLE_FIXITENS:
      //const index = state.paises.findIndex(pais => pais.id === action.idPais);
      return {
        ...state,
        paises: state.paises.map(item => pais(item, action))
      };
    case CHECK_CAMPEONATO_SIDEBAR:
    case REMOVE_CHECK_CAMPEONATO_SIDEBAR:
    {
      const index = state.paises.findIndex(pais => pais.id === action.idPais);

      return {
        ...state,
        paises: [
          ...state.paises.slice(0, index),
          pais(state.paises[index], action),
          ...state.paises.slice(index + 1)
        ]
      };
    }
    default:
      return state;
  }
};
