import {
  REQUEST_BOLAO_RANKING,
  RECEIVE_BOLAO_RANKING,
  ERROR_BOLAO_RANKING
} from "../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: "",
  nome: "",
  momentoInicio: "",
  momentoFim: "",
  status: "",
  premiacao: 0,
  bilhetes: [],
  tipo: 0,
  premiarPontuacaoMaxima: false,
};

export default function bolaoReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BOLAO_RANKING:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_BOLAO_RANKING:
      return {
        isFetching: false,
        aviso: "",
        nome: action.nome,
        momentoInicio: action.momentoInicio,
        momentoFim: action.momentoFim,
        status: action.status,
        premiacao: action.premiacao,
        bilhetes: action.bilhetes,
        tipo: action.tipo,
        premiarPontuacaoMaxima: action.premiarPontuacaoMaxima
      };

    case ERROR_BOLAO_RANKING:
      return {
        ...initialState,
        isFetching: false,
        aviso: action.error
      };

    default:
      return state;
  }
};
