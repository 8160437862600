import {
  INIT_HELLO_BAR,
  SHOW_HELLO_BAR,
  HIDE_HELLO_BAR
} from "../ActionTypes";

export const initHelloBar = () => ({
  type: INIT_HELLO_BAR,
});

export const showHelloBar = ({ texto, acao }) => ({
  type: SHOW_HELLO_BAR,
  texto,
  acao
});

export const hideHelloBar = () => ({
  type: HIDE_HELLO_BAR
});
