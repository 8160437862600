import {
  INIT_BOLAO,
  REQUEST_LISTA_DE_BOLOES,
  RECEIVE_LISTA_DE_BOLOES,
  ERROR_LISTA_DE_BOLOES,
  REQUEST_BOLAO_DETALHE,
  RECEIVE_BOLAO_DETALHE,
  ERROR_BOLAO_DETALHE,
  REQUEST_BOLAO_RANKING,
  RECEIVE_BOLAO_RANKING,
  ERROR_BOLAO_RANKING
} from "../ActionTypes";

// Modules
import { doRequest } from "../../modules/request";

// Lista de Bolões
export const initBolao = () => ({
  type: INIT_BOLAO,
});

export const requestListaDeBoloes = () => ({
  type: REQUEST_LISTA_DE_BOLOES
});

export const receiveListaDeBoloes = (boloesAtivos, boloesInativos) => ({
  type: RECEIVE_LISTA_DE_BOLOES,
  boloesAtivos: boloesAtivos || [],
  boloesInativos: boloesInativos || []
});

export const errorListaDeBoloes = (error) => ({
  type: ERROR_LISTA_DE_BOLOES,
  error
});

export const fetchListaDeBoloes = () => (dispatch, getState) => {
  dispatch(requestListaDeBoloes());

  const params = {
    url: "/bolao",
    method: "GET",
    params: {
      qtdDiasAntes: 30
    }
  };

  // Organizar responses no Gateway
  doRequest(params).then(response => {
    if (!response.boloesAtivos && !response.boloesInativos) {
      return Promise.reject(response.message);
    }

    dispatch(receiveListaDeBoloes(response.boloesAtivos, response.boloesInativos));
  }).catch(error => {
    dispatch(errorListaDeBoloes(error));
  });
};

// Detalhes do Bolão
export const requestBolaoDetalhe = () => ({
  type: REQUEST_BOLAO_DETALHE
});

export const receiveBolaoDetalhe = ({ ...detalhe }) => ({
  type: RECEIVE_BOLAO_DETALHE,
  ...detalhe
});

export const errorBolaoDetalhe = (error) => ({
  type: ERROR_BOLAO_DETALHE,
  error
});

export const fetchBolaoDetalhe = (idBolao) => (dispatch, getState) => {
  if (!idBolao) return;

  dispatch(requestBolaoDetalhe());

  const params = {
    url: "/bolao/detalhe",
    method: "GET",
    params: { idBolao }
  };

  doRequest(params).then(response => {
    dispatch(receiveBolaoDetalhe({ ...response }));
  }).catch(error => {
    dispatch(errorBolaoDetalhe(error));
  });
};

// Ranking
export const requestRankingBolao = () => ({
  type: REQUEST_BOLAO_RANKING
});

export const receiveRankingBolao = ({
  nome, momentoInicio, momentoFim, status, premiacao, bilhetes, tipo, premiarPontuacaoMaxima
}) => ({
  type: RECEIVE_BOLAO_RANKING,
  nome, momentoInicio, momentoFim, status, premiacao, bilhetes, tipo, premiarPontuacaoMaxima
});

export const errorRankingBolao = (error) => ({
  type: ERROR_BOLAO_RANKING,
  error
});

export const fetchRankingBolao = (idBolao) => (dispatch, getState) => {
  dispatch(requestRankingBolao());

  const params = {
    url: "/bolao/ranking",
    method: "GET",
    params: { idBolao }
  };

  doRequest(params).then(response => {
    if (response.codResposta !== "000") {
      return Promise.reject(response.mensagem);
    }

    // Prepara objeto do ranking para o reducer
    const responseRanking = response.viewBolao.rakingBolao;
    const ranking = {
      nome: responseRanking.bolao.nome,
      momentoInicio: responseRanking.bolao.momentoInicio,
      momentoFim: responseRanking.bolao.momentoFim,
      status: responseRanking.bolao.status,
      premiacao: responseRanking.bolao.premiacao,
      bilhetes: responseRanking.dadosRakingBolao,
      tipo: responseRanking.bolao.tipo,
      premiarPontuacaoMaxima: responseRanking.bolao.premiarPontuacaoMaxima
    };

    dispatch(receiveRankingBolao({ ...ranking }));
  }).catch(error => {
    dispatch(errorRankingBolao(error));
  });
};