import {
  INIT_CARRINHO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  REQUEST_APOSTA_CARRINHO,
  RECEIVE_APOSTA_CARRINHO,
  SUCCESS_CARRINHO,
  ERROR_CARRINHO,
  INIT_CUPOM_CARRINHO,
  REQUEST_CUPOM_CARRINHO,
  RECEIVE_CUPOM_CARRINHO,
  ERROR_CUPOM_CARRINHO
} from "../../actions/ActionTypes.js";

import itens from "./ItensCarrinhoReducer";
import aviso from "./AvisoCarrinhoReducer";
import cupom from "./CupomCarrinhoReducer";

const initialState = {
  isFetching: false,
  aviso: aviso(undefined, {}),
  cupom: cupom(undefined, {}),
  itens: []
};

export default function carrinho(state = initialState, action) {
  switch (action.type) {
    case INIT_CARRINHO:
      return {
        ...initialState,
        itens: state.itens
      };

    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
      return {
        ...state,
        itens: itens(state.itens, action),
        aviso: aviso(undefined, action),
        cupom: cupom(state.cupom, action)
      };

    case LIMPAR_CARRINHO:
      return initialState;

    case REQUEST_APOSTA_CARRINHO:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_APOSTA_CARRINHO:
      return {
        ...state,
        isFetching: false
      };

    case SUCCESS_CARRINHO:
      return {
        ...state,
        aviso: aviso(state.aviso, action)
      };

    case ERROR_CARRINHO:
      return {
        ...state,
        isFetching: false,
        aviso: aviso(state.aviso, action)
      };

    case INIT_CUPOM_CARRINHO:
    case REQUEST_CUPOM_CARRINHO:
    case RECEIVE_CUPOM_CARRINHO:
    case ERROR_CUPOM_CARRINHO:
      return {
        ...state,
        cupom: cupom(state.cupom, action)
      };


    default:
      return state;
  }
};
