// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Carrinho/CarrinhoActions";

// Components
import Card from "../../../Common/Card/Card";

// Helpers
import { convertIntegerToFloat } from "../../../../modules/helpers";

const Badge = ({ cotacao }) => (
  <div className="small-4 columns">
    <div className="card-item-carrinho__badge text-center">
      <span className="text-subheader light-secondary">
        { convertIntegerToFloat(cotacao) }
      </span>
    </div>
  </div>
);

const Title = ({ aposta }) => (
  <div className="small-17 columns">
    <h3 className="card-item-carrinho__title text-title accent-1">
      { aposta }
    </h3>
  </div>
);

const CloseButton = ({ onClick }) => (
  <div className="small-3 columns text-right">
    <span
      onClick={onClick}
      className="icon-fechar light-disabled card-item-carrinho__icon">
    </span>
  </div>
);

const Header = ({ cotacao, aposta, onClick }) => (
  <div className="row align-middle">
    <Badge cotacao={cotacao} />
    <Title aposta={aposta} />
    <CloseButton onClick={onClick} />
  </div>
);

class CardItemCarrinho extends Component {
  closeButtonClick() {
    const { item, removerItemCarrinho } = this.props;
    removerItemCarrinho(item.idCampeonato, item.idEvento, item.subevento);
  }

  render() {
    const { item, className, children } = this.props;

    return (
      <Card
        className={`${className}`}
        header={
          <Header
            cotacao={item.subevento.cotacao}
            aposta={item.subevento.aposta}
            onClick={() => this.closeButtonClick(item)}
          />
        }
      >
        { children }
      </Card>
    );
  }
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { removerItemCarrinho: actions.removerItemCarrinho },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardItemCarrinho);
