import {
  ADD_FILTROS_EVENTOS_CAMPEONATOS,
  REMOVE_FILTRO_EVENTOS_CAMPEONATOS,
  ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS,
  CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO,
  CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO,
  CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO
} from "../../actions/ActionTypes.js";


const campeonatosFilterInitialState = {
  idCampeonatos: [],
  busca: "",
  exibirTodos: true,
  exibirJogosDoDia: false,
  exibirProximaHora: false
}

export default function campeonatosFilterReducer(state = campeonatosFilterInitialState, action) {

  switch (action.type) {
    case ADD_FILTROS_EVENTOS_CAMPEONATOS:
    case REMOVE_FILTRO_EVENTOS_CAMPEONATOS:
      return {
        ...state,
        idCampeonatos : idCampeonatoFilter(state.idCampeonatos,action)
      }
    case ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS:
      return {
        ...state,
        busca: action.busca
      }
    case CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO:
      return {
        ...state,
        exibirTodos: true,
        exibirJogosDoDia: false,
        exibirProximaHora: false
      }
    case CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO:
      return {
        ...state,
        exibirTodos: false,
        exibirJogosDoDia: true,
        exibirProximaHora: false
      }
    case CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO:
      return {
        ...state,
        exibirTodos: false,
        exibirJogosDoDia: false,
        exibirProximaHora: true
      }
    default:
      return state;

  }

}

export const idCampeonatoFilter = (state = [],action) => {

  switch (action.type) {
    case ADD_FILTROS_EVENTOS_CAMPEONATOS:
    const campeonatoNoFiltro = state.some(item =>
      item === action.idCampeonato
    );

    if(campeonatoNoFiltro){
      return state;
    }else{
      return [
        action.idCampeonato,
        ...state
      ]
    }
    case REMOVE_FILTRO_EVENTOS_CAMPEONATOS:
      const index = state.findIndex(campeonatoId => campeonatoId === action.idCampeonato);

      return [
        ...state.splice(0, index),
        ...state.splice(index + 1)
      ];
    default:

  }
}
