// React
import React from "react";

// Styles
import "./Evento.css";

// Components
import CardEvento from "./CardEvento";
import Subeventos from "./Subeventos";

export default ({ idCampeonato, evento, tipoEvento = "", outright = false }) => (
  <div className="edge-bottom">
    <CardEvento evento={evento} tipoEvento={tipoEvento} idCampeonato={idCampeonato} outright={outright}>
      <Subeventos idCampeonato={idCampeonato} evento={evento} outright={outright} tipoEvento={tipoEvento}/>
    </CardEvento>
  </div>
);
