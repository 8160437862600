import {
  REQUEST_CADASTRO_RAPIDO_DADOS,
  RECEIVE_CADASTRO_RAPIDO_DADOS,
  ERROR_CADASTRO_RAPIDO_DADOS,
} from "../../../actions/ActionTypes";

const initialState = {
  login: "",
  urlImagem: "",
  descricao: "",
  isFetching: false,
  aviso: ""
};

export default function dados(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CADASTRO_RAPIDO_DADOS:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_CADASTRO_RAPIDO_DADOS:
      return {
        ...initialState,
        login: action.login,
        urlImagem: action.urlImagem,
        descricao: action.descricao,
        isFetching: false
      };

    case ERROR_CADASTRO_RAPIDO_DADOS:
      return {
        ...initialState,
        aviso: action.error
      };

    default:
      return state;
  }
};
