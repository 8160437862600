// React
import React from 'react';

// Libs
import Sidebar from "react-sidebar";

// Components
import Aside from "../../Aside/Aside";

const SidebarAside = ({ children, open, fullWidth, onSetOpen, closeRightSidebar }) => {
  const SidebarContent = (
    <div>
      <div className="row align-right">
        <div className="small-3 columns sidebar-right__close-column text-right">
          <span onClick={closeRightSidebar} className="sidebar-close icon-fechar"></span>
        </div>
      </div>
      <div className="row">
        <Aside />
      </div>
    </div>
  );

  let sideBarClasses = "sidebar sidebar-right";
  sideBarClasses += fullWidth ? " sidebar-full-width" : "";

  const sidebarProps = {
    sidebar: SidebarContent,
    sidebarClassName: sideBarClasses,
    shadow: true,
    touch: fullWidth ? false : true,
    touchHandleWidth: 0,
    dragToggleDistance: 1,
    open: open,
    onSetOpen: onSetOpen,
    pullRight: true
  };

  return (
    <Sidebar {...sidebarProps}>
      { children }
    </Sidebar>
  );
};

export default SidebarAside;
