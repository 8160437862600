// React
import React, { Component } from "react";

// React Router
import { API_ROOT } from "../../../config";

// Axios
import axios from "axios";

// Components
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";


export default class Estatisticas extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentWillMount() {
    const { idEvento, aoVivo } = this.props.match.params;

    // Utilizando Axios pois o doRequest não estava compatível
    axios({
      url: `${API_ROOT}/statistica/evento`,
      method: "GET",
      params: { idEvento, eventoAoVivo: (aoVivo === "true") || null }
    })
    .then(response => {
      if (response.data.location) {
        window.location = response.data.location;
      }
      else {
        this.setState({ error: true });
      }
    }).catch(error => {
      this.setState({ error: true });
    });
  }

  render() {
    return (
      <div className="text-center" style={{ paddingTop: "5rem" }}>
        {
          this.state.error ?
          <h1>Estatísticas indisponíveis no momento.</h1> :
          <LoaderCarregando />
        }
      </div>
    )
  }
}
