// React
import React from "react";

// Styles
import "./ItensCarrinho.css";

// Components
import CardItemCarrinho from "./CardItemCarrinho";
import InfoItemCarrinho from "./InfoItemCarrinho";

export default ({ itens }) => {
  
  return (
  <div>
    {
      itens.map((item, index) => (
        <CardItemCarrinho
          key={index}
          className="card-item-carrinho"
          item={item}
        >
          <InfoItemCarrinho
            titulo={item.subevento.titulo}
            casa={item.subevento.outright ? item.subevento.nome : item.casa}
            fora={item.fora}
          />
        </CardItemCarrinho>
      ))
    }
  </div>
)};
