import {
  INIT_MODALIDADES_EVENTOS_AOVIVO,
  REQUEST_MODALIDADES_EVENTO_AOVIVO,
  RECEIVE_MODALIDADES_EVENTOS_AOVIVO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  SUCCESS_AVISO_MODALIDADES_EVENTO_AOVIVO,
  ERROR_AVISO_MODALIDADES_EVENTO_AOVIVO
} from "../../actions/ActionTypes.js";

import cotacao from "./CotacaoReducer";
import aviso from "./AvisosModalidadesEventosReducer";

const modalidadesEventosAoVivoInitialState = {
  isFetching : null,
  idEvento : 0,
  idCampeonato : 0,
  modalidades : [],
  nomeEvento : "",
  matchID : null,
  horario: "",
  periodo: "",
  placar: "-:-",
  tempoDecorrido: 0,
  tempoDecorridoMin: 0,
  aviso: undefined,
  infoExtraEvento: {}
}

const stateMudou = (state,newState) => {
  for(let x in state.modalidades) {
    for(let y in (state.modalidades[x] || {}).cotacoes) {
      for(let z in ((state.modalidades[x] || {}).cotacoes[y] || {}).subeventos) {

        if(typeof newState.modalidades[x].cotacoes[y].subeventos[z].cotacao === "undefined" || (state.modalidades[x].cotacoes[y].subeventos[z].cotacao !== newState.modalidades[x].cotacoes[y].subeventos[z].cotacao)) {
          return true;
        }

        if(newState.modalidades[x].cotacoes[y].subeventos[z].id !== state.modalidades[x].cotacoes[y].subeventos[z].id &&
        state.modalidades[x].cotacoes[y].subeventos[z].idEvento !== newState.modalidades[x].cotacoes[y].subeventos[z].idEvento){
          return true;
        }
      }
    }
  }

  if(state.modalidades.length<=0){
    return true;
  }else{
    return false;
  }

}

export default function modalidadesEventosPrematchReducer(state = modalidadesEventosAoVivoInitialState, action) {

  switch (action.type) {
    case INIT_MODALIDADES_EVENTOS_AOVIVO:
      return modalidadesEventosAoVivoInitialState;
    case REQUEST_MODALIDADES_EVENTO_AOVIVO:
    return {
      ...state,
      isFetching : true,
      aviso: undefined
    }
    case RECEIVE_MODALIDADES_EVENTOS_AOVIVO:

      const newState = {
        ...state,
        isFetching : false,
        idEvento : action.evento.idEvento,
        modalidades: action.evento.modalidades == null ? [] : action.evento.modalidades.map(item => modalidadeEventoAoVivo(item, action)),
        nomeEvento: action.evento.nomeEvento,
        matchID: action.evento.matchID,
        idCampeonato : action.evento.idCampeonato,
        horario: action.evento.horario,
        periodo: action.evento.periodo,
        placar: action.evento.placar,
        tempoDecorrido: parseInt(action.evento.tempoDecorrido,10),
        tempoDecorridoMin: parseInt(action.evento.tempoDecorridoMin,10),
        aviso: undefined,
        infoExtraEvento: action.evento.infoExtraEvento
      }

      if(stateMudou(state,newState)){
          return newState;
      }else {
        return {
          ...state,
          isFetching : false,
          horario: action.evento.horario,
          periodo: action.evento.periodo,
          placar: action.evento.placar,
          tempoDecorrido: parseInt(action.evento.tempoDecorrido,10),
          tempoDecorridoMin: parseInt(action.evento.tempoDecorridoMin,10),
          aviso: undefined,
        };
      }


    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
      if (state.idEvento === action.idEvento) {
        return {
          ...state,
          modalidades: state.modalidades.map(item => modalidadeEventoAoVivo(item, action)),
        }
      }

      return state;

    case LIMPAR_CARRINHO:
      return {
        ...state,
        modalidades: state.modalidades.map(item => modalidadeEventoAoVivo(item, action)),
      }

    case SUCCESS_AVISO_MODALIDADES_EVENTO_AOVIVO:
      return {
        ...state,
        aviso: aviso(state.aviso, action)
      };

    case ERROR_AVISO_MODALIDADES_EVENTO_AOVIVO:
      return {
        ...state,
        isFetching: false,
        aviso: aviso(state.aviso, action)
      };

    default:
      return state;
  }
};

const modalidadeEventoInitialState = {
  titulo : "",
  cotacoes : []
};

export const modalidadeEventoAoVivo = (state = modalidadeEventoInitialState, action) => {

  switch (action.type) {
    case RECEIVE_MODALIDADES_EVENTOS_AOVIVO:
    {

      const campeonatoNoCarrinho = action.carrinho.itens.some(item =>
        action.evento.idEvento === item.idEvento
      );

      if (campeonatoNoCarrinho) {
        return {
          ...state,
          cotacoes: state.cotacoes.map(item =>
            cotacao(item, action)
          )
        };
      }

      return state;
    }
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      return {
        ...state,
        cotacoes: state.cotacoes.map(item =>
          cotacao(item, action)
        )
      };

    default:
      return state;
  }
};
