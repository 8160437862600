// React
import React from "react";

// Styles
import "./Button.css";

const ButtonOddRounded = ({ title, active, onClick }) => {
  let classes = "button-odd-rounded";
  // active = true;
  classes += active ? " button-odd-rounded--active" : "";

  return (
    <button className={classes} onClick={onClick}>
      <p className="button-odd-rounded__title text-title accent-1">{ title }</p>
    </button>
  );
};

export default ButtonOddRounded;
