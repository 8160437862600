import {
    INIT_TRADICIONAL_ATUAL,
    REQUEST_INICIAR_TRADICIONAL,
    RECEIVE_INICIAR_TRADICIONAL,
    RECEIVE_INICIAR_TRADICIONAL_MOBILE,
    ERROR_INICIAR_TRADICIONAL
} from "../../actions/ActionTypes.js";

const initialState = {
    isFetching: false,
    aviso: null,
    tradicional: {}
};

export default function tradicionalAtual(state = initialState, action) {
    switch (action.type) {
        case INIT_TRADICIONAL_ATUAL:
            return initialState;

        case REQUEST_INICIAR_TRADICIONAL:
            return {
                ...initialState,
                isFetching: true
            };

        case RECEIVE_INICIAR_TRADICIONAL:
            return {
                tradicional: action.tradicional
            };

        case RECEIVE_INICIAR_TRADICIONAL_MOBILE:
            return {
                tradicional: action.tradicional
            };

        case ERROR_INICIAR_TRADICIONAL:
            return {
                ...initialState,
                isFetching: false,
                aviso: action.error
            };

        default:
            return state;
    }
};
