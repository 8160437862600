// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/PromocoesActions";

// Components
import LoaderCarregando from "../../../Common/Loader/LoaderCarregando";
import Aviso from "../../../Common/Aviso/Aviso";
import Cupom from "./Cupom";

class Promocoes extends Component {
  componentWillMount() {
    this.props.fetchCupons();
  }

  componentWillUnmount() {
    this.props.initCupons();
  }

  renderAviso(aviso) {
    if (aviso) {
      return (
        <Aviso type="error">
          { aviso }
        </Aviso>
      );
    }
  }

  renderCupons() {
    const { cupons } = this.props.promocoesReducer;

    if (cupons.length) {
      return cupons.map((cupom, index) => (
        <Cupom key={index} cupom={cupom} resgatar={() => this.props.fetchResgatarCupom(cupom.idVoucher)} />
      ));
    }

    return null;
  }

  render() {
    const { isFetching, aviso } = this.props.promocoesReducer;

    return (
      <div className="row">
        <div className="small-24 columns">
          {
            isFetching ? <LoaderCarregando /> :
            aviso ? this.renderAviso(aviso) :
            this.renderCupons()
          }
        </div>
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { promocoesReducer: state.promocoesReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promocoes);
