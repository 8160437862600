// React
import React, { Component } from "react";

// React Router
import { withRouter } from "react-router-dom";

// Componentes
import Main from "../../Main/Main";
import Nav from "../../Nav/Nav";
import SideNav from "../../SideNav/SideNav";
import Aside from "../../Aside/Aside";
import Header from "../../Header/Header";

class AppEsportes extends Component {
  render() {
    return (
      <div>
        <Header />
        <Nav />
        <div className="row-wrapper">
          <div className="row">
            <SideNav className="show-for-xlarge" />
            <Main />
            <Aside className="show-for-xlarge" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AppEsportes);
