import {
  INIT_JOGOS_LEANDER,
  REQUEST_JOGOS_LEANDER,
  RECEIVE_JOGOS_LEANDER,
  ERROR_JOGOS_LEANDER,
  INIT_JOGO_ATUAL,
  REQUEST_INICIAR_JOGO,
  RECEIVE_INICIAR_JOGO,
  RECEIVE_INICIAR_JOGO_MOBILE,
  ERROR_INICIAR_JOGO
} from "../../actions/ActionTypes.js";

import jogos from "./ItensJogosReducer";
import jogoAtual from "./JogoAtualReducer";

const initialState = {
  isFetching: false,
  aviso: null,
  jogos: jogos(undefined, {}),
  jogoAtual: jogoAtual(undefined, {})
};

export default function jogosReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_JOGOS_LEANDER:
      return initialState;

    case REQUEST_JOGOS_LEANDER:
      return {
        isFetching: true,
        aviso: null,
        jogos: jogos(undefined, {}),
        jogoAtual: jogoAtual(undefined, {})
      };

    case RECEIVE_JOGOS_LEANDER:
      return {
        isFetching: false,
        aviso: null,
        jogos: jogos(action.jogos, action),
        jogoAtual: jogoAtual(undefined, {})
      };

    case ERROR_JOGOS_LEANDER:
      return {
        isFetching: false,
        aviso: true, // Deve ser tratado ainda
        jogos: jogos(undefined, {}),
        jogoAtual: jogoAtual(undefined, {})
      };

    case INIT_JOGO_ATUAL:
    case REQUEST_INICIAR_JOGO:
    case RECEIVE_INICIAR_JOGO:
    case RECEIVE_INICIAR_JOGO_MOBILE:
    case ERROR_INICIAR_JOGO:
      return {
        ...state,
        jogoAtual: jogoAtual(undefined, action)
      };

    default:
      return state;
  }
};
