// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Usuario/UsuarioActions";

// Components
import InputCelular from "../../Common/Input/InputCelular";
import InputTextPrimary from "../../Common/Input/InputTextPrimary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import Loader from "../../Common/Loader/Loader";
import RecuperarSenha from "../../Modal/RecuperarSenha/RecuperarSenha";
import Cadastro from "../../Modal/Cadastro/Cadastro";

class Login extends Component {
  constructor(props) {
    super(props);

    this.loginPorTelefone = props.loginPorTelefone;

    this.state = {
      usuario: "",
      senha: "",
      formValido: false
    };
  }

  componentWillMount() {
    this.props.initLogin();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.usuarioReducer.logado) {
      this.context.closeModal();
      this.context.toggleLeftSidebar(false);
    }
  }

  openModalRecuperarSenha = (e) => {
    e.preventDefault();
    this.context.openModal(
      "Recuperar Senha",
      <RecuperarSenha />,
      "small"
    );
  }

  openModalCadastro = (e) => {
    e.preventDefault();
    this.context.openModal(
      "Cadastro",
      <Cadastro />,
      "large"
    );
  }

  realizarLogin = (e) => {
    e.preventDefault();
    this.props.fetchLogin(this.state.usuario, this.state.senha);
  }

  renderLoginInput = () => {
    const { aviso } = this.props.usuarioReducer;

    if (this.loginPorTelefone) {
      return (
        <InputCelular
          name="usuario"
          type="primary"
          error={aviso}
          onChange={this._onChange}
        />
      );
    }

    return (
      <InputTextPrimary
        name="usuario"
        placeholder="USUÁRIO"
        className="text-transform-none"
        error={aviso}
        onChange={this._onChange}
        value={this.state.usuario}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );
  }

  renderButtonLogin(isFetching) {
    if (isFetching) {
      return (
        <ButtonSecondary disabled>
          <Loader type="white" />
        </ButtonSecondary>
      );
    }

    return (
      <ButtonSecondary
        disabled={!this.state.formValido}
        setDisabled={!this.state.formValido}
        type="submit">
        Login
      </ButtonSecondary>
    );
  }

  renderEsqueceuSuaSenha = () => {
    if (!this.props.loginPorTelefone) return null;

    return (
      <div className="small-24 columns column-block">
        <a
          className="text-subheader accent-1 cursor-pointer"
          onClick={(e) => this.openModalRecuperarSenha(e)}
        >
          Esqueceu sua senha?
        </a>
      </div>
    );
  }

  renderNaoTemCadastro = () => {
    return (
      <div className="small-12 columns column-block">
        <a
          className="text-subheader accent-1 cursor-pointer"
          onClick={(e) => this.openModalCadastro(e)}
        >
          Não tem cadastro?
        </a>
      </div>
    );
  }

  _onChange = (e) => {
    const state = { ...this.state };

    if (e.target.type === "tel") {
      state[e.target.name] = e.target.value.replace(/\D/g, "");
    }
    else {
      state[e.target.name] = e.target.value;
    }

    // Verifica se todos os campos foram preenchidos
    if (this.loginPorTelefone) {
      state.formValido = (
        state.usuario !== "" &&
        state.usuario.length === 11 &&
        state.senha !== ""
      );
    }
    else {
      state.formValido = (
        state.usuario !== "" &&
        state.senha !== ""
      );
    }

    this.setState(state);
  }

  render() {
    const { aviso, isFetching } = this.props.usuarioReducer;

    return (
      <div className="row">
        <div className="small-24 columns">
          <form
            className="row"
            onSubmit={this.realizarLogin}
          >
            <div className="small-24 columns column-block">
              {this.renderLoginInput()}
            </div>
            <div className="small-24 columns column-block">
              <InputTextPrimary
                name="senha"
                placeholder="SENHA"
                error={aviso}
                className="text-transform-none"
                type="password"
                onChange={this._onChange}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </div>
            {this.renderEsqueceuSuaSenha()}
            {/* {this.renderNaoTemCadastro()} */}
            <div className="small-12 columns">
              {this.renderButtonLogin(isFetching)}
            </div>
            <div className="small-12 columns">
            <ButtonSecondary onClick={this.openModalCadastro}>
            Cadastrar
          </ButtonSecondary>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Login.contextTypes = {
  toggleLeftSidebar: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    loginPorTelefone: state.permissoesReducer.configuracoes.loginPorTelefone,
    usuarioReducer: state.usuarioReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
