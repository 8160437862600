// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { Provider } from "react-redux";

// React Router
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

// Helpers
import { disableHoverOnMobile } from "../../modules/helpers";

// Componentes
import Wrapper from "./Wrapper/Wrapper";
import AppPrincipal from "./App/AppPrincipal";
import AppEsportes from "./App/AppEsportes";
import AppJogos from "./App/AppJogos";
import AppBolao from "./App/AppBolao";
import AppPromocoes from "./App/AppPromocoes";
import Estatisticas from "./App/Estatisticas";
import AppPrinter from "./App/AppPrinter";
import AppLotericos from "./App/AppLotericos";
import AppTradicional from "./App/AppTradicional";
import GTMTagManager from "../Common/GTMTagManager/GTMTagManager";

class Root extends Component {
  componentDidMount() {
    // Desabilita efeitos de hover no mobile
    disableHoverOnMobile();
  }

  getPrincipalComponent = () => {

  }

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <Router>
          <Wrapper>
            <Switch>
              <Route exact path="/estatisticas/:idEvento/:aoVivo" component={Estatisticas} />
              <Route path="/jogos" component={AppJogos} />
              <Route path="/lotericos" component={AppLotericos} />
              <Route path="/tradicional" component={AppTradicional} />
              <Route path="/bolao/:idBolao" component={AppBolao} />
              <Route path="/bolao" component={AppBolao} />
              <Route path="/promocoes" component={AppPromocoes} />
              <Route path="/cadastro/:token" component={AppEsportes} />
              <Route path="/concluirCadastro/:token" component={AppEsportes} />
              <Route path="/impressao" component={AppPrinter} />
              <Route path="/cadastroBanner/:promocao" component={AppEsportes} />
              <Route path="/" component={AppPrincipal} />
            </Switch>
            <GTMTagManager />
          </Wrapper>
        </Router>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
