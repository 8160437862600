// Action Types
import {
  INIT_VALIDAR_USUARIO,
  REQUEST_VALIDAR_USUARIO_SMS,
  RECEIVE_VALIDAR_USUARIO_SMS,
  ERROR_VALIDAR_USUARIO_SMS,
  REQUEST_VALIDAR_USUARIO,
  RECEIVE_VALIDAR_USUARIO,
  ERROR_VALIDAR_USUARIO
} from "../ActionTypes";

// Actions
import { initHelloBar } from "../../actions/HelloBar/HelloBarActions";
import { validarUsuario } from "../../actions/Usuario/UsuarioActions";

// Modules
import { doOdinRequest } from "../../modules/request";

// Helpers
import { formatTelefone } from "../../modules/helpers";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initConfiguracoesCadastro = () => ({
  type: INIT_VALIDAR_USUARIO
});

export const requestConfiguracoesCadastroSms = () => ({
  type: REQUEST_VALIDAR_USUARIO_SMS
});

export const receiveConfiguracoesCadastroSms = () => ({
  type: RECEIVE_VALIDAR_USUARIO_SMS
});

export const errorConfiguracoesCadastroSms = (error) => ({
  type: ERROR_VALIDAR_USUARIO_SMS,
  error
});

export const requestConfiguracoesCadastroValidar = () => ({
  type: REQUEST_VALIDAR_USUARIO
});

export const receiveConfiguracoesCadastroValidar = () => ({
  type: RECEIVE_VALIDAR_USUARIO
});

export const errorConfiguracoesCadastroValidar = (error) => ({
  type: ERROR_VALIDAR_USUARIO,
  error
});

export const fetchConfiguracoesCadastroSms = () => (dispatch, getState) => {
  dispatch(requestConfiguracoesCadastroSms());

  const { usuarioReducer } = getState();
  const { accessToken } = usuarioReducer.usuario;
  const { nome: numero } = usuarioReducer.usuario;
  const telefone = `+55${numero}`;

  const params = {
    url: "/sp/site/validar/usuario/enviar",
    method: "POST",
    accessToken,
    data: { telefone } // Chumbando código +55 temporariamente
  };

  return doOdinRequest(params)
    .then(response => {
      if (response.codResposta !== "000") {
        return Promise.reject(response.mensagem);
      }

      Snackbar(`Código enviado para ${formatTelefone(numero)}.`, "Ok");
      dispatch(receiveConfiguracoesCadastroSms());
    })
    .catch(error => {
      dispatch(errorConfiguracoesCadastroSms(error));
    });
};

export const fetchConfiguracoesCadastroValidar = (token) => (dispatch, getState) => {
  dispatch(requestConfiguracoesCadastroValidar());

  const { usuarioReducer } = getState();
  const { accessToken } = usuarioReducer.usuario;

  const params = {
    url: "/sp/site/validar/usuario",
    method: "POST",
    accessToken,
    data: { token }
  };

  return doOdinRequest(params)
    .then(response => {
      if (response.codResposta !== "000") {
        dispatch(errorConfiguracoesCadastroValidar(response.response.data.message));
        return;
      }

      Snackbar("Cadastro validado com sucesso.", "Ok");
      dispatch(initHelloBar());
      dispatch(validarUsuario());
      dispatch(receiveConfiguracoesCadastroValidar());
    })
    .catch(error => {
      dispatch(errorConfiguracoesCadastroValidar(error));
    });
};
