// React
import React, { Component } from 'react';

class Download extends Component {
  render() {
    const { outline } = this.props;

    if (outline) {
      return (
        <svg viewBox="0 0 24 24">
          <path fill="#FFF" d="M15.4 2H8.6C7.1 2 6 3.2 6 4.7v14.5C6 20.8 7.1 22 8.6 22h6.9c1.4 0 2.6-1.2 2.6-2.7V4.7C18 3.2 16.9 2 15.4 2zm-1.7 18.2h-3.4v-.9h3.4v.9zm2.8-2.7h-9V4.7h9v12.8z"/>
          <path fill="#FFF" d="M14.6 10.2h-1.5V7.9h-2.2v2.3H9.4l2.6 2.6zM9.4 13.5h5.1v.8H9.4z"/>
          <path fill="none" d="M0 0h24v24H0z"/>
        </svg>
      );
    }

    return (
      <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="download" fill="#FFFFFF">
            <g id="Rectangle">
              <path d="M4,0 L28,0 C30.209139,-4.05812251e-16 32,1.790861 32,4 L32,28 C32,30.209139 30.209139,32 28,32 L4,32 C1.790861,32 2.705415e-16,30.209139 0,28 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z M20,5 L12,5 C10.34,5 9,6.34 9,8 L9,24 C9,25.66 10.34,27 12,27 L20,27 C21.66,27 23,25.66 23,24 L23,8 C23,6.34 21.66,5 20,5 Z M18,25 L14,25 L14,24 L18,24 L18,25 Z M21.25,22 L10.75,22 L10.75,8 L21.25,8 L21.25,22 Z M19,14 L17.2716939,14 L17.2716939,11.5 L14.7350735,11.5 L14.7350735,14 L13,14 L16.0033837,16.9166667 L19,14 Z M13,17.6700001 L13,18.5033334 L19,18.5033334 L19,17.6700001 L13,17.6700001 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default Download;
