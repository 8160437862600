// React
import React, {Component} from "react";

// Components
import ButtonOdd from "../../../Common/Button/ButtonOdd"

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as actions from "../../../../actions/Carrinho/CarrinhoActions";

class Subeventos extends Component {

  acaoCarrinho = (subevento) => {
    subevento.titulo = subevento.nomeSubevento;

    if (typeof subevento.nome === "undefined") {
      subevento.nome = subevento.label;
    }

    if (typeof subevento.aposta === "undefined") {
      subevento.aposta = subevento.nome;
    }

    if (typeof subevento.idCampeonato === "undefined") {
      subevento.idCampeonato = this.props.idCampeonato;
    }

    if (subevento.active) {
      this.props.removerItemCarrinhoComSubEvento(subevento);
    }
    else {
      const eventoAoVivo = this.props.eventoAoVivo || false;
      subevento.outright = this.props.outright;
      this.props.adicionarItemCarrinhoComSubEvento(subevento, eventoAoVivo);
    }
  }

  render () {
    const {subevento,outright} = this.props;

    let className = "subevento-cotacao--buttonsodds small-8 medium-6 large-4 columns column-block";

    if(outright){
      className = "subevento-cotacao--buttonsodds small-24 medium-24 large-12 columns column-block"
    }

    if (!subevento.cotacao) return null;

    return (
      <div className={className}>
        <ButtonOdd
          label={subevento.label}
          cotacao={subevento.cotacao}
          onClick={() => this.acaoCarrinho(subevento)}
          active={subevento.active}
        />
      </div>
    )
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {}
};

// Apenas Actions do Carrinho
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subeventos);
