import {
  ADICIONAR_ETICKET,
  ADICIONAR_ETICKET_BOLAO,
  REMOVER_ETICKET
} from "../ActionTypes";

export const adicionarEticket = ({ momento, codigo, duracao, valorTotal, possivelRetorno, eventos }) => ({
  type: ADICIONAR_ETICKET,
  momento,
  codigo,
  duracao,
  valorTotal,
  possivelRetorno,
  eventos
});

export const adicionarEticketBolao = ({ momento, codigo, duracao, qtdCotas, valorTotal, eventos }) => ({
  type: ADICIONAR_ETICKET_BOLAO,
  momento,
  codigo,
  duracao,
  qtdCotas,
  valorTotal,
  eventos
});

export const removerEticket = (eticket) => ({
  type: REMOVER_ETICKET,
  eticket
});
