import {
  INIT_LOGIN,
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  ERROR_LOGIN,
  LOGOUT,
  ATUALIZAR_SALDO,
  VALIDAR_USUARIO
} from "../../actions/ActionTypes.js";

import usuario from "./UsuarioInfoReducer";

const initialState = {
  isFetching: false,
  aviso: "",
  logado: false,
  usuario: usuario(undefined, {})
};

export default function usuarioReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_LOGIN:
      return initialState;

    case REQUEST_LOGIN:
      return {
        ...state,
        aviso: "",
        isFetching: true
      };

    case RECEIVE_LOGIN:
      return {
        logado: true,
        isFetching: false,
        aviso: "",
        usuario: usuario(state.usuario, action)
      };

    case ERROR_LOGIN:
      return {
        ...state,
        aviso: action.error,
        isFetching: false
      };

    case LOGOUT:
      return initialState;

    case ATUALIZAR_SALDO:
      return {
        ...state,
        usuario: usuario(state.usuario, action)
      };

    case VALIDAR_USUARIO:
      return {
        ...state,
        usuario: usuario(state.usuario, action)
      };

    default:
      return state;
  }
};
