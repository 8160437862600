// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Usuario/RecuperarSenhaActions";

// Components
import InputCelular from "../../Common/Input/InputCelular";
import InputSMS from "../../Common/Input/InputSMS";
import InputTextPrimary from "../../Common/Input/InputTextPrimary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import Loader from "../../Common/Loader/Loader";
import Aviso from "../../Common/Aviso/Aviso";

class RecuperarSenha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      telefone: "",
      codigo: "",
      novaSenha: "",
      confirmarSenha: "",
      formValido: false
    };
  }

  componentWillMount() {
    this.props.initRecuperarSenha();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.recuperarSenhaReducer.sucesso) {
      this.context.closeModal();
    }
  }

  renderFormInputs = () => {
    const { isFetching, codigoEnviado } = this.props.recuperarSenhaReducer;

    if (codigoEnviado) {
      return (
        <div className="small-24">
          <div className="small-24 columns column-block">
            <InputCelular
              type="primary"
              name="telefone"
              value={this.state.telefone}
              placeholder="Telefone"
              onChange={this._onChange}
            />
          </div>
          <div className="small-24 columns column-block">
            <InputSMS
              type="primary"
              name="codigo"
              disabled={isFetching}
              value={this.state.codigo}
              onChange={this._onChange}
              onClickReenviar={this.reenviarCodigo}
            />
          </div>
          <div className="small-24 columns column-block">
            <InputTextPrimary
              type="password"
              name="novaSenha"
              value={this.state.novaSenha}
              onChange={this._onChange}
              placeholder="Nova Senha"
            />
          </div>
          <div className="small-24 columns column-block">
            <InputTextPrimary
              type="password"
              name="confirmarSenha"
              value={this.state.confirmarSenha}
              onChange={this._onChange}
              placeholder="Confirme a Nova Senha"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="small-24 columns column-block">
        <InputCelular
          type="primary"
          name="telefone"
          value={this.state.telefone}
          placeholder="Telefone"
          onChange={this._onChange}
        />
      </div>
    );
  }

  renderFormSubmit = () => {
    const { isFetching, codigoEnviado } = this.props.recuperarSenhaReducer;

    if (isFetching) {
      return (
        <div className="small-24 columns">
          <ButtonSecondary disabled>
            <Loader type="white" />
          </ButtonSecondary>
        </div>
      );
    }

    if (codigoEnviado) {
      return (
        <div className="small-24 columns">
          <ButtonSecondary
            disabled={!this.state.formValido}
            setDisabled={!this.state.formValido}
          >
            Alterar Senha
          </ButtonSecondary>
        </div>
      );
    }

    return (
      <div className="small-24 columns">
        <ButtonSecondary
          disabled={this.state.telefone.length !== 11}
          setDisabled={this.state.telefone.length !== 11}
        >
          Enviar Código
        </ButtonSecondary>
      </div>
    );
  }

  renderAviso = () => {
    const { aviso } = this.props.recuperarSenhaReducer;

    if (aviso) {
      return (
        <div className="small-24 columns">
          <Aviso className="edge-top" type="error">
            { aviso }
          </Aviso>
        </div>
      );
    }

    return null;
  }

  reenviarCodigo = () => {
    if (this.state.telefone.length < 11) return;
    this.props.fetchEnviarCodigoSms(this.state.telefone);
  }

  _onSubmit = (event) => {
    event.preventDefault();

    const { codigoEnviado } = this.props.recuperarSenhaReducer;

    if (codigoEnviado) {
      return this.props.fetchRecuperarSenha({
        login: this.state.telefone,
        token: this.state.codigo,
        novaSenha: this.state.novaSenha,
        confirmarSenha: this.state.confirmarSenha
      });
    }

    return this.props.fetchEnviarCodigoSms(this.state.telefone);
  }

  _onChange = (e) => {
    const state = { ...this.state };

    if (e.target.name === "telefone") {
      state["telefone"] = e.target.value.replace(/\D/g, "");
    }
    else if (e.target.name === "codigo") {
      state["codigo"] = e.target.value.replace(/_/g, "");
    }
    else {
      state[e.target.name] = e.target.value;
    }

    // Verifica se todos os campos foram preenchidos
    state.formValido = (
      state.telefone !== "" &&
      state.telefone.length === 11 &&
      state.codigo !== "" &&
      state.codigo.length === 4 &&
      state.novaSenha !== "" &&
      state.confirmarSenha !== ""
    );

    this.setState(state);
  }

  render() {
    return (
      <form className="row" onSubmit={this._onSubmit}>
        { this.renderFormInputs() }
        { this.renderFormSubmit() }
        { this.renderAviso() }
      </form>
    );
  }
}

RecuperarSenha.contextTypes = {
  closeModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { recuperarSenhaReducer: state.recuperarSenhaReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecuperarSenha);
