// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Cadastro/CadastroPinActions";

// Styles
import "./Cadastro.css";

// Components
import InputCelular from "../../Common/Input/InputCelular";
import InputTextPrimary from "../../Common/Input/InputTextPrimary";
import ButtonSecondary from "../../Common/Button/ButtonSecondary";
import Aviso from "../../Common/Aviso/Aviso";
import Loader from "../../Common/Loader/Loader";
import InputPin from "../../Common/Input/InputPin";
import banner from "../../../static/img/banner-cadastro-texto.png";

class Cadastro extends Component {
  constructor(props) {
    super(props);
    this.cadastroComTelefone = props.loginPorTelefone;

    if (this.cadastroComTelefone) {
      this.state = {
        pin: "",
        telefone: "",
        confirmarTelefone: "",
        senha: "",
        confirmarSenha: "",
        cadastroValido: false
      };
    }
    else {
      this.state = {
        pin: "",
        login: "",
        senha: "",
        confirmarSenha: "",
        cadastroValido: false
      };
    }

  }

  componentWillMount() {
    this.props.initCadastro();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.cadastroPinReducer.sucesso) {
      this.context.closeModal();
      this.context.toggleLeftSidebar(false);
    }
  }

  realizarCadastro = (e) => {
    e.preventDefault();

    if (this.cadastroComTelefone) {
      this.props.fetchCadastro({
        pin: this.state.pin.replace(/\s/g, ""),
        telefone: this.state.telefone,
        confirmarTelefone: this.state.confirmarTelefone,
        senha: this.state.senha,
        confirmarSenha: this.state.confirmarSenha
      });
    }
    else {
      this.props.fetchCadastro({
        pin: this.state.pin.replace(/\s/g, ""),
        login: this.state.login,
        senha: this.state.senha,
        confirmarSenha: this.state.confirmarSenha
      });
    }
  }

  renderButtonCadastrar(isFetching) {
    if (isFetching) {
      return (
        <ButtonSecondary disabled>
          <Loader type="white" />
        </ButtonSecondary>
      );
    }

    return (
      <ButtonSecondary
        disabled={!this.state.cadastroValido}
        setDisabled={!this.state.cadastroValido}
        className="disabled"
        type="submit">
        Cadastrar
      </ButtonSecondary>
    );
  }

  renderAviso(aviso) {
    if (aviso) {
      return (
        <div className="small-24 columns">
          <Aviso className="edge-top" type="error">
            { aviso }
          </Aviso>
        </div>
      );
    }

    return null;
  }

  _onChange = (e) => {
    const state = {...this.state};

    if (e.target.type === "tel") {
      state[e.target.name] = e.target.value.replace(/\D/g, "");
    }
    else {
      state[e.target.name] = e.target.value;
    }

    // Verifica se todos os campos foram preenchidos
    if (this.cadastroComTelefone) {
      state.cadastroValido = (
        state.pin.indexOf("_") === -1 &&
        state.telefone !== "" &&
        state.telefone.length === 11 &&
        state.confirmarTelefone !== "" &&
        state.confirmarTelefone.length === 11 &&
        state.senha !== "" &&
        state.confirmarSenha !== ""
      );
    }
    else {
      state.cadastroValido = (
        state.pin.indexOf("_") === -1 &&
        state.login !== "" &&
        state.senha !== "" &&
        state.confirmarSenha !== ""
      );
    }


    this.setState(state);
  }

  renderLoginInput = () => {
    if (this.cadastroComTelefone) {
      return (
        <div className="small-24">
          <div className="small-24 columns column-block">
            <InputCelular
              name="telefone"
              type="primary"
              placeholder="Celular (00) 00000-0000"
              onChange={this._onChange}
            />
          </div>
          <div className="small-24 columns column-block">
            <InputCelular
              name="confirmarTelefone"
              type="primary"
              placeholder="Confirmar Cel. (00) 00000-0000"
              onChange={this._onChange}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="small-24 columns column-block">
        <InputTextPrimary
          name="login"
          placeholder="USUÁRIO"
          className="text-transform-none"
          onChange={this._onChange}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      </div>
    );
  }

  render() {
    const { aviso, isFetching } = this.props.cadastroPinReducer;

    return (
      <div className="row">
        <div className="large-15 columns show-for-large">
          <div className="cadastro-banner">
            <img src={banner} className="cadastro-banner__titulo" alt="Cadastre-se agora, é rápido e fácil!"/>
          </div>
        </div>
        <div className="small-24 large-9 columns">
          <form
            className="row"
            onSubmit={this.realizarCadastro}
          >
            <div className="small-24 columns column-block">
              <InputPin
                type="primary"
                name="pin"
                onChange={this._onChange}
              />
            </div>
            { this.renderLoginInput() }
            <div className="small-24 columns column-block">
              <InputTextPrimary
                placeholder="SENHA"
                name="senha"
                className="text-transform-none"
                type="password"
                onChange={this._onChange}
              />
            </div>
            <div className="small-24 columns column-block">
              <InputTextPrimary
                placeholder="CONFIRMAR SENHA"
                name="confirmarSenha"
                className="text-transform-none"
                type="password"
                onChange={this._onChange}
              />
            </div>
            <div className="small-24 columns">
              { this.renderButtonCadastrar(isFetching) }
            </div>
          </form>
        </div>
        { this.renderAviso(aviso) }
      </div>
    );
  }
}

Cadastro.contextTypes = {
  toggleLeftSidebar: PropTypes.func,
  closeModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    loginPorTelefone: state.permissoesReducer.configuracoes.loginPorTelefone,
    cadastroPinReducer: state.cadastroPinReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cadastro);
