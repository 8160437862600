// React
import React from "react";

// Styles
import "./Button.css";

// Components
import Button from "./Button";

export default ({ children, ...props }) => (
  <Button {...props} className="button--rounded text-title light-active">
    { children }
  </Button>
);
