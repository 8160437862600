// React
import React from "react";

// Styles
import "./Input.css";

// Components
import Input from "./Input";

export default ({ className, ...props }) => {
  let classes = "input-text--primary text-title dark-default";
  classes += className ? ` ${className}` : "";

  return (
    <Input
      className={classes}
      {...props}
    />
  )
}
