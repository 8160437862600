// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// Libs
import { CSSTransitionGroup } from "react-transition-group";

// Styles
import "./Link.css";

const NavLink = ({ type, text, permissao, to }) => {
  if (type === "small") {
    return (
      <CSSTransitionGroup
        className={`${permissao ? "nav-divider" : ""}`}
        transitionName="fetching-permissoes"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {
          permissao &&
          <Link to={to} className={`nav-link nav-link--${type || ""} text-title dark-default`}>
            {text}
          </Link>
        }
      </CSSTransitionGroup>
    );
  }

  return (
    <CSSTransitionGroup
      className={`${permissao ? "xlarge-4 columns nav-divider" : ""}`}
      transitionName="fetching-permissoes"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      {
        permissao &&
        <Link to={to} className={`nav-link nav-link--${type || ""} text-title dark-default`}>
          {text}
        </Link>
      }
    </CSSTransitionGroup>
  );
};

export default NavLink;
