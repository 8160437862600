import {
  RECEIVE_LOGIN,
  ATUALIZAR_SALDO,
  VALIDAR_USUARIO
} from "../../actions/ActionTypes.js";

const initialState = {
  nome: "",
  empresa: "",
  validado: false,
  accessToken: "",
  configuracao: {}
};

export default function usuario(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LOGIN:
      return {
        ...action.usuario,
        validado: action.usuario.Validado
      };

    case ATUALIZAR_SALDO:
      return {
        ...state,
        configuracao: {
          ...state.configuracao,
          saldo: action.saldo
        }
      };

    case VALIDAR_USUARIO:
      return {
        ...state,
        validado: true
      };

    default:
      return state;
  }
};
