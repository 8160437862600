import {
  REQUEST_CONCLUIR_CADASTRO_FORMULARIO,
  RECEIVE_CONCLUIR_CADASTRO_FORMULARIO,
  ERROR_CONCLUIR_CADASTRO_FORMULARIO,
} from "../../../actions/ActionTypes";

const initialState = {
  isFetching: false,
  sucesso: false,
  aviso: ""
};

export default function formulario(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CONCLUIR_CADASTRO_FORMULARIO:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_CONCLUIR_CADASTRO_FORMULARIO:
      return {
        ...initialState,
        sucesso: true
      };

    case ERROR_CONCLUIR_CADASTRO_FORMULARIO:
      return {
        ...initialState,
        aviso: action.error
      };

    default:
      return state;
  }
};
