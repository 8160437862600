// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Bolao/BolaoCarrinhoActions";

// React Router
import { withRouter } from "react-router-dom";

// Components
import Aviso from "../../Common/Aviso/Aviso";
import BolaoCarrinhoCard from "./BolaoCarrinhoCard";
import BolaoCarrinhoFooter from "./BolaoCarrinhoFooter";

class BolaoCarrinho extends Component {
  componentWillMount() {
    this.props.initCarrinho();
  }

  renderCarrinho() {
    return <h1>:D</h1>;
  }

  // Mesma lógica do Carrinho normal
  renderAviso = (aviso) => {
    if (aviso.type !== "success") {
      return (
        <Aviso type="default">
          { aviso.text }
        </Aviso>
      );
    }

    return null;
  }

  successOrError = (aviso, bolaoPreenchido) => {
    if (aviso.type === "error" && bolaoPreenchido) {
      return (
        <Aviso className="edge-top" type={aviso.type}>
          { aviso.text }
        </Aviso>
      );
    }

    if (aviso.type === "success" && !bolaoPreenchido) {
      return (
        <Aviso className="edge-top" type={aviso.type}>
          { aviso.text }
        </Aviso>
      );
    }

    return null;
  }

  render() {
    const { bolaoPreenchido, aviso } = this.props.bolaoCarrinhoReducer;

    return (
      <Container>
        <h1 className="text-display edge-bottom light-default">Bolão</h1>
        {
          bolaoPreenchido ? <BolaoCarrinhoCard /> : this.renderAviso(aviso)
        }
        <BolaoCarrinhoFooter />
        { this.successOrError(aviso, bolaoPreenchido) }
      </Container>
    );
  }
}

const Container = ({ children }) => (
  <div className="carrinho">
    <div className="inner-row-wrapper">
      <div className="row">
        <div className="small-24 columns">
          { children }
        </div>
      </div>
    </div>
  </div>
);

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    bolaoCarrinhoReducer: state.bolaoCarrinhoReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BolaoCarrinho)
);
