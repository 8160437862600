// Action Types
import {
  INIT_PERMISSOES,
  REQUEST_PERMISSOES,
  RECEIVE_PERMISSOES,
  ERROR_PERMISSOES
} from "../ActionTypes";

// Modules
import { doRequest } from "../../modules/request";
import addHeadInfo from "../../modules/head";

// Lista dos jogos
export const initPermissoes = () => ({
  type: INIT_PERMISSOES
});

export const requestPermissoes = () => ({
  type: REQUEST_PERMISSOES
});

export const receivePermissoes = (permissoes) => ({
  type: RECEIVE_PERMISSOES,
  permissoes
});

export const errorPermissoes = (error) => ({
  type: ERROR_PERMISSOES,
  error
});

export const fetchPermissoes = () => (dispatch, getState) => {
  dispatch(requestPermissoes());

  const params = {
    url: "/permissao",
    method: "GET"
  };

  doRequest(params).then(response => {
    // Adiciona links dos ícones, manifest.json e <title> no <head>
    const headInfo = {
      nomeFantasia: response.nomeFantasia,
      pathAssets: response.pathAssets
    };

    addHeadInfo({ ...headInfo });

    dispatch(receivePermissoes(response));
  }).catch(error => {
    dispatch(errorPermissoes(error));
  });
};
