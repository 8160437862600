import {
  INIT_EVENTOS_POR_CAMPEONATO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  RECEIVE_EVENTOS_CAMPEONATO,
  RECEIVE_EVENTOS_AOVIVO,
  INIT_EVENTOS_AOVIVO,
  RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO,
  RECEIVE_MODALIDADES_EVENTOS_AOVIVO
} from "../../actions/ActionTypes.js";

export default function subeventos(state = [], action) {
  switch (action.type) {
    case RECEIVE_EVENTOS_AOVIVO:
    case INIT_EVENTOS_AOVIVO:
    case INIT_EVENTOS_POR_CAMPEONATO:
    case RECEIVE_EVENTOS_CAMPEONATO:
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO:
    case RECEIVE_MODALIDADES_EVENTOS_AOVIVO:
    case LIMPAR_CARRINHO:
      return state.map(item =>
        subevento(item, action)
      );

    default:
      return state;
  }
};

const initialState = {
  id: null,
  nome: "",
  idOpcao: null,
  aposta: "",
  label: "",
  cotacao: null,
  titulo: "",
  active: false
};

function subevento(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_EVENTOS_AOVIVO:
    case INIT_EVENTOS_AOVIVO:
    case INIT_EVENTOS_POR_CAMPEONATO:
    case RECEIVE_EVENTOS_CAMPEONATO:
    case RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO:
    case RECEIVE_MODALIDADES_EVENTOS_AOVIVO:
    {
      const subeventoNoCarrinho = action.carrinho.itens.some(item =>
        state.id === item.subevento.id &&
        state.idOpcao === item.subevento.idOpcao
      );



      if (subeventoNoCarrinho) {
        return {
          ...state,
          active: true
        };
      }

      return state;
    }

    case LIMPAR_CARRINHO:
    {
      const subeventoNoCarrinho = action.carrinho.itens.some(item =>
        state.id === item.subevento.id &&
        state.idOpcao === item.subevento.idOpcao
      );

      if (subeventoNoCarrinho) {
        return {
          ...state,
          active: false
        };
      }

      return state;
    }

    case ADICIONAR_ITEM_CARRINHO:
      if (state.id === action.subevento.id && state.idOpcao === action.subevento.idOpcao) {
        return {
          ...state,
          active: true
        };
      }

      return {
        ...state,
        active: false
      };

    case REMOVER_ITEM_CARRINHO:
      if (state.id === action.subevento.id && state.idOpcao === action.subevento.idOpcao) {
        return {
          ...state,
          active: false
        };
      }

      return state;

    default:
      return state;
  }
};
