import {
  INIT_HELLO_BAR,
  SHOW_HELLO_BAR,
  HIDE_HELLO_BAR
} from "../../actions/ActionTypes.js";

const initialState = {
  active: false,
  texto: "",
  acao: ""
};

export default function helloBarReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_HELLO_BAR:
      return initialState;

    case SHOW_HELLO_BAR:
      return {
        active: true,
        texto: action.texto,
        acao: action.acao
      };

    case HIDE_HELLO_BAR:
      return {
        ...state,
        active: false,
      };

    default:
      return state;
  }
};
