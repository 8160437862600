import {
  INIT_MODALIDADES_EVENTOS_AOVIVO,
  REQUEST_MODALIDADES_EVENTO_AOVIVO,
  RECEIVE_MODALIDADES_EVENTOS_AOVIVO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  ERROR_AVISO_MODALIDADES_EVENTO_AOVIVO
} from "../ActionTypes";

import { doRequest } from "../../modules/request";

export const initModalidadesEventosAoVivo = () => ({
  type: INIT_MODALIDADES_EVENTOS_AOVIVO,
});

export const requestModalidadesEventosAoVivo = (modalidades) => {
  return {
    type: REQUEST_MODALIDADES_EVENTO_AOVIVO
  };
};

export const receiveModalidadesEventosAoVivo = (evento,carrinho) => {
  return {
    type: RECEIVE_MODALIDADES_EVENTOS_AOVIVO,
    evento,
    carrinho
  };
};

export const errorModalidadeEventoAoVivo = (text) => ({
  type: ERROR_AVISO_MODALIDADES_EVENTO_AOVIVO,
  text
});

export const fetchModalidadesEventosAoVivo = (idEvento,idCampeonato) => (dispatch, getState) => {
  //dispatch(requestModalidadesEventosAoVivo());

  const params = {
    url: "principal/aovivo/subevento",
    method: "GET",
    params : {idEvento}
  };

  doRequest(params).then(response => {
    if(typeof response.errors !== 'undefined' && response.errors != null) {
      return Promise.reject(response.message);
    }else{
      if(typeof response.idCampeonato === "undefined") {
        response.idCampeonato = parseInt(idCampeonato,10)
      }

      if (!response.modalidades) {
        response.modalidades = [];
      }

      dispatch(receiveModalidadesEventosAoVivo(response,getState().carrinho));
    }

  }).catch(error => {
    dispatch(errorModalidadeEventoAoVivo(error));
  });

};

export const adicionarItemCarrinho = (subevento) => {
  return {type: ADICIONAR_ITEM_CARRINHO,
    subevento
  }
}

export const removerItemCarrinho = (subevento) => {
  return {type: REMOVER_ITEM_CARRINHO,
    subevento
  }
}
