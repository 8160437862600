import {
  RECEIVE_EVENTOS,
  // ERROR_EVENTOS
} from "../../../actions/ActionTypes.js";

export default function eventos(state = [], action) {
  switch (action.type) {
    case RECEIVE_EVENTOS:
      return state.map(itemEvento => evento(itemEvento, action));

    default:
      return state;
  }
};

// Deixei o state igual a resposta que vem do Odin.
// Caso mude futuramente no Gateway, tem que reajustar.
const initialState = {
  cotacao: 0,
  momentoEvento: "",
  resultadoFormatado: "",
  tituloEvento: "",
  tituloSubevento: "",
  valorPago: 0,
  retornoPossivel: 0
};

function evento(state = initialState, action) {
  switch (action) {
    case RECEIVE_EVENTOS:
    default:
      return state;
  }
};
