import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { injectIntl, defineMessages } from 'react-intl';

import Loading from '../Loader/LoaderCarregando';

import classnames from 'classnames';
import { fetchURLPrivateStream, initPrivateStream } from '../../../reducers/privateStream/privateStream';
import ReactPlayer from 'react-player';

import './StreamPlayer.css';

class StreamPlayer extends Component {
  state = {
    channelSelected: null,
    showPip: false,
    infoExtraEvento: {}
  };

  componentDidMount() {
    const { initPrivateStream, idEvento, fetchURLPrivateStream, doisFatores } = this.props;
    initPrivateStream();
    ((this.props.usuarioLogado && this.props.usuarioTemSaldo) || !doisFatores) &&
      (this.timeout = setTimeout(() => {
        fetchURLPrivateStream(idEvento);
        //E depois a cada 10 seundos.
        this.intervalStream = setInterval(() => {
          fetchURLPrivateStream(idEvento);
        }, 10000);
      }, 5000));

    window.addEventListener('scroll', this.checkPip.bind(this));
    window.addEventListener('resize', this.checkPip.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    const { infoExtraEvento, errorCountStreamUrl, idEvento, usuarioLogado, usuarioTemSaldo } = this.props;
    if (!this.state.channelSelected && Object.keys(infoExtraEvento.tvChannel || {}).length > 0) {
      this.setChannel(Object.keys(infoExtraEvento.tvChannel)[0]);
    }
    if (prevProps.errorCountStreamUrl < 5 && errorCountStreamUrl >= 5) {
      clearInterval(this.intervalStream);
    }
    if (
      !prevProps.usuarioLogado &&
      usuarioLogado &&
      !this.intervalStream &&
      !this.timeout &&
      usuarioTemSaldo // se o usuário logou, inicia stream
    ) {
      this.timeout = setTimeout(() => {
        fetchURLPrivateStream(idEvento);
        //E depois a cada 10 seundos.
        this.intervalStream = setInterval(() => {
          fetchURLPrivateStream(idEvento);
        }, 10000);
      }, 5000);
    }
  }

  componentWillUnmount() {
    initPrivateStream();
    clearInterval(this.intervalStream);
    clearTimeout(this.timeout);
    window.removeEventListener('scroll', this.checkPip);
    window.removeEventListener('resize', this.checkPip);
  }

  setChannel(channel) {
    if (channel === this.state.channelSelected) {
      return;
    }
    this.setState({ channelSelected: channel });
  }

  getStreamUrl(channel) {
    const url = this.props.infoExtraEvento.tvChannel[channel] || null;
    return url;
  }

  getUrlStream(channel) {
    let url = this.props.infoExtraEvento.tvChannel[channel] || null;

    switch (channel) {
      case 'YouTube':
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return `https://www.youtube.com/embed/${
          match && match[7].length === 11 ? match[7] : ''
        }?controls=1&autoplay=1;mute=1&playsinline=1`;

      case 'Twitch':
        let result = url
          .split('/')
          .pop()
          .split('?')
          .shift();
        return `https://player.twitch.tv/?channel=${result ? result : url}`;

      default:
        return url;
    }
  }

  checkPip() {
    let el = document.querySelector('.stream');
    if (!el) {
      return;
    }
    const posObj = el.getBoundingClientRect();
    var showPip = posObj.top < posObj.height * -1;
    if (showPip === this.state.showPip) {
      return;
    }
    this.setState({ showPip });
  }

  renderAviso = () => {
    return (
      <div className="aviso-stream">
        {!this.props.usuarioLogado && (
          <div>
            Para assistir a esta transmissão, você precisa fazer login ou se cadastrar
          </div>
        )}
        {this.props.usuarioLogado &&
          !this.props.usuarioTemSaldo && (
            <div>
              Para assistir a esta transmissão, você precisa adicionar saldo
            </div>
          )}
      </div>
    );
  };

  render() {
    const {
      idEvento,
      privateStreamURL,
      isMobile,
      errorCountStreamUrl,
      usuarioLogado,
      usuarioTemSaldo,
      doisFatores,
      isFetchingStreamUrl
    } = this.props;
    // const { formatMessage } = this.props.intl;
    let infoExtraEvento = this.props.infoExtraEvento;
    const { channelSelected, showPip } = this.state;
    if (privateStreamURL) {
      infoExtraEvento = infoExtraEvento || {};
      infoExtraEvento.tvChannel = infoExtraEvento.tvChannel || {};
      if (infoExtraEvento.tvChannel.Padrao === undefined) {
        infoExtraEvento.tvChannel.Padrao = privateStreamURL;
      }
    }
    const isLoading =
      (Object.keys(infoExtraEvento.tvChannel || {}).length === 0 && errorCountStreamUrl < 5) ||
      isFetchingStreamUrl;
    const usuarioNaoLogadoOuSemSaldo = !usuarioLogado || (usuarioLogado && !usuarioTemSaldo);

    return (
      <div className={classnames('stream', { loading: isLoading }, { mobile: isMobile })}>
        <div
          className={classnames('stream__tabs', {
            showPip: showPip
          })}
        >
          {usuarioNaoLogadoOuSemSaldo && this.renderAviso()}
          {isLoading &&
            !usuarioNaoLogadoOuSemSaldo && (
              <div className="loading__screen">
                <Loading />
                <span>Procurando Stream</span>
              </div>
            )}

          {!!infoExtraEvento.tvChannel &&
            !usuarioNaoLogadoOuSemSaldo &&
            !isLoading && (
              <div className="stream__tab stream__tab--live">
                {Object.keys(infoExtraEvento.tvChannel).length > 1 && (
                  <div className="stream__tab__channels">
                    {Object.keys(infoExtraEvento.tvChannel)
                      .sort()
                      .map((channel, index) => (
                        <button
                          key={`button-channel-${index}`}
                          type="button"
                          className={`stream__tab__channel ${channelSelected === channel ? ' active' : ''}`}
                          onClick={e => this.setChannel(channel)}
                        >
                          {channel}
                        </button>
                      ))}
                  </div>
                )}
                {channelSelected && (
                  <div
                    className={classnames('stream__tab__video', { stream__private__video: privateStreamURL })}
                  >
                    <ReactPlayer
                      className="react-player"
                      height="auto"
                      width="100%"
                      url={this.getStreamUrl(channelSelected)}
                      controls={true}
                      pip={true}
                      onError={e => {
                        //console.log('Teve um err no player: ', e);
                      }}
                      config={{
                        youtube: {
                          playerVars: {
                            controls: 0,
                            autoplay: 0,
                            mute: 1
                          }
                        },
                        twitch: {
                          options: {
                            autoplay: !isMobile,
                            muted: true,
                            controls: false
                          },
                          playerId: `${idEvento}-twitch-stream`
                        },
                        file: {
                          forceVideo: true,
                          forceHLS: doisFatores,
                          hlsOptions: {
                            startPosition: 3,
                            debug: false,
                            liveDurationInfinity: true
                          },
                          attributes: {
                            autoPlay: !isMobile,
                            playsInline: true,
                            muted: true,
                            preload: 'auto',
                            src: this.getStreamUrl(channelSelected)
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

// Redux
const mapStateToProps = state => {
  return {
    matchID: state.modalidadesEventosAoVivoReducer.matchID,
    infoExtraEvento: state.modalidadesEventosAoVivoReducer.infoExtraEvento,
    privateStreamURL: state.privateStream.streamURL,
    doisFatores: state.privateStream.doisFatores,
    isFetchingStreamUrl: state.privateStream.isFetching,
    errorCountStreamUrl: state.privateStream.erroCount
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchURLPrivateStream,
      initPrivateStream
    },
    dispatch
  );
};

export default 
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StreamPlayer)
;

// Intl Messages