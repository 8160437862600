import {
  INIT_JOGO_ATUAL,
  REQUEST_INICIAR_JOGO,
  RECEIVE_INICIAR_JOGO,
  RECEIVE_INICIAR_JOGO_MOBILE,
  ERROR_INICIAR_JOGO
} from "../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: null,
  id: "",
  nome: "",
  urlImagem: "",
  valorMinimo: 0,
  valorMaximo: 0,
  urlLaunch: "",
  token: "",
  target: {desktop: "", mobile: ""}
};

export default function jogoAtual(state = initialState, action) {
  switch (action.type) {
    case INIT_JOGO_ATUAL:
      return initialState;

    case REQUEST_INICIAR_JOGO:
      return {
        ...initialState,
        isFetching: true
      };

    case RECEIVE_INICIAR_JOGO:
      return {
        id: action.jogo.id,
        nome: action.jogo.nome,
        urlImagem: action.jogo.urlImagem,
        valorMinimo: action.jogo.valorMinimo,
        valorMaximo: action.jogo.valorMaximo,
        urlLaunch: action.urlLaunch,
        token: action.token,
        target: action.target,
        isFetching: false,
        aviso: null
      };

    case RECEIVE_INICIAR_JOGO_MOBILE:
      return {
        id: action.jogo.id,
        nome: action.jogo.nome,
        urlImagem: action.jogo.urlImagem,
        valorMinimo: action.jogo.valorMinimo,
        valorMaximo: action.jogo.valorMaximo,
        urlLaunch: action.urlLaunch,
        token: action.token,
        target: action.target,
        isFetching: true,
        aviso: null
      };

    case ERROR_INICIAR_JOGO:
      return {
        ...initialState,
        isFetching: false,
        aviso: action.error
      };

    default:
      return state;
  }
};
