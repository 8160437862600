// React
import React, { Component } from "react";

// Libs
import moment from "moment";

// Helpers
import { formatOdinDateToDateJs, convertIntegerToMoney } from "../../../../modules/helpers";

// Components
import Card from "../../../Common/Card/Card";

const Header = ({ momentoSolicitacao }) => (
  <div className="row align-middle">
    <div className="small-24 columns">
      <h3 className="text-title padding-7 light-default">
        {momentoSolicitacao}
      </h3>
    </div>
  </div>
);

const InfoGeral = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-title light-secondary">{label}</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-title light-default">{valor}</p>
    </div>
  </div>
);

export default class InfoSaque extends Component {
  render() {
    const { saque } = this.props;
    const codigoAutorizacao = saque.codigoAutorizacao || "Não informado";
    const momentoSolicitacao = moment(formatOdinDateToDateJs(saque.momentoSolicitacao)).format("DD/MM/YYYY [-] HH:mm:ss");
    const valorSaque = convertIntegerToMoney(saque.valorSaque);
    const status = saque.nomeStatusSolicitacao;
    const header = <Header momentoSolicitacao={momentoSolicitacao} />;

    return (
      <Card className="edge-bottom-list card-block" header={header}>
        <InfoGeral label="Código de Autorização" valor={codigoAutorizacao} />
        <InfoGeral label="Valor do Saque" valor={valorSaque} />
        <InfoGeral label="Status" valor={status} />
      </Card>
    );
  }
}
