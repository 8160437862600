// React
import React, { Component } from "react";

// Styles
import "../../ShareConfiguracoes.css";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/ValidarUsuarioActions";

// Components
import InputSMS from "../../../Common/Input/InputSMS";
import Card from "../../../Common/Card/Card";
import InputCelular from "../../../Common/Input/InputCelular";
import ButtonSecondary from "../../../Common/Button/ButtonSecondary";
import Aviso from "../../../Common/Aviso/Aviso";
import LoaderCarregando from "../../../Common/Loader/LoaderCarregando";

class ValidarUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = { codigo: "" };
  }

  componentWillUnmount() {
    this.props.initConfiguracoesCadastro();
  }

  atualizarCodigo = (codigo) => {
    this.setState({ codigo });
  }

  renderHeader = () => {
    return (
      <p className="padding-7 extrato__saldo-atual">
        <span className="text-subheader light-default">Validar Cadastro</span>
      </p>
    );
  }

  renderAviso() {
    const { aviso } = this.props.validarUsuarioReducer;

    if (aviso) {
      return (
        <div className="small-24 columns column-block">
          <Aviso type="error">
            {aviso}
          </Aviso>
        </div>
      );
    }
  }

  renderFormInput = () => {
    const { isFetching, codigoEnviado } = this.props.validarUsuarioReducer;

    if (codigoEnviado) {
      return (
        <InputSMS
          type="secondary"
          disabled={isFetching}
          onChange={(e) => this.atualizarCodigo(e.target.value)}
          onClickReenviar={(e) => {
            e.preventDefault();
            this.props.fetchConfiguracoesCadastroSms();
          }}
        />
      );
    }

    return (
      <InputCelular
        value={this.props.telefoneUsuario}
        disabled
      />
    );
  }

  renderButtonSubmit = () => {
    const { isFetching, codigoEnviado } = this.props.validarUsuarioReducer;

    if (codigoEnviado) {
      const codigoIncompleto = (
        !this.state.codigo ||
        this.state.codigo.indexOf("_") !== -1
      );

      return (
        <ButtonSecondary
          type="submit"
          disabled={isFetching || codigoIncompleto}
          setDisabled={isFetching || codigoIncompleto}
        >
          Validar
        </ButtonSecondary>
      );
    }

    return (
      <ButtonSecondary
        type="submit"
        disabled={isFetching}
        setDisabled={isFetching}
      >
        Enviar Código
      </ButtonSecondary>
    );
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { codigoEnviado } = this.props.validarUsuarioReducer;

    if (codigoEnviado) {
      return this.props.fetchConfiguracoesCadastroValidar(this.state.codigo);
    }

    return this.props.fetchConfiguracoesCadastroSms();
  }

  render() {
    const { isFetching } = this.props.validarUsuarioReducer;

    return (
      <form className="row edge-bottom configuracoes-validar-usuario" onSubmit={this.onSubmit}>
        <div className="small-24 columns">
          <Card header={this.renderHeader()} className="card-block">
            <div className="row">
              <div className="small-24 medium-12 large-8 columns column-block">
                {this.renderFormInput()}
              </div>
              <div className="small-24 medium-9 large-8 xlarge-6 columns column-block">
                {this.renderButtonSubmit()}
              </div>
              {
                isFetching ?
                <LoaderCarregando className="small-24 columns column-block text-center" /> :
                this.renderAviso()
              }
            </div>
          </Card>
        </div>
      </form>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    validarUsuarioReducer: state.validarUsuarioReducer,
    telefoneUsuario: state.usuarioReducer.usuario.nome
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidarUsuario);
