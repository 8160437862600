import {
  REQUEST_BILHETES,
  RECEIVE_BILHETES,
  ERROR_BILHETES,
  REQUEST_CANCELAR_BILHETE,
  RECEIVE_CANCELAR_BILHETE,
  RECEIVE_PRECONSULTA_CASHOUT,
  ERROR_CANCELAR_BILHETE,
  SET_BILHETE_CANCELADO,
  TOGGLE_BILHETE,
  REQUEST_EVENTOS,
  RECEIVE_EVENTOS,
  REQUEST_CASHOUT,
  RECEIVE_CASHOUT,
  ERROR_CASHOUT,
  // ERROR_EVENTOS,
} from "../../../actions/ActionTypes.js";

import eventos from "./EventosReducer";

export function bilhetes(state = [], action) {
  switch (action.type) {
    case REQUEST_BILHETES:
    case ERROR_BILHETES:
    case RECEIVE_BILHETES:
     { return state.map((itemBilhete) => bilhete(itemBilhete, action));}
    case ERROR_CASHOUT:
    case RECEIVE_CASHOUT:
    case REQUEST_CASHOUT:
    case RECEIVE_PRECONSULTA_CASHOUT:
    case TOGGLE_BILHETE:
    case REQUEST_EVENTOS:
    case RECEIVE_EVENTOS:
    case REQUEST_CANCELAR_BILHETE:
    case RECEIVE_CANCELAR_BILHETE:
    case ERROR_CANCELAR_BILHETE:
    case SET_BILHETE_CANCELADO: {
      const index = state.findIndex(
        (bilhete) => bilhete.numeroBilhete === action.numeroBilhete
      );

      return [
        ...state.slice(0, index),
        bilhete(state[index], action),
        ...state.slice(index + 1),
      ];
    }

    // case ERROR_EVENTOS:
    default:
      return state;
  }
}

const initialState = {
  isFetchingEventos: false,
  isFetchingCancelamento: false,
  isFetchingCashout: false,
  showEventos: false,
  autenticacao: "",
  numeroBilhete: 0,
  dataComprovante: "",
  quantidadeDeEventos: 0,
  valorPago: 0,
  valorPremio: 0,
  statusBilhete: "",
  idStatus: 0,
  eventos: [],
  cashout: null,
};

function bilhete(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_BILHETE:
      return {
        ...state,
        showEventos: !state.showEventos,
      };

    case REQUEST_EVENTOS:
      return {
        ...state,
        isFetchingEventos: true,
        eventos: eventos(undefined, action),
      };

    case RECEIVE_EVENTOS:
      return {
        ...state,
        isFetchingEventos: false,
        eventos: eventos(action.eventos, action),
      };

    case RECEIVE_BILHETES:
      return {
        ...initialState,
        autenticacao: state.autenticacao,
        numeroBilhete: state.numeroBilhete,
        dataComprovante: state.momento,
        quantidadeDeEventos: state.qtdEventos,
        valorPago: state.valorPago,
        valorPremio: state.valorPremio,
        statusBilhete: state.nomeStatus,
        idStatus: state.idStatus,
        eventos: eventos(undefined, action),
      };

    case RECEIVE_PRECONSULTA_CASHOUT:
      return {
        ...initialState,
      };

    case REQUEST_CASHOUT:
      return {
        ...state,
        isFetchingCashout: true,
      };
    case RECEIVE_CASHOUT:
      return {
        ...state,
        statusBilhete: "Cashout",
        idStatus: 6,
        isFetchingCashout: false,
        cashout: false,
        showEventos: true,
        eventos: state.eventos.map(evento => ({...evento, nomeStatus: evento.nomeStatus === "Aguardando Resultado" ? "Não Processado" : evento.nomeStatus }))
      }
    case ERROR_CASHOUT:
      return {
        ...state,
        isFetchingCashout: false,
      };

    case REQUEST_CANCELAR_BILHETE:
      return {
        ...state,
        isFetchingCancelamento: true,
      };

    case RECEIVE_CANCELAR_BILHETE:
    case ERROR_CANCELAR_BILHETE:
      return {
        ...state,
        isFetchingCancelamento: false,
      };

    case SET_BILHETE_CANCELADO:
      return {
        ...state,
        statusBilhete: "Cancelado",
        idStatus: 2,
      };

    default:
      return state;
  }
}

export const errorPreconsultaCashout = (state, action) => {
  const { idBilhete } = action;
  state.bilhetes[
    state.bilhetes.findIndex(
      (itemBilhete) => (itemBilhete.numeroBilhete === idBilhete)
    )
  ].cashout = false;
  return state.bilhetes;
};

export const setCashout = (state, action) => {
  const { valorCashout, idBilhete } = action;
  state.bilhetes[
    state.bilhetes.findIndex(
      (itemBilhete) => itemBilhete.numeroBilhete === idBilhete
    )
  ].cashout = valorCashout;
  return state.bilhetes;
};
