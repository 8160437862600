import {
  INIT_CARRINHO_BOLAO,
  ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA,
  REMOVER_ITEM_CARRINHO_BOLAO_PALPITE,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA,
  LIMPAR_CARRINHO_BOLAO,
  REQUEST_APOSTA_CARRINHO_BOLAO,
  RECEIVE_APOSTA_CARRINHO_BOLAO,
  ERROR_CARRINHO_BOLAO,
  SUCCESS_CARRINHO_BOLAO,
  RECEIVE_BOLAO_DETALHE,
  ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS,
  REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS
} from "../../../actions/ActionTypes";

import eventos from "./BolaoCarrinhoEventosReducer";
import aviso from "./BolaoCarrinhoAvisoReducer";

const initialState = {
  isFetching: false,
  bolaoPreenchido: false,
  aviso: aviso(undefined, {}),
  idBolao: 0,
  tipo: 0,
  nome: "",
  inicio: "",
  fim: "",
  vlrCota: 0,
  qtdCotas: 0,
  qtdEventos: 0,
  eventos: [],
};


const getIdByOptions = id => {
  switch (id) {
    case '1':
      return { id: 1, resultado: 'Casa' };
    case '2':
      return { id: 2, resultado: 'Empate' };
    case '3':
      return { id: 3, resultado: 'Fora' };
    case '12':
      return { id: 5, resultado: 'Casa ou Empate' };
    case '13':
      return { id: 4, resultado: 'Casa ou Fora' };
    case '23':
      return { id: 6, resultado: 'Empate ou Fora' };
    case '123':
      return { id: 54, resultado: 'Casa ou Empate ou Fora' };
    default:
      break;
  }
};


export default function bolaoCarrinhoReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_CARRINHO_BOLAO:
      return initialState;

    case RECEIVE_BOLAO_DETALHE: 
    {
      // Usa o reduce para somar a quantidade de eventos
      const qtdEventos = action.campeonatos.reduce((sum, campeonato) => {
        return campeonato.momentos.reduce((sum, momento) => {
          return momento.eventos.length + sum;
        }, 0) + sum;
      }, 0);

      return {
        ...state,
        idBolao: action.id,
        tipo: action.tipo,
        nome: action.nome,
        inicio: action.inicio,
        fim: action.fim,
        vlrCota: action.vlrCota,
        qtdEventos
      };
    }

    case ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE:
    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA:
    case REMOVER_ITEM_CARRINHO_BOLAO_PALPITE:
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA:
      {
      const newEventos = eventos(state.eventos, action);
      let bolaoPreenchido = false;

      if (state.qtdEventos === newEventos.length) {
        bolaoPreenchido = true;
      }
      return {
        ...state,
        bolaoPreenchido,
        eventos: newEventos
      };
    }

    //adicionar o item ao carrinho
    case REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS:
    case ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS: {

      const { campeonatos } = action.bolaoDetalhe;
      const carrinhoEventos = [];

      campeonatos.forEach(campeonato =>
        campeonato.momentos.forEach(momento =>
          momento.eventos.forEach(evento => {
            if (evento.opcoes.some(opcao => opcao.active)) {
              const newIdOpcao = getIdByOptions(
                evento.opcoes
                  .filter(opcao => opcao.active)
                  .map(filteredOpcao => filteredOpcao.idTipoResultado)
                  .sort()
                  .join('')
              );
              carrinhoEventos.push({
                idEvento: evento.id,
                idTipoResultado: newIdOpcao.id,
                detalhes: {
                  casa: evento.casa,
                  fora: evento.fora,
                  resultado: newIdOpcao.resultado
                }
              });
            }
          })
        )
      );

      state.eventos = carrinhoEventos;
      state.bolaoPreenchido = state.qtdEventos === state.eventos.length;
      return { ...state };
    }

    case LIMPAR_CARRINHO_BOLAO:
      return {
        ...state,
        bolaoPreenchido: false,
          qtdCotas: 0,
          eventos: []
      };

    case REQUEST_APOSTA_CARRINHO_BOLAO:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_APOSTA_CARRINHO_BOLAO:
      return {
        ...state,
        isFetching: false
      };

    case SUCCESS_CARRINHO_BOLAO:
      return {
        ...state,
        isFetching: false,
          aviso: aviso(state.aviso, action)
      };

    case ERROR_CARRINHO_BOLAO:
      return {
        ...state,
        isFetching: false,
          aviso: aviso(state.aviso, action)
      };

    default:
      return state;
  }
};


