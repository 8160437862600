// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Eticket/EticketActions";

// Styles
import "./Eticket.css";

// Components
import CardEticket from "./CardEticket";

class Eticket extends Component {
  renderEtickets = () => {
    const { etickets } = this.props.eticketReducer;
    const { removerEticket } = this.props;

    return etickets.map((eticket, index) => (
      <CardEticket
        key={index}
        eticket={eticket}
        removerEticket={removerEticket}
      />
    ));
  }

  render() {
    return (
      <div className="eticket edge-top">
        <div className="inner-row-wrapper">
          <div className="row">
            <div className="small-24 columns">
              <h1 className="text-display edge-bottom light-default">E-Ticket</h1>
              { this.renderEtickets() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { eticketReducer: state.eticketReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Eticket);
