// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/ApostasActions";

// Libs
import moment from "moment";

// Styles
import "./Apostas.css";

// Components
import InfoBilhete from "./InfoBilhete";
import Card from "../../../Common/Card/Card";
import InputDate from "../../../Common/Input/InputDate";
import ButtonSecondary from "../../../Common/Button/ButtonSecondary";
import LoaderCarregando from "../../../Common/Loader/LoaderCarregando";
import Aviso from "../../../Common/Aviso/Aviso";

class Apostas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInicio: moment(new Date()).format("YYYY-MM-DD"),
      dataFim: moment(new Date()).format("YYYY-MM-DD")
    };
  }

  componentWillUnmount() {
    this.props.initApostas();
  }

  atualizarDataState = (event, tipo) => {
    if (tipo === "inicio") {
      this.setState({
        dataInicio: event.target.value
      });
    }
    else {
      this.setState({
        dataFim: event.target.value
      });
    }
  }

  renderContent() {
    const { bilhetes, aviso } = this.props.apostasReducer;

    if (aviso) {
      return (
        <Aviso className="edge-top" type={aviso.type}>
          { aviso.text }
        </Aviso>
      );
    }

    if (bilhetes.length) {
      return (
        <div className="edge-top">
          {
            bilhetes.map((bilhete, index ) => (
              <InfoBilhete
                key={index}
                bilhete={bilhete}
              />
            ))
          }
        </div>
      );
    }
  }

  render() {
    const { isFetching } = this.props.apostasReducer;

    return (
      <div className="configuracoes-apostas">
        <form
          className="row"
          onSubmit={(event) => {
            event.preventDefault();
            this.props.fetchBilhetes(
              this.state.dataInicio,
              this.state.dataFim
            );
          }}
        >
          <div className="small-24 columns">
            <Card className="card-block">
              <div className="row">
                <div className="small-24 medium-12 large-8 columns column-block">
                  <p className="filtro-label text-subheader light-default">Data Início</p>
                  <InputDate
                    value={this.state.dataInicio}
                    onChange={(event) => this.atualizarDataState(event, "inicio")}
                  />
                </div>
                <div className="small-24 medium-12 large-8 columns column-block">
                  <p className="filtro-label text-subheader light-default">Data Fim</p>
                  <InputDate
                    value={this.state.dataFim}
                    onChange={(event) => this.atualizarDataState(event, "fim")}
                  />
                </div>
                <div className="small-24 medium-12 large-8 xlarge-6 columns column-block">
                  <p className="filtro-label filtro-label--empty show-for-large"></p>
                  <ButtonSecondary
                    type="submit"
                    disabled={isFetching}
                    setDisabled={isFetching}
                  >
                    Exibir
                  </ButtonSecondary>
                </div>
              </div>
            </Card>
          </div>
        </form>
        {
          isFetching ?
          <LoaderCarregando className="edge-top" /> :
          this.renderContent()
        }
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { apostasReducer: state.apostasReducer }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Apostas);
