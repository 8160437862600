// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/SaquesActions";

// Styles
import "../../ShareConfiguracoes.css";

// Helpers
import { convertIntegerToMoney } from "../../../../modules/helpers";

// Components
import Card from "../../../Common/Card/Card";
import InputMoney from "../../../Common/Input/InputMoney";
import ButtonSecondary from "../../../Common/Button/ButtonSecondary";
import LoaderCarregando from "../../../Common/Loader/LoaderCarregando";
import Aviso from "../../../Common/Aviso/Aviso";
import InfoSaque from "./InfoSaque";

class Saques extends Component {
  constructor(props) {
    super(props);
    this.state = { valor: 0 };
  }

  componentWillMount() {
    this.props.fetchSaquesAnteriores();
    this.props.fetchSaqueDisponivel();
  }

  componentWillUnmount() {
    this.props.initSaques();
  }

  atualizarValor = valor => {
    this.setState({ valor });
  };

  renderHeader = saldo => {
    return (
      <p className="padding-7 extrato__saldo-atual">
        <span className="text-subheader light-default">Solicitar Saque</span>
      </p>
    );
  };

  renderAviso() {
    const { aviso } = this.props.saquesReducer;

    if (aviso) {
      return (
        <Aviso className="edge-top" type={aviso.type}>
          {aviso.text}
        </Aviso>
      );
    }
  }

  renderSaquesAnteriores() {
    const { saques } = this.props.saquesReducer;

    if (saques.length) {
      return (
        <div className="edge-top">
          {saques.map((saque, index) => (
            <InfoSaque key={index} saque={saque} />
          ))}
        </div>
      );
    }

    return null;
  }

  render() {
    const { isFetching, info } = this.props.saquesReducer;
    const { exibirPromocoes } = this.props;

    return (
      <div>
        {info.hasInfo && (
          <Card className="edge-bottom card-block">
            <div className="row align-middle">
              <div className="small-18 columns column-block">
                <p className="text-title light-secondary">Saldo Total</p>
              </div>
              <div className="small-6 columns column-block text-right">
                <p className="text-title accent-1">
                  R$ {convertIntegerToMoney(info.saldo)}
                </p>
              </div>
            </div>
            <div className="row align-middle">
              <div className="small-18 columns column-block">
                <p className="text-title light-secondary">Saldo Prêmios</p>
              </div>
              <div className="small-6 columns column-block text-right">
                <p className="text-title accent-1">
                  R$ {convertIntegerToMoney(info.saqueDisp)}
                </p>
              </div>
            </div>
            {exibirPromocoes && (
              <div className="row align-middle">
                <div className="small-18 columns column-block">
                  <p className="text-title light-secondary">Saldo Bônus</p>
                </div>
                <div className="small-6 columns column-block text-right">
                  <p className="text-title accent-1">
                    R$ {convertIntegerToMoney(info.saldoBonus)}
                  </p>
                </div>
              </div>
            )}
            <div className="row align-middle">
              <div className="small-18 columns column-block">
                <p className="text-title light-secondary">
                  Saldo Restante PIN - Comissão ({info.percentualSaqueBloqueado}
                  %)
                </p>
              </div>
              <div className="small-6 columns column-block text-right">
                <p className="text-title accent-1">
                  R$ {convertIntegerToMoney(info.saqueDispBloqueado)}
                </p>
              </div>
            </div>
            <div className="row align-middle">
              <div className="small-18 columns column-block">
                <p className="text-title light-secondary">
                  Saldo Disponível para Saque
                </p>
              </div>
              <div className="small-6 columns column-block text-right">
                <p className="text-title accent-1">
                  R$ {convertIntegerToMoney(info.saldoDisponivelParaSaque)}
                </p>
              </div>
            </div>
          </Card>
        )}
        <form
          className="row"
          onSubmit={event => {
            event.preventDefault();
            this.props.fetchSaque(this.state.valor);
          }}
        >
          <div className="small-24 columns">
            <Card header={this.renderHeader()} className="card-block">
              <div className="row">
                <div className="small-24 medium-12 large-8 columns column-block configuracoes-saque">
                  <p className="filtro-label text-subheader light-default">
                    Valor
                  </p>
                  <InputMoney
                    className="text-right"
                    value={this.state.valor}
                    onChange={this.atualizarValor}
                  />
                </div>
                <div className="small-24 medium-8 large-6 columns column-block">
                  <p className="filtro-label filtro-label--empty show-for-medium" />
                  <ButtonSecondary
                    type="submit"
                    disabled={isFetching || this.state.valor <=0}
                    setDisabled={isFetching}
                  >
                    Solicitar
                  </ButtonSecondary>
                </div>
              </div>
            </Card>
          </div>
        </form>
        {isFetching ? (
          <LoaderCarregando className="edge-top" />
        ) : (
          this.renderAviso()
        )}

        {this.renderSaquesAnteriores()}
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = state => {
  return {
    saquesReducer: state.saquesReducer,
    exibirPromocoes: state.permissoesReducer.configuracoes.exibirPromocoes
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Saques);
