import React, { Component } from 'react';

class Sms extends Component {
  render () {
    const { outline } = this.props;

    if (outline) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>

      );
    }

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <g id="Layer-1" stroke="none" strokeWidth="1" fill="none" clipRule="evenodd" fillRule="evenodd">
          <g id="sms" fill="#fff">
            <g id="Rectangle">
              {/* <style>
          {.st0{fill - rule: evenodd;clip-rule:evenodd;fill:#fff}
        </style> */}
              <path className="st0" d="M19 13h2v2h-2zM15 13h2v2h-2zM11 13h2v2h-2z" />
              <path className="st0" d="M28 0H4C1.8 0 0 1.8 0 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4zm-2 20c0 1.1-.9 2-2 2H10l-4 4V8c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v12z" />
              <path d="M4 4h24v24H4V4z" fill="none" />
            </g>
          </g>
        </g>
      </svg>

    );
  }
}

export default Sms;
