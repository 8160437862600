// React
import React, { Component } from 'react';
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchPermissoes } from "../../../actions/Permissoes/PermissoesActions";

// React Router
import { withRouter } from "react-router-dom";

// Libs
import { throttle } from "underscore";

// Styles
import "./Wrapper.css";

// Modules
import addHeadInfo from "../../../modules/head";

// Components
import SidebarSideNav from "./SidebarSideNav";
import SidebarAside from "./SidebarAside";
import Modal from "../../Modal/Modal";
import HelloBar from "../../Common/HelloBar/HelloBar";

// Renderiza as Sidebars dependendo da largura da tela.
// Renderiza o Modal de acordo com o State (atualizado pelo Context).
// Referência:
// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
class Wrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 1280,
      sidebar: {
        leftSidebar: false,
        rightSidebar: false
      },
      modal: {
        modalOpen: false,
        modalTitle: null,
        modalContent: null,
        modalSize: null
      }
    };

    this.BREAKPOINT_WIDTH = 1024;
  }

  componentWillMount() {
    // Carrega as permissões se for o primeiro acesso, ou se
    // o tempo das permissões já estiver expirado
    const { permissoesReducer } = this.props;
    const tempoExpirado = new Date() > new Date(permissoesReducer.momentoExpiracao);

    if (
      !permissoesReducer.finish ||
      // Checagem necessária na transição antes/depois de configurações
      // (tava dando crash e não carregava os sites)
      !permissoesReducer.hasOwnProperty("configuracoes") ||
      tempoExpirado
    ) {
      this.props.fetchPermissoes();
    }

    // Quando atualiza a página e precisa reexecutar o addHeadInfo
    if (
      permissoesReducer.finish &&
      !document.getElementsByTagName("title").length
    ) {
      const headInfo = {
        nomeFantasia: permissoesReducer.configuracoes.nomeFantasia,
        pathAssets: permissoesReducer.configuracoes.pathAssets
      };

      addHeadInfo({ ...headInfo });
    }
  }

  // Passa métodos do Modal e Sidebars no context
  getChildContext() {
    return {
      toggleLeftSidebar: this.toggleLeftSidebar,
      toggleRightSidebar: this.toggleRightSidebar,
      openModal: this.openModal,
      closeModal: this.closeModal
    };
  }

  // Adiciona e remove listener do resize
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', throttle(this.updateWindowDimensions, 2000));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  // Atualiza width do state no resize
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  /*** LÓGICA DO MODAL ***/
  openModal = (title, content, size) => {
    const modal = {
      modalOpen: true,
      modalTitle: title,
      modalContent: content,
      modalSize: size
    };

    this.setState({ modal });
    document.body.style.overflow = "hidden";
  }

  closeModal = () => {
    const modal = {
      modalOpen: false,
      modalTitle: null,
      modalContent: null,
      modalSize: null
    };

    this.setState({ modal });
    document.body.style.overflow = "auto";
  }

  renderModal = () => {
    const {
      modalOpen,
      modalTitle,
      modalContent,
      modalSize
    } = this.state.modal;

    if (modalOpen) {
      return (
        <Modal title={modalTitle} size={modalSize}>
          {modalContent}
        </Modal>
      );
    }

    return null;
  }

  renderFooter() {
    // Não renderiza regulamento na tela de carregamento das estatísticas
    if (this.props.location.pathname.includes("/estatisticas")) return null;

    // Verifica se há regulamento setado nas permissões
    const { regulamento } = this.props.permissoesReducer.permissoes;
    let regulamentoHTML;

    if (regulamento) {
      // Transforma String em HTML com dangerouslySetInnerHTML do JSX
      regulamentoHTML = {
        __html: regulamento.replace(/className/g, "class")
      };
    }

    const { nomeFantasia, exibirEsportes } = this.props.permissoesReducer.configuracoes;
    const { isFetching } = this.props.permissoesReducer
    
    return !isFetching && exibirEsportes ? (
      <footer className="row align-center footer">
        <div className="small-24 medium-20 xlarge-12 columns">
          <h1 className="text-display edge-top edge-bottom">{nomeFantasia}</h1>
          <h2 className="text-title edge-bottom">Regulamento</h2>
          {
            // Renderiza regulamento das permissões ou o default
            regulamento ?
              <div dangerouslySetInnerHTML={regulamentoHTML}></div> :
              (
                <div>
                  <p className="text-body footer__regulamento">
                    Não pagaremos jogos: já realizados e que por falha, continuem no sistema, por erro de hora ou por jogos antecipados. No caso de apostas casadinhas, serão calculados os valores, e o jogo não realizado será devolvido o valor pago, sendo pago apenas os jogos realizados com sucesso.
                </p>
                  <p className="text-body footer__regulamento">
                    Combinações com dois jogos ou mais, se forem adiados ou cancelados, receberá o valor dos jogos.
                </p>
                  <p className="text-body footer__regulamento">
                    Nas demais opções de apostas, jogos adiados ou cancelados são esperados por até 24 hs.
                </p>
                  <p className="text-body footer__regulamento">
                    Todos os jogos são definidos ao final dos 90 minutos de jogo, incluindo acréscimos definidos pelo árbitro. Não valerá prorrogação, nem disputa de pênaltis.
                </p>
                  <p className="text-body footer__regulamento">
                    Quando o jogo não alcançar o final do tempo regulamentar, independente do tempo transcorrido, será considerado o jogo adiado ou cancelado ou excluído.
                </p>
                </div>
              )
          }
        </div>
      </footer>
    ) : null;
  }

  /*** LÓGICA DAS SIDEBARS ***/
  toggleLeftSidebar = (open) => {
    const sidebar = { ...this.state.sidebar };
    sidebar.leftSidebar = open;
    this.setState({ sidebar })
  }

  toggleRightSidebar = (open) => {
    const sidebar = { ...this.state.sidebar };
    sidebar.rightSidebar = open;
    this.setState({ sidebar })
  }

  render() {
    const { width } = this.state;

    if (width <= this.BREAKPOINT_WIDTH) {
      const { children } = this.props;
      const { leftSidebar, rightSidebar } = this.state.sidebar;

      return (
        <div>
          <div className="container">
            <SidebarSideNav
              open={leftSidebar}
              onSetOpen={this.toggleLeftSidebar}
              closeLeftSidebar={() => this.toggleLeftSidebar(false)}
            >
              <SidebarAside
                open={rightSidebar}
                onSetOpen={this.toggleRightSidebar}
                closeRightSidebar={() => this.toggleRightSidebar(false)}
                fullWidth={width <= 480 ? true : false}
              >
                <HelloBar />
                {children}
                {this.renderFooter()}
              </SidebarAside>
            </SidebarSideNav>
          </div>
          {this.renderModal()}
        </div>
      );
    }

    return (
      <div>
        <HelloBar />
        <div className="container">
          {this.props.children}
          {this.renderFooter()}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

Wrapper.childContextTypes = {
  toggleLeftSidebar: PropTypes.func,
  toggleRightSidebar: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return { permissoesReducer: state.permissoesReducer };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchPermissoes: fetchPermissoes
  }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wrapper)
);
