// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Carrinho/CarrinhoActions";

// Components
import ButtonOdd from "../../../Common/Button/ButtonOdd";
import Aviso from "../../../Common/Aviso/Aviso";

// Others
import { typeEventoAoVivo } from "../EventosTypes";

class Subeventos extends Component {
  acaoCarrinho = (idCampeonato, evento, subevento, eventoAoVivo) => {
    if (subevento.active) {
      this.props.removerItemCarrinho(idCampeonato, evento.id, subevento);
    }
    else {
      subevento.outright = this.props.outright;
      this.props.adicionarItemCarrinho(idCampeonato, evento, subevento, eventoAoVivo);
    }
  }

  renderSubeventos = () => {
    const { idCampeonato, evento } = this.props;
    const { subeventos } = evento;

    // Apenas eventos Ao Vivo tem matchID
    const eventoAoVivo = !!evento.matchID;
    const temCotacoes = subeventos.some((subevento) => subevento.cotacao !== 0);

    let oddIndex = 1;

    return subeventos.map((subevento) => {
      // - Exibe no máximo 6 Odds
      // - Não exibe Odd Prematch com cotação zerada
      if (!this.props.outright && oddIndex > 6) return null;
      //if (!eventoAoVivo && subevento.cotacao === 0) return null;
      
      let className = "";
      if(this.props.outright) {
        className = "small-24 medium-24 large-8 columns";
      } else {
        className = "small-8 medium-6 large-4 columns";
      }
      className += temCotacoes ? "" : " half-opacity";
      className += this.props.outright ? " column-block": "";

      // 4a. Odd >= Medium
      if (oddIndex === 4) {
        className += " show-for-medium";
      }

      // 5a. e 6a. Odd >= Large
      if (oddIndex > 4 && oddIndex <= 6) {
        className += " show-for-large";
      }

      oddIndex += 1;

      return (
        <div key={oddIndex} className={className}>
          <ButtonOdd
            label={subevento.label}
            cotacao={subevento.cotacao}
            onClick={() => this.acaoCarrinho(idCampeonato, evento, subevento, eventoAoVivo)}
            active={subevento.active}
          />
        </div>
      );
    })
  }

  render() {
    const { evento, tipoEvento } = this.props;

    // Ao vivo
    const periodoIniciado = evento.periodo !== "Não Iniciado";
    const semCotacoes = !evento.subeventos.some((subevento) => subevento.cotacao !== 0);
    const eventoBloqueado = tipoEvento === typeEventoAoVivo && periodoIniciado && semCotacoes;

    return (
      <div className="row">
        {
          eventoBloqueado ?
          (
            <div className="small-24 columns">
              <Aviso type="secondary">
                Evento bloqueado temporariamente
              </Aviso>
            </div>
          ) :
          this.renderSubeventos()
        }
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {}
};

// Apenas Actions do Carrinho
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subeventos);
