import { SUCCESS_CARRINHO, ERROR_CARRINHO } from "../../actions/ActionTypes.js";

const initialState = {
  type: "default",
  text: "Adicione um Evento ao Carrinho"
};

export default function aviso(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CARRINHO:
      return {
        type: "success",
        text: action.text
      };

    case ERROR_CARRINHO:
      return {
        type: "error",
        text: action.text
      };

    default:
      return state;
  }
};
