// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Carrinho/CarrinhoActions";

// Components
import ValorAposta from "./ValorAposta";
import Acoes from "./Acoes";
import Login from "../../../Modal/Login/Login";
import Input from "../../../Common/Input/Input";

class Footer extends Component {
  constructor(props) {
    super(props);

    // OBS: o cálculo do possivelRetorno no State tem mais
    // performance do que que despachar várias ações no reducer.
    this.state = {
      valorTotal: 0,
      possivelRetorno: 0,
      nomeCliente: ""
    };
  }

  // Recalcula o possivelRetorno se o carrinho for modificado
  // e o valorTotal for maior que 0 / Ou se foi aplicado cupom
  // que adicione valor no ganho.
  componentWillUpdate(nextProps, nextState) {
    // Checa se o carrinho foi modificado
    const thisItens = this.props.carrinho.itens;
    const nextItens = nextProps.carrinho.itens;
    const atualizouItens = nextItens !== thisItens && nextState.valorTotal > 0;

    // Checa se cupom foi aplicado
    const thisPercentualGanho = this.props.percentualGanho;
    const nextPercentualGanho = nextProps.percentualGanho;
    const atualizouPercentualGanho =
      thisPercentualGanho !== nextPercentualGanho;

    if (atualizouItens || atualizouPercentualGanho) {
      this.atualizarPossivelRetorno(
        this.state.valorTotal,
        nextItens,
        nextPercentualGanho
      );
    }
  }

  openLoginModal = titulo => {
    this.context.openModal(titulo, <Login />, "small");
  };

  // Atualiza valorTotal e possivelRetorno do State.
  atualizarPossivelRetorno(valorTotal, itens, percentualGanho) {
    // Não permite valor digitado passar de 7 caractéres
    if (valorTotal > 9999999) valorTotal = 9999999;

    // Cálculo do possível retorno.
    // Referência (sporting_business): https://goo.gl/mBemgs
    let fator = 1;

    itens.forEach(item => {
      fator *= item.subevento.cotacao / 100;
    });

    if (this.props.fatorGanhoFixo && this.props.fatorGanhoFixo > 0) {
      if (fator > this.props.fatorGanhoFixo) {
        fator = this.props.fatorGanhoFixo;
      }
    }

    let possivelRetorno = Math.round(fator * (valorTotal / 100) * 100);

    // Subtrai valor do desconto do ganho (se houver)
    if (this.props.descontoGanho) {
      const valorDescontado = Math.round(
        (this.props.descontoGanho / 100) * possivelRetorno
      );
      possivelRetorno -= valorDescontado;
    }

    // Adiciona valor do percentual do ganho (se houver cupom)
    // percentualGanho = percentualGanho || this.props.percentualGanho;
    if (percentualGanho) {
      const valorAdicionado = Math.round(
        (percentualGanho / 100) * possivelRetorno
      );
      possivelRetorno += valorAdicionado;
    }

    if (this.props.valorMaxPremio && this.props.valorMaxPremio > 0) {
      if (possivelRetorno > this.props.valorMaxPremio * 100) {
        possivelRetorno = this.props.valorMaxPremio * 100;
      }
    }

    this.setState({ valorTotal, possivelRetorno });
  }

  render() {
    const {
      carrinho,
      limparCarrinho,
      apostar,
      percentualGanho,
      usuarioLogado
    } = this.props;
    const { isFetching, itens } = this.props.carrinho;

    return (
      <div>
        <ValorAposta
          valorTotal={this.state.valorTotal}
          possivelRetorno={this.state.possivelRetorno}
          atualizarPossivelRetorno={valorTotal =>
            this.atualizarPossivelRetorno(valorTotal, itens, percentualGanho)
          }
        />
        {!usuarioLogado && (
          <div className="input-nome">
            <Input
              name="nomeCLiente"
              placeholder="Nome do apostador"
              onChange={e => this.setState({ nomeCliente: e.target.value })}
            />
          </div>
        )}
        <Acoes
          onClickLimpar={() => limparCarrinho(carrinho)}
          onClickApostar={() =>
            apostar(
              this.state.valorTotal,
              this.state.possivelRetorno,
              this.openLoginModal,
              this.state.nomeCliente
            )
          }
          isFetching={isFetching}
        />
      </div>
    );
  }
}

Footer.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = state => {
  return {
    carrinho: state.carrinho,
    percentualGanho: state.carrinho.cupom.dados.percentualGanho,
    descontoGanho: state.permissoesReducer.permissoes.descontoGanho,
    fatorGanhoFixo: state.permissoesReducer.permissoes.fatorGanhoFixo,
    valorMaxPremio: state.permissoesReducer.permissoes.valorMaxPremio,
    usuarioLogado: state.usuarioReducer.logado
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      limparCarrinho: actions.limparCarrinho,
      apostar: actions.apostar
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
