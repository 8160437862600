import {
  INIT_EVENTOS_POR_CAMPEONATO,
  REQUEST_EVENTOS_CAMPEONATO,
  RECEIVE_EVENTOS_CAMPEONATO,
  ADD_FILTROS_EVENTOS_CAMPEONATOS,
  REMOVE_FILTRO_EVENTOS_CAMPEONATOS,
  ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS,
  ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS,
  CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO,
  CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO,
  CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO,
  RECEIVE_BANNER_PREMATCH,
  CAMPEONATO_EVENTOS_LONGO_PRAZO,
  CAMPEONATO_EVENTOS_DISPUTA_INDIRETA
} from "../ActionTypes";

import { API_CRM } from "../../config";

import { doRequest } from "../../modules/request";

export const initEventosPorCampeonato = (campeonatos, carrinho) => ({
  type: INIT_EVENTOS_POR_CAMPEONATO,
  campeonatos,
  carrinho
});

export const requestEventosPorCampeonato = (modalidades) => {
  return {
    type: REQUEST_EVENTOS_CAMPEONATO
  };
};

export const errorEventosPorCampeonato = (text) => ({
  type: ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS,
  text
});

export const receiveEventosPorCampeonato = (campeonatos,carrinho) => {
  return {
    type: RECEIVE_EVENTOS_CAMPEONATO,
    campeonatos,
    carrinho
  };
};

export const ClickExibirTodosEventosPrematch = () => {
  return {
    type: CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO,
  };
}

export const ClickExibirEventosDoDiaPrematch = () => {
  return {
    type: CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO
  };
}

export const ClickExibirEventosProximaHoraPrematch = () => {
  return {
    type: CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO
  };
}

export const atualizarEventosLongoPrazo = (eventosLongoPrazo) => {
  return {
    type: CAMPEONATO_EVENTOS_LONGO_PRAZO,
    eventosLongoPrazo
  };
}

export const atualizarEventosDisputaIndireta = (eventosDisputaIndireta) => {
  return {
    type: CAMPEONATO_EVENTOS_DISPUTA_INDIRETA,
    eventosDisputaIndireta
  };
}

export const setEventosLongoPrazo = (eventosLongoPrazo = true) => (dispatch, getState) => {
  dispatch(atualizarEventosLongoPrazo(eventosLongoPrazo));
}

export const setEventosDisputaIndireta = (eventosDisputaIndireta = true) => (dispatch, getState) => {
  dispatch(atualizarEventosDisputaIndireta(eventosDisputaIndireta));
}

// Variável estatica para armazenar os eventos para serem filtrados.
let evetosPorcampeonatoRaw = null;

export const getEventosRaw = () => (dispatch, getState) => {
  const eventosRaw = JSON.parse(JSON.stringify(evetosPorcampeonatoRaw));
  return eventosRaw
}

export const fetchEventosPorCampeonato = (carrinho) => (dispatch, getState) => {
  dispatch(requestEventosPorCampeonato());
  let params = null
  if(getState().eventosPorCampeonatoReducer.campeonatosFiltros.idCampeonatos.length > 0) {

    params = {
      url: "/filter",
      method: "POST",
      data: {ids: getState().eventosPorCampeonatoReducer.campeonatosFiltros.idCampeonatos}
    };
  }else if(getState().eventosPorCampeonatoReducer.eventosLongoPrazo) {
    params = {
      url: "/principal/prematch/outright",
      method: "GET",
      params: {
        tipoEsporte: 1
      }
    };
  } else if ( getState().eventosPorCampeonatoReducer.eventosDisputaIndireta ) {
    params = {
      url: "/principal/disputaDireta",
      method: "GET",
      params: {
        tipoEsporte: 1
      }
    };
  }  
  else {

    params = {
      url: "/principal/prematch",
      method: "GET"
    };
  }

  doRequest(params).then(response => {
    if(response.campeonatos == null) {
      return Promise.reject("Evento Não Encontrado");
    }else if(typeof response.campeonatos === "undefined" || response.campeonatos.length <= 0){
      return Promise.reject(response.message);
    }else{
      evetosPorcampeonatoRaw = response.campeonatos;
      if(getState().eventosPorCampeonatoReducer.campeonatosFiltros.exibirJogosDoDia){
        dispatch(FiltrarEventosDoDiaCampeonato());
      } else if(getState().eventosPorCampeonatoReducer.campeonatosFiltros.exibirProximaHora){
        dispatch(FiltrarEventosCampeonatoProximaHora())
      }else {
        dispatch(filtrarEventosPorCampeonatoPorBusca());
      }
    }
  }).catch(error => {
    dispatch(errorEventosPorCampeonato(error));
  });

};

export const filtrarEventosPorCampeonatoPorBusca = (eventos=null) => (dispatch, getState) => {

  let updatedList = null;
  if(eventos == null){
    updatedList = JSON.parse(JSON.stringify(evetosPorcampeonatoRaw));
  }else{
    updatedList = JSON.parse(JSON.stringify(eventos));
  }

  //Criando copia
  const query = getState().eventosPorCampeonatoReducer.campeonatosFiltros.busca;

  if(query.length > 0){
    //Filtrando pelo nome dos eventos
    for(let x in updatedList) {
      updatedList[x].qtdEventos = 0;

      for(let y in updatedList[x].momentos){
        updatedList[x].momentos[y].eventos = updatedList[x].momentos[y].eventos.filter(function(item){
          return item.casa.toLowerCase().search(query.toLowerCase()) !== -1 ||
          item.fora.toLowerCase().search(query.toLowerCase()) !== -1 || (item.seqBuscaDiario !== "0" && item.seqBuscaDiario === query);
        });

        updatedList[x].qtdEventos += updatedList[x].momentos[y].eventos.length
      }

      //remover momentos sem eventos
      updatedList[x].momentos = updatedList[x].momentos.filter(function(item){
          return item.eventos.length > 0;
      });
    }

    //Filtrar Campeonatos sem eventos
    updatedList = updatedList.filter(function(item){
        return item.qtdEventos > 0;
    });
  }
  else {
    const {
      exibirJogosDoDia,
      exibirProximaHora,
      idCampeonatos
    } = getState().eventosPorCampeonatoReducer.campeonatosFiltros;

    const listaSemFiltros = !exibirJogosDoDia && !exibirProximaHora && !idCampeonatos.length;

    if (listaSemFiltros) {
      updatedList = updatedList.slice(0, 10); // fix
    }
  }

  if(updatedList.length <=0 ){
    dispatch(errorEventosPorCampeonato("Evento não Encontrado"));
  }else{
    dispatch(receiveEventosPorCampeonato(updatedList,getState().carrinho));
  }

};

export const FiltrarEventosCampeonatoProximaHora = () => (dispatch, getState) => {
  if (evetosPorcampeonatoRaw != null){
    dispatch(ClickExibirEventosProximaHoraPrematch());
    let updatedList = JSON.parse(JSON.stringify(evetosPorcampeonatoRaw));

    for(let x in updatedList) {

      updatedList[x].qtdEventos = 0;

      updatedList[x].momentos = updatedList[x].momentos.filter(function(item){
          const dataEvento = new Date(item.data), dataAtual = new Date();

          return dataEvento.getDate() === dataAtual.getDate() &&
              dataEvento.getMonth() === dataAtual.getMonth()
      });


      for(let y in updatedList[x].momentos){
        updatedList[x].momentos[y].eventos = updatedList[x].momentos[y].eventos.filter(function(item){
          const horaAtual = new Date().getHours(),horaEvento = item.horario.split("h")[0];
          return Math.abs(horaEvento - horaAtual) <=2;
        });

        updatedList[x].qtdEventos += updatedList[x].momentos[y].eventos.length
      }
    }

    updatedList = updatedList.filter(function(item){
        return item.momentos.length > 0 && item.qtdEventos > 0;
    });

    dispatch(filtrarEventosPorCampeonatoPorBusca(updatedList,getState().carrinho));
  }
}

export const FiltrarEventosDoDiaCampeonato = () => (dispatch, getState) => {
  if (evetosPorcampeonatoRaw != null){
    dispatch(ClickExibirEventosDoDiaPrematch());
    let updatedList = JSON.parse(JSON.stringify(evetosPorcampeonatoRaw));



    for(let x in updatedList) {
      //remover momentos sem eventos
      updatedList[x].momentos = updatedList[x].momentos.filter(function(item){
          const dataEvento = new Date(item.data), dataAtual = new Date();

          return dataEvento.getDate() === dataAtual.getDate() &&
              dataEvento.getMonth() === dataAtual.getMonth()
      });
    }

    updatedList = updatedList.filter(function(item){
        return item.momentos.length > 0;
    });

    dispatch(filtrarEventosPorCampeonatoPorBusca(updatedList,getState().carrinho));
  }
}

export const FiltrarEventosDeAmanhaCampeonato = () => (dispatch, getState) => {
  if (evetosPorcampeonatoRaw != null){
    dispatch(ClickExibirEventosDoDiaPrematch());
    let updatedList = JSON.parse(JSON.stringify(evetosPorcampeonatoRaw));



    for(let x in updatedList) {
      //remover momentos sem eventos
      updatedList[x].momentos = updatedList[x].momentos.filter(function(item){
          const dataEvento = new Date(item.data), dataAtual = new Date();
          const dataAmanha = new Date(dataAtual);
          dataAmanha.setDate(dataAmanha.getDate()+1)

          return dataEvento.getDate() === dataAmanha.getDate() &&
              dataEvento.getMonth() === dataAmanha.getMonth()
      });
    }

    updatedList = updatedList.filter(function(item){
        return item.momentos.length > 0;
    });

    dispatch(filtrarEventosPorCampeonatoPorBusca(updatedList,getState().carrinho));
  }
}

export const FiltrarTodosEventosCampeonato = () => (dispatch, getState) => {
  if (evetosPorcampeonatoRaw != null){
    dispatch(ClickExibirTodosEventosPrematch())
    let updatedList = JSON.parse(JSON.stringify(evetosPorcampeonatoRaw));

    dispatch(filtrarEventosPorCampeonatoPorBusca(updatedList,getState().carrinho));
  }
}

export const addFiltroEventosPorCampeonato = (idCampeonato,carrinho) => {
  return {
    type: ADD_FILTROS_EVENTOS_CAMPEONATOS,
    idCampeonato
  }
}

export const removeFiltroEventosPorCampeonato = (idCampeonato,carrinho) => {
  return {
    type: REMOVE_FILTRO_EVENTOS_CAMPEONATOS,
    idCampeonato
  }

}

export const setBuscaEventosCampeonato = (busca) => {
  return {
    type: ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS,
    busca: busca
  }
}

export const carregarMaisEventos = () => (dispatch, getState) => {
  const {
    busca,
    exibirJogosDoDia,
    exibirProximaHora
  } = getState().eventosPorCampeonatoReducer.campeonatosFiltros;

  const listaSemFiltros = !busca && !exibirJogosDoDia && !exibirProximaHora;

  if (listaSemFiltros) {
    const listaAtual = [...getState().eventosPorCampeonatoReducer.campeonatos];

    if (listaAtual.length === evetosPorcampeonatoRaw.length) {
      return;
    }

    const novosCampeonatos = [...evetosPorcampeonatoRaw.slice(listaAtual.length, listaAtual.length + 10)];
    const novaLista = [...listaAtual, ...novosCampeonatos];

    dispatch(receiveEventosPorCampeonato(novaLista, getState().carrinho));
  }
}

// Banners do Pre-match
export const receiveBannerPrematch = (media) => {
  return { type: RECEIVE_BANNER_PREMATCH, media };
};

export const fetchBannersPrematch = () => (dispatch, getState) => {
  const params = {
    baseURL: API_CRM,
    url: "/v2/crm/banners",
    method: "GET",
    params: { target: "prematch" }
  };

  doRequest(params).then(response => {
    dispatch(receiveBannerPrematch(response.media));
  }).catch(error => { /* ... */ });
};
