import {
  INIT_MODALIDADES_EVENTOS_POR_CAMPEONATO,
  REQUEST_MODALIDADES_EVENTOS_CAMPEONATO,
  RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  ERROR_AVISO_MODALIDADES_EVENTO_PREMATCH
} from "../ActionTypes";

import { doRequest } from "../../modules/request";

export const initModalidadesEventosPrematch = (campeonatos, carrinho) => ({
  type: INIT_MODALIDADES_EVENTOS_POR_CAMPEONATO,
  campeonatos,
  carrinho
});

export const requestModalidadesEventosPrematch = (modalidades) => {
  return {
    type: REQUEST_MODALIDADES_EVENTOS_CAMPEONATO
  };
};

export const receiveModalidadesEventosPrematch = (evento,carrinho) => {
  return {
    type: RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO,
    evento,
    carrinho
  };
};

export const errorModalidadeEventoPrematch = (text) => ({
  type: ERROR_AVISO_MODALIDADES_EVENTO_PREMATCH,
  text
});

export const fetchSubEventosPorCampeonato = (idEvento,idCampeonato) => (dispatch, getState) => {
  dispatch(requestModalidadesEventosPrematch());

  const params = {
    url: "principal/prematch/subevento",
    method: "GET",
    params : {idEvento}
  };

  doRequest(params).then(response => {
    if(!response.modalidades) {
      return Promise.reject(response.message);
    }else{
      if(typeof response.idCampeonato === "undefined") {
        response.idCampeonato = parseInt(idCampeonato,10)
      }
      dispatch(receiveModalidadesEventosPrematch(response,getState().carrinho));
    }
  }).catch(error => {
    dispatch(errorModalidadeEventoPrematch(error));
  });

};

export const adicionarItemCarrinho = (subevento) => {
  return {type: ADICIONAR_ITEM_CARRINHO,
    subevento
  }
}

export const removerItemCarrinho = (subevento) => {
  return {type: REMOVER_ITEM_CARRINHO,
    subevento
  }
}
