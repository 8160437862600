import {
  INIT_INSERIR_CREDITOS,
  REQUEST_PIN,
  RECEIVE_PIN,
  SUCCESS_PIN,
  ERROR_PIN
} from "../../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: null,
};

export default function inserirCreditosReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_INSERIR_CREDITOS:
      return initialState;

    case REQUEST_PIN:
      return {
        isFetching: true,
        aviso: null,
      };

    case RECEIVE_PIN:
      return {
        ...state,
        isFetching: false,
      };

    case SUCCESS_PIN:
      return {
        ...state,
        aviso: { type: "success", text: action.success }
      };

    case ERROR_PIN:
      return {
        ...state,
        aviso: { type: "error", text: action.error }
      };

    default:
      return state;
  }
};
