// React
import React, { Component } from "react";

// React Router
import { withRouter } from "react-router-dom";

// Styles
import "./Button.css";

// Helpers
import { convertIntegerToFloat } from "../../../modules/helpers";

class ButtonOdd extends Component {
  constructor(props) {
    super(props);
    this.state = { animation: null };
  }

  componentWillUpdate(nextProps) {
    if (this.props.location.pathname.includes("aovivo")) {
      // Se cotação aumentar
      if (nextProps.cotacao > this.props.cotacao) {
        this.setState({ animation: null });
        setTimeout(() => {
          this.setState({ animation: "green-animation" });
        }, 100);
      }

      // Se cotação diminuir
      if (nextProps.cotacao < this.props.cotacao) {
        this.setState({ animation: null });
        setTimeout(() => {
          this.setState({ animation: "red-animation" });
        }, 100);
      }
    }
  }

  render() {
    const { label, cotacao, active, isHeader, onClick } = this.props;

    const cotacaoInvalida = cotacao === 0;

    let classes = "button-odd row align-middle";
    classes += isHeader ? " button-odd--header" : " button-odd--normal";
    classes += active ? " button-odd--active" : "";
    classes += cotacaoInvalida ? " button-odd--disabled" : "";

    const cotacaoFormatada = cotacaoInvalida ? "-" : convertIntegerToFloat(cotacao);
    const cotacaoAnimation = this.state.animation ? `button-odd--${this.state.animation}` : "";

    return (
      <button
        className={`${classes} ${cotacaoAnimation}`}
        onClick={onClick}
        disabled={cotacaoInvalida}
      >
        <span className="small-8 button-odd__label text-left text-title light-default">
          { label }
        </span>
        <span className="small-16 button-odd__cotacao text-right text-headline">
          { cotacaoFormatada }
        </span>
      </button>
    );
  }
}

export default withRouter(ButtonOdd);
