// React
import React from "react";

// Styles
import "./Loader.css";

// Components
import Loader from "./Loader";

export default ({ className, ...props }) => (
  <p
    {...props}
    className={`row align-middle align-center edge-padding-8 text-title light-active ${className || ""}`}
  >
    Carregando... &nbsp; <Loader />
  </p>
);
