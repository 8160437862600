// React
import React from "react";

// Styles
import "./Button.css";

export default ({ className, setDisabled, children, ...props }) => {
  let classes = `button ${className}`;
  classes += setDisabled ? " button--disabled" : "";

  return (
    <button
      className={classes}
      {...props}
    >
      { children }
    </button>
  );
};
