import {
  INIT_EVENTOS_POR_CAMPEONATO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  REQUEST_EVENTOS_CAMPEONATO,
  RECEIVE_EVENTOS_CAMPEONATO,
  ADD_FILTROS_EVENTOS_CAMPEONATOS,
  REMOVE_FILTRO_EVENTOS_CAMPEONATOS,
  RECEIVE_EVENTOS_AOVIVO,
  INIT_EVENTOS_AOVIVO,
  ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS,
  ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS,
  CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO,
  CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO,
  CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO,
  CAMPEONATO_EVENTOS_LONGO_PRAZO,
  CAMPEONATO_EVENTOS_DISPUTA_INDIRETA
} from "../../actions/ActionTypes.js";

import momentos from "./MomentosReducer";
import eventos from "./EventosReducer"

import campeonatosFiltros from "./CampeonatosFilterReducer";
import aviso from "./AvisosEventos";


const eventosPorCampeonatoInitialState = {
  isFetching : null,
  campeonatos : [],
  campeonatosFiltros : {
    idCampeonatos: [],
    busca: "",
    exibirTodos: true,
    exibirJogosDoDia: false,
    exibirProximaHora: false
  },
  aviso: undefined,
  eventosLongoPrazo : false,
  eventosDisputaIndireta : false
}


export default function eventosPorCampeonatoReducer(state = eventosPorCampeonatoInitialState, action) {
  switch (action.type) {
    case REQUEST_EVENTOS_CAMPEONATO:
    return {
      ...state,
      isFetching : true,
      aviso: undefined
    }
    case CAMPEONATO_EVENTOS_LONGO_PRAZO:
    return {
      ...state,
      eventosLongoPrazo: action.eventosLongoPrazo
    }
    case CAMPEONATO_EVENTOS_DISPUTA_INDIRETA:
    return {
      ...state,
      eventosDisputaIndireta: action.eventosDisputaIndireta
    }
    case RECEIVE_EVENTOS_CAMPEONATO:
    case INIT_EVENTOS_POR_CAMPEONATO:
      return {
        ...state,
        isFetching : false,
        campeonatos: action.campeonatos.map(item => campeonato(item, action)),
        aviso: undefined,
        eventosLongoPrazo: false,
        eventosDisputaIndireta: false,
      }


    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      return {
        ...state,
        campeonatos: state.campeonatos.map(item => campeonato(item, action))
      }
    case ADD_FILTROS_EVENTOS_CAMPEONATOS:
    case REMOVE_FILTRO_EVENTOS_CAMPEONATOS:
      return {
        ...state,
        campeonatosFiltros: campeonatosFiltros(state.campeonatosFiltros, action)
      }
    case ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS:
      return {
        ...state,
        isFetching: false,
        aviso: aviso(state.aviso, action)
      };
    case CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO:
    case CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO:
    case CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO:
    case ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS:
      return {
        ...state,
        campeonatosFiltros : campeonatosFiltros(state.campeonatosFiltros, action),
        eventosLongoPrazo: false,
      }


    default:
      return state;
  }
};

const initialState = {
  id: null,
  nome: "",
  pais: "",
  momentos: []
};

export const campeonato = (state = initialState, action) => {

  switch (action.type) {
    case RECEIVE_EVENTOS_CAMPEONATO:
    case INIT_EVENTOS_POR_CAMPEONATO:
    {
      const campeonatoNoCarrinho = action.carrinho.itens.some(item =>
        state.id === item.idCampeonato
      );

      if (campeonatoNoCarrinho) {
        return {
          ...state,
          momentos: momentos(state.momentos, action)
        };
      }

      return state;
    }

    case LIMPAR_CARRINHO:
    {
      const campeonatoNoCarrinho = action.carrinho.itens.some(item =>
        state.id === item.idCampeonato
      );

      if (campeonatoNoCarrinho) {

        //Quer dizer q o evento e aovivo, pois nao tem momento.'
        if(typeof state.momentos === "undefined" || state.momentos.lenght <= 0){
          return {
            ...state,
            eventos: eventos(state.eventos, action)
          };
        }else{
          return {
            ...state,
            momentos: momentos(state.momentos, action)
          };
        }
      }

      return state;
    }

    case RECEIVE_EVENTOS_AOVIVO:
    case INIT_EVENTOS_AOVIVO: {
      const campeonatoNoCarrinho = action.carrinho.itens.some(item =>
        state.id === item.idCampeonato
      );

      if (campeonatoNoCarrinho) {
        return {
          ...state,
          eventos: eventos(state.eventos, action)
        };
      }

      return state;
    }
    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
      if (state.id === action.idCampeonato) {

        //Quer dizer q o evento e aovivo, pois nao tem momento.'
        if(typeof state.eventos !== "undefined" && state.eventos.length > 0){
          return {
            ...state,
            eventos: eventos(state.eventos, action)
          };
        }else{
          return {
            ...state,
            momentos: momentos(state.momentos, action)
          };
        }

      }

      return state;

    default:
      return state;
  }
};
