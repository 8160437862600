import { Observable } from 'rxjs/Observable';
import { fetchEventosPorCampeonato } from '../actions/EventosPorCampeonato/EventosPorCampeonatoActions'
import * as ActionTypes from '../actions/ActionTypes';


export default function filtroEventosPorCampeonato(action$) {
  return action$.ofType(ActionTypes.ADD_FILTROS_EVENTOS_CAMPEONATOS,ActionTypes.REMOVE_FILTRO_EVENTOS_CAMPEONATOS)
  .debounceTime(1000)
  .mergeMap(() => Observable.merge(
      Observable.of(fetchEventosPorCampeonato())
    ));
}
