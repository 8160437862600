import React from 'react';

//Component
import LinhaJogo from './LinhaJogo';

import './LinhaJogo.css'


export default ({cabecalho,linhas}) => {
    return (
        <div>
            {
                linhas.map((l,i) =>{
                    return (
                        <LinhaJogo key={l.id} cabecalho={cabecalho} linha={l} indice={i+1}/>
                    )
                })
            }
        </div>
    )
}
