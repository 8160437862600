// Action Types
import {
  INIT_CADASTRO_PIN,
  REQUEST_CADASTRO_PIN,
  RECEIVE_CADASTRO_PIN,
  ERROR_CADASTRO_PIN
} from "../ActionTypes";

// Actions
import { fetchLogin } from "../Usuario/UsuarioActions";

// Modules
import { doRequest } from "../../modules/request";

// Snackbar
import Snackbar from "../../components/Common/Snackbar/Snackbar";

export const initCadastro = () => ({
  type: INIT_CADASTRO_PIN
});

export const requestCadastro = () => ({
  type: REQUEST_CADASTRO_PIN
});

export const receiveCadastro = () => ({
  type: RECEIVE_CADASTRO_PIN
});

export const errorCadastro = (error) => ({
  type: ERROR_CADASTRO_PIN,
  error
});

export const fetchCadastro = ({ login, pin, telefone, confirmarTelefone, senha, confirmarSenha }) => (dispatch, getState) => {
  dispatch(requestCadastro());

  // Verifica se campos são válidos
  const validarCampos = checarCampos(telefone, confirmarTelefone, senha, confirmarSenha);

  if (!validarCampos.valido) {
    return dispatch(errorCadastro(validarCampos.error));
  }

  const data = {
    login: login || telefone,
    pin,
    senha
  };

  const params = {
    url: "/pin/autoCadastro/usuario",
    method: "POST",
    data
  };

  return doRequest(params)
    .then(response => {
      if (response.codResposta === "000") {
        dispatch(fetchLogin(login || telefone, senha));
        dispatch(receiveCadastro());
        Snackbar("Cadastro realizado com sucesso", "Ok");
      }
      else {
        dispatch(errorCadastro(response.response.data.message));
      }
    },
    error => {
      dispatch(errorCadastro(error));
    });
};

const checarCampos = (telefone, confirmarTelefone, senha, confirmarSenha) => {
  // Se o telefone não conferir
  if (telefone) {
    if (telefone !== confirmarTelefone) {
      return { valido: false, error: "Telefone não confere" };
    }
  }

  // Se a senha não conferir
  if (senha !== confirmarSenha) {
    return { valido: false, error: "Senha não confere" };
  }

  // Se a senha for menor que 6 dígitos
  if (senha.length < 6) {
    return { valido: false, error: "Senha deve ter 6 ou mais dígitos" };
  }

  // Se a senha não possuir ao menos uma letra e um número
  if (senha.search(/\d/) === -1 || senha.search(/[a-zA-Z]/) === -1) {
    return { valido: false, error: "Senha deve conter ao menos uma letra e um número" };
  }

  return { valido: true };
};
