// React
import React from "react";

const SideNavDivider = ({ children, className }) => (
  <li className={`sidenav-divider text-subheader light-secondary ${className || ""}`}>
    { children }
  </li>
);

export default SideNavDivider;
