// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Components
import ValidarUsuario from "./ValidarUsuario";
import AlterarSenha from "../AlterarSenha/AlterarSenha";

class Cadastro extends Component {
  render() {
    const { usuarioValidado, loginPorTelefone } = this.props;

    return (
      <div>
        {
          (loginPorTelefone && !usuarioValidado) &&
          <ValidarUsuario />
        }
        <AlterarSenha />
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    loginPorTelefone: state.permissoesReducer.configuracoes.loginPorTelefone,
    usuarioValidado: state.usuarioReducer.usuario.validado
  }
};

export default connect(mapStateToProps)(Cadastro);
