// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/EventosPorCampeonato/EventosPorCampeonatoActions.js";

// React Router
import { withRouter,Link } from "react-router-dom";

// Components
import Campeonato from "./Campeonato/Campeonato";
import LoaderCarregando from "../../Common/Loader/LoaderCarregando";
import Aviso from "../../Common/Aviso/Aviso";
import LegendaSubeventos from "../../Modal/LegendaSubeventos/LegendaSubeventos";
import ButtonRounded from "../../Common/Button/ButtonRounded";
import BannerSlider from "../../Common/Banner/BannerSlider";

const Campeonatos = ({ campeonatos, carregar }) => (
  <div className="row">
    <div className="small-24 columns">
      {
        campeonatos.map((campeonato, index) => (
          <Campeonato key={index} campeonato={campeonato} outright={true} />
        ))
      }
    </div>
  </div>
);

const Title = ({ title, openModalLegenda,saveObjectPrinter, showPrinterButton }) => (
  <div className="row">
    <div className="small-24 columns">
      <h1 className="text-display light-default">
        { title }
        <span
          style={{ fontSize: "2rem" }}
          className="cursor-pointer float-right icon icon-ajuda-outline"
          onClick={openModalLegenda}
        >
        </span>
        {
          showPrinterButton || showPrinterButton === null ?
          (
            <Link to="/impressao/eventos"
              style={{ fontSize: "2rem" }}
              className="cursor-pointer float-right icon icon-imprimir"
              target="_blank"
              onClick={saveObjectPrinter}
            >
            </Link>
          ) : (<span></span>)
        }

      </h1>
    </div>
  </div>
);

class EventosLongoPrazo extends Component {
  componentWillMount() {
    this.props.setEventosLongoPrazo(true);
    this.props.fetchEventosPorCampeonato(this.props.carrinho);
    this.props.fetchBannersPrematch();
  }

  openModalLegenda = () => {
    this.context.openModal(
      "Legenda",
      <LegendaSubeventos />,
      "small"
    );
  }

  componentWillUnmount() {
    this.props.setEventosLongoPrazo(false);
  }

  saveObjectPrinter = () => {
    let eventosPrinter = Object.assign({},this.props.eventosPorCampeonato);

    const {
      busca,
      exibirJogosDoDia,
      exibirProximaHora,
      idCampeonatos
    } = this.props.eventosPorCampeonato.campeonatosFiltros;

    const listaSemFiltros = !busca && !exibirJogosDoDia && !exibirProximaHora && !idCampeonatos.length;

    if (listaSemFiltros) {
      eventosPrinter.campeonatos = this.props.getEventosRaw()
    }

    localStorage.removeItem('eventosPrinter')
    localStorage.setItem('eventosPrinter', JSON.stringify(eventosPrinter));
  }


  renderAvisos = () => {
    const { aviso } = this.props.eventosPorCampeonato;

    return (
      <div className="inner-row-wrapper">
        <Aviso className="edge-top" type={aviso.type}> { aviso.text } </Aviso>
      </div>
    )
  }

  renderCarregarMaisButton = () => {
    const {
      busca,
      exibirJogosDoDia,
      exibirProximaHora,
      idCampeonatos
    } = this.props.eventosPorCampeonato.campeonatosFiltros;

    const listaSemFiltros = !busca && !exibirJogosDoDia && !exibirProximaHora && !idCampeonatos.length;

    if (listaSemFiltros) {
      return (
        <div className="row align-center edge-top">
          <div className="small-24 medium-12 large-10 columns">
            <ButtonRounded className="edge-top" onClick={this.props.carregarMaisEventos}>
              Carregar Mais Eventos
            </ButtonRounded>
          </div>
        </div>
      );
    }

    return null;
  }

  renderBanner = () => {
    if (this.props.bannerMedia.length) {
      return <BannerSlider media={this.props.bannerMedia} />;
    }

    return null;
  }

  render() {
    const { campeonatos, isFetching, aviso } = this.props.eventosPorCampeonato;
    const { exibirBotaoImprimir } = this.props;
    const { exibirJogosDoDia } = this.props.eventosPorCampeonato.campeonatosFiltros;

    return (
      <div>
        { this.renderBanner() }
        <div className="main">
          <div className="inner-row-wrapper">
            <Title title="Eventos Longo Prazo" openModalLegenda={this.openModalLegenda} saveObjectPrinter={this.saveObjectPrinter} showPrinterButton={exibirBotaoImprimir && !isFetching && exibirJogosDoDia} />
            {
              typeof aviso !== "undefined" ? this.renderAvisos() : isFetching || isFetching == null ?
              <LoaderCarregando className="edge-top" /> :
              (
                <div>
                  <Campeonatos campeonatos={campeonatos} />
                  { this.renderCarregarMaisButton() }
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

EventosLongoPrazo.contextTypes = {
  openModal: PropTypes.func
};

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    eventosPorCampeonato: state.eventosPorCampeonatoReducer,
    bannerMedia: state.bannerReducer.prematch.media,
    carrinho: state.carrinho,
    exibirBotaoImprimir: state.permissoesReducer.configuracoes.exibirBotaoImprimir,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventosLongoPrazo)
);
