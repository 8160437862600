// React
import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// React Router
import { withRouter } from "react-router-dom";

// Styles
import "./Nav.css";

// Components
import NavLink from "../Common/Link/NavLink";
import { checarPermissao } from "../../modules/helpers";

class Nav extends Component {
  render() {
    const { isFetching } = this.props.permissoesReducer;

    const {
      aoVivo: permissaoAoVivo,
      slot: permissaoJogos,
      bolao: permissaoBolao,
      promocoes: permissaoPromocoes
    } = this.props.permissoesReducer.permissoes;

    const { produtosPermitidos } = this.props.permissoesReducer;

    return (
      <div>
        {/* Small e Medium */}
        <nav
          className={
            `nav nav--small hide-for-large
            ${isFetching ? "nav--fetching" : ""}`
          }
        >
          <NavLink to="/" type="small" text="Esportes" permissao={true} />
          <NavLink to="/aovivo" type="small" text="Ao Vivo" permissao={permissaoAoVivo} />
          <NavLink to="/jogos" type="small" text="Slots" permissao={permissaoJogos} />
          <NavLink to="/lotericos" type="small" text="Lotéricos" permissao={checarPermissao(5, produtosPermitidos)} />
          <NavLink to="/tradicional" type="small" text="Tradicional" permissao={checarPermissao(7, produtosPermitidos)} />
          <NavLink to="/bolao" type="small" text="Bolão" permissao={permissaoBolao} />
          <NavLink to="/promocoes" type="small" text="Promoções" permissao={permissaoPromocoes} />
        </nav>

        {/* > Large */}
        <nav
          className={
            `nav nav--large show-for-large full-width
            ${isFetching ? "nav--fetching" : ""}`
          }
        >
          <div className="row align-middle">
            <NavLink to="/" type="large" text="Esportes" permissao={true} />
            <NavLink to="/aovivo" type="large" text="Ao Vivo" permissao={permissaoAoVivo} />
            <NavLink to="/jogos" type="large" text="Slots" permissao={permissaoJogos} />
            <NavLink to="/lotericos" type="large" text="Lotéricos" permissao={checarPermissao(5, produtosPermitidos)} />
            <NavLink to="/tradicional" type="large" text="Tradicional" permissao={checarPermissao(7, produtosPermitidos)} />
            <NavLink to="/bolao" type="large" text="Bolão" permissao={permissaoBolao} />
            <NavLink to="/promocoes" type="large" text="Promoções" permissao={permissaoPromocoes} />
          </div>
        </nav>
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = state => ({
  permissoesReducer: state.permissoesReducer,
  produtosPermitidos: state.permissoesReducer.produtosPermitidos
});

export default withRouter(
  connect(mapStateToProps)(Nav)
);
