import tradicionais from "./ItensLotericoTradicionalReducer";
import tradicionalAtual from "./LotericoTradicionalAtualReducer";
import {
    INIT_TRADICIONAIS,
    REQUEST_TRADICIONAIS,
    RECEIVE_TRADICIONAIS,
    ERROR_TRADICIONAIS,
    REQUEST_INICIAR_TRADICIONAL,
    RECEIVE_INICIAR_TRADICIONAL,
    RECEIVE_INICIAR_TRADICIONAL_MOBILE,
    ERROR_INICIAR_TRADICIONAL,
    INIT_TRADICIONAL_ATUAL
} from "../../actions/ActionTypes.js";

const initialState = {
    isFetching: false,
    aviso: null,
    tradicionais: tradicionais(undefined, {}),
    tradicionalAtual: tradicionalAtual(undefined, {})
};


export default function tradicionalReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_TRADICIONAIS:
            return initialState;

        case REQUEST_TRADICIONAIS:
            return {
                isFetching: true,
                aviso: null,
                tradicionais: tradicionais(undefined, {}),
                tradicionalAtual: tradicionalAtual(undefined, {})
            };

        case RECEIVE_TRADICIONAIS:
            return {
                isFetching: false,
                aviso: null,
                tradicionais: tradicionais(action.tradicionais, action),
                tradicionalAtual: tradicionalAtual(undefined, {})
            };

        case ERROR_TRADICIONAIS:
            return {
                isFetching: false,
                aviso: true, // Deve ser tratado ainda
                tradicionais: tradicionais(undefined, {}),
                tradicionalAtual: tradicionalAtual(undefined, {})
            };

        case INIT_TRADICIONAL_ATUAL:
        case REQUEST_INICIAR_TRADICIONAL:
        case RECEIVE_INICIAR_TRADICIONAL:
        case RECEIVE_INICIAR_TRADICIONAL_MOBILE:
        case ERROR_INICIAR_TRADICIONAL:
            return {
                ...state,
                tradicionalAtual: tradicionalAtual(undefined, action)
            };

        default:
            return state;
    }
};
