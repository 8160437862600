import {
  INIT_CONCLUIR_CADASTRO,
  REQUEST_CONCLUIR_CADASTRO_DADOS,
  RECEIVE_CONCLUIR_CADASTRO_DADOS,
  ERROR_CONCLUIR_CADASTRO_DADOS,
  REQUEST_CONCLUIR_CADASTRO_FORMULARIO,
  RECEIVE_CONCLUIR_CADASTRO_FORMULARIO,
  ERROR_CONCLUIR_CADASTRO_FORMULARIO,
} from "../../../actions/ActionTypes";

import dados from "./ConcluirCadastroDadosReducer";
import formulario from "./ConcluirCadastroFormularioReducer";

const initialState = {
  dados: dados(undefined, {}),
  formulario: formulario(undefined, {})
};

export default function concluirCadastro(state = initialState, action) {
  switch (action.type) {
    case INIT_CONCLUIR_CADASTRO:
      return initialState;

    case REQUEST_CONCLUIR_CADASTRO_DADOS:
    case RECEIVE_CONCLUIR_CADASTRO_DADOS:
    case ERROR_CONCLUIR_CADASTRO_DADOS:
      return {
        ...state,
        dados: dados(state.dados, action)
      };

    case REQUEST_CONCLUIR_CADASTRO_FORMULARIO:
    case RECEIVE_CONCLUIR_CADASTRO_FORMULARIO:
    case ERROR_CONCLUIR_CADASTRO_FORMULARIO:
      return {
        ...state,
        formulario: formulario(state.formulario, action)
      };

    default:
      return state;
  }
};
