// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../actions/Configuracoes/InserirCreditosActions";

// Helpers
import { convertIntegerToMoney } from "../../../../modules/helpers";


// Styles
import "../../ShareConfiguracoes.css";

// Components
import Card from "../../../Common/Card/Card";
// import InputTextSecondary from "../../../Common/Input/InputTextSecondary";
import ButtonSecondary from "../../../Common/Button/ButtonSecondary";
import LoaderCarregando from "../../../Common/Loader/LoaderCarregando";
import Aviso from "../../../Common/Aviso/Aviso";
import InputPin from "../../../Common/Input/InputPin";

class InserirCreditos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      pinValido: false
    };
  }

  componentWillUnmount() {
    this.props.initInserirCreditos();
  }

  atualizarCodigo = (e) => {
    const state = {...this.state};
    state.pin = e.target.value;
    state.pinValido = state.pin.indexOf("_") === -1;
    this.setState(state);
  }

  renderHeader = (saldo) => {
    return (
      <p className="padding-7 extrato__saldo-atual">
        <span className="text-subheader light-default">Saldo Atual</span>
        <span className="float-right text-title accent-1 text-right">
          { convertIntegerToMoney(saldo) }
        </span>
      </p>
    );
  }

  renderAviso() {
    const { aviso } = this.props.inserirCreditosReducer;

    if (aviso) {
      return (
        <Aviso className="edge-top" type={aviso.type}>
          { aviso.text }
        </Aviso>
      );
    }
  }

  render() {
    const { isFetching } = this.props.inserirCreditosReducer;
    const { saldo } = this.props.usuarioReducer.usuario.configuracao;

    return (
      <div className="configuracoes-inserir-creditos">
        <form
          className="row"
          onSubmit={(event) => {
            event.preventDefault();
            this.props.fetchPin(this.state.pin.replace(/\s/g, ""));
          }}
        >
          <div className="small-24 columns">
            <Card header={this.renderHeader(saldo)} className="card-block">
              <div className="row">
                <div className="small-24 medium-12 large-8 columns column-block">
                  <InputPin
                    name="pin"
                    onChange={this.atualizarCodigo}
                  />
                </div>
                <div className="small-24 medium-8 large-6 columns column-block">
                  <ButtonSecondary
                    type="submit"
                    disabled={!this.state.pinValido || isFetching}
                    setDisabled={!this.state.pinValido || isFetching}
                  >
                    Vincular
                  </ButtonSecondary>
                </div>
              </div>
            </Card>
          </div>
        </form>
        {
          isFetching ?
          <LoaderCarregando className="edge-top" /> :
          this.renderAviso()
        }
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    inserirCreditosReducer: state.inserirCreditosReducer,
    usuarioReducer: state.usuarioReducer
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InserirCreditos);
