import {
    INIT_LOTERICO_ATUAL,
    REQUEST_INICIAR_LOTERICO,
    RECEIVE_INICIAR_LOTERICO,
    RECEIVE_INICIAR_LOTERICO_MOBILE,
    ERROR_INICIAR_LOTERICO
} from "../../actions/ActionTypes.js";

const initialState = {
    isFetching: false,
    aviso: null,
    loterico: {}
};

export default function lotericoAtual(state = initialState, action) {
    switch (action.type) {
        case INIT_LOTERICO_ATUAL:
            return initialState;

        case REQUEST_INICIAR_LOTERICO:
            return {
                ...initialState,
                isFetching: true
            };

        case RECEIVE_INICIAR_LOTERICO:
            return {
                loterico: action.loterico
            };

        case RECEIVE_INICIAR_LOTERICO_MOBILE:
            return {
                loterico: action.loterico
            };

        case ERROR_INICIAR_LOTERICO:
            return {
                ...initialState,
                isFetching: false,
                aviso: action.error
            };

        default:
            return state;
    }
};
