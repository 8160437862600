// React
import React, {Component} from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as actions from "../../../../actions/Carrinho/CarrinhoActions";

import Card from "../../../Common/Card/Card";
import Cotacao from "../Cotacoes/Cotacoes";

class Modalidade extends Component {
  render () {
    const { modalidade, idCampeonato, eventoAoVivo, outright} = this.props;

    return (
      <div>
        <h2 className="text-title accent-1 edge-padding-8">
          {modalidade.titulo}
        </h2>
        <Card className="card-block">
          {
            modalidade.cotacoes.map((cotacao, index) => (
              <Cotacao
                key={index}
                cotacao={cotacao}
                idCampeonato={idCampeonato}
                eventoAoVivo={eventoAoVivo}
                outright={outright}
              />
            ))
          }
        </Card>
      </div>
    )
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {}
};

// Apenas Actions do Carrinho
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...actions}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modalidade);
