// Redux
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/rootReducer";

// Modules
import { loadState, saveState } from "./modules/localStorage";

// Libs
// import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { throttle } from "underscore";

import { createEpicMiddleware } from 'redux-observable';

import rootEpic from './epics';

// URLs do Gateway
export const API_ROOT = (
  process.env.REACT_APP_NODE_ENV === "development" ?
    "http://godin-gateway.dev.sptcode.com/web" :
    "http://gateway.sportingplay.info/web"
);

export const API_ROOT_ODIN = (
  process.env.REACT_APP_NODE_ENV === "development" ?
    "https://godin-gateway.dev.sptcode.com/web/r" :
    "http://gateway.sportingplay.info/web/r"
);

export const API_CRM = (
  process.env.REACT_APP_NODE_ENV === "development" ?
    "https://godin-gateway.dev.sptcode.com" :
    "http://gateway.sportingplay.info"
);

export const KEY_CLIENT_APP = 13;

const epicMiddleware = createEpicMiddleware(rootEpic);

export const configureStore = () => {
  const persistedState = loadState();

  // Debug pela extens�o redux-devtools-extension do Chrome
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    // () => {},
    persistedState,
    composeEnhancers(
      applyMiddleware(
        epicMiddleware,
        thunkMiddleware
      )
    )
  );

  // Salva dados do State no localStorage
  store.subscribe(throttle(() => {
    saveState({
      carrinho: store.getState().carrinho,
      eticketReducer: store.getState().eticketReducer,
      usuarioReducer: store.getState().usuarioReducer,
      comprovantesReducer: store.getState().comprovantesReducer,
      jogosReducer: store.getState().jogosReducer,
      lotericosReducer: store.getState().lotericosReducer,
      permissoesReducer: store.getState().permissoesReducer,
      helloBarReducer: store.getState().helloBarReducer
    });
  }, 1000));

  return store;
};

// Configura Locale do moment.js
moment.locale("pt-br");

export const store = configureStore();

