// OldSideNav
export const TOGGLE_SUBITENS = "TOGGLE_SUBITENS";
export const TOGGLE_FIXITENS = "TOGGLE_FIXITENS";
export const INIT_MODALIDADES = "INIT_MODALIDADES";
export const CHECK_CAMPEONATO_SIDEBAR = "CHECK_CAMPEONATO_SIDEBAR";
export const REMOVE_CHECK_CAMPEONATO_SIDEBAR = "REMOVE_CHECK_CAMPEONATO_SIDEBAR";
export const CHECK_PAIS_SIDEBAR = "CHECK_PAIS_SIDEBAR";
export const REMOVE_CHECK_PAIS_SIDEBAR = "REMOVE_CHECK_PAIS_SIDEBAR";

// Eventos por Campeonato
export const INIT_EVENTOS_POR_CAMPEONATO = "INIT_EVENTOS_POR_CAMPEONATO";
export const RECEIVE_EVENTOS_CAMPEONATO = "RECEIVE_EVENTOS_CAMPEONATO";
export const REQUEST_EVENTOS_CAMPEONATO = "REQUEST_EVENTOS_CAMPEONATO";
export const ADD_FILTROS_EVENTOS_CAMPEONATOS = "ADD_FILTROS_EVENTOS_CAMPEONATOS";
export const REMOVE_FILTRO_EVENTOS_CAMPEONATOS = "REMOVE_FILTRO_EVENTOS_CAMPEONATOS";
export const ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS = "ERROR_AVISO_EVENTOS_PREMATCH_CAMPEONATOS";
export const ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS = "ADD_FILTRO_SEARCH_EVENTOS_CAMPEONATOS";
export const CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO = "CLICK_EXIBIR_TODOS_EVENTOS_CAMPEONATO";
export const CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO = "CLICK_EXIBIR_EVENTOS_DO_DIA_CAMPEONATO";
export const CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO = "CLICK_EXIBIR_EVENTOS_PROXIMA_HORA_CAMPEONATO";
export const CAMPEONATO_EVENTOS_LONGO_PRAZO = "CAMPEONATO_EVENTOS_LONGO_PRAZO";
export const CAMPEONATO_EVENTOS_DISPUTA_INDIRETA = "CAMPEONATO_EVENTOS_DISPUTA_INDIRETA";

// Eventos AoVivo
export const INIT_EVENTOS_AOVIVO = "INIT_EVENTOS_AOVIVO";
export const REQUEST_EVENTOS_AOVIVO = "REQUEST_EVENTOS_AOVIVO";
export const RECEIVE_EVENTOS_AOVIVO = "RECEIVE_EVENTOS_AOVIVO";
export const ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS = "ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS";

// Configurações
export const SET_CONFIGURACAO_ACTIVE = "SET_CONFIGURACAO_ACTIVE";

// Carrinho
export const INIT_CARRINHO = "INIT_CARRINHO";
export const ADICIONAR_ITEM_CARRINHO = "ADICIONAR_ITEM_CARRINHO";
export const REMOVER_ITEM_CARRINHO = "REMOVER_ITEM_CARRINHO";
export const LIMPAR_CARRINHO = "LIMPAR_CARRINHO";
export const ATUALIZAR_VALOR_CARRINHO = "ATUALIZAR_VALOR_CARRINHO";
export const REQUEST_APOSTA_CARRINHO = "REQUEST_APOSTA_CARRINHO";
export const RECEIVE_APOSTA_CARRINHO = "RECEIVE_APOSTA_CARRINHO";
export const ERROR_CARRINHO = "ERROR_CARRINHO";
export const SUCCESS_CARRINHO = "SUCCESS_CARRINHO";
export const INIT_CUPOM_CARRINHO = "INIT_CUPOM_CARRINHO";
export const REQUEST_CUPOM_CARRINHO = "REQUEST_CUPOM_CARRINHO";
export const RECEIVE_CUPOM_CARRINHO = "RECEIVE_CUPOM_CARRINHO";
export const ERROR_CUPOM_CARRINHO = "ERROR_CUPOM_CARRINHO";

// Modalidades
export const REQUEST_MODALIDADES = "REQUEST_MODALIDADES";
export const RECEIVE_MODALIDADES = "RECEIVE_MODALIDADES";

// Cadastro PIN
export const INIT_CADASTRO_PIN = "INIT_CADASTRO_PIN";
export const REQUEST_CADASTRO_PIN = "REQUEST_CADASTRO_PIN";
export const RECEIVE_CADASTRO_PIN = "RECEIVE_CADASTRO_PIN";
export const ERROR_CADASTRO_PIN = "ERROR_CADASTRO_PIN";

// Cadastro Rápido
export const INIT_CADASTRO_RAPIDO = "INIT_CADASTRO_RAPIDO";
export const REQUEST_CADASTRO_RAPIDO_DADOS = "REQUEST_CADASTRO_RAPIDO_DADOS";
export const RECEIVE_CADASTRO_RAPIDO_DADOS = "RECEIVE_CADASTRO_RAPIDO_DADOS";
export const ERROR_CADASTRO_RAPIDO_DADOS = "ERROR_CADASTRO_RAPIDO_DADOS";
export const REQUEST_CADASTRO_RAPIDO_FORMULARIO = "REQUEST_CADASTRO_RAPIDO_FORMULARIO";
export const RECEIVE_CADASTRO_RAPIDO_FORMULARIO = "RECEIVE_CADASTRO_RAPIDO_FORMULARIO";
export const ERROR_CADASTRO_RAPIDO_FORMULARIO = "ERROR_CADASTRO_RAPIDO_FORMULARIO";

// Concluir Cadastro
export const INIT_CONCLUIR_CADASTRO = "INIT_CONCLUIR_CADASTRO";
export const REQUEST_CONCLUIR_CADASTRO_DADOS = "REQUEST_CONCLUIR_CADASTRO_DADOS";
export const RECEIVE_CONCLUIR_CADASTRO_DADOS = "RECEIVE_CONCLUIR_CADASTRO_DADOS";
export const ERROR_CONCLUIR_CADASTRO_DADOS = "ERROR_CONCLUIR_CADASTRO_DADOS";
export const REQUEST_CONCLUIR_CADASTRO_FORMULARIO = "REQUEST_CONCLUIR_CADASTRO_FORMULARIO";
export const RECEIVE_CONCLUIR_CADASTRO_FORMULARIO = "RECEIVE_CONCLUIR_CADASTRO_FORMULARIO";
export const ERROR_CONCLUIR_CADASTRO_FORMULARIO = "ERROR_CONCLUIR_CADASTRO_FORMULARIO";

// Cadastro Banner
export const INIT_CADASTRO_BANNER = "INIT_CADASTRO_BANNER";
export const REQUEST_CADASTRO_BANNER = "REQUEST_CADASTRO_BANNER";
export const RECEIVE_CADASTRO_BANNER = "RECEIVE_CADASTRO_BANNER";
export const ERROR_CADASTRO_BANNER = "ERROR_CADASTRO_BANNER";

// Usuário - Autenticação
export const INIT_LOGIN = "INIT_LOGIN";
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const LOGOUT = "LOGOUT";
export const ATUALIZAR_SALDO = "ATUALIZAR_SALDO";
export const VALIDAR_USUARIO = "VALIDAR_USUARIO";

// Usuário - Recuperar Senha
export const INIT_RECUPERAR_SENHA = "INIT_RECUPERAR_SENHA";
export const REQUEST_ENVIAR_CODIGO_SMS = "REQUEST_ENVIAR_CODIGO_SMS";
export const RECEIVE_ENVIAR_CODIGO_SMS = "RECEIVE_ENVIAR_CODIGO_SMS";
export const ERROR_ENVIAR_CODIGO_SMS = "ERROR_ENVIAR_CODIGO_SMS";
export const REQUEST_RECUPERAR_SENHA = "REQUEST_RECUPERAR_SENHA";
export const RECEIVE_RECUPERAR_SENHA = "RECEIVE_RECUPERAR_SENHA";
export const ERROR_RECUPERAR_SENHA = "ERROR_RECUPERAR_SENHA";

// Configurações - Apostas - Listar Bilhetes
export const INIT_APOSTAS = "INIT_APOSTAS";
export const REQUEST_BILHETES = "REQUEST_BILHETES";
export const RECEIVE_BILHETES = "RECEIVE_BILHETES";
export const ERROR_BILHETES = "ERROR_BILHETES";

// Configurações - Apostas - Cancelar Bilhete
export const REQUEST_PRECONSULTA_CASHOUT = "REQUEST_PRECONSULTA_CASHOUT";
export const RECEIVE_PRECONSULTA_CASHOUT = "RECEIVE_PRECONSULTA_CASHOUT";
export const REQUEST_CASHOUT = "REQUEST_CASHOUT";
export const RECEIVE_CASHOUT = "RECEIVE_CASHOUT";
export const ERROR_CASHOUT = "ERROR_CASHOUT";
export const ERROR_PRECONSULTA_CASHOUT = "ERROR_PRECONSULTA_CASHOUT";

// Configurações - Apostas - Cancelar Bilhete
export const REQUEST_CANCELAR_BILHETE = "REQUEST_CANCELAR_BILHETE";
export const RECEIVE_CANCELAR_BILHETE = "RECEIVE_CANCELAR_BILHETE";
export const ERROR_CANCELAR_BILHETE = "ERROR_CANCELAR_BILHETE";
export const SET_BILHETE_CANCELADO = "SET_BILHETE_CANCELADO";

// Configurações - Apostas - Eventos
export const TOGGLE_BILHETE = "TOGGLE_BILHETE";
export const REQUEST_EVENTOS = "REQUEST_EVENTOS";
export const RECEIVE_EVENTOS = "RECEIVE_EVENTOS";
export const ERROR_EVENTOS = "ERROR_EVENTOS";

// Configurações - Extrato
export const INIT_EXTRATO = "INIT_APOSTAS";
export const REQUEST_EXTRATO = "REQUEST_EXTRATO";
export const RECEIVE_EXTRATO = "RECEIVE_EXTRATO";
export const ERROR_EXTRATO = "ERROR_EXTRATO";

// Configurações - Saques
export const INIT_SAQUES = "INIT_SAQUES";
export const REQUEST_SAQUE = "REQUEST_SAQUE";
export const RECEIVE_SAQUE = "RECEIVE_SAQUE";
export const SUCCESS_SAQUE = "SUCCESS_SAQUE";
export const ERROR_SAQUE = "ERROR_SAQUE";
export const RECEIVE_SAQUES_ANTERIORES = "RECEIVE_SAQUES_ANTERIORES";
export const RECEIVE_INFO_SAQUE = "RECEIVE_INFO_SAQUE";

// Configurações - Inserir Créditos
export const INIT_INSERIR_CREDITOS = "INIT_INSERIR_CREDITOS";
export const REQUEST_PIN = "REQUEST_PIN";
export const RECEIVE_PIN = "RECEIVE_PIN";
export const SUCCESS_PIN = "SUCCESS_PIN";
export const ERROR_PIN = "ERROR_PIN";

// Configurações - Promoções
export const INIT_CUPONS = "INIT_CUPONS";
export const REQUEST_CUPONS = "REQUEST_CUPONS";
export const RECEIVE_CUPONS = "RECEIVE_CUPONS";
export const ERROR_CUPONS = "ERROR_CUPONS";
export const REQUEST_RESGATAR_CUPOM = "REQUEST_RESGATAR_CUPOM";
export const RECEIVE_RESGATAR_CUPOM = "RECEIVE_RESGATAR_CUPOM";
export const ERROR_RESGATAR_CUPOM = "ERROR_RESGATAR_CUPOM";

// Configurações - Cadastro - Validar Usuário
export const INIT_VALIDAR_USUARIO = "INIT_VALIDAR_USUARIO";
export const REQUEST_VALIDAR_USUARIO_SMS = "REQUEST_VALIDAR_USUARIO_SMS";
export const RECEIVE_VALIDAR_USUARIO_SMS = "RECEIVE_VALIDAR_USUARIO_SMS";
export const ERROR_VALIDAR_USUARIO_SMS = "ERROR_VALIDAR_USUARIO_SMS";
export const REQUEST_VALIDAR_USUARIO = "REQUEST_VALIDAR_USUARIO";
export const RECEIVE_VALIDAR_USUARIO = "RECEIVE_VALIDAR_USUARIO";
export const ERROR_VALIDAR_USUARIO = "ERROR_VALIDAR_USUARIO";

// Configurações - Cadastro - Alterar Senha
export const INIT_ALTERAR_SENHA = "INIT_ALTERAR_SENHA";
export const REQUEST_ALTERAR_SENHA = "REQUEST_ALTERAR_SENHA";
export const RECEIVE_ALTERAR_SENHA = "RECEIVE_ALTERAR_SENHA";
export const ERROR_ALTERAR_SENHA = "ERROR_ALTERAR_SENHA";

// Eticket
export const ADICIONAR_ETICKET = "ADICIONAR_ETICKET";
export const ADICIONAR_ETICKET_BOLAO = "ADICIONAR_ETICKET_BOLAO";
export const REMOVER_ETICKET = "REMOVER_ETICKET";

// Comprovantes
export const INIT_COMPROVANTES = "INIT_COMPROVANTES";
export const ADICIONAR_COMPROVANTE = "ADICIONAR_COMPROVANTE";

// ModalidadesEventos
export const INIT_MODALIDADES_EVENTOS_POR_CAMPEONATO = "INIT_MODALIDADES_EVENTOS_POR_CAMPEONATO";
export const REQUEST_MODALIDADES_EVENTOS_CAMPEONATO = "REQUEST_MODALIDADES_EVENTOS_CAMPEONATO";
export const RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO = "RECEIVE_MODALIDADES_EVENTOS_CAMPEONATO";
export const INIT_MODALIDADES_EVENTOS_AOVIVO = "INIT_MODALIDADES_EVENTOS_AOVIVO";
export const REQUEST_MODALIDADES_EVENTO_AOVIVO = "REQUEST_MODALIDADES_EVENTO_AOVIVO";
export const RECEIVE_MODALIDADES_EVENTOS_AOVIVO = "RECEIVE_MODALIDADES_EVENTOS_AOVIVO";
export const SUCCESS_AVISO_MODALIDADES_EVENTO_PREMATCH = "SUCCESS_AVISO_MODALIDADES_EVENTO_PREMATCH";
export const ERROR_AVISO_MODALIDADES_EVENTO_PREMATCH = "ERROR_AVISO_MODALIDADES_EVENTO_PREMATCH";
export const ERROR_AVISO_MODALIDADES_EVENTO_AOVIVO = "ERROR_AVISO_MODALIDADES_EVENTO_AOVIVO";
export const SUCCESS_AVISO_MODALIDADES_EVENTO_AOVIVO = "SUCCESS_AVISO_MODALIDADES_EVENTO_AOVIVO";

// Jogos (Leander)
export const INIT_JOGOS_LEANDER = "INIT_JOGOS_LEANDER";
export const REQUEST_JOGOS_LEANDER = "REQUEST_JOGOS_LEANDER";
export const RECEIVE_JOGOS_LEANDER = "RECEIVE_JOGOS_LEANDER";
export const ERROR_JOGOS_LEANDER = "ERROR_JOGOS_LEANDER";
export const INIT_JOGO_ATUAL = "INIT_JOGO_ATUAL";
export const REQUEST_INICIAR_JOGO = "REQUEST_INICIAR_JOGO";
export const RECEIVE_INICIAR_JOGO = "RECEIVE_INICIAR_JOGO";
export const RECEIVE_INICIAR_JOGO_MOBILE = "RECEIVE_INICIAR_JOGO_MOBILE";
export const ERROR_INICIAR_JOGO = "ERROR_INICIAR_JOGO";

// Lotéricos
export const INIT_LOTERICOS = "INIT_LOTERICOS";
export const REQUEST_LOTERICOS = "REQUEST_LOTERICOS";
export const RECEIVE_LOTERICOS = "RECEIVE_LOTERICOS";
export const ERROR_LOTERICOS = "ERROR_LOTERICOS";
export const INIT_LOTERICO_ATUAL = "INIT_LOTERICO_ATUAL";
export const REQUEST_INICIAR_LOTERICO = "REQUEST_INICIAR_LOTERICO";
export const RECEIVE_INICIAR_LOTERICO = "RECEIVE_INICIAR_LOTERICO";
export const RECEIVE_INICIAR_LOTERICO_MOBILE = "RECEIVE_INICIAR_LOTERICO_MOBILE";
export const ERROR_INICIAR_LOTERICO = "ERROR_INICIAR_LOTERICO";

// Permissões
export const INIT_PERMISSOES = "INIT_PERMISSOES";
export const REQUEST_PERMISSOES = "REQUEST_PERMISSOES";
export const RECEIVE_PERMISSOES = "RECEIVE_PERMISSOES";
export const ERROR_PERMISSOES = "ERROR_PERMISSOES";

// Banners
export const RECEIVE_BANNER_PREMATCH = "RECEIVE_BANNER_PREMATCH";
export const RECEIVE_BANNER_AO_VIVO = "RECEIVE_BANNER_AO_VIVO";
export const RECEIVE_BANNER_SLOT = "RECEIVE_BANNER_SLOT";
export const RECEIVE_BANNER_LOTERICO = "RECEIVE_BANNER_LOTERICO";
export const RECEIVE_BANNER_TRADICIONAL = "RECEIVE_BANNER_TRADICIONAL";

// Bolão - Lista
export const INIT_BOLAO = "INIT_BOLAO";
export const REQUEST_LISTA_DE_BOLOES = "REQUEST_LISTA_DE_BOLOES";
export const RECEIVE_LISTA_DE_BOLOES = "RECEIVE_LISTA_DE_BOLOES";
export const ERROR_LISTA_DE_BOLOES = "ERROR_LISTA_DE_BOLOES";

// Bolão - Detalhe
export const REQUEST_BOLAO_DETALHE = "REQUEST_BOLAO_DETALHE";
export const RECEIVE_BOLAO_DETALHE = "RECEIVE_BOLAO_DETALHE";
export const ERROR_BOLAO_DETALHE = "ERROR_BOLAO_DETALHE";
export const INIT_BOLAO_DETALHE = "INIT_BOLAO_DETALHE";

// Bolão - Ranking
export const REQUEST_BOLAO_RANKING = "REQUEST_BOLAO_RANKING";
export const RECEIVE_BOLAO_RANKING = "RECEIVE_BOLAO_RANKING";
export const ERROR_BOLAO_RANKING = "ERROR_BOLAO_RANKING";

// Bolão - Carrinho
export const INIT_CARRINHO_BOLAO = "INIT_CARRINHO_BOLAO";
export const ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE = "ADICIONAR_ITEM_CARRINHO_BOLAO_PALPITE";
export const ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA = "ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA";
export const ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS = "ADICIONAR_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS";
export const REMOVER_ITEM_CARRINHO_BOLAO_PALPITE = "REMOVER_ITEM_CARRINHO_BOLAO_PALPITE";
export const REMOVER_ITEM_CARRINHO_BOLAO_COLUNA = "REMOVER_ITEM_CARRINHO_BOLAO_COLUNA";
export const REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS = "REMOVER_ITEM_CARRINHO_BOLAO_COLUNA_MULTIPLAS";
export const LIMPAR_CARRINHO_BOLAO = "LIMPAR_CARRINHO_BOLAO";
export const REQUEST_APOSTA_CARRINHO_BOLAO = "REQUEST_APOSTA_CARRINHO_BOLAO";
export const RECEIVE_APOSTA_CARRINHO_BOLAO = "RECEIVE_APOSTA_CARRINHO_BOLAO";
export const ERROR_CARRINHO_BOLAO = "ERROR_CARRINHO_BOLAO";
export const SUCCESS_CARRINHO_BOLAO = "SUCCESS_CARRINHO_BOLAO";

// Hello Bar
export const INIT_HELLO_BAR = "INIT_HELLO_BAR";
export const SHOW_HELLO_BAR = "SHOW_HELLO_BAR";
export const HIDE_HELLO_BAR = "HIDE_HELLO_BAR";

// Consulta Bilhete
export const INIT_CONSULTA_BILHETE = "INIT_CONSULTA_BILHETE";
export const REQUEST_CONSULTA_BILHETE = "REQUEST_CONSULTA_BILHETE";
export const RECEIVE_CONSULTA_BILHETE = "RECEIVE_CONSULTA_BILHETE";
export const ERROR_CONSULTA_BILHETE = "ERROR_CONSULTA_BILHETE";

// Tradicional
export const INIT_TRADICIONAIS = "INIT_TRADICIONAIS";
export const REQUEST_TRADICIONAIS = "REQUEST_TRADICIONAIS";
export const RECEIVE_TRADICIONAIS = "RECEIVE_TRADICIONAIS";
export const ERROR_TRADICIONAIS = "ERROR_TRADICIONAIS";
export const INIT_TRADICIONAL_ATUAL = "INIT_TRADICIONAL_ATUAL";
export const REQUEST_INICIAR_TRADICIONAL = "REQUEST_INICIAR_TRADICIONAL";
export const RECEIVE_INICIAR_TRADICIONAL = "RECEIVE_INICIAR_TRADICIONAL";
export const RECEIVE_INICIAR_TRADICIONAL_MOBILE = "RECEIVE_INICIAR_TRADICIONAL_MOBILE";
export const ERROR_INICIAR_TRADICIONAL = "ERROR_INICIAR_TRADICIONAL";
