// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Carrinho/CarrinhoActions";

// React Router
import { withRouter } from "react-router-dom";

// Styles
import "./Carrinho.css";

// Components
import ItensCarrinho from "./ItensCarrinho/ItensCarrinho";
import Footer from "./Footer/Footer";
import Aviso from "../../Common/Aviso/Aviso";

const CarrinhoContainer = ({ children }) => (
  <div className="carrinho">
    <div className="inner-row-wrapper">
      <div className="row">
        <div className="small-24 columns">
          { children }
        </div>
      </div>
    </div>
  </div>
);

/*
* TODO: Refatorar esse Carrinho em componentes distribuídos e
* comentar uma explicação compreensível pelo cérebro humano
* acerca desses avisos de erro, sucesso e default.
*/
class Carrinho extends Component {
  componentWillMount() {
    this.props.initCarrinho();
  }

  renderCarrinho = (itens) => (
    <div>
      <ItensCarrinho itens={itens} />
      <Footer />
    </div>
  )

  renderAviso = (aviso) => {
    if (aviso.type !== "success") {
      return (
        <Aviso type="default">
          Adicione um Evento ao Carrinho
        </Aviso>
      );
    }

    return null;
  }

  successOrError = (aviso, itens) => {
    if (aviso.type === "error" && itens.length) {
      return (
        <Aviso className="edge-top" type={aviso.type}>
          { aviso.text }
        </Aviso>
      );
    }

    if (aviso.type === "success" && !itens.length) {
      return (
        <Aviso type={aviso.type}>
          { aviso.text }
        </Aviso>
      );
    }

    return null;
  }

  render() {
    const { itens, aviso } = this.props.carrinho;

    return (
      <CarrinhoContainer>
        <h1 className="text-display edge-bottom light-default">Suas Apostas</h1>
        {
          itens.length > 0 ? this.renderCarrinho(itens) : this.renderAviso(aviso)
        }
        { this.successOrError(aviso, itens) }
      </CarrinhoContainer>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {...state}
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { initCarrinho: actions.initCarrinho },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Carrinho)
);
