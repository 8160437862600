// Action Types
import {
  INIT_INSERIR_CREDITOS,
  REQUEST_PIN,
  RECEIVE_PIN,
  SUCCESS_PIN,
  ERROR_PIN
} from "../ActionTypes";

// Modules
import { doOdinRequest } from "../../modules/request";

// Actions
import { fetchSaldo } from "../Usuario/UsuarioActions";

export const initInserirCreditos = () => ({
  type: INIT_INSERIR_CREDITOS
});

export const requestPin = () => ({
  type: REQUEST_PIN
});

export const receivePin = (bilhetes) => ({
  type: RECEIVE_PIN,
  bilhetes
});

export const successPin = (success) => ({
  type: SUCCESS_PIN,
  success
});

export const errorPin = (error) => ({
  type: ERROR_PIN,
  error
});

export const fetchPin = (codigo) => (dispatch, getState) => {
  dispatch(requestPin());

  const accessToken = getState().usuarioReducer.usuario.accessToken;

  const params = {
    url: "/sp/pin/vincular/",
    method: "POST",
    accessToken: accessToken,
    data: {
      pin: codigo
    }
  };

  return doOdinRequest(params)
    .then(response => {
      // Atualiza saldo do apostador após creditar o Pin
      dispatch(fetchSaldo());
      dispatch(successPin(response.mensagem));
      dispatch(receivePin());
    })
    .catch(error => {
      dispatch(errorPin(error));
      dispatch(receivePin());
    });
};
