// Referência:
// http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
export const formatMoney = function (a, n, x, s, c) {
  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
  let num = a.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

// Transforma valor de float para String formatada em dinheiro
export const convertFloatToMoney = (valor) => {
  return formatMoney(valor, 2, 3, ".", ",");
};

// Divide o Inteiro por 100 e converte em String formatada em dinheiro
// (Útil para exibir valores no Carrinho, Apostas, Extrato, Saques e etc.)
export const convertIntegerToMoney = (valor) => {
  return formatMoney((valor / 100), 2, 3, ".", ",");
};

// Divide o Inteiro por 100 e converte para Float com 2 casas decimais
// (Útil para exibir as Cotações)
export const convertIntegerToFloat = (valor) => {
  return (valor / 100).toFixed(2);
};

// Remove os hífens das datas no formato "YYYY-MM-DD"
// (Útil para enviar datas para o Odin, em Apostas, Extrato e etc.)
export const formatStringDateToOdin = (dataString) => {
  return dataString.replace(/-/g, "");
};

// Separa o código do E-Ticket / PIN / Autorização de acordo com
// a quantidade passada por parâmetro.
export const splitCodigo = (codigo, qtdSeparador) => {
  const regExp = new RegExp(`.{${qtdSeparador - 1},${qtdSeparador}}`, "g");
  return codigo.match(regExp).join(" ");
}

// Gera caracteres aleatórios para o E-Ticket.
// Referência: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export const makeTicket = () => {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  for (let i = 0; i < 12; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

// Formatação de datas do Odin para objetos Date() em JS
export const formatOdinDateToDateJs = (dataOdin) => {
  if (typeof dataOdin === 'undefined' || dataOdin == null) {
    return new Date();
  }
  else {
    return new Date(
      parseInt(dataOdin.slice(0, 4), 10),
      parseInt(dataOdin.slice(4, 6), 10) - 1,
      parseInt(dataOdin.slice(6, 8), 10),
      parseInt(dataOdin.slice(8, 10), 10),
      parseInt(dataOdin.slice(10, 12), 10),
      parseInt(dataOdin.slice(12, 14), 10)
    );
  }
};

export const formatOdinDate2ToDateJs = (dataOdin) => {
  if (typeof dataOdin === 'undefined' || dataOdin == null) {
    return new Date();
  }
  else {
    return new Date(
      parseInt(dataOdin.slice(0, 4), 10),
      parseInt(dataOdin.slice(4, 6), 10) - 1,
      parseInt(dataOdin.slice(6, 8), 10)
    );
  }
};

// Script responsável por varrer os estilos e remover os eventos
// de hover no mobile.
// Referência: https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices
export const disableHoverOnMobile = () => {
  const touch = 'ontouchstart' in document.documentElement
    || navigator.maxTouchPoints > 0
    || navigator.msMaxTouchPoints > 0;

  if (touch) { // remove all :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (let si in document.styleSheets) {
        const styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;

        for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;

          if (styleSheet.rules[ri].selectorText.match(':hover')) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    } catch (ex) { }
  }
};

// Detecta se dispositivo é mobile ou desktop
// (Útil na renderização dos iframes dos slots)
export const getDeviceType = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return "mobile";
  }

  return "desktop";
};

// Detecta se é dispositivo iOS
export const iOSDevice = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

// Máscara de 9 números para telefone
// (Útil no Login por Telefone)
export const formatTelefone = (telefone) => {
  return `(${telefone.substr(0, 2)}) ${telefone.substr(2, 5)}-${telefone.substr(7, 9)}`;
};

// Callback para permitir que apenas números sejam inseridos no input
// (Útil para os inputs do Bolão por palpite)
export const onlyAllowNumbers = (e) => {
  if (!(e.charCode >= 48 && e.charCode <= 57)) {
    e.preventDefault();
  }
};

// Abre nova janela de popup no centro da tela
// (Útil para abrir o popup de estatísticas)
// https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
export const popupCenter = (url, title, w, h) => {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

  const left = ((width / 2) - (w / 2)) + dualScreenLeft;
  const top = ((height / 2) - (h / 2)) + dualScreenTop;
  const newWindow = window.open(url, title, `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`);

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

export const getDataMetadaContato = (contatos, tipo) => {
  for (const i in contatos) {
    if (contatos[i].tipo === tipo) {
      switch (contatos[i].tipo) {
        case 1:
          const metadata = contatos[i].metadata;
          const link = 'https://wa.me/' + metadata.numero;
          return link;
        default:
          break;
      }
    }
  }

  return '';
};

/**
 * Checa se o usuário logado tem permissão suficiente 
 * para acessar o produto. Aceita dois parâmetros, um
 * número e um array de números. Retorna true se a 
 * permissão fornecida estiver no array e false se 
 * não estiver.
 * 
 * Permissões:
 * 
 * 1 - Bolão
 * 
 * 2 - PIN
 * 
 * 3 - E-Ticket
 * 
 * 4 - Slot
 * 
 * 5 - Lotéricos
 * 
 * 6 - Surpresinha
 * 
 * 7 - Tradicional
 * 
 * @param {number} num - Número da permissão. 
 * @param {Array.<number>} permissoes - Array de permissões.
 * @returns {boolean} Se o usuário tem permissão suficiente. 
 */
export const checarPermissao = (num, permissoes) => permissoes.includes(num);