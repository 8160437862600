import {
  INIT_VALIDAR_USUARIO,
  REQUEST_VALIDAR_USUARIO_SMS,
  RECEIVE_VALIDAR_USUARIO_SMS,
  ERROR_VALIDAR_USUARIO_SMS,
  REQUEST_VALIDAR_USUARIO,
  RECEIVE_VALIDAR_USUARIO,
  ERROR_VALIDAR_USUARIO
} from "../../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: null,
  codigoEnviado: false,
  validado: false
};

export default function validarUsuarioReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_VALIDAR_USUARIO:
      return initialState;

    case REQUEST_VALIDAR_USUARIO_SMS:
      return {
        ...state,
        aviso: null,
        isFetching: true
      };

    case RECEIVE_VALIDAR_USUARIO_SMS:
      return {
        ...state,
        isFetching: false,
        codigoEnviado: true
      };

    case REQUEST_VALIDAR_USUARIO:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_VALIDAR_USUARIO:
      return {
        ...initialState,
        validado: true
      };

    case ERROR_VALIDAR_USUARIO_SMS:
    case ERROR_VALIDAR_USUARIO:
      return {
        ...state,
        isFetching: false,
        aviso: action.error
      };

    default:
      return state;
  }
};
