// React
import React, { Component } from "react";

// React Router
import { withRouter } from "react-router-dom";

// Componentes
import BolaoMain from "../../Main/Bolao/";
import Nav from "../../Nav/Nav";
import BolaoSideNav from "../../SideNav/BolaoSideNav";
import Aside from "../../Aside/Aside";
import Header from "../../Header/Header";

class AppBolao extends Component {
  render() {
    return (
      <div>
        <Header />
        <Nav />
        <div className="row-wrapper">
          <div className="row">
            <BolaoSideNav className="show-for-xlarge" />
            <BolaoMain />
            <Aside className="show-for-xlarge" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AppBolao);
