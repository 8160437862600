// React
import React, { Component } from "react";
import { connect } from "react-redux";

// Componentes
import Nav from "../../Nav/Nav";
import Header from "../../Header/Header";
import Lotericos from "../../Lotericos/Lotericos"

class AppLotericos extends Component {
    render() {
        const { exibirEsportes } = this.props.configuracoes
        return (
            <div>
                <Header />
                {exibirEsportes && <Nav />}
                <div className="row-wrapper">
                    <div className="row">
                        <Lotericos />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    configuracoes: state.permissoesReducer.configuracoes
})

export default connect(mapStateToProps, null)(AppLotericos)