import {
  RECEIVE_CUPONS,
  REQUEST_RESGATAR_CUPOM,
  RECEIVE_RESGATAR_CUPOM,
  ERROR_RESGATAR_CUPOM
} from "../../../actions/ActionTypes.js";

export default function cupons(state = [], action) {
  switch (action.type) {
    case RECEIVE_CUPONS:
      return action.cupons.map(itemCupom => cupom(itemCupom, action));

    case REQUEST_RESGATAR_CUPOM:
    case RECEIVE_RESGATAR_CUPOM:
    case ERROR_RESGATAR_CUPOM:
      const index = state.findIndex(cupom => cupom.idVoucher === action.idVoucher);

      return [
        ...state.slice(0, index),
        cupom(state[index], action),
        ...state.slice(index + 1)
      ];

    default:
      return state;
  }
}

const initialState = {
  isFetchingResgatar: false,
  titulo: "",
  descricao: "",
  validade: new Date(),
  mercado: "",
  status: "",
  resgatado: false,
  idVoucher: ""
};

function cupom(state = initialState, action) {
  switch (action.type) {
    // Enquanto back-end não definir a transação, vai ficar repetido assim mesmo
    case RECEIVE_CUPONS:
      return {
        ...initialState,
        titulo: state.titulo,
        descricao: state.descricao,
        validade: state.validade,
        mercado: state.mercado,
        status: state.status,
        resgatado: state.resgatado,
        idVoucher: state.idVoucher
      };

    case REQUEST_RESGATAR_CUPOM:
      return {
        ...state,
        isFetchingResgatar: true
      };

    case RECEIVE_RESGATAR_CUPOM:
      return {
        ...state,
        isFetchingResgatar: false,
        resgatado: true
      };

    case ERROR_RESGATAR_CUPOM:
      return {
        ...state,
        isFetchingResgatar: false
      };

    default:
      return state;
  }
};
