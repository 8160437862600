import {
  INIT_MODALIDADES,
  TOGGLE_SUBITENS,
  TOGGLE_FIXITENS,
  REQUEST_MODALIDADES,
  RECEIVE_MODALIDADES,
  CHECK_CAMPEONATO_SIDEBAR,
  REMOVE_CHECK_CAMPEONATO_SIDEBAR,
  CHECK_PAIS_SIDEBAR,
  REMOVE_CHECK_PAIS_SIDEBAR
} from "../../actions/ActionTypes.js";
import modalidade from "./ModalidadeReducer.js";

const modalidadesInitialState = {
  isFetching : false,
  modalidades : []
};


export default function modalidadesReducer(state = modalidadesInitialState, action) {
  switch (action.type) {
    case REQUEST_MODALIDADES:
    return {
      ...state,
      isFetching : true
    }
    // case RECEIVE_MODALIDADES:
    case INIT_MODALIDADES:
      return {
        isFetching : false,
        modalidades : action.modalidades
      }
    case RECEIVE_MODALIDADES:
      return {
        ...state,
        isFetching : false,
        modalidades: action.modalidades.map(item => modalidade(item, action))
      }
    case TOGGLE_SUBITENS:
    {
      return {
        ...state,
        modalidades: state.modalidades.map(item => modalidade(item, action))
      }
    }

    case CHECK_CAMPEONATO_SIDEBAR:
    case REMOVE_CHECK_CAMPEONATO_SIDEBAR:
    case CHECK_PAIS_SIDEBAR:
    case REMOVE_CHECK_PAIS_SIDEBAR:
    case TOGGLE_FIXITENS:
    {
      const index = state.modalidades.findIndex(modalidade => modalidade.id === action.idModalidade);

      return {
        ...state,
        modalidades : [
          ...state.modalidades.slice(0, index),
          modalidade(state.modalidades[index], action),
          ...state.modalidades.slice(index + 1),
        ]
      }
    }
    default:
      return state;
  }
};
