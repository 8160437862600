// React
import React from "react";

export const SideNavList = ({ children }) => (
  <nav className="sidenav-list">
    <ul>
      { children }
    </ul>
  </nav>
);

export default SideNavList;
