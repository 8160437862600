// React
import React, { Component } from "react";

// Libs
import moment from "moment";

// Components
import Card from "../../Common/Card/Card";

// Helpers
import { convertIntegerToFloat, formatOdinDateToDateJs } from "../../../modules/helpers";

// Constantes que definem o tipo do Card
export const EVENTO_BILHETE = "EVENTO_BILHETE";
export const EVENTO_ETICKET = "EVENTO_ETICKET";
export const EVENTO_BOLAO = "EVENTO_BOLAO";
export const EVENTO_BOLAO_CONSULTA = "EVENTO_BOLAO_CONSULTA";

const Badge = ({ cotacao }) => (
  <div className="small-4 medium-3 large-2 columns">
    <div className="card-item-carrinho__badge text-center">
      <span className="text-title light-secondary">
        { convertIntegerToFloat(cotacao) }
      </span>
    </div>
  </div>
);

const Title = ({ aposta, ...props }) => (
  <div className="small-17 columns" {...props}>
    <h3 className="card-item-carrinho__title text-title accent-1">
      { aposta }
    </h3>
  </div>
);

const Header = ({ type, cotacao, aposta }) => {
  if (type === "bolao") {
    return (
      <div className="row align-middle">
        <Title aposta={aposta} style={{ padding: "0.7rem 1.2rem" }} />
      </div>
    );
  }
  else {
    return (
      <div className="row align-middle">
        <Badge cotacao={cotacao} />
        <Title aposta={aposta} />
      </div>
    );
  }
};

const InfoEvento = ({ label, valor }) => (
  <div className="row align-middle">
    <div className="small-12 columns column-block">
      <p className="text-title light-secondary">{ label }</p>
    </div>
    <div className="small-12 columns column-block text-right">
      <p className="text-title light-default">{ valor }</p>
    </div>
  </div>
);

export default class CardEventoAposta extends Component {
  renderEventoBilhete(evento, bilhetePerdido) {
    let nomeStatus = evento.nomeStatus;

    if (
      bilhetePerdido &&
      evento.nomeStatus === "Aguardando Resultado"
    ) {
      nomeStatus = "Não Processado - Bilhete Perdido";
    }

    const momentoEvento = moment(formatOdinDateToDateJs(evento.momentoEvento))
      .format("DD/MM/YYYY [-] HH:mm:ss");

    return (
      <div>
        <InfoEvento label="Data / Hora Evento" valor={momentoEvento} />
        <InfoEvento label="Evento" valor={evento.tituloEvento} />
        <InfoEvento label="Tipo Aposta" valor={evento.tituloSubEvento} />
        <InfoEvento label="Status" valor={nomeStatus} />
      </div>
    );
  }

  renderEventoEticket(evento) {
    // TODO: uma nova transação será necessária para exibir
    // mais informações dos E-Tickets

    // const momentoEvento = moment(new Date(evento.momento))
    //   .format("DD/MM/YYYY [-] HH:mm:ss");

    return (
      <div>
        {/* <InfoEvento label="Data / Hora Evento" valor={momentoEvento} /> */}
        <InfoEvento label="Evento" valor={`${evento.casa} X ${evento.fora}`} />
        <InfoEvento label="Tipo Aposta" valor={evento.subevento.titulo} />
      </div>
    );
  }

  renderEventoBolao(evento) {
    return (
      <InfoEvento label="Evento" valor={`${evento.detalhes.casa} X ${evento.detalhes.fora}`} />
    );
  }

  renderEventoBolaoConsulta(evento, bilhetePerdido) {
    let nomeStatus = evento.nomeStatus;

    if (
      bilhetePerdido &&
      evento.nomeStatus === "Aguardando Resultado"
    ) {
      nomeStatus = "Não Processado - Bilhete Perdido";
    }

    return (
      <div>
        <InfoEvento label="Evento" valor={evento.tituloEvento} />
        <InfoEvento label="Status" valor={nomeStatus} />
      </div>
    );
  }

  renderHeader = () => {
    const { evento, type } = this.props;

    if (type === EVENTO_BOLAO) {
      return (
        <Header
          type={"bolao"}
          aposta={evento.detalhes.resultado}
        />
      );
    }

    if (type === EVENTO_BOLAO_CONSULTA) {
      return (
        <Header
          type={"bolao"}
          aposta={evento.resultadoFormatado}
        />
      );
    }

    const cotacao = type === EVENTO_BILHETE ? evento.cotacao : evento.subevento.cotacao;
    const aposta = type === EVENTO_BILHETE ? evento.resultadoFormatado : evento.subevento.aposta;

    return (
      <Header
        cotacao={cotacao}
        aposta={aposta}
      />
    );
  }

  render() {
    const { evento, bilhetePerdido, type } = this.props;
    const header = this.renderHeader();

    return (
      <Card className="card-evento-aposta card-block" header={header}>
        { type === EVENTO_BILHETE && this.renderEventoBilhete(evento, bilhetePerdido) }
        { type === EVENTO_ETICKET && this.renderEventoEticket(evento) }
        { type === EVENTO_BOLAO && this.renderEventoBolao(evento) }
        { type === EVENTO_BOLAO_CONSULTA && this.renderEventoBolaoConsulta(evento, bilhetePerdido) }
      </Card>
    );
  }
};
