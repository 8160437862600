// React
import React, { Component } from "react";

// React Router
import { withRouter } from "react-router-dom";

// Styles
import "./Banner.css";

// Components
import Slider from "react-slick";

class BannerSlider extends Component {
  // Docs: https://github.com/akiran/react-slick
  getSliderSettings = (bannerLength) => {
    const settings = {
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      infinite: false,
      // adaptiveHeight tava bugando a altura do Slider (1px)
      // adaptiveHeight: true
    };

    if (bannerLength > 1) {
      settings.infinite = true;
      settings.arrows = true;
      settings.autoplay = true;
      settings.autoplaySpeed = 5000;
      settings.pauseOnHover = true;
    }

    return settings;
  }

  renderImageSource(image, index) {
    switch (image.size) {
      case "small":
        return (
          <source
            key={index}
            srcSet={image.url}
            media={`(max-width: ${image.width || "480px"})`}
          />
        );

      case "medium":
        return (
          <source
            key={index}
            srcSet={image.url}
            media={`(max-width: ${image.width || "768px"})`}
          />
        );

      default:
        return <img key={index} src={image.url} alt="Banner" />;
    }
  }

  render() {
    const { media } = this.props;

    // OBS: tem que ser feito com map mesmo, pois essa lib lixo
    // buga quando você cria um componente que retorna um <picture>
    return (
      <Slider {...this.getSliderSettings(media.length)} className="edge-bottom">
        {
          media.map((banner, index) => (
            <picture key={index} onClick={() => this.props.history.push(banner.link)}>
              {
                banner.images.map((image, index) => {
                  return this.renderImageSource(image, index);
                  // if (image.width) {
                    // return <source
                    //   key={index}
                    //   srcSet={image.url}
                    //   media={`(max-width: ${image.width})`}
                    // />;
                  // }

                  // return <img key={index} src={image.url} alt="Banner" />;
                })
              }
            </picture>
          ))
        }
      </Slider>
    );
  }
};

export default withRouter(BannerSlider);
