import {
  INIT_EVENTOS_AOVIVO,
  ADICIONAR_ITEM_CARRINHO,
  REMOVER_ITEM_CARRINHO,
  LIMPAR_CARRINHO,
  REQUEST_EVENTOS_AOVIVO,
  RECEIVE_EVENTOS_AOVIVO,
  ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS
} from "../../actions/ActionTypes.js";

import { campeonato } from "./EventosPorCampeonatoReducer";
import aviso from "./AvisosEventos";


const eventosAoVivoInitialState = {
  isFetching : null,
  campeonatos : [],
  aviso: undefined
}

export default function eventosAoVivoReducer(state = eventosAoVivoInitialState, action) {
  switch (action.type) {
    case REQUEST_EVENTOS_AOVIVO:
    return {
      ...state,
      isFetching : true,
      aviso: undefined
    }
    case INIT_EVENTOS_AOVIVO:
      return eventosAoVivoInitialState;
    case RECEIVE_EVENTOS_AOVIVO:
      return {
        ...state,
        isFetching : false,
        campeonatos: action.campeonatos.map(item => campeonato(item, action))
      }


    case ADICIONAR_ITEM_CARRINHO:
    case REMOVER_ITEM_CARRINHO:
    case LIMPAR_CARRINHO:
      return {
        ...state,
        campeonatos: state.campeonatos.map(item => campeonato(item, action))
      }
    case ERROR_AVISO_EVENTOS_AOVIVO_CAMPEONATOS:
      return {
        ...state,
        isFetching: false,
        aviso: aviso(state.aviso, action)
      };


    default:
      return state;
  }
}
