// React
import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions/Usuario/UsuarioActions";
import { initHelloBar, showHelloBar } from "../../../actions/HelloBar/HelloBarActions";

// React Router
import { withRouter } from "react-router-dom";

// Libs
import { CSSTransitionGroup } from "react-transition-group";

// Components
import Topo from "./Topo.js";
import LoginForm from "./LoginForm.js";
import UserInfo from "./UserInfo";
import { OPEN_MODAL_CADASTRO } from "../../Common/HelloBar/HelloBar";
import Download from "../../../static/svg/Download.js";
import Sms from "../../../static/svg/Sms.js";

// Helpers
import { formatTelefone, getDataMetadaContato } from "../../../modules/helpers";

class Usuario extends Component {
  componentWillUpdate (nextProps, nextState) {
    const { usuarioReducer, loginPorTelefone } = this.props;
    const { usuarioReducer: nextUsuarioReducer } = nextProps;

    // Se for login por telefone e usuário não estiver validado,
    // exibe HelloBar sugerindo validação.
    if (
      loginPorTelefone &&
      (!usuarioReducer.logado && nextUsuarioReducer.logado)
    ) {
      if (!nextUsuarioReducer.usuario.validado) {
        this.renderHelloBar();
      }
    }

    // Init na HelloBar ao fazer logout
    if (usuarioReducer.logado && !nextUsuarioReducer.logado) {
      this.props.initHelloBar();
    }
  }

  renderHelloBar = () => {
    const helloBar = {
      texto: "Seu cadastro ainda não está completo. Clique aqui e valide seu telefone",
      acao: OPEN_MODAL_CADASTRO
    };

    this.props.showHelloBar(helloBar);
  }

  renderUsuario () {
    const { permissaoLogin, usuarioReducer, fetchLogin, loginPorTelefone } = this.props;

    if (!permissaoLogin) return null;

    if (usuarioReducer.logado) {
      const nome = loginPorTelefone ?
        formatTelefone(usuarioReducer.usuario.nome) :
        usuarioReducer.usuario.nome;

      return (
        <UserInfo
          nome={nome}
          saldo={usuarioReducer.usuario.configuracao.saldo}
        />
      );
    }

    // LoginForm só é exibido em > Large
    return (
      <LoginForm
        onSubmit={fetchLogin}
        isFetching={usuarioReducer.isFetching}
        error={usuarioReducer.aviso}
      />
    );
  }

  render () {
    const { usuarioLogado, linkAplicativo, contatos } = this.props;

    return (
      <div className="small-5 medium-3 large-16 xlarge-12 columns">
        <div className="row">
          {(!usuarioLogado && contatos.length > 0) && (
            <div style={{ width: '3.2rem' }} className="hide-for-large small-12 columns">
              <a href={getDataMetadaContato(contatos, 1)}>
                <Sms />
              </a>
            </div>
          )}
          {(!usuarioLogado && !!linkAplicativo) && (
            <div style={{ width: '3.2rem' }} className="hide-for-large small-12 columns">
              <a href={linkAplicativo}>
                <Download />
              </a>
            </div>
          )}
        </div>
        <Topo />
        <CSSTransitionGroup
          transitionName="fetching-permissoes"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {this.renderUsuario()}
        </CSSTransitionGroup>
      </div>
    );
  }
}

// --- Container Logic --- //
const mapStateToProps = (state) => {
  return {
    loginPorTelefone: state.permissoesReducer.configuracoes.loginPorTelefone,
    permissaoLogin: state.permissoesReducer.permissoes.pin,
    usuarioReducer: state.usuarioReducer,
    usuarioLogado: state.usuarioReducer.logado,
    linkAplicativo: state.permissoesReducer.configuracoes.linkAplicativo,
    contatos: state.permissoesReducer.configuracoes.contatos,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ...actions,
    initHelloBar,
    showHelloBar
  }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Usuario)
);
