import {
  INIT_CUPONS,
  REQUEST_CUPONS,
  RECEIVE_CUPONS,
  ERROR_CUPONS,
  REQUEST_RESGATAR_CUPOM,
  RECEIVE_RESGATAR_CUPOM,
  ERROR_RESGATAR_CUPOM
} from "../../../actions/ActionTypes.js";

import cupons from "./CupomReducer";

const initialState = {
  isFetching: false,
  aviso: "",
  cupons: cupons(undefined, {})
};

export default function promocoesReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_CUPONS:
      return initialState;

    case REQUEST_CUPONS:
      return {
        ...state,
        isFetching: true,
        aviso: null,
        cupons: []
      };

    case RECEIVE_CUPONS:
      return {
        ...state,
        isFetching: false,
        aviso: null,
        cupons: cupons(state.cupons, action)
      };

    case ERROR_CUPONS:
      return {
        ...initialState,
        isFetching: false,
        aviso: action.error
      };

    case REQUEST_RESGATAR_CUPOM:
    case RECEIVE_RESGATAR_CUPOM:
    case ERROR_RESGATAR_CUPOM:
      return {
        ...state,
        cupons: cupons(state.cupons, action)
      }

    default:
      return state;
  }
};
