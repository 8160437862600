import {
  INIT_SAQUES,
  REQUEST_SAQUE,
  RECEIVE_SAQUE,
  SUCCESS_SAQUE,
  ERROR_SAQUE,
  RECEIVE_SAQUES_ANTERIORES,
  RECEIVE_INFO_SAQUE
} from "../../../actions/ActionTypes.js";

const initialState = {
  isFetching: false,
  aviso: null,
  info: {
    hasInfo: false,
    saldo: 0,
    saqueDisp: 0,
    saqueDispBloqueado: 0,
    percentualSaqueBloqueado: 0,
    saldoDisponivelParaSaque: 0,
    saldoBonus: 0
  },
  saques: []
};

export default function apostasReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_SAQUES:
      return initialState;

    case REQUEST_SAQUE:
      return {
        ...state,
        isFetching: true,
        aviso: null
      };

    case RECEIVE_SAQUE:
      return {
        ...state,
        isFetching: false
      };

    case SUCCESS_SAQUE:
      return {
        ...state,
        aviso: { type: "success", text: action.success }
      };

    case ERROR_SAQUE:
      return {
        ...state,
        aviso: { type: "error", text: action.error }
      };

    case RECEIVE_SAQUES_ANTERIORES:
      return {
        ...state,
        saques: action.saques
      };

    case RECEIVE_INFO_SAQUE:
      return {
        ...state,
        info: {
          hasInfo: action.hasInfo,
          saldo: action.saldo,
          saqueDisp: action.saqueDisp,
          saqueDispBloqueado: action.saqueDispBloqueado,
          percentualSaqueBloqueado: action.percentualSaqueBloqueado,
          saldoDisponivelParaSaque:
            action.saqueDispBloqueado + action.saqueDisp,
          saldoBonus: action.saldoBonus || 0
        }
      };

    default:
      return state;
  }
}
